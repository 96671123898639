import moment from 'moment'
import React, { Component } from 'react'
import { Button } from 'react-bootstrap'
import CallbackRequest from './CallbackRequest'

export default class LiveChatBlock extends Component {
  openLiveChat () {
    if (window.lhnJsSdk) {
      window.lhnJsSdk.openHOC('c')
    }
  }

  render () {
    return (
            <div className={`full-width bg-${this.props.style ? this.props.style : 'dull'} p-40 pb-60 pl-sm-20 pr-sm-20`}>
                <div className="container text-center">
                    <h3 className={`text-500 pb-10 ${this.props.style !== 'light' ? 'text-lightest' : ''}`}>
                        {this.props.title
                          ? this.props.title
                          : "Can't find what you&apos;re looking for?"
                        }
                    </h3>
                    <p className={`lead text-400 pb-20 ${this.props.style !== 'light' ? 'text-lightest' : ''}`}>
                        {this.props.subTitle
                          ? this.props.subTitle
                          : 'Get in touch with us and one of our VoIP experts will be happy to help you.'
                        }
                    </p>
                    <p>
                        {moment().day() === 6 || moment().day() === 0
                        // Weekend, so just show callback form
                          ? <CallbackRequest />
                          :
                        // Not a weekend, double check hours
                          moment().hour() >= 9 && moment().hour() < 18
                            ? <Button variant="light" className="open-live-chat" onClick={() => this.openLiveChat()}>Chat to us</Button>
                            : <CallbackRequest />
                        }
                    </p>
                </div>
            </div>
    )
  }
}
