import moment from 'moment'
import React from 'react'
import { Button, Modal, OverlayTrigger, Popover } from 'react-bootstrap'
import 'react-datetime/css/react-datetime.css'
import { Helmet } from 'react-helmet'
import { RequestAuthorizationCode } from 'react-oauth2-auth-code-flow'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import PocketSectionHelperDialog from '../components/configuration/InstructionHelperDialogs/PocketSectionHelperDialog'
import MyNumbers from '../components/Dashboard/MyNumbers'
import MyTeams from '../components/Dashboard/MyTeams'
import ProfileProgress from '../components/Dashboard/ProfileProgress'
import StatsBlocks from '../components/Dashboard/Stats'
import DisplayLoggedInFooter from '../components/DisplayLoggedInFooter'
import DisplayMainHeader from '../components/DisplayMainHeader'
import LoadingNotification from '../components/LoadingNotification'
import CallRecordingPackages from '../components/purchase/CallRecordingPackages'
import ChoosePayment from '../components/purchase/ChoosePayment'
import PurchasePhones from '../components/purchase/PurchasePhones'
import ScrollToTopOnMount from '../components/ScrollToTopOnMount'
import SvgRender from '../components/SvgRender'
import { fetchHelper } from '../helpers/fetchHelper'
import LocalStorageHelper from '../helpers/LocalStorageHelper'
import OauthHelper from '../helpers/OauthHelper'
import { getEmptyBasket, getQueryStringFromProps } from '../helpers/utils'
import * as fromNumbers from '../store/MyNumbers'
import { getFirstName, handleDeliveryAdd1Change, handleDeliveryAdd2Change, handleDeliveryCountyChange, handleDeliveryNameChange, handleDeliveryNoteChange, handleDeliveryPcodeChange, handleDeliveryTownChange, handleNotificationChange, handleShowPaymentOptionsModal, updateUserFromLocalStorage } from '../store/SBF'

class Dashboard extends React.Component {
  state = {
    showCancelModal: false,
    showCancelCRModal: false,
    showCRPurchased: false,
    showNoAgreementWarning: false,
    hasCallRecordingActivated: true,
    isLoading: true,
    notificationMessage: '',
    syncSettings: undefined,
    displayUnfinishedSignupWarning: false,
    bannerHidden: false,
    updateBillingCode: undefined
  }

  constructor(props) {
    super(props)
  }

  handleCloseNoAgreementWarning() {
    localStorage.setItem('hideBillingWarning', moment())
    window.location.reload()
  }

  handleCloseSignupWarning() {
    this.setState({ displayUnfinishedSignupWarning: false }, () => {
      localStorage.setItem('hideSignupWarning', moment())
    })
  }

  // when user click "dismiss" on the promo banner, set a cookie "hide_banner" to true, and add class "hidden" to hide it.
  handleHideBanner() {
    localStorage.setItem('hide_banner', true)

    this.setState({
      bannerHidden: true
    })
  }

  getRemainingLandlineMessage() {
    const minutesRemaining = Math.ceil(this.state.outboundDetails.landlineSeconds / 60) - Math.ceil(this.state.outboundDetails.landlineUsedSeconds / 60)

    if (minutesRemaining < 0) {
      return 'You are over your landline allowance by ' + minutesRemaining * -1 + ' minutes. Please contact support on 02031891213'
    } else {
      return minutesRemaining + ''
    }
  }

  getRemainingMobileMessage() {
    const minutesRemaining = Math.ceil(this.state.outboundDetails.mobileSeconds / 60) - Math.ceil(this.state.outboundDetails.mobileUsedSeconds / 60)

    if (minutesRemaining < 0) {
      return 'You are over your mobile allowance by ' + minutesRemaining * -1 + ' minutes. Please contact support on 02031891213'
    } else {
      return minutesRemaining + ''
    }
  }

  renderNoBillingPopover() {
    return (
      <Popover>
        <p className="text-500">Your billing details are missing.</p>
        <p>You need to add a payment method before you can add any new products to your account.</p>
        <p>Without active billing details, your SwitchboardFREE account and service might be restricted to some degree.</p>
      </Popover>
    )
  }

  render() {
    const basket = getEmptyBasket()
    if (LocalStorageHelper.getItem('BasketHardware')?.value !== null) {
      basket.hardware = LocalStorageHelper.getItem('BasketHardware').value
    }

    const warningShownDate = localStorage.getItem('hideBillingWarning')
    let warningDuration = 0
    if (warningShownDate) { warningDuration = moment.duration(moment().diff(warningShownDate)).seconds() }

    if (!this.state.showNoAgreementWarning && this.props.services.displayBillingWarning === true && (warningDuration >= 86400 || warningDuration === 0)) {
      this.setState({ showNoAgreementWarning: true })
    }

    const signupWarningShown = localStorage.getItem('hideSignupWarning')
    let signupWarningDuration = 0
    if (signupWarningShown) { signupWarningDuration = moment.duration(moment().diff(signupWarningShown)).seconds() }

    if (!this.state.displayUnfinishedSignupWarning && this.props.services.displayUnfinishedSignupWarning === true && (signupWarningDuration >= 86400 || signupWarningDuration === 0)) {
      this.setState({ displayUnfinishedSignupWarning: true })
    }

    return <><ScrollToTopOnMount /><DisplayLoggedInFooter /><DisplayMainHeader />
      <Helmet>
        <body className={`logged-in  my-numbers body-dashboard ${this.state.sidebarOpen ? 'sidebar-open' : ''}`} />
        <title>Dashboard - SwitchboardFREE</title>
      </Helmet>

      {this.state.isLoading
        ? <div className="mt-50">
          <LoadingNotification loadingText={'Loading...'} isLoading={this.state.isLoading} textColor="text-dark"
            width={50} height={50} />
        </div>
        : <ProfileProgress firstName={this.props.userFirstName} profile={this.state.profile} />
      }


      <div className="container dashboard-container">
        {this.props.services.displayBillingWarning === true
          ? <>
            <div className="alert-container text-danger text-start mt-30 mb-0">
              <div className="bg-light box-shadow shadow-lighter p-20 brad-20">
                <p className="p-0 m-0">
                  <span className="icon icon-notification mr-5"></span>
                  <span className="text-500 mr-10" dange>You have no billing details</span>
                  <em className="text-dark">To ensure your service continues as normal, <span onClick={e => this.handleCreateNewAgreementClicked()} className="underline cursor-pointer">please update your billing details now</span>.</em>

                  <ChoosePayment
                    total={0}
                    clientId={this.props.clientId}
                    purchaseData={this.state.key}
                    email={this.props.userDetails.email}
                    name={`${this.props.userDetails.fName} ${this.props.userDetails.sName}`}
                    mandate={true}
                    returnRoute={'/customer/dashboard'}
                    newAgreement={this.state.newAgreement}
                  />
                </p>
              </div>
            </div>
          </>
          : this.state.notificationMessage && this.state.notificationMessage.length > 0
            ? <div className="alert-container text-danger text-start mt-30 mb-0">
              <div className="bg-light box-shadow shadow-lighter p-20 brad-20">
                <p className="p-0 m-0">
                  <span className="icon icon-notification mr-5"></span>
                  <span className="text-500">Action Required:</span> <em className="text-dark">{this.state.notificationMessage}</em>
                  {this.state.notificationUrl && this.state.notificationUrl.length > 0 &&
                    <Link to={this.state.notificationUrl} className="underline pl-10">{this.state.notificationUrlTitle}.</Link>
                  }
                </p>
              </div>
            </div>
            : <></>
        }

        {this.props.services.displayOutstandingInvoiceWarning &&
          <div className="alert-container text-danger text-start mt-30 mb-0">
            <div className="bg-light box-shadow shadow-lighter p-20 brad-20">
              <p className="p-0 m-0">
                <span className="icon icon-notification mr-5"></span>
                <span className="text-500" dange>Action Required:</span>
                <em className="text-dark">
                  You have outstanding invoices. <Link to={'/UpdateBillingDetails/' + this.state.updateBillingCode} className="underline">View &amp; pay invoices.</Link>
                </em>
              </p>
            </div>
          </div>
        }

        {!this.props.services.hasEmergencyContacts &&
          <div className="alert-container text-danger text-start mt-30 mb-0">
            <div className="bg-light box-shadow shadow-lighter p-20 brad-20">
              <p className="p-0 m-0">
                <span className="icon icon-notification mr-5"></span>
                <span className="text-500" dange>Action Required:</span> <em className="text-dark">We need your Emergency Contact Details. <Link to="/customer/profile/emergency-contacts" className="underline">Please update them here.</Link></em>
              </p>
            </div>
          </div>
        }

        {/* } Blocks { */}
        <div className="row row-flex flex-4-col primary-blocks mt-50">
          <div className="col-md-3 bg-primary bg-gradient box-shadow shadow-lighter brad-20 pt-30  mb-xxs-30 pt-xs-50">
            <div className="p-30 pl-sm-0 pr-sm-0 pl-xs-10 pr-xs-10 pt-xs-0 text-center pb-xs-0">
              <SvgRender icon="make-receive-calls" svgWidth={65} fillColor2="#ffffff" secondaryColor2="#ffd581" />
              <h2>My calls</h2>
              <p className="text-400">Your call history</p>
              <div className="button-set">
                <Link to="/customer/calls" className="btn btn-lightest btn-sm brad-20 text-dark">
                  View my calls
                </Link>
              </div>
            </div>
          </div>

          <div className="col-md-3 bg-primary bg-gradient box-shadow shadow-lighter brad-20 pt-30  mb-xxs-30 pt-xs-50">
            <div className="p-30 pl-sm-0 pr-sm-0 pl-xs-10 pr-xs-10 pt-xs-0 text-center pb-xs-0">
              <SvgRender icon="my-numbers" svgWidth={50} fillColor="#ffd581" secondaryColor="#fff" />
              <h2>My numbers</h2>
              <p className="text-400">Manage &amp; add numbers</p>
              <div className="button-set">
                <Link to="/customer/numbers" className="btn btn-outline-light btn-sm brad-20">
                  Manage
                </Link>

                {this.props.services.displayBillingWarning
                  ? <OverlayTrigger id="te" trigger={['hover', 'focus']} placement="top" overlay={this.renderNoBillingPopover()}>
                    <Link to="/customer/numbers/new" className="btn btn-lightest btn-sm brad-20 text-dark" disabled>
                      Add new <span className="d-none d-sm-inline-block">number</span>
                    </Link>
                  </OverlayTrigger>
                  : <Link to="/customer/numbers/new" className="btn btn-lightest btn-sm brad-20 text-dark">
                    Add new <span className="d-none d-sm-inline-block">number</span>
                  </Link>
                }

                {/* }
                                <Button variant="outline-light" size="sm" className="brad-20" onClick={() => this.setState({ showMyNumbers: true, showMyTeams: false, showMyUsers: false, sidebarOpen: true })}>Manage </Button>
                                <Button variant="lightest" size="sm" className="brad-20 text-primary" onClick={() => this.setState({ showMyNumbers: true, sidebarOpen: true })}><i className="icon icon-plus3 mr-5"></i> Add new <span className="d-none d-sm-block">number</span></Button>
                                { */}
              </div>
            </div>
          </div>

          <div className="col-md-3 bg-primary bg-gradient box-shadow shadow-lighter brad-20 pt-30 mb-xxs-30 pt-xs-50">
            <div className="p-30 pl-sm-0 pr-sm-0 pl-xs-10 pr-xs-10 pt-xs-0 text-center pb-xs-0">
              <SvgRender icon="my-users" svgWidth={65} fillColour="#fff" />
              <h2>My users</h2>
              <p className="text-400 text-lightest">Manage &amp; add users</p>
              <div className="button-set">
                <Link to="/customer/outbound-management" className="btn btn-outline-light btn-sm brad-20">
                  Manage
                </Link>

                {this.props.services.displayBillingWarning
                  ? <OverlayTrigger id="te" trigger={['hover', 'focus']} placement="top" overlay={this.renderNoBillingPopover()}>
                    <Link to="/customer/outbound-management/new" className="btn btn-lightest btn-sm brad-20 text-dark" disabled>
                      Add new <span className="d-none d-sm-inline-block">user</span>
                    </Link>
                  </OverlayTrigger>
                  : <Link to="/customer/outbound-management/new" className="btn btn-lightest btn-sm brad-20 text-dark">
                    Add new <span className="d-none d-sm-inline-block">user</span>
                  </Link>
                }
                {/* }
                                <Button variant="outline-light" size="sm" className="brad-20" onClick={() => this.setState({ showMyUsers: true, showMyNumbers: false, showMyTeams: false, sidebarOpen: true })}>Manage</Button>
                                <Button variant="lightest" size="sm" className="brad-20 text-cta"><i className="icon icon-plus3 mr-5"></i> Add new <span className="d-none d-sm-block">user</span></Button>
                            { */}
              </div>
            </div>
          </div>

          <div className="col-md-3 bg-primary bg-gradient box-shadow shadow-lighter brad-20 pt-30 mb-xxs-30 pt-xs-50">
            <div className="p-30 pl-sm-0 pr-sm-0 pl-xs-10 pr-xs-10 pt-xs-0 text-center pb-xs-0">
              <SvgRender icon="team-users" svgWidth={65} />
              <h2>My teams</h2>
              <p className="text-400">Manage &amp; add teams</p>
              <div className="button-set">
                <Link to="/customer/team-management" className="btn btn-outline-light btn-sm brad-20">
                  Manage
                </Link>
                {this.props.services.displayBillingWarning
                  ? <OverlayTrigger id="te" trigger={['hover', 'focus']} placement="top" overlay={this.renderNoBillingPopover()}>
                    <Link to="/customer/team-management/new" className="btn btn-lightest btn-sm brad-20 text-dark" disabled>
                      Add new <span className="d-none d-sm-inline-block">team</span>
                    </Link>
                  </OverlayTrigger>
                  : <Link to="/customer/team-management/new" className="btn btn-lightest btn-sm brad-20 text-dark">
                    Add new <span className="d-none d-sm-inline-block">team</span>
                  </Link>
                }
                {/* }
                                <Button variant="outline-light" size="sm" className="brad-20" onClick={() => this.setState({ showMyTeams: true, showMyNumbers: false, showMyUsers: false, sidebarOpen: true })}>Manage</Button>
                                <Button variant="lightest" size="sm" className="brad-20 text-dark"><i className="icon icon-plus3 mr-5"></i> Add new <span className="d-none d-sm-block">team</span></Button>
                            { */}
              </div>
            </div>
          </div>
        </div>

        {/* } Call stats { */}
        <div className="stats-container mt-50 mb-50">
          <StatsBlocks history={this.props.history} />
        </div>

      </div>

      <div className="full-width pt-50 pb-50">
        <div className="container">
          <div className="row">
            <div className='col-xs-12 text-center'>
              <h2 className="text-500 text-xl">Purchase a new VoIP Phone</h2>
              <h2 className="text-dullest text-lg text-400 mb-30">
                Choose from our range of plug-and-play, pre-configured, VoIP Phones.
              </h2>
            </div>

          </div>
          <PurchasePhones
            displayInline={true}
            handleUpdateHardware={(hardware) => handleHandsetUpdated(hardware)}
            currentBasket={basket}
            showEditDevices={false}
            handleCloseDevicesModal={() => setState({
              ...state,
              showEditDevices: false
            })}
            isSignup={false}
            hideFree={true}
            purchaseUrl="/api/Purchase/PurchasePhones"
            notSlider={true}
            CustomDivClass="w-16 text-center p-10"
            displayInstalments={true}

          />

        </div>
      </div>

      <aside className={`sidebar bl-1 ${this.state.sidebarOpen ? 'open' : ''}`}>

        {this.state.showMyNumbers === true && !this.state.showMyTeams && !this.state.showMyUsers &&
          <>
            <div className="col-xs-12 bb-2 mb-20 pb-20">
              <div className="row">
                <div className="col-xs-6 pr-0">
                  <Link to={''} className="btn btn-outline-primary btn-block btn-sm brad-10">+ Import number</Link>
                </div>
                <div className="col-xs-6 pl-10">
                  <Link to={''} className="btn btn-primary btn-block btn-sm brad-10">+ Add new number</Link>
                </div>
              </div>
            </div>
            <MyNumbers clientId={this.props.clientId} services={this.props.services} />
          </>
        }

        {this.state.showMyTeams === true && !this.state.showMyUsers && !this.state.showMyNumbers &&
          <MyTeams clientId={this.props.clientId} />
        }

      </aside>

      <Modal show={this.state.showCancelModal} onHide={e => this.setState({ showCancelModal: false })} size="sm">
        <Modal.Body>
          <div className="icon-wrapper text-start text-danger text-xxl">
            <span className="icon icon-warning"></span>
          </div>

          <h4 className="text-danger mt-0 mb-10">Your account setup was unsuccessful </h4>
          <p>Please revisit the secure WorldPay website to add your card details.</p>
          <p className="text-start mt-20 mb-20">
            <Button variant="cta" onClick={e => this.handleCreateNewAgreementClicked()}>Add Card Details</Button>
          </p>
          <p>Unless you have an existing WorldPay account please ignore the username and password section on the WorldPay site.</p>
          <p>
            <strong>Need help?</strong><br />
            Call us on <a href="tel:02031891213">0203 189 1213</a>, <a href="#" className="open-live-chat">chat to us</a> or <a href="mailto:support@switchboardfree.co.uk?subject=Adding%20Payment%20Details">send us an email</a>.
          </p>
          <ChoosePayment
            total={0}
            clientId={this.props.clientId}
            purchaseData={this.state.key}
            email={this.props.userDetails.email}
            name={`${this.props.userDetails.fName} ${this.props.userDetails.sName}`}
            // mandate={true}
            returnRoute={localStorage.getItem('Basket') ? '/signup/checkout/complete' : '/customer/profile/billing'}
            newAgreement={this.state.newAgreement}
          />
        </Modal.Body>
        <Modal.Footer>
          <div className="text-start mb-20">
            <img src={'/images/credit-cards/stripe-badge-transparent.png'} width="320" className="worldPay"
              alt="Payments powered by Stripe" />
          </div>
        </Modal.Footer>
      </Modal>

      <Modal show={this.state.showCancelCRModal} onHide={() => this.setState({ showCancelCRModal: false })} className="w-900px" >
        <Modal.Header closeButton>
          <Modal.Title>Your payment was cancelled</Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-0">
          <CallRecordingPackages firstName={this.props.userFirstName} purchaseCancelled={true} userServices={this.props.services} className="p-20" />
        </Modal.Body>
      </Modal>

      <Modal className="w-900px" show={this.props.callRecordingDialogDisplayed} onHide={this.props.handleDismissCallRecordingDialog}>
        <Modal.Body className="text-start p-0">
          <CallRecordingPackages userServices={this.props.services} className="p-20" />
        </Modal.Body>
        <Modal.Footer>
          <div className="col-md-12">
            <Button variant="default" size="xs" onClick={() => this.props.handleDismissCallRecordingDialog()}>Close</Button>
          </div>
        </Modal.Footer>
      </Modal>

      <Modal show={this.state.showCRPurchased} onHide={() => this.setState({ showCRPurchased: false })}>
        <Modal.Header closeButton>
          <Modal.Title>
            Call recording has been added
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-0">
          <div className="full-width full-secondary bg-overlay text-lightest pt-30 pb-40" style={{ backgroundImage: "url('/images/backgrounds/call-recording.jpg')", backgroundPosition: 'right' }}>
            <div className="container text-start container-sm" style={{ width: '100%' }}>
              <h2 className="text-500">{!this.props.userFirstName || this.props.userFirstName.toLowerCase() === 'null' ? 'Congratulations' : `Congratulations, ${this.props.userFirstName}`}  </h2>
              <p className="text-md mb-50">
                Call recording has now been enabled on your account.
              </p>
              <p>
                You can now turn call recording on, or off, for your numbers via the "Numbers page" using the toggle switch.<br />
                Alternatively, you can enable Call Recording per number and/or department via the number settings page.
              </p>
              <p>
                You can access your recorded calls to download, listen or delete recordings via your <a href="customer/call-recording/">call recording page</a>.
              </p>
              <p className="mt-30 mb-30">
                <Button variant="cta" onClick={() => this.setState({ showCRPurchased: false })}>View your numbers</Button>
              </p>
              <p>Should you need any help, or have any questions, related to call recording feel free to <a href="/contact">get in touch with us</a>.</p>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal show={this.state.showNoAgreementWarning && !this.state.showCancelModal && !this.state.showCancelCRModal && !this.state.displayUnfinishedSignupWarning} onHide={() => this.handleCloseNoAgreementWarning(this)} size="sm">
        <Modal.Header closeButton>
          <Modal.Title>We still need your payment details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Unfortunately, it looks as though you have added some products to your account that require a billing agreement to be setup.</p>
          <p>To keep these products on your account please add your billing details via <Link to="/customer/profile/billing">your profile page</Link></p>
          <p className="text-center mt-20">
            <Link to="/customer/profile/billing" className="btn btn-cta btn-md">Add Billing Details</Link>
          </p>
        </Modal.Body>
        <Modal.Footer>
          <p className="text-sm text-center">Alternatively, you can get in touch with us on <br className="d-none d-md-block" /><a href="tel:02031891213" className="text-cta underline">0203 189 1213</a> or <a className="text-cta underline" href="javascript:window.lhnJsSdk.openHOC('c');">live chat</a></p>
        </Modal.Footer>
      </Modal>

      {(this.props.services.signupSummary && this.props.services.displayUnfinishedSignupWarning) &&
        <Modal show={this.state.displayUnfinishedSignupWarning} backdrop="static" size="sm">
          <Modal.Header>
            <Modal.Title className="mx-auto text-lg">Continue where you left off</Modal.Title>
          </Modal.Header>
          <Modal.Body className="text-center">
            <p className="text-md text-400">Looks like you abandoned your previous checkout!</p>
            <p>Do you want to continue with your <span className="text-500">{this.props.services.signupSummary.plan} plan</span>?</p>
            <p><span className="text-500">Summary:</span><br />
              Numbers:<br />
              {this.props.services.signupSummary.numbers.map(number => <span>{number}<br /></span>)}
              <span className="text-500">Monthly Total: &pound;{this.props.services.signupSummary.total}</span>
            </p>
            <Link to={`/get-started/${this.props.services.signupSummary.signupKey}`} className="btn btn-cta mt-20">Continue Checkout</Link>
            <Button variant="link" size="sm" className="mt-15 underline" onClick={() => this.handleCloseSignupWarning(this)}>Cancel checkout</Button>
          </Modal.Body>
          <Modal.Footer>
            <p className="text-sm text-center">Alternatively, you can get in touch with us on <br className="d-none d-md-block" /><a href="tel:02031891213" className="text-cta underline">0203 189 1213</a> or <a className="text-cta underline" href="javascript:window.lhnJsSdk.openHOC('c');">live chat</a></p>
          </Modal.Footer>
        </Modal>
      }

      {this.state.hasCallRecordingActivated === false &&
        <div className="text-start pb-20 pt-20">
          <PocketSectionHelperDialog
            visable={this.props.callAnsweringDialogDisplayed}
            disableActivate={!this.callAnsweringDialogActivate}
            onClose={this.props.handleDismissCallAnsweringDialog}
            liteMode={this.callAnsweringDialogLite}
            onActivate={() => {
              if (this.onActivateClick) { this.onActivateClick() }
            }}
          />

          <a onClick={() => {
            this.callAnsweringDialogLite = false
            this.callAnsweringDialogActivate = false
            this.props.handleShowCallAnsweringDialog()
          }}>
            <img src="images/campaigns/pr-integration-lg1.jpg" className="d-none d-xs-block mt-20" style={{ maxWidth: '100%' }} />
            <img src="images/campaigns/pr-integration-sm1.jpg" className="d-block d-xs-none" style={{ width: '100%', maxWidth: '100%' }} />
          </a>
        </div>
      }

    </>
  }

  handleCreateNewAgreementClicked() {
    // this.props.handleShowPaymentOptionsModal();
    this.setState({ newAgreement: true }, () => {
      this.props.handleShowPaymentOptionsModal()
    })
  }

  moveCallRecording(qlid) {
    fetchHelper.postJson('api/Numbers/MoveCallRecordingNumber/', { Qlid: qlid })
      .then(res => {
        this.props.handleDismissCallRecordingDialog()
        if (res.data) {
          this.props.notify({ message: "We've moved your call recording" })
          this.props.fetchNumbers({ history: this.props.history })
        } else {
          this.props.notify({ message: 'There was a problem moving your call recording', isErrorState: true })
        }
      })
  }

  componentDidUpdate() {
    if (this.props.services.displayOutstandingInvoiceWarning && this.state.updateBillingCode === undefined) {
      fetchHelper.getJson('api/Home/GetUpdateBillingDetailsInfoWithClientId').then(response => {
        if (response.data !== '') {
          this.setState({ updateBillingCode: response.data.code })
        }
      }).catch(error => console.error(error))
    }
  }

  componentDidMount() {
    const bannerHidden = localStorage.getItem('hide_banner')

    if (bannerHidden) {
      this.setState({
        bannerHidden: (bannerHidden === 'true')
      })
    }

    if (localStorage.getItem('isNewCustomer')) {
      localStorage.removeItem('isNewCustomer')
      localStorage.removeItem('basket')
    }

    if (!this.props.userFirstName) {
      const user = JSON.parse(localStorage.getItem('user'))
      if (user && user.fName) {
        this.props.updateUserFromLocalStorage(user)
      }
    }

    const queryString = getQueryStringFromProps(this.props)
    if (queryString) {
      const params = queryString.toLowerCase()
      if (params.includes('new=true')) {
        this.props.notify({ message: 'Thankyou, unlimited mobile redirect are active' })
      } else if (params.includes('newcr=true')) {
        this.setState({
          showCRPurchased: true
        })
      } else if (params.includes('newcred=true')) {
        this.props.notify({ message: 'Thankyou, your credit has been added' })
      } else if (params.includes('newnumber=true')) {
        this.props.notify({ message: 'Thankyou, your new number has been added to your account' })
      } else if (params.includes('newmultinumber=true')) {
        this.props.notify({ message: 'Thankyou, your numbers have been added to your account' })
      } else if (params.includes('cancel=true')) {
        this.setState({
          showCancelModal: true
        })
      } else if (params.includes('cancelcr=true')) {
        this.setState({
          showCancelCRModal: true
        })
      }
    }

    const context = this
    fetchHelper.getJson('api/Profile/getOutboundDetails/')
      .then(res => {
        context.setState({
          outboundDetails: res.data,
          showMyNumbers: true
          // outboundDialRates:res.data
        })
      })

    const url = 'api/Dashboard/GetProfileCompletePercentage'
    fetchHelper.getJson(url)
      .then(res => {
        const data = res.data
        const billingComplete =
          (data.billingName && data.billingName.length > 0) &&
          (data.billingEmail && data.billingEmail.length > 0) &&
          (data.billingPhone && data.billingPhone.length > 0) &&
          (data.billingAgreement && data.billingAgreement.length > 0)

        this.setState({
          isLoading: false,
          notificationMessage: billingComplete !== true ? 'Please add your billing contact details.' : '',
          notificationUrl: '/customer/profile/billing',
          notificationUrlTitle: 'Add details',
          profile: {
            complete: data.percentComplete,
            nameComplete:
              (data.firstName && data.firstName.length > 0) &&
              (data.lastName && data.lastName.length > 0),
            contactComplete:
              (data.mobile && data.mobile.length > 0) &&
              (data.landline && data.landline.length > 0) &&
              (data.email && data.email.length > 0),
            addressComplete:
              (data.address1 && data.address1.length > 0) &&
              (data.postcode && data.postcode.length > 0),
            billingComplete,
            companyComplete:
              (data.companyName && data.companyName.length > 0)
          }
        })
      }).catch(err => console.error(err))
  }

  handleUpdateAddress(address) {
    LocalStorageHelper.setItem('DeliveryAddress', address)

    this.props.handleDeliveryNameChange({ newValue: address.name })
    this.props.handleDeliveryAdd1Change({ newValue: address.add1 })
    this.props.handleDeliveryAdd2Change({ newValue: address.add2 })
    this.props.handleDeliveryCountyChange({ newValue: address.add3 })
    this.props.handleDeliveryTownChange({ newValue: address.add4 })
    this.props.handleDeliveryPcodeChange({ newValue: address.pcode })
  }
}

export default connect(
  state => {
    const deliveryData = state.sbfApp.deliveryData

    return ({
      delivery: {
        name: deliveryData.deliveryName ? deliveryData.deliveryName.value : '',
        add1: deliveryData.deliveryAdd1 ? deliveryData.deliveryAdd1.value : '',
        add2: deliveryData.deliveryAdd2 ? deliveryData.deliveryAdd2.value : '',
        add3: deliveryData.deliveryTown ? deliveryData.deliveryTown.value : '',
        add4: deliveryData.deliveryCounty ? deliveryData.deliveryCounty.value : '',
        pcode: deliveryData.deliveryPcode ? deliveryData.deliveryPcode.value : ''
      },
      clientId: state.sbfApp.authentication.clientId,
      callRecordingDialogDisplayed: state.myNumbers.callRecordingDialogDisplayed,
      callAnsweringDialogDisplayed: state.myNumbers.callAnsweringDialogDisplayed,
      userFirstName: getFirstName(state),
      services: state.sbfApp.enabledServices,
      userDetails: state.sbfApp.authentication
    })
  },
  {
    updateUserFromLocalStorage,
    notify: handleNotificationChange,
    handleShowCallRecordingDialog: fromNumbers.handleShowCallRecordingDialog,
    handleDismissCallRecordingDialog: fromNumbers.handleDismissCallRecordingDialog,
    handleShowCallAnsweringDialog: fromNumbers.handleShowCallAnsweringDialog,
    handleDismissCallAnsweringDialog: fromNumbers.handleDismissCallAnsweringDialog,
    handleShowPaymentOptionsModal,
    handleDeliveryNameChange,
    handleDeliveryAdd1Change,
    handleDeliveryAdd2Change,
    handleDeliveryTownChange,
    handleDeliveryCountyChange,
    handleDeliveryPcodeChange,
    handleDeliveryNoteChange
  }
)(Dashboard)
