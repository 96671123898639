import React from 'react'
import { Button, Modal, Popover } from 'react-bootstrap'
import 'react-datetime/css/react-datetime.css'
import Helmet from 'react-helmet'
import { connect } from 'react-redux'
import AddUsers from '../components/Dashboard/AddUser'
import DisplayLoggedInFooter from '../components/DisplayLoggedInFooter'
import DisplayMainHeader from '../components/DisplayMainHeader'
import HandsetOrders from '../components/leadgen/HandsetOrders'
import ChoosePayment from '../components/purchase/ChoosePayment'
import PurchasePhones from '../components/purchase/PurchasePhones'
import RefreshUserServices from '../components/RefreshUserServices'
import ScrollToTopOnMount from '../components/ScrollToTopOnMount'
import VoipUsers from '../components/VoipUsers/VoipUsers'
import { fetchHelper } from '../helpers/fetchHelper'
import LocalStorageHelper from '../helpers/LocalStorageHelper'
import { getEmptyBasket, getQueryStringFromProps } from '../helpers/utils'
import * as fromNumbers from '../store/MyNumbers'
import { handleNotificationChange, handleShowBillingWarning, handleShowPaymentOptionsModal } from '../store/SBF'

const FreePhoneMoreInfo = (
	<Popover id="popover-basic">
		<p>To get this phone(s) for free, during the last step of the signup process (Checkout page), select the <i>Bi-Annual</i> payment option.</p>
        <p>
            <img src="/images/bi-annual.png" className="w-100" alt="Bi-Annual payment screenshot" />
        </p>
	</Popover>
)

const _ = require('lodash')

class CustomerOutboundSettings extends React.Component {
  constructor (props) {
    super(props)
  }

  state = {
    showHandsetOrder: false,
    showUsersAndTeamsVideo: false
  }

  retrieveEndpoints () {
    const url = '/api/Outbound/RetrieveOutboundEndpoints'
    fetchHelper.getJson(url)
      .then(res => {
        const endpoints = res.data
        this.setState({ endpoints, isLoading: false })
      }).catch(err => console.error(err))
  }

  retrieveOutboundDetails () {
    fetchHelper.getJson('api/Profile/getOutboundDetails/')
      .then(res => {
        this.setState({
          outboundDetails: res.data
        })
      })
  }

  async componentDidMount () {
    this.getHardware()
    this.retrieveEndpoints()
    this.retrieveOutboundDetails()

    const queryString = getQueryStringFromProps(this.props)
    if (queryString) {
      const params = queryString.toLowerCase()
      if (params.includes('newEndpoint=true')) {
        const userToAdd = JSON.parse(localStorage.getItem('userToCreate'))
        this.handleAddNewUser(userToAdd)
      }

      if (params.includes('newOutbound=true')) {
        this.props.notify({ message: 'Your outbound package has been added to your account' })
      }
    }

    if (this.props.match.params.createuser) { this.handleAddUser() }
  }

  render () {
    const basket = getEmptyBasket()
    if (LocalStorageHelper.getItem('BasketHardware')?.value !== null) {
      basket.hardware = LocalStorageHelper.getItem('BasketHardware').value
    }

    function addNewUser (context) {
      context.handleAddUser()
    }

    return <><ScrollToTopOnMount /><DisplayLoggedInFooter /><DisplayMainHeader /><RefreshUserServices />
            <Helmet>
                <title>User Management</title>
                <meta name="description" content="Outbound call settings" />
            </Helmet>
            <HandsetOrders showModal={this.state.showHandsetOrder} onHide={() => this.setState({ showHandsetOrder: false })} model={this.state.model} modelId={this.state.modelId} />

            <header className="full-width my-calls-header">
                <h1>User Management</h1>

                {!_.isEmpty(this.state.outboundDetails)
                  ? <h2 className="subtitle pb-30">Manage &amp; Add users so they can make and receive calls <br className="d-none d-md-block"/>presenting your SwitchboardFREE phone number. <span className='cursor-pointer underline' onClick={() => this.setState({ showUsersAndTeamsVideo: true })}>See video</span></h2>
                  : <h2 className="subtitle pb-30">
                        Make outbound calls with our powerful cloud-based VoIP phone system FROM JUST £4.99 a month! <span className='cursor-pointer underline' onClick={() => this.setState({ showUsersAndTeamsVideo: true })}>See video</span>
                    </h2>
                }

            </header>

            <div className="container pt-30">
                <div className="my-numbers outbound-users center-block mt-0 pt-0 mb-30 brad-30 p-40 pl-20 pr-20">
                    <div className="text-center pb-30">
                        {!_.isEmpty(this.state.outboundDetails)
                          ? <></>
                          : <>

                                <p className="pt-40 pb-60">
                                    Present any of your virtual telephone numbers as your outbound number and make and receive phone calls from anywhere in the world, <br className="d-none d-lg-block" />
                                        at UK call rates. You can have as many users or extensions as you wish, and you can transfer between users in any <br className="d-none d-lg-block" />
                                        location worldwide for free. Use our fully featured mobile phone app, softphone or one of our professional, plug <br className="d-none d-lg-block" />
                                        and play desktop phones for complete freedom. There’s no long-term contracts and you’ll be supported by <br className="d-none d-lg-block" />
                                        our 5-star, UK customer service team.
                                   </p>
                                <div className="row">
                                    <div className="col-lg-8 mx-auto">
                                        <div className="row">
                                            <div className="col-md-6 text-start">
                                                <ul className="styled-list cta rounded text-400 pr-30 mb-30 mb-sm-0 ml-0 pl-0">
                                                    <li className="pb-xs-10 pt-xs-10 brad-5">
                                                        Present any of your virtual telephone numbers as your outbound number
                                                        </li>
                                                    <li className="pb-xs-10 pt-xs-10 brad-5">
                                                        Have as many users (extensions) as you wish
                                                        </li>
                                                    <li className="pb-xs-10 pt-xs-10 brad-5">
                                                        Professional, HD call clarity.
                                                        </li>
                                                    <li className="pb-xs-10 pt-xs-10 brad-5">
                                                        Easy to use mobile phone app, ‘plug and play’ desktop phone, or softphone
                                                        </li>
                                                </ul>
                                            </div>
                                            <div className="col-md-6 text-start">
                                                <ul className="styled-list cta rounded text-400 pr-30 mb-30 ml-0 pl-0">
                                                    <li className="pb-xs-10 pt-xs-10 brad-5">
                                                        Make and receive phone calls from anywhere in the world, at UK call rates
                                                        </li>
                                                    <li className="pb-xs-10 pt-xs-10 brad-5">
                                                        Over 40 call management features
                                                        </li>
                                                    <li className="pb-xs-10 pt-xs-10 brad-5">
                                                        Free transfer between users in any location worldwide
                                                        </li>

                                                    <li className="pb-xs-10 pt-xs-10 brad-5">
                                                        Fantastic UK based support team, and more
                                                        </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </>

                        }

                    </div>
                    <div className="row">
                        <div className={_.isEmpty(this.state.outboundDetails)
                          ? 'w-600px center-block brad-50 box-shadow bg-dull'
                          : 'col-lg-10 mx-auto bg-dull'
                                }
                            >
                        </div>
                    </div>

                    <div className="row">
                        <VoipUsers userCount={this.state.endpoints ? this.state.endpoints.length : 0} basket={basket} />

                        {this.state.endpoints && this.state.endpoints.length > 0
                          ? <div className="pb-30 text-center pt-30 pb-30">
                                <Button variant="cta" size="lg" onClick={() => this.handleAddUser()}>Add another user</Button>
                            </div>
                          : <>
                                {this.props.services.isInOutboundBeta &&
                                    <div className="col-xs-12 text-center">
                                        <div className="bg bg-dull p-30">
                                            <h4 className="mb-30 text-500">You've not setup any users yet</h4>
                                            <p>
                                                <Button variant="cta" size="lg" onClick={() => this.handleAddUser()}>Add a new user</Button>
                                            </p>
                                        </div>
                                    </div>
                                }
                            </>

                        }

                    </div>

                </div>
            </div>

            <div className="full-width bg-dull">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 mx-auto pt-30 mb-100">
                            <div className="row">
                                <div className="col-md-12 text-center pt-30">
                                    <h3 className="text-500">Get Your HD VoIP Handset</h3>
                                    <h5 className="text-500 pt-10 pb-30">
                                        HASSLE-FREE Installation <span className="text-cta">•</span> FREE UK Support <span className="text-cta">•</span> UNRIVALLED Quality
                                    </h5>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12 mx-auto">
                                    <PurchasePhones
                                        displayInline={true}
                                        handleUpdateHardware={(hardware) => handleHandsetUpdated(hardware)}
                                        currentBasket={basket}
                                        showEditDevices={false}
                                        handleCloseDevicesModal={() => setState({
                                          ...state,
                                          showEditDevices: false
                                        })}
                                        isSignup={false}
                                        hideFree={true}
                                        displayInstalments={true}
                                        purchaseUrl="/api/Purchase/PurchasePhones"
                                        returnRoute="customer/outbound-management?success=true"
                                        notSlider={true}
							            CustomDivClass="w-16 text-center p-10"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Modal size="lg" backdrop="static" show={this.state.createEndpoint} onHide={() => this.closeCreateEndpoint()}>
                <Modal.Header closeButton>
                    <Modal.Title>Add a new user</Modal.Title>
                </Modal.Header>
                <Modal.Body className="p-40">
                    <AddUsers isPurchasing={e => this.setState({ isPurchasing: e })} closeModal={() => this.closeCreateEndpoint()} reloadEndpoints={() => this.retrieveEndpoints()} reloadDetails={() => this.retrieveOutboundDetails()} />
                </Modal.Body>
            </Modal>

            <Modal className='w-900px' show={this.state.showUsersAndTeamsVideo} onHide={e => this.setState({ showUsersAndTeamsVideo: false })}>
                <Modal.Header closeButton className='bb-0' />
                <Modal.Body className="text-center">
                <div className="ratio ratio-16x9 pt-20">
                    <iframe src="https://www.youtube.com/embed/cykYlQGGbmE" frameBorder="0" allow="autoplay; encrypted-media" allowfullscreen></iframe>
                </div>
                </Modal.Body>
            </Modal>

            <ChoosePayment
                total={this.state.wpAmount}
                clientId={this.props.clientId}
                purchaseData={this.state.key}
                returnRoute="/customer/outbound-management"
                email={this.props.userDetails.email}
                name={`${this.props.userDetails.fName} ${this.props.userDetails.sName}`}
                mandate={!this.props.services.hasAgreement}
            />

        </>
  }

  handleAddUser () {
    if (this.props.services.displayBillingWarning === true) {
      this.props.handleShowBillingWarning()
    } else {
      this.setState({ createEndpoint: true })
    }
  }

  closeCreateEndpoint () {
    this.setState({
      createEndpoint: false,
      isPurchasing: false
    })
  }

  showUsersVideo () {
    this.setState({
      showUsersVideo: false
    })
  }

  getHardware () {
    const url = 'api/Purchase/GetHardware'
    fetchHelper.getJson(url)
      .then(res => {
        if (res.data) {
          this.setState({ products: res.data })
        }
      }).catch(err => console.error(err))
  }

  handleAddHandset (prodId) {
    const product = this.state.products.filter(f => f.prodId === prodId)
    const tempData = [{
      qty: 1,
      ...product[0]
    }]

    localStorage.setItem('HardwareFirst', JSON.stringify(tempData))

    this.props.history.push('/get-started')
  }
}

export default connect(
  state => {
    return {
      services: state.sbfApp.enabledServices,
      productDetails: state.home.productDetails.products,
      callRecordingDialogDisplayed: state.myNumbers.callRecordingDialogDisplayed,
      clientId: state.sbfApp.authentication.clientId,
      userDetails: state.sbfApp.authentication
    }
  },
  {
    notify: handleNotificationChange,
    handleDismissCallRecordingDialog: fromNumbers.handleDismissCallRecordingDialog,
    handleShowCallRecordingDialog: fromNumbers.handleShowCallRecordingDialog,
    handleShowPaymentOptionsModal,
    handleShowBillingWarning
  }
)(CustomerOutboundSettings)
