import * as React from 'react'
import { useEffect, useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { Helmet } from 'react-helmet'
import { type RouteComponentProps, type RouteProps } from 'react-router-dom'
import DisplayNoFooter from '../../components/DisplayNoFooter'
import DisplaySimpleHeaderV2 from '../../components/DisplaySimpleHeaderV2'
import ScrollToTopOnMount from '../../components/ScrollToTopOnMount'
import Toasty from '../../components/Toasty'
import {
	AppOnlyPrice,
	AppOnlyPrice0800, billingPeriod, MultiNumberPrice, NumbersGlobalOfferPrice, SignupStart,
	SignupStep, UnlimitedOutboundPlan, validationStates, type LoginDetails
} from '../../constants/Constants'
import { fetchHelper } from '../../helpers/fetchHelper'
import LocalStorageHelper from '../../helpers/LocalStorageHelper'
import { arraySum, getEmptyBasket, getFirstNameFromName, getLastNameFromName, getSignupKey, simplePhoneNumberFormat } from '../../helpers/utils'
import {
	type AddressDto, type NumberType
} from '../../models/GenericTypes'
import {
	isHardware, isSbfNumber, SbfPackage,
	SupportedCallDirection, type Basket,
	type BasketItem, type EditBasketParams, type Hardware, type MarketingDataDto, type SbfNumber,
	type SignupProfile,
	type SignupProgressDto
} from '../../models/SignupTypes'
import { login } from '../../services/UserService'
import ChoosePlan from './ChoosePlan'
import GetNumber from './GetNumber'
import SignupCheckout from './SignupCheckout'
import SignupPage from './SignupPage'
import SignupType from './SignupType'

interface SignupState {
	isQuote: boolean
	beganAt: SignupStart
	currentBasket: Basket
	signupStep: SignupStep
	numberType: NumberType
	numberOnly: boolean
	numberFull: boolean
	firstName: string
	lastName: string
	email: string
	phone: string
	company: string
	topupCredit: number
	callRecording: boolean
	transcription: boolean
	isLoggedIn: boolean
	billingPeriod: billingPeriod
	billingAddress: AddressDto
	deliveryAddress: AddressDto
	isNewCustomer: boolean
	callBlocking: boolean
	isPorting: boolean
	marketingData?: MarketingDataDto
}

interface NonPersistState {
	products?: Hardware[]
	notifyMessage?: string | undefined
	removeFromBasket?: boolean | undefined
	customerHasPlan?: boolean
	billingDay?: number
	showContinueWarning?: boolean
	isInternal?: boolean
}

const Signup: React.FC<RouteComponentProps & RouteProps> = (props) => {
	function persistState(signupState: SignupState, delay = 1500) {
		// save the updated state whenever there's a change. Don't update anymore frequently
		// than the value set in delay so to avoid spamming backend

		useEffect(
			() => {
				const handler = setTimeout(() => {
					const api = `api/Users/SignupProgress?signupKey=${getSignupKey()}`
					const data: SignupProgressDto = {
						state: JSON.stringify(signupState),
						clientId: LocalStorageHelper.getItem<number>('clientId')?.value ?? 0
					}
					fetchHelper.postJson<any, SignupProgressDto>(api, data)
				}, delay)
				return () => {
					clearTimeout(handler)
				}
			},
			[signupState, delay]
		)
	}

	function restoreLandingPageSelectionsFromLocalStorage() {
		let loggedIn = false
		const userDetails = LocalStorageHelper.getItem<SignupProfile>('userDetails')?.value
		const inboundOnly = LocalStorageHelper.getItem<boolean>('inboundOnly')?.value ?? false
		const user = localStorage.getItem('user')
		if (user) {
			const parsedUser = JSON.parse(user)
			if (Number.parseInt(parsedUser?.clientId) > 0) {
				loggedIn = true
				LocalStorageHelper.setItem<number>('clientId', parsedUser?.clientId)
			}
		}

		if (LocalStorageHelper.hasItem('landingPage')) {
			if (userDetails !== null) {
				const tempBasket = LocalStorageHelper.getItem<Basket>('basket').value!

				setSignupState({
					...signupState,
					currentBasket: tempBasket,
					signupStep: (tempBasket.package !== null && tempBasket.package !== undefined) ? SignupStep.IsCheckingOut : SignupStep.IsSelectingPlan,
					beganAt: SignupStart.NumberTypeLandingPage,
					firstName: getFirstNameFromName(userDetails.name),
					lastName: getLastNameFromName(userDetails.name),
					email: userDetails.email ?? '',
					phone: userDetails.phone ?? '',
					company: userDetails.company ?? ''
				})
			} else {
				const currentBasket = signupState.currentBasket
				setSignupState({
					...signupState,
					signupStep: SignupStep.IsSelectingNumberType,
					currentBasket: {
						...currentBasket,
						isOutbound: (!inboundOnly && !loggedIn)
					}
				})
			}
		} else {
			// check to see if the user has chosen numbers from the landing pages
			// if so add those numbers to the basket and then remove them from the local storage
			const landingPageNumberStoredSelection = LocalStorageHelper.getItem<SbfNumber[]>('LandingPageNumberSelection')
			if (landingPageNumberStoredSelection.value !== null) {
				// Start over!
				LocalStorageHelper.removeItem('signupKey')
				emptyBasket.numbers = landingPageNumberStoredSelection.value

				// the purchase came from a number type landing page
				// set the signupStart enum flag to indicate that the user has come from the landing page
				let prefix = '01'
				const firstNumber = emptyBasket.numbers.length > 0 ? emptyBasket.numbers[0].number : ''

				if (firstNumber.startsWith('08') || firstNumber.startsWith('020')) {
					prefix = firstNumber.substring(0, 4)
				} else {
					prefix = firstNumber.substring(0, 2)
				}

				setSignupState({
					...signupState,
					beganAt: SignupStart.NumberTypeLandingPage,
					numberType: prefix,
					signupStep: loggedIn ? SignupStep.IsSelectingPackage : SignupStep.IsSelectingPackage
				})

				// Numbers and other settings will now be auto persisted in the backend so we don&apos;t need them in local storage anymore
				landingPageNumberStoredSelection.onNotRequiredAnymore(true)
			} else if (LocalStorageHelper.getItem<SbfNumber[]>('LoggedInNumberSelection')?.value !== null) {
				setSignupState({
					...signupState,
					currentBasket: {
						...signupState.currentBasket,
						numbers: LocalStorageHelper.getItem<SbfNumber[]>('LoggedInNumberSelection').value!,
						isOutbound: false
					},
					signupStep: SignupStep.IsCheckingOut,
					numberFull: false,
					numberOnly: true,
					isLoggedIn: true,
					isNewCustomer: false
				})
			}
		}

		if (!LocalStorageHelper.hasItem('landingPage')) {
			LocalStorageHelper.removeItem('SignupStep');
		}

	}

	const [state, setState] = useState<NonPersistState>()
	function useSignupState(): [SignupState, React.Dispatch<React.SetStateAction<SignupState>>] {
		const initialSignupState: SignupState = {
			isPorting: false,
			isQuote: false,
			isNewCustomer: true,
			billingPeriod: billingPeriod.MONTH,
			currentBasket: emptyBasket,
			numberFull: false,
			numberOnly: false,
			numberType: '',
			signupStep: LocalStorageHelper.getItem<SignupStep>('SignupStep')?.value ?? SignupStep.IsSelectingPlan,
			firstName: '',
			lastName: '',
			email: '',
			phone: '',
			company: '',
			topupCredit: 20,
			callRecording: true,
			transcription: false,
			isLoggedIn: false,
			beganAt: SignupStart.HomePage,
			callBlocking: false,
			billingAddress: {
				add1: '',
				add2: '',
				add3: '',
				add4: '',
				pcode: '',
				name: '',
				notes: ''
			},
			deliveryAddress: {
				add1: '',
				add2: '',
				add3: '',
				add4: '',
				pcode: '',
				name: '',
				notes: '',
			}
		}

		const [storedState, setStoredState] = useState(initialSignupState)

		useEffect(
			() => {
				// Check if we have hardware from elsewhere on the site
				if (LocalStorageHelper.getItem('HardwareFirst')?.value !== null && signupState?.currentBasket?.hardware?.length === 0) {
					const hardware = LocalStorageHelper.getItem<Hardware[]>('HardwareFirst').value
					setSignupState({
						...signupState,
						currentBasket: {
							...signupState.currentBasket,
							hardware: hardware!
						}
					})
				}

				restoreLandingPageSelectionsFromLocalStorage()
				// @ts-expect-error
				const paramKey = props.match.params?.signupKey

				let type = ''
				const query = new URLSearchParams(props.location.search)
				if (query) { type = query.get('type') ?? '' }

				// Check if internal signup
				fetchHelper.getJson<boolean>('/api/Home/CheckIfInternalUser')
					.then(res => {
						let loggedIn = false

						// Check if logged in
						const user = localStorage.getItem('user')
						if (user) {
							const parsedUser = JSON.parse(user)
							if (Number.parseInt(parsedUser?.clientId) > 0) {
								loggedIn = true
								LocalStorageHelper.setItem<number>('clientId', parsedUser?.clientId)
							}
						}

						if (res.data && !loggedIn) {
							// Clear any old signup
							setState({
								...state,
								showContinueWarning: true,
								isInternal: true
							})
						}

						if (loggedIn) {
							fetchHelper.getJson<number>('api/Users/GetCustomersBillingDay').then((res) => {
								setState({ ...state, billingDay: res.data })
								LocalStorageHelper.setItem<boolean>('isNewCustomer', false)
							}).catch(err => { console.error(err) })
						}

						const api = `api/Users/SignupProgress?signupKey=${getSignupKey(paramKey)}`

						fetchHelper.getJson<SignupState>(api)
							.then(savedState => {
								if (savedState.data) {
									const wasLoggedinState = savedState.data?.isLoggedIn
									if (wasLoggedinState !== loggedIn && !(savedState.data?.isNewCustomer) && !paramKey) {
										LocalStorageHelper.removeItem('signupKey')
										setStoredState({
											...initialSignupState,
											// isQuote: state?.isInternal === true,
											isLoggedIn: loggedIn,
											isNewCustomer: savedState.data.isNewCustomer,
											signupStep: loggedIn ? SignupStep.IsSelectingNumberType : SignupStep.IsSelectingPlan
										})
									} else {
										setStoredState({
											...savedState.data,
											// isQuote: state?.isInternal === true,
											isLoggedIn: loggedIn,
											isNewCustomer: savedState.data.isNewCustomer,
											signupStep: savedState.data?.signupStep > 0 ? savedState.data.signupStep : loggedIn ? SignupStep.IsSelectingNumberType : SignupStep.IsSelectingPlan
										})

										if ((savedState.data.signupStep > SignupStep.HasSelectedPhoneNumbers || signupState.signupStep > SignupStep.HasSelectedPhoneNumbers) && signupState.signupStep < SignupStep.IsCheckingOut) {
											setState({
												...state,
												showContinueWarning: true
											})
										}
									}
								} else if (loggedIn && LocalStorageHelper.getItem<SbfNumber[]>('LoggedInNumberSelection')?.value === null) {
									// New logged in checkout
									setStoredState({
										...initialSignupState,
										// isQuote: state?.isInternal === true,
										isLoggedIn: loggedIn,
										signupStep: (signupState?.currentBasket?.numbers.length ?? 0 > 0) ? SignupStep.IsSelectingPackage : SignupStep.IsSelectingNumberType,
										isNewCustomer: false,
										currentBasket: {
											...signupState.currentBasket,
											isOutbound: true
										}
									})
								} else if (type !== '') {
									// New signup
									setStoredState({
										...initialSignupState,
										isQuote: state?.isInternal === true,
										signupStep: SignupStep.IsSelectingNumberType,
										currentBasket: {
											...initialSignupState.currentBasket,
											isOutbound: Number(type) === 1
										}
									})
								}
							}).catch(err => {
								LocalStorageHelper.removeItem('signupKey')
								console.error(err)
							})
					}).catch(err => { console.error(err) })
			},
			[]
		)

		return [storedState, setStoredState]
	}

	const emptyBasket = getEmptyBasket()
	const [signupState, setSignupState] = useSignupState()

	const step = signupState.signupStep
	const isOnSelectSignupTypeStep =
		step === SignupStep.IsSelectingPlan || step === SignupStep.HasSelectedPlan
	const isOnSelectNumberStep =
		step >= SignupStep.IsSelectingNumberType &&
		step < SignupStep.IsSelectingPackage
	const isOnChoosePlanStep =
		step >= SignupStep.IsSelectingPackage && step < SignupStep.IsProvidingProfile
	const isOnProvideSignupProfileDetailsStep =
		step >= SignupStep.IsProvidingProfile &&
		step < SignupStep.HasProfileSignupProfile
	const isOnSignupCheckoutStep = step >= SignupStep.HasProfileSignupProfile

	persistState(signupState)

	useEffect(() => {
		LocalStorageHelper.setItem<boolean>('IsOutbound', signupState.currentBasket?.isOutbound)

		if (signupState.isLoggedIn) {
			if (state?.customerHasPlan === undefined) { handleCheckCustomerHasPlan() }

			if (signupState.signupStep >= SignupStep.IsProvidingProfile &&
				signupState.signupStep < SignupStep.HasProfileSignupProfile) {
				setSignupState({
					...signupState,
					signupStep: SignupStep.IsCheckingOut
				})
			}
		}

		if ((state?.products === undefined || state?.products?.length === 0) && isOnSignupCheckoutStep) {
			setTimeout(() => {
				handleGetHardware()
			}, 2000)
		}

	}, [signupState])

	useEffect(() => {
		window.history.pushState(null, '', location.href)

		window.onpopstate = () => {
			const signupStep = LocalStorageHelper.getItem<SignupStep>('SignupStep')?.value ?? SignupStep.IsSelectingPlan

			if (signupStep > 0) {
				history.go(1)

				const newStep = signupStep >= SignupStep.HasProfileSignupProfile
					? SignupStep.IsProvidingProfile
					: signupStep >= SignupStep.IsProvidingProfile
						? SignupStep.IsSelectingPackage
						: signupStep >= SignupStep.IsSelectingPackage
							? SignupStep.IsSelectingNumberType
							: SignupStep.IsSelectingPlan

				// Double check loggedin
				if (LocalStorageHelper.getItem('isNewCustomer')?.value === false && signupState.signupStep < SignupStep.IsSelectingNumberType) {
					setSignupState({
						...signupState,
						isNewCustomer: false,
						isLoggedIn: true,
						signupStep: SignupStep.IsSelectingNumberType
					})
				} else {
					setSignupState({
						...signupState,
						currentBasket: {
							...signupState.currentBasket,
							isOutbound: LocalStorageHelper.getItem<boolean>('IsOutbound')?.value ?? true
						},
						signupStep: newStep
					})
				}
			}
		}

		const marketingData = LocalStorageHelper.getItem<MarketingDataDto>('marketingData')?.value
		if (marketingData !== null && (signupState.marketingData?.source !== marketingData.source)) {
			setSignupState({
				...signupState,
				marketingData: marketingData
			});
		}

	}, [signupState])


	const loginDetails: LoginDetails = {
		clientId: 0,
		email: '',
		fName: '',
		isAuthenticated: false,
		sName: ''
	}

	function incrementUsersQuantity() {
		// get the currently selected package
		const selectedPackage = signupState.currentBasket.package
		const hardware = signupState.currentBasket?.hardware
		if (selectedPackage !== null) {
			// create a new package class instance with the same details but with the number of users incremented
			// @ts-expect-error
			const newPackage = new SbfPackage(selectedPackage.name, selectedPackage.seconds, selectedPackage.rental, selectedPackage.fullPrice, selectedPackage.packageId, selectedPackage.packageIndex, selectedPackage.numberType, selectedPackage.users, selectedPackage.whatsApp)
			// increment the number of users by 1
			newPackage.users.qty++

			// Update free handsets to reflect new user qty
			if (hardware.length > 0) {
				hardware.forEach((item) => {
					if (item.amount === 0) { item.qty = newPackage.users.qty }
				})
			}

			// update the current basket with the new package with the updated number of users
			setSignupState({
				...signupState,
				currentBasket: {
					...signupState.currentBasket,
					package: newPackage,
					hardware
				}
			})
		}
	}

	function decrementUsersQuantity() {
		// get the currently selected package
		const selectedPackage = signupState.currentBasket.package
		const hardware = signupState.currentBasket?.hardware

		if (selectedPackage !== null) {
			// create a new package class instance with the same details but with the number of users decremented
			// @ts-expect-error
			const newPackage = new SbfPackage(selectedPackage.name, selectedPackage.seconds, selectedPackage.rental, selectedPackage.fullPrice, selectedPackage.packageId, selectedPackage.packageIndex, selectedPackage.numberType, selectedPackage.users, selectedPackage.whatsApp)
			// decrement the number of users if the count is greater than 1
			if (newPackage.users.qty > 1) {
				newPackage.users.qty--

				// Update free handsets to reflect new user qty
				if (hardware.length > 0) {
					hardware.forEach((item) => {
						if (item.amount === 0) { item.qty = newPackage.users.qty }
					})
				}

				// update the current basket with the new package with the updated number of users
				setSignupState({
					...signupState,
					currentBasket: {
						...signupState.currentBasket,
						package: newPackage,
						hardware
					}
				})
			}
		}
	}

	function handleGetHardware() {
		const url = 'api/Purchase/GetHardware'
		fetchHelper.getJson<Hardware[]>(url)
			.then(res => {
				if (res.data) {
					setState({
						...state,
						products: res.data
					})
				}
			}).catch(err => { console.error(err) })
	}

	function handleCheckCustomerHasPlan() {
		const url = 'api/Purchase/CustomerHasPlan'
		fetchHelper.getJson<boolean>(url)
			.then(res => {
				setState({ ...state, customerHasPlan: res.data })
			}).catch(err => { console.error(err) })
	}

	function handleContinueCheckout() {
		setState({ ...state, showContinueWarning: false })
		setSignupState({
			...signupState,
			isQuote: state?.isInternal === true
		})
	}

	function handleRestartCheckout() {
		localStorage.clear();
		setState({ ...state, showContinueWarning: false })
		setSignupState({
			...signupState,
			isQuote: state?.isInternal === true,
			signupStep: SignupStep.IsSelectingPlan,
			currentBasket: getEmptyBasket()
		})
	}

	return (
		<div className="main-signup-container mt-0 mb-100 mt-xs-0">
			<ScrollToTopOnMount />
			<DisplayNoFooter />
			<DisplaySimpleHeaderV2 />
			<Helmet>
				<title>
					{(isOnSelectSignupTypeStep || isOnSelectNumberStep)
						? 'Choose your perfect number'
						: isOnChoosePlanStep
							? 'Choose your plan'
							: isOnProvideSignupProfileDetailsStep
								? 'Enter your details'
								: 'Review & Checkout'}
				</title>
			</Helmet>

			{(state?.showContinueWarning === true && signupState.signupStep < SignupStep.IsCheckingOut) &&
				<Modal show={state?.showContinueWarning} backdrop="static"
					onHide={() => { setState({ ...state, showContinueWarning: false }) }}>
					<Modal.Header>
						<Modal.Title className="mx-auto text-lg">Continue where you left off</Modal.Title>
					</Modal.Header>
					<Modal.Body className="text-center">
						{signupState.firstName !== ''
							? <p className="text-md text-400">Looks like you've been here before, {signupState.firstName}!</p>
							: <p className="text-md text-400">Looks like you've been here before!</p>
						}
						{(signupState.currentBasket?.package?.name !== undefined)
							? <p>
								Do you want to continue with your <span className="text-500">{signupState.currentBasket?.package?.name} plan</span>?
							</p>
							: <p>
								Do you want to continue where you left off?
							</p>
						}

						{signupState.currentBasket?.numbers?.length > 0 &&
							<>
								<p><span className="text-500">Selected Numbers:</span><br />
									{signupState.currentBasket.numbers.map(number => <span>{number.number}<br /></span>)}
								</p>

								{((signupState.currentBasket?.package?.rental ?? 0) +
									arraySum(signupState.currentBasket.numbers, 'ddipremium') +
									arraySum(signupState.currentBasket.hardware, 'amount') +
									(signupState.currentBasket.numbers.length > 1 ? (signupState.currentBasket.numbers.length - 1) * MultiNumberPrice : 0)) > 0 &&

									<p><span className="text-500">
										Monthly Total: &pound;{(signupState.currentBasket?.package?.rental ?? 0) +
											arraySum(signupState.currentBasket.numbers, 'ddipremium') +
											arraySum(signupState.currentBasket.hardware, 'amount') +
											(signupState.currentBasket.numbers.length > 1 ? (signupState.currentBasket.numbers.length - 1) * MultiNumberPrice : 0)
										}
									</span></p>
								}

							</>
						}

						<Button variant="cta" onClick={() => { handleContinueCheckout() }}>Continue Checkout</Button>
						<Button variant="link" size="sm" className="mt-15 underline" onClick={() => { handleRestartCheckout() }}>Start again</Button>
					</Modal.Body>
					<Modal.Footer>
						<p className="text-sm text-center">Alternatively, you can get in touch with us on <br className="d-none d-md-block" /><a href="tel:02031891213" className="text-cta underline">0203 189 1213</a> or <a className="text-cta underline" href="javascript:window.lhnJsSdk.openHOC('c');">live chat</a></p>
					</Modal.Footer>
				</Modal>
			}

			{isOnSelectSignupTypeStep && (
				<SignupType
					firstName={''}
					lastName={''}
					age={0}
					basket={signupState.currentBasket}
					numberOnly={signupState.numberOnly}
					numberFull={signupState.numberFull}
					showMobileVoipInfo={false}
					showMobileInboundInfo={false}
					isLoggedIn={signupState.isLoggedIn}
					handleChangeNumberOnly={(
						numberOnly: boolean,
						numberFull: boolean
					) => {
						setSignupState({
							...signupState,
							currentBasket: {
								...signupState.currentBasket,
								isOutbound: !numberOnly
							},
							signupStep: numberOnly ? SignupStep.IsCheckingOut : SignupStep.IsSelectingPackage,
							numberFull,
							numberOnly
						})
					}}
					handleInboundOrOutboundSelection={(
						selectedPlanType: SupportedCallDirection
					) => {
						const currentBasket = signupState.currentBasket
						const updatedState = {
							...signupState,
							currentBasket: {
								...currentBasket,
								isQuote: state?.isInternal === true,
								isOutbound:
									selectedPlanType === SupportedCallDirection.InboundAndOutbound
							}
						}
						setSignupState(updatedState)

						setTimeout(() => {
							let signupStep = SignupStep.IsSelectingNumberType

							if (signupState.beganAt === SignupStart.NumberTypeLandingPage) {
								signupStep = SignupStep.IsSelectingPackage
							}

							setSignupState({
								...updatedState,
								isQuote: state?.isInternal === true,
								signupStep
							})
						}, 250)
					}}
					history={props.history}
					location={props.location}
					match={props.match}
					selectedNumberType={signupState.numberType}
				/>
			)}

			{isOnSelectNumberStep && (
				<GetNumber
					signupPort={''}
					handleClearAllPhoneNumbersFromBasket={function (): void {
						throw new Error('Function not implemented.')
					}}
					selectedNumberType={signupState.numberType}
					isLoggedIn={signupState.isLoggedIn}
					handleAddToBasket={function (args: BasketItem): void {
						if (isSbfNumber(args)) {
							const sbfNumbers = [...emptyBasket.numbers, args]

							setSignupState({
								...signupState,
								currentBasket: {
									...emptyBasket,
									numbers: sbfNumbers,
									isOutbound: LocalStorageHelper.getItem<boolean>('IsOutbound')?.value ?? true
								}
							})

							setState({
								...state,
								notifyMessage: simplePhoneNumberFormat(args.number),
							})
						}
					}}
					handleChangeNumberType={function (numberType: NumberType): void {
						setSignupState({
							...signupState,
							numberType,
							signupStep: SignupStep.IsSelectingNumberType
						})
					}}
					history={props.history}
					location={props.location}
					match={props.match}
					currentBasket={signupState.currentBasket}
					handleUpdateBasket={(basket: Basket, number?: SbfNumber, removed?: boolean) => {
						setSignupState({ ...signupState, currentBasket: basket })

						if (number) {
							setState({
								...state,
								notifyMessage: simplePhoneNumberFormat(number.number),
								removeFromBasket: removed
							})
						}
					}}
					handleContinue={(isPorting: boolean) => {
						setSignupState({
							...signupState,
							isPorting,
							signupStep: (signupState.isLoggedIn && state?.customerHasPlan === true) ? SignupStep.IsSelectingPlan : SignupStep.IsSelectingPackage
						})
					}}
					handleClearNumberType={function (): void {
						const currentBasket = signupState.currentBasket

						setSignupState({
							...signupState,
							numberType: '',
							currentBasket: {
								...emptyBasket,
								isOutbound: currentBasket.isOutbound
							}
						})
					}}
					handleClearGeoRegion={function (): void {
						setSignupState({
							...signupState,
							signupStep: SignupStep.IsSelectingPhoneNumbers,
							numberType: '01'
						})
					}}
					handleBack={function (step?: SignupStep): void {
						// if a number type has already been selected then clear the number type and geo region as well as clear the basket and set the step to the IsSelectingNumberType step
						// If a number type has not been set then return them to the select plan step
						if (step !== undefined) {
							setSignupState({
								...signupState,
								signupStep: step
							})
						} else if (signupState.numberType !== '') {
							setSignupState({
								...signupState,
								numberType: '',
								signupStep: SignupStep.IsSelectingNumberType,
								currentBasket: emptyBasket
							})
						} else {
							setSignupState({
								...signupState,
								signupStep: SignupStep.IsSelectingPlan
							})
						}
					}} />
			)}

			{isOnChoosePlanStep && <ChoosePlan
				prefix={signupState.numberType}
				isLoggedIn={signupState.isLoggedIn}
				currentBasket={signupState.currentBasket}
				history={props.history}
				location={props.location}
				match={props.match}
				handleSetPackage={(selectedPackage: SbfPackage) => {
					const basketWithPackageAdded: Basket = {
						...signupState.currentBasket,
						package: selectedPackage
					}
					const hasDetails = (signupState.email.length > 0 && signupState.firstName.length > 0 && signupState.phone.length > 0);
					setSignupState({
						...signupState,
						// signupStep: signupStep,
						currentBasket: basketWithPackageAdded,
						signupStep: (signupState.isLoggedIn || (!signupState.isLoggedIn && hasDetails === true)) ? SignupStep.IsCheckingOut : SignupStep.IsProvidingProfile
					})
				}}
				handleContinue={(selectedPackage?: SbfPackage) => {
					let basket = signupState.currentBasket
					if (selectedPackage !== null) { basket = { ...basket, package: selectedPackage } }
					const hasDetails = (signupState.email.length > 0 && signupState.firstName.length > 0 && signupState.phone.length > 0);

					setSignupState({
						...signupState,
						currentBasket: basket,
						signupStep: (signupState.isLoggedIn || hasDetails === true) ? SignupStep.IsCheckingOut : SignupStep.IsProvidingProfile
					})
				}}
				handleRemoveBoltOn={function (): void {
					const basket = { ...signupState.currentBasket }
					basket.package = new SbfPackage(
						'Unlimited to App',
						0,
						signupState.currentBasket.numbers?.[0].number.startsWith('0800') ? NumbersGlobalOfferPrice : AppOnlyPrice,
						signupState.currentBasket.numbers?.[0].number.startsWith('0800') ? AppOnlyPrice0800 : AppOnlyPrice,
						0,
						0,
						signupState.numberType,
						{
							qty: 1,
							monthlyPrice: 0
						}
					)
					setSignupState({ ...signupState, currentBasket: basket })
				}}
				handleBack={function (step?: SignupStep): void {
					// if the customer is on the select extra users step then return them to the select package step
					if (step !== undefined) {
						setSignupState({
							...signupState,
							signupStep: step
						})
					} else if (signupState.signupStep === SignupStep.IsSelectingAdditionalOutboundUsers) {
						setSignupState({
							...signupState,
							signupStep: SignupStep.IsSelectingPackage
						})
					} else {
						// If the user selected their numbers on the landing page then return them to the select plan step
						// otherwise, return them to the select numbers step bypassing the possibiilty to change numbers at this stage
						// This is as per the current design
						if (signupState.beganAt === SignupStart.NumberTypeLandingPage) {
							setSignupState({
								...signupState,
								signupStep: SignupStep.IsSelectingPlan
							})
						} else {
							setSignupState({
								...signupState,
								signupStep: SignupStep.IsSelectingPhoneNumbers
							})
						}
					}
				}} />}

			{isOnProvideSignupProfileDetailsStep && (
				<SignupPage
					handleAuthCredentialsReceived={(firstName: string, lastName: string, email: string, platform: string) => {
						// Add event so we can track usage in Analytics
						window.dataLayer.push({
							event: 'signupOauthComplete',
							auth_platform: platform
						})

						setSignupState({ ...signupState, firstName, lastName, email });
					}}
					firstName={signupState.firstName}
					lastName={signupState.lastName}
					email={signupState.email}
					phone={signupState.phone}
					basket={signupState.currentBasket}
					company={signupState.company}
					handleUpdateSignupFirstName={function (name: string): void {
						setSignupState({ ...signupState, firstName: name })
					}}
					handleUpdateSignupLastName={function (name: string): void {
						setSignupState({ ...signupState, lastName: name })
					}}
					handleUpdateSignupEmail={function (email: string): void {
						setSignupState({ ...signupState, email })
					}}
					handleUpdateSignupPhone={function (phone: string): void {
						setSignupState({ ...signupState, phone })
					}}
					handleUpdateSignupCompany={function (companyName: string): void {
						setSignupState({ ...signupState, company: companyName })
					}}
					history={props.history}
					location={props.location}
					match={props.match}
					handlePartialSignup={(signup) => {
						setSignupState({
							...signupState,
							...signup,
							signupStep: SignupStep.HasProfileSignupProfile
						})
					}}
					handleBack={function (step?: SignupStep): void {
						// take the user back to the select package step
						setSignupState({
							...signupState,
							signupStep: step !== undefined ? step : SignupStep.IsSelectingPackage
						})
					}} />
			)}

			{isOnSignupCheckoutStep && (
				<SignupCheckout
					marketingData={signupState.marketingData}
					isPorting={signupState.isPorting}
					isQuote={signupState.isQuote}
					isInternal={state?.isInternal === true}
					isNewCustomer={!signupState.isLoggedIn || signupState.isNewCustomer}
					billingDay={state?.billingDay ?? 1}
					signupKey={getSignupKey()}
					handleChangeNumberType={function (numberType: NumberType): void {
						setSignupState({
							...signupState,
							numberType
						})
					}}
					handleUpdateSignupCallRecording={(enabled: boolean) => {
						setSignupState({ ...signupState, callRecording: enabled })
					}}
					handleUpdateSignupTopup={(amount: number) => {
						setSignupState({ ...signupState, topupCredit: amount })
					}}
					handleUpdateSignupTranscription={(enabled: boolean) => {
						setSignupState({ ...signupState, transcription: enabled })
					}}
					handleUpdateSignupCallBlocking={(enabled: boolean) => {
						setSignupState({ ...signupState, callBlocking: enabled })
					}}
					selectedNumberType={signupState.numberType}
					callRecording={signupState.callRecording}
					transcription={signupState.transcription}
					callBlocking={signupState.callBlocking}
					topupCredit={signupState.topupCredit}
					handleRemoveFromBasket={function (e: EditBasketParams): void {
						const basket = { ...signupState.currentBasket }

						if (isSbfNumber(e.item)) {
							const itemToRemove = e.item
							basket.numbers = basket.numbers.filter((item) => item.number !== itemToRemove.number)
						} else if (isHardware(e.item)) {
							const item = e.item
							basket.hardware = basket.hardware.filter((hardware) => hardware.prodId !== item.prodId)
						}

						setSignupState({ ...signupState, currentBasket: basket })
					}}
					currentBasket={signupState.currentBasket}
					isLoggedIn={signupState.isLoggedIn}
					signupValidation={{
						pcodeValidationState: validationStates.UNCHECKED,
						townValidationState: validationStates.UNCHECKED,
						add1ValidationState: validationStates.UNCHECKED,
						nameValidationState: validationStates.UNCHECKED,
						billing: {
							nameValidationState: validationStates.UNCHECKED,
							add1ValidationState: validationStates.UNCHECKED,
							townValidationState: validationStates.UNCHECKED,
							pcodeValidationState: validationStates.UNCHECKED,
							cardNumberValidationState: validationStates.UNCHECKED,
							cardExpiryValidationState: validationStates.UNCHECKED,
							cardCodeValidationState: validationStates.UNCHECKED
						},
						delivery: {
							nameValidationState: validationStates.UNCHECKED,
							add1ValidationState: validationStates.UNCHECKED,
							townValidationState: validationStates.UNCHECKED,
							pcodeValidationState: validationStates.UNCHECKED
						}
					}}
					signupInfo={{
						user: {
							name: `${signupState.firstName} ${signupState.lastName}`,
							email: signupState.email,
							phone: signupState.phone,
							company: signupState.company
						},
						delivery: signupState.deliveryAddress?.add1?.length > 0 ? signupState.deliveryAddress : signupState.billingAddress,
						billing: {
							address: signupState.billingAddress,
							cardNumber: {
								value: ''
							},
							cardExpiry: {
								value: ''
							},
							cardCode: {
								value: ''
							}
						}
					}}
					isStep2Visible={''}
					isStep2Reached={false}
					productDetails={[]}
					userDetails={loginDetails}
					handleLogin={function (param: LoginDetails): void {
						login(param.email, param.password ?? '', param.persist ?? false)
					}}
					history={props.history}
					location={props.location}
					match={props.match}
					handleBack={function (step?: SignupStep): void {
						// return the user to the provide user profile step
						setSignupState({
							...signupState,
							signupStep: step !== undefined ? step : SignupStep.IsProvidingProfile
						})
					}}
					handleClearGeoRegion={function (): void {
						setSignupState({
							...signupState,
							numberType: '01'
						})
					}}
					handleChooseBillingPeriod={function (bp: billingPeriod, freebies: boolean, freeBluetooth?: boolean): void {
						const basket = signupState.currentBasket
						const hardware = basket?.hardware?.filter(f => f.amount !== 0 && f.qty > 0)
						const isUnlimited = basket?.package?.packageId === UnlimitedOutboundPlan
						let isFreebies = freebies && bp === billingPeriod.YEAR

						if (isUnlimited) { isFreebies = freebies && (bp === billingPeriod.YEAR || bp === billingPeriod.BIANNUAL) }

						if (isFreebies && state !== undefined && state.products !== undefined) {
							hardware.push({ ...state.products.find(f => f.model?.includes('J80'))!, qty: basket.package?.users.qty ?? 1, amount: 0 })
							hardware.push({ ...state.products.find(f => f.model?.includes('T31P'))!, qty: basket.package?.users.qty ?? 1, amount: 0 })

							setState({
								...state,
								notifyMessage: 'Phones',
								removeFromBasket: !(bp === billingPeriod.BIANNUAL || bp === billingPeriod.YEAR)
							})
						}

						if (freeBluetooth === true && state !== undefined && state.products !== undefined) {
							hardware.push({ ...state.products.find(f => f.model?.includes('J80'))!, qty: basket.package?.users.qty ?? 1, amount: 0 })
							setState({
								...state,
								notifyMessage: 'Earbuds',
								removeFromBasket: !(bp === billingPeriod.BIANNUAL || bp === billingPeriod.YEAR)
							})
						}

						setSignupState({
							...signupState,
							billingPeriod: bp,
							currentBasket: { ...basket, hardware }
						})
					}}
					billingPeriod={signupState.billingPeriod}
					handleDecrementUsers={() => { decrementUsersQuantity() }}
					handleIncrementUsers={() => { incrementUsersQuantity() }}
					handleUpdateBasket={(basket: Basket) => {
						setSignupState({ ...signupState, currentBasket: basket })
					}}
					handleSetPackage={(selectedPackage: SbfPackage) => {
						const basketWithPackageAdded: Basket = {
							...signupState.currentBasket,
							package: selectedPackage
						}
						setSignupState({
							...signupState,
							// signupStep: signupStep,
							currentBasket: basketWithPackageAdded
						})
					}}
					handleUpdateAddress={function (data: AddressDto, isBilling?: boolean): void {
						if (isBilling === true) {
							setSignupState({ ...signupState, billingAddress: data })
						} else {
							setSignupState({ ...signupState, deliveryAddress: data })
						}
					}}
					handleRemoveBoltOn={function (): void {
						const basket = { ...signupState.currentBasket }
						basket.package = new SbfPackage(
							'Unlimited to App',
							0,
							signupState.currentBasket.numbers?.[0].number.startsWith('0800') ? NumbersGlobalOfferPrice : AppOnlyPrice,
							signupState.currentBasket.numbers?.[0].number.startsWith('0800') ? AppOnlyPrice0800 : AppOnlyPrice,
							0,
							0,
							signupState.numberType,
							{
								qty: 1,
								monthlyPrice: 0
							}
						)
						setSignupState({ ...signupState, currentBasket: basket })
					}}
					handleApplyDiscount={() => {
						const basket = { ...signupState.currentBasket }

						basket.discountApplied = 1

						setSignupState({
							...signupState,
							currentBasket: basket
						})
					}}
					handleRemoveDiscount={() => {
						const basket = { ...signupState.currentBasket }

						basket.discountApplied = 0

						setSignupState({
							...signupState,
							currentBasket: basket
						})
					}}
				/>
			)}

			<Toasty checkoutMessage={state?.notifyMessage} checkoutIsBasket={true} checkoutRemoveBasket={state?.removeFromBasket} />
		</div>
	)
}

export default Signup
