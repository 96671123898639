import moment from 'moment'
import React from 'react'
import { Button, Modal } from 'react-bootstrap'
import Countdown from 'react-countdown'

export default class EarbudsModal extends React.Component {
  constructor (props) {
    super(props)
  }

  state = {
    stock: 0
  }

  render () {
    const renderCountdown = ({ days, hours, minutes, seconds, completed }) => {
      // Render a countdown
      return <>
				<p className="text-700 text-cta text-uppercase text-md mb-20 mt-10">
					Create your account within...
				</p>
				<div className="countdown-timer">
					<div className="timer-block">
						{hours}<br /><span className="text-sm">hours</span>
					</div>
					<div className="timer-block">
						{minutes}<br /><span className="text-sm">minutes</span>
					</div>
					<div className="timer-block">
						{seconds}<br /><span className="text-sm">seconds</span>
					</div>
				</div>
				<p className="mt-10 mb-20 text-lightest text-500">
					To secure your FREE Bluetooth Earbuds
				</p>
			</>
    }

    return <Modal className="full-screen" show={this.props.show} onHide={this.props.hide}>
			<Modal.Body>
				<span className="close-modal" onClick={this.props.hide}>
					<span>&times;</span>
				</span>
				<div className="row">
					<div className="col-md-5 text-center pt-30">
						<img className="" src="/images/product-shots/bluetooth-earphones.jpg" alt="SwitchboardFREE Bluetooth Earphones" style={{ maxWidth: '100%' }} />
					</div>
					<div className="col-md-7">
						<h2 className="text-cta text-500 pb-30">SwitchboardFREE Bluetooth Earbuds</h2>
						<p className="mb-30">
							Light and comfortable Bluetooth earbuds. Perfect for making calls, taking calls and listening to music on the move, in the car or for use in the office.
						</p>
						<p className="mb-0">
							With digital noise cancelling, a built-in mic and a talk time of 4.5 hours, you can go truly hands-free without compromise.
						</p>
						<p className="text-primary underline text-italic pt-30 text-500">
							Features:
						</p>

						<ul className="unstyled cta text-500 ml-xs-0 ml-0 pl-0 mt-30 mb-30">
							<li className="text mb-10"><i className="icon icon-checkmark4 text-cta mr-5"></i><em>Easy touch control and one step pairing</em></li>
							<li className="text mb-10"><i className="icon icon-checkmark4 text-cta mr-5"></i><em>Bluetooth 5.1 + EDR (Enhanced Data Rate) </em></li>
							<li className="text mb-10"><i className="icon icon-checkmark4 text-cta mr-5"></i><em>Built-in Mic with digital noise cancelling</em></li>
							<li className="text mb-10"><i className="icon icon-checkmark4 text-cta mr-5"></i><em>4.5 hour talk time / 6.5 hour music playtime / 120-day standby</em></li>
							<li className="text mb-10"><i className="icon icon-checkmark4 text-cta mr-5"></i><em>Quick 40 minute charge time + 300mAh charging case</em></li>
							<li className="text mb-10"><i className="icon icon-checkmark4 text-cta mr-5"></i><em>Waterproof (IPX4)</em></li>
						</ul>

						<p className="mb-30 p-0 bt-1 pt-20 text-md">
							Hurry, only  <span className="text-500">{this.state.stock} left in-stock</span>
						</p>

						<span className="p-10 bg-darker mb-20 d-inline-block center-block mt-0 text-center w-100 brad-5">
							<Countdown date={moment().endOf('day').format('yyyy-MM-DDTHH:mm:ss')} renderer={renderCountdown} />
						</span>

						{this.props.chooseType &&
							<>
								<Button variant="cta" size="lg" className="btn-xxs-block" onClick={this.props.chooseType}>
									Continue with <span className="text-500 underline">Full VoIP Plan</span>
								</Button>
								<p className="text-sm underline mt-20 text-dullest cursor-pointer" onClick={this.props.hide}>
									Or, go back to compare plans.
								</p>
							</>
						}

						{this.props.choosePlan &&
							<>
								<Button variant="cta" size="lg" className="btn-xxs-block" onClick={() => this.props.choosePlan(4)}>
									Select Unlimited
								</Button>
								<span className="ml-20 mr-20">or</span>
								<Button variant="cta" size="lg" className="btn-xxs-block" onClick={() => this.props.choosePlan(3)}>
									Select Standard
								</Button>
								<p className="text-sm underline mt-20 text-dullest cursor-pointer" onClick={this.props.hide}>
									Close &amp; compare plans.
								</p>
							</>
						}

						{this.props.checkout &&
							<>
								<Button variant="cta" size="lg" className="btn-xxs-block" onClick={this.props.hide}>
									Continue Checkout
								</Button>
							</>
						}

					</div>
				</div>
			</Modal.Body>
		</Modal>
  }

  componentDidMount () {
    const context = this
    context.setState({ stock: ((Math.random() + 3) * 15).toFixed(0) })
  }
}
