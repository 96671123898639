import React from 'react'
import ReactAudioPlayer from 'react-audio-player'
import { Button, Modal } from 'react-bootstrap'
import Helmet from 'react-helmet'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import CallbackRequest from '../../components/CallbackRequest'
import DisplayLoggedInFooter from '../../components/DisplayLoggedInFooter'
import DisplayMainHeader from '../../components/DisplayMainHeader'
import ChoosePayment from '../../components/purchase/ChoosePayment'
import CarouselWidget from '../../components/Reviews/CarouselWidget'
import ScrollToTopOnMount from '../../components/ScrollToTopOnMount'
import { MinimumPromptPurchaseThreshold, MinimumPromptPurchaseThresholdInhouse, PricePerWord } from '../../constants/Constants'
import { fetchHelper } from '../../helpers/fetchHelper'
import { calculateVat, getQueryStringFromProps } from '../../helpers/utils'
import { isLoggedIn } from '../../selectors'
import janiceMp3Sample from '../../sounds/janiceDemo.mp3'
import georgeMp3Sample from '../../sounds/george.mp3'
import zoeMp3Sample from '../../sounds/zoe.mp3'
import katieMp3Sample from '../../sounds/katie.mp3'
import * as fromHome from '../../store/Home'
import { handleNotificationChange, handleShowPaymentOptionsModal, handleShowPaymentPostponedModal } from '../../store/SBF'
import PromptsExamples from '../../Text/PromptsExamples'
import TermsAndConditions from '../../Text/TermsAndConditions'

const _ = require('lodash')

const inititialState = {
  showingAddPromptModal: false,
  promptMenuSelection: {
    value: 1,
    title: 'Standard introduction',
    subtitle: 'This is the first thing your callers will hear when you don\'t have multiple departments set.'
  },
  voiceMenuSelection: {
    value: 2,
    title: 'Janice',
    subtitle: 'A professional and re-assuring female voice',
    artistPhoto: '/images/janice-round.png'
  },
  prompts: [],
  purchasingPrompts: false,
  inhouse: true,
  StandardIntroExample: false,
  GlobalIntroExample: false,
  DepartmentsExample: false,
  IndividualDepartmentsExample: false,
  OutOfHoursExample: false,
  CallScreeningExample: false
}

class ProfessionalPrompts extends React.Component {
  state = inititialState

  constructor (props) {
    super(props)

    // this.state = { StandardIntroExample: false };
  }

  purchasePrompts () {
    const url = '/api/Purchase/PurchasePrompts'
    const context = this

    context.setState({ purchasingPrompts: true }, () => {
      fetchHelper.postJson(url, _.map(context.state.prompts, prompt => ({
        promptVoice: prompt.voiceMenuSelection.title,
        text: prompt.promptText,
        promptType: prompt.promptMenuSelection.value,
        professional: (prompt.voiceMenuSelection.value === 2 || prompt.voiceMenuSelection.value === 3)
      }))).then(res => {
        if (res.data && (res.data.success === true || res.data.isSuccess === true)) {
          context.props.notify({ message: 'Thank you, your prompts have been ordered', isError: false })
        } else if (res.data && res.data.postponed === true) {
          context.props.handleShowPaymentPostponedModal()
        } else {
          context.setState({ key: res.data.key })
          context.props.handleShowPaymentOptionsModal()
        }
        context.setState(inititialState)
      }).catch(function (error) {
        context.setState({ purchasingPrompts: false })
        console.log(error)
      })
    })
  }

  getWordCount (text) {
    return text ? text.trim().split(' ').length : 0
  }

  getPriceForText (text) {
    return Number(this.getWordCount(text) * PricePerWord)
  }

  getAudioFile (val) {
    switch (val) {
      case 2:
        return janiceMp3Sample
      case 6:
        return zoeMp3Sample
    }
  }

  minimizeOrderBox () {
    const orderBox = document.querySelector('.order-box')
    orderBox.classList.add('minimized')
  }

  maximizeOrderBox () {
    const ExpandorderBox = document.querySelector('.order-box')
    ExpandorderBox.classList.remove('minimized')
  }

  render () {
    const isActive = this.state.isActive
    const promptDropdownSelectionData = [
      {
        value: 1,
        title: 'Standard introduction',
        subtitle: 'The first thing your callers will hear when you don\'t have multiple departments set'
      },
      {
        value: 4,
        title: 'Global Departments Introduction',
        subtitle: 'The first message played to your callers when you have multiple department set up'
      },
      {
        value: 5,
        title: 'Departments Menu',
        subtitle: 'Press 1 for ..., press 2 for ...'
      },
      {
        value: 1,
        title: 'Individual Department Introduction',
        subtitle: 'The first thing your callers will hear when your caller has selected a department (if enabled)'
      }, {
        value: 2,
        title: 'Out of Hours',
        subtitle: 'This message is played when your lines are closed'
      }, {
        value: 3,
        title: 'Call Screening',
        subtitle: 'A short message giving you information about the call before you are connected with your caller'
      }
      // ,
      // {
      // 	value: 7,
      // 	title: 'Voicemail',
      // 	subtitle: 'This is a message telling callers that your lines are busy and you offer them to leave a voicemail message'
      // },
    ]

    const voicePromptDropdownSelectionData = [
      {
        value: 2,
        title: 'Janice',
        subtitle: 'A professional and re-assuring female voice',
        artistPhoto: '/images/janice-round.png'
      },
      {
        value: 6,
        title: 'Zoe',
        subtitle: 'Zoe....',
        artistPhoto: '/images/team/zoe2.jpg'
      },
      {
        value: 7,
        title: 'George',
				subtitle: 'George....',
				artistPhoto: '/images/team/george.jpg'
      },
	  {
        value: 8,
        title: 'Katie',
        subtitle: 'Katie....',
        artistPhoto: '/images/team/katie.jpg'
      }
    ]

    const totalWordCountAllPrompts = _.reduce(this.state.prompts, (sum, prompt) => sum + this.getWordCount(prompt.promptText), 0)
    let totalWordCountAllPromptsPrice = Math.max((Number(totalWordCountAllPrompts) * PricePerWord), MinimumPromptPurchaseThresholdInhouse).toFixed(2)

    if (this.state.prompts && this.state.prompts.length > 0) {
      if (this.state.prompts.filter(f => (f.voiceMenuSelection.value === 2 || f.voiceMenuSelection.value === 3)).length > 0) {
        totalWordCountAllPromptsPrice = Math.max((Number(totalWordCountAllPrompts) * PricePerWord), MinimumPromptPurchaseThreshold).toFixed(2)
        if (this.state.inhouse === true) { this.setState({ inhouse: false }) }
      }
    }


    return <><ScrollToTopOnMount /><DisplayMainHeader />
			{this.props.isLoggedIn
			  ? <>
					{/* <UpgradesMenu /> */}
					<DisplayLoggedInFooter />
				</>

			  :				<></>
			}

			<Helmet>
				<title>Professional Prompts Voice Recordings</title>
				<meta name="description" content="Professional prompts allow you to get your own bespoke introductions, out-of-hours messages and departmental prompts all recorded by the voice artist." />
				<style>
					{
						`
                        div#lhnHocButton.lhnround {
                            z-index: 9001;
                        }
                        audio {
                            width:300px;
                            max-width:100%;
                            display:block;
                            margin:0 auto;
                        }
                        
                       `
					}
				</style>
			</Helmet>
			<header id="start">
				<div className="full-width full-hero-small with-text" style={{ backgroundImage: "url('/images/backgrounds/pro-prompts.jpg')" }}>
					<h1 className="text-500" id="ProfessionalPromptsH1">
						Professional Prompts
					</h1>
					<h2 className="subtitle">
						The powerful marketing tool that can instantly improve your conversions &amp; phone image.
					</h2>
				</div>
			</header>

			<Modal size={`${this.state.showExamples === true ? 'w-1200px' : 'w-600px'}`} show={this.state.showingAddPromptModal} onHide={event => this.setState({ showingAddPromptModal: false })}>
				<Modal.Body className="bt-0 bg-dull">
					<span className="close-modal" onClick={e => this.setState({ showingAddPromptModal: false })}>
						<span>&times;</span>
					</span>
					<div className="row">
						<div className="col-md-12">
							<div className={`${this.state.showExamples === true ? 'w-48 w-xs-100 pull-xs-none float-start bg-dull' : 'bg-dull'}`}>
								<h6 className="text-center text-500"><span className="text-cta">Step 1:</span> Select prompt type</h6>

												<div className="simple-dropdown call-stats-dropdown w-100" id="SelectPromptTypeDropdown">
													{this.state.promptMenuExpanded &&
														<ul className="text-start b-2 brad-5 bg-light">
															<li className="" onClick={e => this.setState({ promptMenuExpanded: false })}>
																<a className="text-start p-15 no-underline">
																	<span
																		className="text-500">{this.state.promptMenuSelection.title}
																	</span>
																	<span className="caret mt-10 float-end"></span>
																	<br />
																	<small className="text-400">{this.state.promptMenuSelection.subtitle}</small>
																</a>
															</li>
															{this.state.promptMenuExpanded && promptDropdownSelectionData.map((prompt) => {
															  return <li className={prompt.title === this.state.promptMenuSelection.title ? 'selected bg-light' : ''}
																	onClick={e => prompt.title === this.state.promptMenuSelection.title
																	  ? ''
																	  : this.setState({
																	    promptMenuSelection: prompt,
																	    promptMenuExpanded: false
																	  })
																	}
																	id={`Prompt${prompt.title.replace(/\s/g,'')}MenuOption`}
																	key={prompt.report}>
																	<a className="b-1 text-start p-15 no-underline">
																		<span className="text-500">{prompt.title}</span>
																		<br />
																		<small className="text-400">{prompt.subtitle}</small>
																	</a>
																</li>
															})}
														</ul>
													}

									{!this.state.promptMenuExpanded &&
										<ul className="text-start b-2 brad-5 bg-light"
											onClick={e => this.setState({ promptMenuExpanded: true })}>
											<li className="">
												<a className="text-start p-15 no-underline">
													<span
														className="text-500"
														id={`${this.state.promptMenuSelection.title.replace(/\s/g, "")}Selected`}>{this.state.promptMenuSelection.title}
													</span>
													<span className="caret mt-10 float-end"></span>
													<br />
													<small className="text-400">{this.state.promptMenuSelection.subtitle}</small>
												</a>
											</li>
										</ul>
									}
								</div>

								<h6 className="pt-60 pt-xxs-30 text-500 text-center"><span className="text-cta">Step 2:</span> Choose a voice artist</h6>

												<div className="simple-dropdown call-stats-dropdown w-100">
													{this.state.voiceMenuExpanded &&
														<ul className="text-start b-2 brad-5 bg-light">
															<li className="bb-1" onClick={e => this.setState({ voiceMenuExpanded: false })}>
																<a className="text-start pl-15 pr-15 pt-5 pb-5 text-sm no-underline">
																	<span className="img-circle bg-secondary absolute" style={{ width: "16px", height: "16px" }}>
																		<span className="icon icon-checkmark3 text-lightest block" style={{ fontSize: "9px", padding: "4px" }}></span>
																	</span>
																	<img width="50" height="50" className="img-circle mr-10" src={this.state.voiceMenuSelection.artistPhoto} />
																	{/* <br/> */}
																	<span
																		className="text-400">{this.state.voiceMenuSelection.title}</span> <span className="caret mt-20 float-end"></span>
																	{/* <br/>
																<small>{this.state.voiceMenuSelection.subtitle}</small> */}
																</a>
															</li>
															{this.state.voiceMenuExpanded && voicePromptDropdownSelectionData.map((voice) => {
															  return <li
																	className={voice.title === this.state.voiceMenuSelection.title ? 'selected' : 'bb-1'}
																	onClick={e => voice.title === this.state.voiceMenuSelection.title
																	  ? ''
																	  : this.setState({
																	    voiceMenuSelection: voice,
																	    voiceMenuExpanded: false
																	  })
																	}
																	id={`Voice${voice.title}MenuOption`}
																	key={voice.report}>
																	<a className=" text-start pl-15 pr-15 pt-5 pb-5 text-sm no-underline">
																		<img width="50" height="50" className="img-circle mr-10" src={voice.artistPhoto} />
																		<span className="text-400">{voice.title}</span>
																		{/* <br/>
																	<small>{voice.subtitle}</small> */}
													</a>
												</li>
											})}
										</ul>
									}

													{!this.state.voiceMenuExpanded &&
														<ul className="text-start b-2 brad-5 bg-light"
															onClick={e => this.setState({ voiceMenuExpanded: true })}>
															<li className="">
																<a className="text-start pl-15 pr-15 pt-5 pb-5 text-sm no-underline">
																	<span className="img-circle bg-secondary absolute" style={{ width: '16px', height: '16px' }}>
																		<span className="icon icon-checkmark3 text-lightest block" style={{ fontSize: '9px', padding: '4px' }}></span>
																	</span>
																	<img width="50" height="50" className="img-circle mr-10" src={this.state.voiceMenuSelection.artistPhoto} />
																	<span
																		className="text-400" id={`${this.state.voiceMenuSelection.title}Selected`}>{this.state.voiceMenuSelection.title}</span>
																	<span className="caret mt-20 float-end"></span>
																</a>
															</li>
														</ul>
													}
												</div>

												<h6 className="pt-60 text-center text-500 pt-xxs-30">
													<span className="text-cta">Step 3:</span> Write your message 
													<span className="text-xs ml-10 d-none d-sm-inline-block">
													{this.state.showExamples === true ? 
														<>
															(<Link to={'#'} onClick={() => this.setState({ showExamples: false })} className="text-cta underline">Hide examples</Link>)
														</>
														: 
														<>
															(<Link to={'#'} onClick={() => this.setState({ showExamples: true })} className="text-cta underline">See examples</Link>)
														</>
														
													}
														
													</span>
												</h6>
												<textarea className="form-control" height="200" onChange={event => this.setState({ promptText: event.target.value })} />
												<p className="pt-5 text-sm">
													Word count: {this.getWordCount(this.state.promptText)}
												</p>
												<div className="pt-20 pb-10 text-center">
													<Button
														className="btn-block"
														disabled={!this.state.promptText} variant="cta" size="lg" onClick={event => {
															let prompts = this.state.prompts;
															let newPrompts = [...prompts, {
																voiceMenuSelection: this.state.voiceMenuSelection,
																promptMenuSelection: this.state.promptMenuSelection,
																promptText: this.state.promptText
															}];
															this.handleAddPrompts(newPrompts);
														}}>Add Prompt</Button>
												</div>
											</div>
											<div className={`${this.state.showExamples === true ? 'w-48 float-start visible ml-20 d-none d-sm-block' : 'd-none'}`}>
												<div className="ml-20 mt-40" style={{maxHeight:"585px",overflow:"auto"}}>
													<span className="close-modal" title="Hide examples" onClick={() => this.setState({ showExamples: false })} style={{right:"70px",top:"56px",width:"20px",height:"20px"}}>
														<span style={{fontSize:"14px",top:"-8px"}}>&times;</span>
													</span>

													<PromptsExamples />

												</div>
											</div>
										</div>
									</div>
								</Modal.Body>
							</Modal>

			<Modal className="terms-modal" show={this.state.showTsAndCs} onHide={e => this.setState({ showTsAndCs: false })}>
				<Modal.Header closeButton>
					<Modal.Title>Terms &amp; Conditions</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<TermsAndConditions />
				</Modal.Body>
				<Modal.Footer>
					<Button variant="primary" onClick={e => this.setState({ showTsAndCs: false })}>Close</Button>
				</Modal.Footer>
			</Modal>

			{this.props.isLoggedIn &&
				<>
					{this.state.prompts.length > 0 &&
						<div id="orderBox" className="bg-light pt-10 text-center w-300px center-block order-box">
							<span className="img-circle b-2 bg-light minimize-order-box" onClick={this.minimizeOrderBox}>
								<span className="icon icon-shrink7"></span>
							</span>
							<h5 className="text-500 text-start text-dark pb-10 bb-1 pl-10 pr-10" onClick={this.maximizeOrderBox}>
								<span className="icon icon-cart"></span><span className="badge img-circle text-lightest">{this.state.prompts.length}</span>

								<span className="text-xs float-end pt-5">Total:

									{this.state.prompts.length > 0 && <span className="text-secondary ml-10">
										&pound;{totalWordCountAllPromptsPrice}
									</span>
									}
									<span className="text-underline text-dark text-400 text-xxs" style={{ fontSzie: '11px' }}>Click to see your order</span>
								</span>
							</h5>

							<div className="order-items-wrapper">
								{this.state.prompts.map((prompt, index) => {
								  return <div className="col-md-12 bb-1 text-400 text-start pt-20 pb-20 pr-0 text-dark order-item scale-up-ver-top">

										<span className="bg-light b-2 img-circle box-shadow" title="Remove item"
											onClick={event => {
											  const prompts = this.state.prompts
											  const newPrompts = [...prompts.slice(0, index), ...prompts.slice(index + 1, prompts.length)]
											  this.handleAddPrompts(newPrompts)
											}}>
											<span>&times;</span>
										</span>

										<div className="col-md-12 p-0 pr-30">
											<span className="text-500">Prompt: </span>
											{prompt.promptMenuSelection.title} ({this.getWordCount(prompt.promptText)} words)
										</div>
										<div className="col-md-12 p-0">
											<span className="text-500">Voice Artist: </span>
											{prompt.voiceMenuSelection.title}
										</div>
										<div className="col-md-12 p-0 pr-10">
											<span className="text-500">Message: </span>
											<i>
												{prompt.promptText}
											</i>
										</div>
										<div className="col-md-12 p-0">
											<span className="text-500">Cost: </span>
											<span className="text-secondary">&pound;{this.getPriceForText(prompt.promptText).toFixed(2)}</span>
										</div>
									</div>
								})}

								{this.state.prompts.length > 0 && <div className="order-box-summary text-center text-dark col-md-12 pt-30 pb-30 mt-30" style={{ overflow: 'auto' }}>

									<p className="text-400 pb-20">Total word count is {totalWordCountAllPrompts} words.
										<br />
										<span className="text-500">Total cost is &pound;{totalWordCountAllPromptsPrice}</span>
										<span className="text-xs text-dullest clearfix w-100 d-block">
											{this.state.inhouse === true
											  ? totalWordCountAllPromptsPrice === MinimumPromptPurchaseThresholdInhouse ? `£${MinimumPromptPurchaseThresholdInhouse} minimum order.` : ''
											  :												totalWordCountAllPromptsPrice === MinimumPromptPurchaseThreshold ? `£${MinimumPromptPurchaseThreshold} minimum order.` : ''
											}
										</span>
									</p>
									<Button variant="cta" size="lg" className="btn-xxs-block btn-rounded" disabled={this.state.purchasingPrompts} onClick={event => this.purchasePrompts()}>
										Purchase Prompts
									</Button>
								</div>

								}
							</div>
						</div>

					}
				</>
			}

			<div className="full-width bg-dull pb-60 pt-60">
				<div className="container">
					<div className="row">
						<div className="col-xs-12 col-md-6">
							<div className="bg-light brad-20 box-shadow-v3 p-60 pl-xxs-30 pr-xxs-30">
								<h3 className="text-500 pb-30 mb-0">What we can do for you</h3>
								<p>
									Get your custom prompts recorded by the same voice artist as the rest of the SwitchboardFREE system, providing your callers with a high quality and consistent call experience.
								</p>
								<p>
									There is no limit to the number of prompts you can order. Your Professional Prompts will usually appear in your account on the same day, but please note that it may take up to 6 working days.
								</p>
								<p className="pt-20">
									We can record the following for you:
								</p>
								<ul className="fancy cta pl-0 mt-30">
									<li className="mb-20 bg-dull pl-40 pr-30 pt-20 pb-10 brad-10">
										<h6 className="text-500 mt-5">Standard introduction</h6>
										<p>
											The first thing your callers will hear when you don&apos;t have multiple departments set.
											<span className="text-cta underline cursor-pointer ml-10 text-sm"
											id="StandardIntroductionShowHideLink"
											onClick={() => this.setState({ StandardIntroExample: !this.state.StandardIntroExample })}
											>
												{this.state.StandardIntroExample
												  ? <>
													Hide example
												</>
												  :												<>
													Show example
												</>
												}
											</span>
										</p>
										{this.state.StandardIntroExample &&
											<p id="StandardIntroExample">
												<i>
													&quot;
													Hello and thank you for calling (company name). Please wait while we connect your call.
													&quot;
												</i>
											</p>
										}

									</li>
									<li className="mb-20 bg-dull pl-40 pr-30 pt-20 pb-10 brad-10">
										<h6 className="text-500 mt-5">Global Departments Introduction</h6>
										<p>
											The first message played to your callers when you have multiple department set up.
											<span className="text-cta underline cursor-pointer ml-10 text-sm"
											id="GlobalIntroductionShowHideLink"
											onClick={() => this.setState({ GlobalIntroExample: !this.state.GlobalIntroExample })}
											>
												{this.state.GlobalIntroExample
												  ? <>
													Hide example
												</>
												  :												<>
													Show example
												</>
												}
											</span>
										</p>
										{this.state.GlobalIntroExample &&
											<p id="GlobalIntroExample">
												<i>
													&quot;
													Hello and thank you for calling (company name). Please choose one of the following options...
													&quot;
												</i>
											</p>
										}
									</li>
									<li className="mb-20 bg-dull pl-40 pr-30 pt-20 pb-10 brad-10">
										<h6 className="text-500 mt-5">Departments Menu</h6>
										<p>
											Message listing all available department options.
											<span className="text-cta underline cursor-pointer ml-10 text-sm"
											onClick={() => this.setState({ DepartmentsExample: !this.state.DepartmentsExample })}
											id="DepartmentsExampleShowHideLink"
											>
												{this.state.DepartmentsExample
												  ? <>
													Hide example
												</>
												  :												<>
													Show example
												</>
												}
											</span>
										</p>
										{this.state.DepartmentsExample &&
											<p id="DepartmentsExample">
												<i>
													&quot;
													Press 1 for General Enquiries, press 2 for Sales and press 3 for Accounts... etc.
													&quot;
												</i>
											</p>
										}
									</li>
									<li className="mb-20 bg-dull pl-40 pr-30 pt-20 pb-10 brad-10">
										<h6 className="text-500 mt-5">Individual Department Introduction</h6>
										<p>
											The first thing your callers will hear after they select a department (if enabled).
											<span className="text-cta underline cursor-pointer ml-10 text-sm"
											id="IndividualDepartmentsExampleShowHideLink"
											onClick={() => this.setState({ IndividualDepartmentsExample: !this.state.IndividualDepartmentsExample })}
											>
												{this.state.IndividualDepartmentsExample
												  ? <>
													Hide example
												</>
												  :												<>
													Show example
												</>
												}
											</span>
										</p>
										{this.state.IndividualDepartmentsExample &&
											<p id="IndividualDepartmentsExample">
												<i>
													&quot;
													We are connecting you to our next available consultant*
													&quot;
												</i>
											</p>
										}
									</li>
									<li className="mb-20 bg-dull pl-40 pr-30 pt-20 pb-10 brad-10">
										<h6 className="text-500 mt-5">Out of Hours</h6>
										<p>
											This message is played when your lines are closed.
											<span className="text-cta underline cursor-pointer ml-10 text-sm"
											id="OutOfHoursExampleShowHideLink"
											onClick={() => this.setState({ OutOfHoursExample: !this.state.OutOfHoursExample })}
											>
												{this.state.OutOfHoursExample
												  ? <>
													Hide example
												</>
												  :												<>
													Show example
												</>
												}
											</span>
										</p>
										{this.state.OutOfHoursExample &&
											<p id="OutOfHoursExample">
												<i>
													&quot;
													Thank you for calling (company name), I’m afraid we are now closed. Our offices hours are Monday to Friday 9am till 5.30pm. Could you please leave your name, number and a brief message and somebody will contact you within office hours, thank you.
													&quot;
												</i>
											</p>
										}
									</li>
									<li className="mb-20 bg-dull pl-40 pr-30 pt-20 pb-10 brad-10">
										<h6 className="text-500 mt-5">Call Screening</h6>
										<p>
											A very short message that helps you identify the type of call you receive. You will hear this message before you connect with the caller.
											<span className="text-cta underline cursor-pointer ml-10 text-sm"
											id="CallScreeningExampleShowHideLink"
											onClick={() => this.setState({ CallScreeningExample: !this.state.CallScreeningExample })}
											>
												{this.state.CallScreeningExample
												  ? <>
													Hide example
												</>
												  :												<>
													Show example
												</>
												}
											</span>
										</p>
										{this.state.CallScreeningExample &&
											<p id="CallScreeningExample">
												<i>
													&quot;
													You have a general enquiries call, press the star (*) key to accept the call or press 3 to send the caller to voicemail.
													&quot;
												</i>
											</p>
										}
									</li>
									<li className="mb-20 bg-dull pl-40 pr-30 pt-20 pb-10 brad-10">
										<h6 className="text-500 mt-5">Voicemail</h6>
										<p>
										This is the message your callers will hear if your lines are busy.
											<span className="text-cta underline cursor-pointer ml-10 text-sm"
											id="VoicemailExampleShowHideLink"
											onClick={() => this.setState({ VoicemailExample: !this.state.VoicemailExample })}
											>
												{this.state.VoicemailExample
												  ? <>
													Hide example
												</>
												  :												<>
													Show example
												</>
												}
											</span>
										</p>
										{this.state.VoicemailExample &&
											<p id="VoicemailExample">
												<i>
													&quot;
													Thank you for calling (company name). I am afraid our team is busy at the moment. Can you please leave your name, number and a brief message and we will call you back as soon as possible. Thank you.
													&quot;
												</i>
											</p>
										}
									</li>
								</ul>
							</div>
						</div>
						<div className="col-xs-12 col-md-6">
							<div className="bg-light brad-20 box-shadow-v3 p-60 pl-xxs-30 pr-xxs-30 mt-sm-30">
								<div className="row">
									<div className="col-md-12 pb-30">
										<h3 className="text-500 pb-20">
											Our Professional Voices
										</h3>
										<p>
											Why not use our in-house voice recording team. Currently available at only &pound;60 for 100 words as a minimum order and 27p per word thereafter.
										</p>
									</div>
								</div>
								<div className="row pb-30">
									<div className="col-xs-12 pl-0 pr-0">
										<div className="row inhouse-voices">
										<div className="col-xxs-12 col-xs-6 col-sm-4 col-md-12 col-lg-4">
												<div className="bg-dark bg-gradient p-20 b-1 brad-20 text-center mb-30 zoom">
													<img className="mb-10 mt-10 img-circle" src="/images/team/katie.jpg" alt="Katie - the voice artist" width="80" style={{ maxWidth: '100%' }} />
													<h4 className="text-500 text-lightest mb-20">Katie</h4>
													<ReactAudioPlayer src={katieMp3Sample} controls className="brad-10" />
													{this.props.isLoggedIn ?
														<Link to={'#'} className="btn btn-lightest btn-sm mt-30 btn-rounded mb-10" onClick={event => this.setState({ showingAddPromptModal: true, voiceMenuSelection: voicePromptDropdownSelectionData[3] })} id="KatieSelectButton">Select</Link>
														:
														<>
															<Link to={'#'} onClick={() => this.setState({ showLogin: true })} className="btn btn-lightest btn-sm mt-30 btn-rounded mb-10">Select</Link>
														</>
													}
												</div>
											</div>
											<div className="col-xxs-12 col-xs-6 col-sm-4 col-md-12 col-lg-4">
												<div className="bg-dark bg-gradient p-20 b-1 brad-20 text-center mb-30 zoom">
													<img className="mb-10 mt-10 img-circle" src="/images/team/george.jpg" alt="George - the voice artist" width="80" style={{ maxWidth: '100%' }} />
													<h4 className="text-500 text-lightest mb-20">George</h4>
													<ReactAudioPlayer src={georgeMp3Sample} controls className="brad-10" />
													{this.props.isLoggedIn ?
														<Link to={'#'} className="btn btn-lightest btn-sm mt-30 btn-rounded mb-10" onClick={event => this.setState({ showingAddPromptModal: true, voiceMenuSelection: voicePromptDropdownSelectionData[3] })} id="GeorgeSelectButton">Select</Link>
														:
														<>
															<Link to={'#'} onClick={() => this.setState({ showLogin: true })} className="btn btn-lightest btn-sm mt-30 btn-rounded mb-10">Select</Link>
														</>
													}
												</div>
											</div>
											<div className="col-xxs-12 col-xs-6 col-sm-4 col-md-12 col-lg-4">
												<div className="bg-dark bg-gradient p-20 b-1 brad-20 text-center mb-30 zoom">
													<img className="mb-10 mt-10 img-circle" src="/images/team/zoe2.jpg" alt="Zoe - the voice artist" width="80" style={{ maxWidth: '100%' }} />
													<h4 className="text-500 text-lightest mb-20">Zoe</h4>
													<ReactAudioPlayer src={zoeMp3Sample} controls className="brad-10" />
													{this.props.isLoggedIn
													  ? <Link to={'#'} className="btn btn-lightest btn-sm mt-30 btn-rounded mb-10" onClick={event => this.setState({ showingAddPromptModal: true, voiceMenuSelection: voicePromptDropdownSelectionData[2] })} id="ZoeSelectButton">Select</Link>
													  :														<>
															<Link to={'#'} onClick={() => this.setState({ showLogin: true })} className="btn btn-lightest btn-sm mt-30 btn-rounded mb-10">Select</Link>
														</>
													}
												</div>
											</div>
										</div>
									</div>
								</div>
								<div className="row">
									<div className="col-md-12 text-left pb-30 pt-20">
										<h3 className="text-500 pb-20">
											Our Premium Voices
										</h3>
										<p>
										Use one of the UK's most recognised voices for your phone greetings and prompts. Prices for your recordings start from £180.00.
										<br/>
										Email us your script to <a href="mailto:support@switchboardfree.co.uk?subject=Custom%20Prompts%order">support@switchboardfree.co.uk</a> to get your quote now.
										</p>
										{/* <p>
											Use one of the UK's most recognised voices for your phone greetings and prompts. Get your Custom Prompt <br className="d-none d-lg-block" />recorded for only 70p per word. Minimum order of only &pound;180.00 applies.
										</p> */}
									</div>
								</div>
								<div className="row pb-30">
									<div className="col-xs-12">
										<div className="row">
											<div className="col-xxs-12 col-xs-6 col-md-12 col-lg-8 mx-auto">
												<div className="bg-dark bg-gradient p-20 brad-20 text-center zoom mb-30">
													<img className="mb-10 mt-10" src="/images/janice-round.png" alt="Janice - the voice artist" width="80" style={{ maxWidth: '100%' }} />
													<h4 className="text-500 text-lightest">Janice</h4>
													<p className="pb-20 text-lightest text-sm">
														As the voice of the Vodafone Network, Janice provides a warm reassuring female voice with southern dialect.
													</p>
													<ReactAudioPlayer src={janiceMp3Sample} controls className="brad-10" />
													{this.props.isLoggedIn
													  ? <Link to={'#'} className="btn btn-lightest btn-sm mt-30 btn-rounded mb-30" id="JaniceSelectButton" onClick={event => this.setState({ showingAddPromptModal: true, voiceMenuSelection: voicePromptDropdownSelectionData[0] })}>Select</Link>
													  :														<>
															<Link to={'#'} onClick={() => this.setState({ showLogin: true })} className="btn btn-lightest btn-sm mt-30 btn-rounded mb-30">Select</Link>
														</>
													}
												</div>
											</div>
										</div>
										<div className="col-sm-12 text-center pt-30 pb-30">
											<p className="text-xs">
												By purchasing this product you agree to the <a onClick={e => this.setState({ showTsAndCs: true })} className="text-dark underline">terms &amp; conditions</a> of this product.
												<br />All prices are exclusive of VAT at 20%.
											</p>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="full-width bg-secondary pt-60 pb-60">
				<div className="container pl-xs-0 pr-xs-0">
					<CarouselWidget light={true} />
				</div>
			</div>
			<div className="full-width full-img bg-light pt-30 pb-60">
				<div className="container">
					<div className="row">
						<div className="col-md-12 text-center">
							<h4 className="text-500">Have any questions?</h4>
							<h5 className="mb-20 text-400"> Give us a call on <a href="tel:02031891213">0203 189 1213</a> or request a callback</h5>
							<CallbackRequest />
						</div>
					</div>
				</div>
			</div>

			{this.props.isLoggedIn &&
				<ChoosePayment
					total={calculateVat(totalWordCountAllPromptsPrice)}
					clientId={this.props.clientId}
					purchaseData={this.state.key}
					email={this.props.userDetails.email}
					name={`${this.props.userDetails.fName} ${this.props.userDetails.sName}`}
					mandate={!this.props.services.hasAgreement}
				/>

			}
			{this.props.isLoggedIn
			  ? <></>
			  :				<Modal size="w-400px" show={this.state.showLogin} onHide={() => this.setState({ showLogin: false })}>
					{/* <Modal.Header  className="b-0">
                    <h5 className="text-center text-400">Log into your account</h5>
                </Modal.Header> */}
					<Modal.Body>
						<span className="close-modal" onClick={e => this.setState({ showLogin: false })}>
							<span>&times;</span>
						</span>
						<div className="row">
							<div className="col-md-12 text-center pb-20">
								<h5 className="text-center text-400">Log into your account</h5>
							</div>
							<div className="col-md-12 text-center pb-30">
								<form name="form" className="text-start" onSubmit={e => this.props.login({
								  userName: this.props.userName,
								  password: this.props.password,
								  event: e,
								  history: this.props.history,
								  persist: document.getElementById('persistLogin').checked,
								  route: '/professional-prompts'
								})}>
									<div className="row">
										<div className="col-xs-12">
											<label>Email</label>
											<input className="form-control" placeholder="Your login email..." type='email' value={this.props.userName} onChange={e => this.props.updateUsername(e.target.value)} />
										</div>
										<div className="col-xs-12 mt-20">
											<label>Password</label>
											<input className="form-control" placeholder="Your password..." type='password' value={this.props.password} onChange={e => this.props.updatePassword(e.target.value)} />
										</div>
									</div>
									<div className="pt-10 pb-10 row pl-15 pr-15">
										<div className="col-xs-6 pl-0">
											<input type="checkbox" id='persistLogin' defaultChecked={true} /> <label>Remember</label>
										</div>
										<div className="col-xs-6 text-end pr-0">
											<Link to={'/forgot-password'} className="text-xs text-cta">Forgot password?</Link>
										</div>
									</div>
									<div className="text-center pt-30">
										{this.props.loggingIn && <p>Now logging you in, please wait ...</p>}

										{this.props.loginFailed && <p className="alert alert-danger text-sm">There has been an error logging in, please check your username and password and try again. If that doesn't help, try <Link to={'/forgot-password'} className="underline text-danger">resetting your password</Link>.</p>}

										<button className="btn btn-cta btn-lg btn-block">Log In</button>
										<p className="text-center bt-1 mt-30 pt-30 text-sm">
											Don&apos;t have an account with us yet? <br /><Link to="/get-started" className="underline text-cta">Create your account now</Link>
										</p>

									</div>
								</form>
							</div>
						</div>
					</Modal.Body>
					{/* <Modal.Footer>
                    <Button variant="default" size="xs" onClick={() => this.setState({ showLogin: false })}>Close</Button>
                </Modal.Footer> */}
				</Modal>
			}
		</>
  }

  handleAddPrompts (newPrompts) {
    this.setState({
      prompts: newPrompts,
      showingAddPromptModal: false,
      promptText: undefined
    }, () => {
      localStorage.setItem('promptsBasket', JSON.stringify(newPrompts))
    })
  }

  componentDidMount () {
    const basket = localStorage.getItem('promptsBasket')
    if (basket) {
      this.setState({ prompts: JSON.parse(basket) })
    }

    // stops from playing two audios at the same time.
    document.addEventListener('play', function (e) {
      const audios = document.getElementsByTagName('audio')
      for (let i = 0, len = audios.length; i < len; i++) {
        if (audios[i] !== e.target) {
          audios[i].pause()
        }
      }
    }, true)

    const queryString = getQueryStringFromProps(this.props)
    if (queryString) {
      const params = queryString.toLowerCase()
      if (params.includes('new=true')) {
        this.props.notify({ message: 'Thank you, your prompts have been ordered' })
      }
    }

    // if (this.state.isLoggedIn) {

    //         scroller.scrollTo('start', {
    //             duration: 1500,
    //             delay: 100,
    //             smooth: true,
    //             offset: -125
    //         });

    // }
  }

  componentDidUpdate () {
    if (this.props.isLoggedIn) {
      document.body.scrollTop = 0
    }
  }
}

export default connect(
  state => {
    return {
      isLoggedIn: isLoggedIn(state),
      // userFirstName: getFirstName(state),
      userName: state.home.login.username,
      password: state.home.login.password,
      loggingIn: state.home.login.loggingIn,
      loginFailed: state.home.login.loginFailed,
      clientId: state.sbfApp.authentication.clientId,
      services: state.sbfApp.enabledServices,
      userDetails: state.sbfApp.authentication
    }
  },
  {
    notify: handleNotificationChange,
    login: fromHome.handleLogin,
    updateUsername: fromHome.updateUsername,
    updatePassword: fromHome.updatePassword,
    handleShowPaymentOptionsModal,
    handleShowPaymentPostponedModal
  }
)(ProfessionalPrompts)
