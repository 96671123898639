import moment from 'moment'
import React from 'react'
import { Button, FloatingLabel, Form, Modal } from 'react-bootstrap'
import Toggle from 'react-bootstrap-toggle'
import Countdown from 'react-countdown'
import { Helmet } from 'react-helmet'
import { connect } from 'react-redux'
import { fetchHelper } from '../../helpers/fetchHelper'
import { handleNotificationChange, handleShowCallbackForm, handleShowPaymentOptionsModal } from '../../store/SBF'
import { UnlimitedOutboundPlan, BasicOutboundPlan, MultiNumberPrice } from '../../constants/Constants'
import { isLoggedIn } from '../../selectors'
import DisplayMainFooter from '../../components/DisplayMainFooter'
import Logos from '../../components/ClientLogos'
import LoadingNotification from '../../components/LoadingNotification'
import ChoosePayment from '../../components/purchase/ChoosePayment'
import CarouselWidget from '../../components/Reviews/CarouselWidget'
import StatsBanner from '../../components/StatsBanner'
import { BillingPeriodUnit } from '../../models/SignupTypes'
import * as fromHome from '../../store/Home'
import TermsAndConditions from '../../Text/TermsAndConditions'
import { Link } from 'react-router-dom'
import PlansAndNumbersDropdown from '../../components/PlansAndNumbersDropdown'
import DdiSelectorNew from '../../components/purchase/DdiSelectorNew'
import { getEmptyBasket, calculateVat, calculateRemainingMonth } from '../../helpers/utils'
import LocalStorageHelper from '../../helpers/LocalStorageHelper'

class VoIPUpsell extends React.Component {
  state = {
    isLoading: true,
    prefix: this.props.match.params.prefix ? this.props.match.params.prefix.substring(0, 5) : "01",
    isPurchaseInProgress: false
  }

  constructor(props) {
    super(props)
  }

  render() {
    if (this.state.finished === true)
      localStorage.removeItem('LandingPageNumberSelection');

    const basket = getEmptyBasket()
    const numbers = LocalStorageHelper.getItem('LandingPageNumberSelection')
    basket.numbers = numbers.value ?? []

    let total = basket.numbers?.length ?? 0 * MultiNumberPrice;
    basket.numbers.forEach((num) => total = total + num.ddipremium)


    return <><DisplayMainFooter />
      <Helmet>
        <body className={'body-simple bg-light'} />
        <title>EXCLUSIVE! Claim Your FREE VoIP Phones!</title>
        <style type="text/css">{`
          body .number-page .numbers-wrapper { width: 65%; }
          body .number-page #currentBasket { width: 33%; }
        `}</style>
      </Helmet>
      <>
        <div className="p-50 p-xs-20" >
          <div className="container p-xs-0">
            <div className="row mb-50">
              <div className="col-md-10 mx-auto text-center">
                <p className="text-lg text-400 mt-30">
                  <em>Make your business <span className="text-cta">stand out</span> and more memorable to customers.</em>
                </p>
                <h1 className="text-600 text-xxl">
                  Add a <em className="text-cta"><u>Gold, or Silver</u></em>, <br className="d-none d-md-inline" />Premium Number To Your Business!
                </h1>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-10 col-md-12 col-sm-12 mx-auto ">
                <div className="bg-dull p-50 p-sm-30 p-xs-20 brad-20 pr-xs-20 pl-xs-20 clearfix">
                  {this.state.finished
                    ? <>
                      <h3 className="text-500 mt-30">
                        All done!
                      </h3>
                      <p className="text-400 lead mb-10 mt-0">Congratulations, your new numbers have been added to your account.</p>
                      <p className="mt-30">
                        <Button variant="cta" size="lg" className="pl-100 pr-100 pl-xs-20 pr-xs-20" onClick={() => this.props.history.push('/customer/dashboard')}>Go to my dashboard</Button>
                      </p>
                    </>
                    : <>
                      <p className="text-400 text-uppercase text-lg mb-10 mt-0">Find your perfect number!</p>
                      <p className="text-md mb-30 mt-10">
                        <span className="text-uppercase text-500 mr-10 text-md"><em>
                          Add a new memorable number to your account today<br /><span className="text-cta underline">from just &pound;{5 + MultiNumberPrice} per number.</span></em>
                        </span>
                      </p>

                      <div className="row mb-30">
                        <div className="col-sm-3 col-md-4 col-xs-3 text-sm pt-10 col-xxs-12 text-xxs-center pb-xxs-10 text-400">
                          <em><span className="text-500">Step 1:</span> Choose numbers:</em>
                        </div>
                      </div>

                      <div className="number-page">
                        <PlansAndNumbersDropdown
                          //displayAll={true}
                          prefix={this.state.prefix && this.state.prefix.length <= 4 ? this.state.prefix : '01'}
                          ddiLid={this.state.ddiLid?.value}
                          defaultArea={this.state.prefix}
                          handleChangeNumberType={e => this.setState({ prefix: e })}
                          handleChangeAreaCode={e => this.setState({ ddiLid: e })}
                          hidePlans={true}
                          preventDefaultSelection={true}

                          WrapperClassName="mb-40"
                          LabelsClassName="text-xs text-dark text-400"

                          NumberButtonClassName={'text-start bg-light pl-25 pr-25 ml-5 b-0 inbound-only'}
                          NumberNameClassName="text-dark text-400"
                          NumbersResultsDropdownClassName="bg-light ml-5 box-shadow"
                          NumberNameClassNameSelected="text-cta text-500"

                          AreaCodeButtonClassName="text-start bg-light pl-25 pr-25 ml-5 b-0"
                          AreaCodeNameClassName="text-dark text-400"
                          AreaCodeResultsDropdownClassName="bg-light box-shadow"
                          AreaCodeNameClassNameSelected="text-cta text-500"
                        />

                        <DdiSelectorNew
                          selectedGeoRegion={this.state.ddiLid}
                          hideReviews={true}
                          isSimple={true}
                          premiumOnly={true}
                          numberType={this.state.prefix}
                          isSignup={false}
                          currentBasket={basket}
                          hideShowAll={false}
                          isModal={false}
                          hideLoadMore={false}
                          hideRegions={true}
                          hidestars={false}
                          isLoggedIn={true}
                          isEditing={false}
                          menuOpen={false}
                          citysOnly={false}
                          ddiLid={this.state.ddiLid?.value}
                          handleUpdateBasket={(_, sbfNumber) => {
                            // let item: LocalStorageItem<SbfNumber[]> = LocalStorageHelper.getItem < SbfNumber[] > ("LandingPageNumberSelection");
                            const item = LocalStorageHelper.getItem('LandingPageNumberSelection')
                            let sbfNumbers = item.value ?? []
                            // add the number to the array if it doesn't already exist, otherwise remove it
                            if (sbfNumbers.find(number => number.number === sbfNumber.number)) {
                              sbfNumbers = sbfNumbers.filter(number => number.number !== sbfNumber.number)
                            } else {
                              sbfNumbers.push(sbfNumber)
                            }
                            LocalStorageHelper.setItem('LandingPageNumberSelection', sbfNumbers)
                            this.setState({})
                          }}
                        />
                      </div>

                      <div className="mb-20 mt-30">
                        {this.state.isPurchaseInProgress
                          ? <div className="clearfix">
                              <LoadingNotification loadingText={'Please wait...'} textColor="text-dark" isLoading={true} width={50} height={50} />
                            </div>
                          : <>
                            <div className="clearfix">
                              {(basket && basket.numbers && basket.numbers.length > 0) &&
                                <>
                                  <p className="text-400 lead">
                                    Add {basket.numbers.length} numbers to your account<br />
                                    Total payment today &pound;{parseFloat(calculateRemainingMonth(total, this.state.billingDay)).toFixed(2)}+vat
                                  </p>
                                </>
                              }
                              <Button variant="cta" size="lg" disabled={(basket.numbers?.length ?? 0) === 0} onClick={() => this.handlePurchase(basket)}>Add to account now</Button>
                              {(basket && basket.numbers && basket.numbers.length > 0) &&
                                <>
                                  <p className="text-sm mt-10">
                                    Pro-rated payment today.<br />Following payments will be &pound;{total} per month.
                                  </p>
                                </>
                              }
                            </div>
                          </>
                        }
                      </div>

                      {this.state.diallerSent === false &&
                        <p className="text-400 text-danger text-center">
                          Sorry, there was a problem submitting your request, please try again.
                        </p>
                      }
                    </>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="bg-dull p-50 text-center">
          <sup>*</sup> Standard terms &amp; conditions apply. <Button variant="link" className="text-dark underline" onClick={() => this.setState({ showTsAndCs: true })}>Click here to find out more</Button>
        </div>
      </>

      <div className="bg-light pt-50 pb-50">
        <h3 className="text-center text-500 mb-20 text-lg">Proud to work with leading UK businesses</h3>
        <Logos style="light" />
      </div>

      <div className="full-width bg-dull">
        <div className="container pl-xs-0 pr-xs-0">
          <CarouselWidget />
        </div>
      </div>

      <div className="full-width full-secondary p-20">
        <div className="row">
          <div className="col-md-12">
            <h3 className="text-center text-lightest mt-10 text-100 text-xl">
              <StatsBanner />
            </h3>
          </div>
        </div>
      </div>

      <div className="full-width bg-light">
        <div className="container">
          <div className="row">
            <div className="col-md-12 text-center">
              <h3 className="text-500">SwitchboardFREE</h3>
              <h6 className="text-400">The UK's favourite call management system</h6>
              <p className="text-400">Instant activation  &bull;  No long-term contracts  &bull;  Award-winning UK support</p>
            </div>
          </div>
        </div>
      </div>

      <Modal className="terms-modal" show={this.state.showTsAndCs} onHide={e => this.setState({ showTsAndCs: false })}>
        <Modal.Header closeButton>
          <Modal.Title className="text-500">Terms &amp; Conditions</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <TermsAndConditions />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={e => this.setState({ showTsAndCs: false })}>Close</Button>
        </Modal.Footer>
      </Modal>

      <ChoosePayment
        total={this.state.wpAmount}
        clientId={this.props.clientId}
        purchaseData={this.state.key}
        returnRoute={`/get/free-voip-phone?t=${this.state.token}`}
        email={this.props.userDetails.email}
        name={`${this.props.userDetails.fName} ${this.props.userDetails.sName}`}
        mandate={!this.props.services.hasAgreement}
      />

      <Modal size="sm" show={this.state.showLogin}>
        <Modal.Header>
          <Modal.Title className="mx-auto">Log into your account</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-md-12 text-center pb-30">
              <form name="form" className="text-left" onSubmit={e => this.props.login({
                userName: this.props.userName,
                password: this.props.password,
                event: e,
                history: this.props.history,
                persist: document.getElementById('persistLogin').checked,
                route: `/get/golden-numbers/?t=${this.state.token}`
              })}>
                <div className="row">
                  <div className="col-xs-12">
                    <p className="lead text-400">Oops, it looks like your token has expired, or is not valid. Please log into your account below.</p>
                  </div>
                  <div className="col-xs-12">
                    <label>Email</label>
                    <input className="form-control" placeholder="Your login email..." type='email' value={this.props.userName} onChange={e => this.props.updateUsername(e.target.value)} />
                  </div>
                  <div className="col-xs-12 mt-20">
                    <label>Password</label>
                    <input className="form-control" placeholder="Your password..." type='password' value={this.props.password} onChange={e => this.props.updatePassword(e.target.value)} />
                  </div>
                </div>
                <div className="pt-10 pb-10">
                  <div className="col-xs-6 pl-0">
                    <input type="checkbox" id='persistLogin' defaultChecked={true} /> <label>Remember</label>
                  </div>
                  <div className="col-xs-6 text-right pr-0">
                    <Link to={'/forgot-password'} className="text-xs text-cta">Forgot password?</Link>
                  </div>
                </div>
                <div className="text-center pt-30">
                  {this.props.loggingIn && <p>Now logging you in, please wait ...</p>}

                  {this.props.loginFailed && <p className="alert alert-danger">There has been an error logging in, please check your username and password and try again. If that doesn't help, try <Link to={'/forgot-password'}>resetting your password</Link>.</p>}

                  <button className="btn btn-cta btn-lg btn-mobile-block">Log In</button>

                  <p className="lead text-center bt-1 mt-30 pt-30">Don&apos;t have an account with us yet?</p>
                  <p className="text-center"><Link to="/get-started" className="btn btn-secondary btn-sm">Create an account</Link></p>
                </div>
              </form>
            </div>
          </div>
        </Modal.Body>
      </Modal>

    </>
  }

  handlePurchase(basket) {
    const context = this
    const url = `api/AccessToken/PurchaseNumber?t=${context.state.token}`

    const data = {
      Basket: basket
    }

    context.setState({ isPurchaseInProgress: true }, () => {
      fetchHelper.postJson(url, data)
        .then(res => {
          if (res.data && res.data.success === true) {
            context.setState({ finished: true })
          } else {
            context.setState({ wpAmount: res.data.amount, key: res.data.key, isPurchaseInProgress: false, clientId: res.data.clientId }, () => {
              context.props.handleShowPaymentOptionsModal()
            })
          }
        }).catch(function (error) {
          context.setState({ isPurchaseInProgress: false })
          console.error(error)
        })
    })
  }

  componentWillMount() {
    const context = this
    let token = localStorage.getItem('accessToken')
    const query = new URLSearchParams(this.props.location.search)
    if (!token && query) {
      // auth
      token = query.get('t')
    }

    context.setState({ token })
  }

  componentDidMount() {
    const context = this
    context.setState({ added: ((Math.random() * 18) + 2).toFixed(0), unitsLeft: ((Math.random() * 100) + 50).toFixed(0) })

    if (!context.state.token && !this.props.isLoggedIn) {
      // Login
      this.setState({ showLogin: true })
    } else if (context.state.token) {
      fetchHelper.getJson(`/api/AccessToken/GetClientBillingDay?t=${context.state.token}`)
        .then(res => this.setState({ billingDay: res.data }))
        .catch(err => console.error(err));
    }
  }
}

export default connect(
  state => {
    return {
      isLoggedIn: isLoggedIn(state),
      services: state.sbfApp.enabledServices,
      userDetails: state.sbfApp.authentication,
      userName: state.home.login.username,
      password: state.home.login.password,
      loggingIn: state.home.login.loggingIn,
      loginFailed: state.home.login.loginFailed
    }
  },
  {
    login: fromHome.handleLogin,
    updateUsername: fromHome.updateUsername,
    updatePassword: fromHome.updatePassword,
    notify: handleNotificationChange,
    handleShowCallbackForm,
    handleShowPaymentOptionsModal
  }
)(VoIPUpsell)
