import moment from 'moment'
import React from 'react'
import Toggle from 'react-bootstrap-toggle'
import Countdown from 'react-countdown'
import LoadingNotification from '../../components/LoadingNotification'
import { Button, Modal, Form, FloatingLabel } from 'react-bootstrap'
import { Helmet } from 'react-helmet'
import { connect } from 'react-redux'
import { fetchHelper } from '../../helpers/fetchHelper'
import { handleNotificationChange, handleShowCallbackForm, handleCallRecordingAllEnableChange, handleShowPaymentOptionsModal, handleDeliveryNameChange, handleDeliveryAdd1Change, handleDeliveryAdd2Change, handleDeliveryTownChange, handleDeliveryCountyChange, handleDeliveryPcodeChange, handleDeliveryNoteChange } from '../../store/SBF'
import { ProductIds, productBillingOption, billingPeriod } from '../../constants/Constants'
import { getProductPrice } from '../../store/Home'
import { isLoggedIn } from '../../selectors'
import { calculateVat, calculateRemainingMonth } from '../../helpers/utils'
import Logos from '../../components/ClientLogos'
import DisplayMainFooter from '../../components/DisplayMainFooter'
import ChoosePayment from '../../components/purchase/ChoosePayment'
import CarouselWidget from '../../components/Reviews/CarouselWidget'
import StatsBanner from '../../components/StatsBanner'
import * as fromHome from '../../store/Home'
import TermsAndConditions from '../../Text/TermsAndConditions'
import { Link } from 'react-router-dom'
import AddressLookup from '../../components/AddressLookup'


class CallRecordingUpsell extends React.Component {
  state = {
    isPurchaseInProgress: false,
    finished: false,
    addTranscription: false,
    isUnlimited: true,
    isAnnual: false,
    billingDay: 1,
    showCheckout: false,
    isHalfPrice: false,
    billingOptionId: productBillingOption.callRecordingAllBillingOptionMonthly
  }

  constructor(props) {
    super(props)
  }

  getPrice() {
    const callRecordPaymentDetails = this.props.productDetails.find(prod => prod.billingOptionId === productBillingOption.callRecordingAllBillingOptionMonthly)
    const unlimitedCallRecording = this.props.productDetails.find(prod => prod.billingOptionId === productBillingOption.callRecordingUnlimitedBillingOptionMonthly)

    this.setState({ 
      price: {
        unlimited: {
          monthly: unlimitedCallRecording.monthlyPrice.repeatPaymentAmount,
          yearly: unlimitedCallRecording.yearlyPrice.repeatPaymentAmount
        },
        standard: {
          monthly: callRecordPaymentDetails.monthlyPrice.repeatPaymentAmount,
          yearly: callRecordPaymentDetails.yearlyPrice.repeatPaymentAmount
        }
      }

    })
  }

  render() {
    const renderCountdown = ({ days, hours, minutes, seconds, completed }) => {
      // Check if less than 2 weeks left, 50% discount kicks in
      if (days <= 14 && !this.state.isHalfPrice)
        this.setState({ isHalfPrice: true })

      // Render a countdown
      return <>
        <p className="text-700 text-cta text-uppercase text-md mb-20">
          Ends in:
        </p>
        <div className="countdown-timer">
          <div className="timer-block">
            {days}<br /><span className="text-sm">days</span>
          </div>
          <div className="timer-block">
            {hours}<br /><span className="text-sm">hours</span>
          </div>
          <div className="timer-block">
            {minutes}<br /><span className="text-sm">minutes</span>
          </div>
          <div className="timer-block">
            {seconds}<br /><span className="text-sm">seconds</span>
          </div>
        </div>
      </>
    }

    if (this.props.productDetails && !this.state.price) {
      this.getPrice();
    }

    let peopleAddedThis = 0;

    if (localStorage.getItem('peopleAdded')) {
      peopleAddedThis = localStorage.getItem('peopleAdded');
    } else {
      peopleAddedThis = ((Math.random() * 15) + 2).toFixed(0);
      localStorage.setItem('peopleAdded', peopleAddedThis);
    }

    let totalToday = 0;

    if (!this.state.isAnnual) {
      totalToday = parseFloat(calculateRemainingMonth((this.state.isUnlimited ? this.state.price?.unlimited?.monthly : this.state.price?.standard?.monthly), this.state.billingDay)).toFixed(2)
    } else {
      totalToday = parseFloat((this.state.isUnlimited ? this.state.price?.unlimited?.monthly : this.state.price?.standard?.monthly) * 12).toFixed(2)
    }

    if (this.state.isHalfPrice)
      totalToday = (totalToday / 2).toFixed(2);

    return <><DisplayMainFooter />
      <Helmet>
        <body className={'body-simple bg-dull'} />
        <title>{this.state.isHalfPrice ? "EXCLUSIVE. Half Price Call Recording, For Life!" : "Get Call Recording Enabled On Your Account"}</title>
        <style type="text/css">{`
						.button-bar {
							background: #fff;
							border-top: #ddd 1px solid;
							padding: 20px;
							position: fixed;
							bottom: 0;
							left: 0;
							right: 0;
							z-index: 900;
							text-align: center;
							padding-bottom: 10px;
						}
						.background {
							background-position: 95% 55% !important;
							background-size: 40% !important;
						}
						.background.smaller {
							background-position: 95% 95% !important;
							background-size: 40% !important;
						}
					}
					`}</style>
      </Helmet>
      {this.state.isLoading
        ? <div className="p-50">
          <LoadingNotification loadingText={'Getting your plans, please wait...'} textColor="text-dark" isLoading={true} width={50} height={50} />
        </div>
        : <>
          <div className="p-50 background" style={{ background: '#fff url(/images/campaigns/headphones/headphones.png) no-repeat' }}>
            <div className="container p-xs-0">
              <div className="row mb-50">
                <div className="col-md-10 mx-auto text-center">
                  <span className="bg-secondary p-10 pr-50 pl-50 pl-xs-10 pr-xs-10 text-light text-lg text-500 text-uppercase text-lightest text-xs-sm">
                    By Invitation Only
                  </span>
                  <p className="text-lg text-400 mt-30">
                    {this.state.isHalfPrice ?
                      <>
                        If you’ve landed on this page then you have an exclusive invitation to take <br className="d-none d-xs-block" />advantage of <span className="text-cta text-500">half-price call recording... for life!</span>
                      </> : <>
                        If you’ve landed on this page then you have an exclusive invitation to take <br className="d-none d-xs-block" />advantage of <span className="text-cta text-500">unlimited call recording... for life!</span>
                      </>
                    }
                  </p>

                  <h1 className="text-600">
                    <em className="text-cta"><u>Call Recording</u></em> - We Don’t <br className="d-none d-xs-block" />Shout About it Enough!
                  </h1>
                  {this.state.isHalfPrice ?
                    <h2 className="text-500 h3 mt-30">
                      Overlooked &amp; under-utilised, call recording can offer <br className="d-none d-xs-block" />a whole new perspective on your business.<br /><br />And now you can get it for <span className="text-cta">HALF PRICE - FOR LIFE!</span>
                    </h2>
                    :
                    <h2 className="text-500 h3 mt-30">
                      Overlooked &amp; under-utilised, call recording can offer <br className="d-none d-xs-block" />a whole new perspective on your business.<br /><br />And now you can get it <span className="text-cta">UNLIMITED - FOR LIFE!</span>
                    </h2>
                  }
                </div>
              </div>
              <div className="row">
                <div className="col-lg-7 col-md-8 col-sm-12">
                  <div className="bg-dull p-50 p-sm-30 p-xs-20 brad-20 pr-100 pl-100 pr-sm-50 pl-sm-50 pr-xs-20 pl-xs-20 clearfix">
                    {this.state.hasCallRecording
                      ? <>
                        <p className="text-400 text-uppercase text-lg mb-10 mt-0">Good news!</p>
                        <h3 className="text-500">
                          You've already got Call Recording.
                        </h3>
                      </>
                      : this.state.offerEnded
                        ? <>
                          <h3 className="text-500 mt-30">
                            Oh no!
                          </h3>
                          <p className="text-400 lead mb-10 mt-0">Sorry, but this offer has now ended.</p>
                          <p className="text-md mt-30">
                            Please keep an eye on your inbox and our social media channels for new offers in the future.
                          </p>
                          <p className="mt-30">
                            <Button variant="cta" size="lg" className="pl-100 pr-100 pl-xs-20 pr-xs-20" onClick={() => this.props.history.push('/customer/dashboard')}>Go to my dashboard</Button>
                          </p>
                        </>
                        : this.state.finished
                          ? <>
                            <h3 className="text-500 mt-30">
                              All done!
                            </h3>
                            <p className="text-400 lead mb-10 mt-0">Congratulations, you can now start recording your calls.</p>
                            <p className="text-md mt-30">
                              We've successfully added Call Recording on to your account for you!
                            </p>
                            <p className="mt-30">
                              <Button variant="cta" size="lg" className="pl-100 pr-100 pl-xs-20 pr-xs-20" onClick={() => this.props.history.push('/customer/dashboard')}>Go to my dashboard</Button>
                            </p>
                          </>
                          : <>

                            <p className="text-500">
                              When you record every detail from each call, you can download it, save it and listen back, to...
                            </p>

                            <ul className="unstyled cta text-500 ml-xs-0 ml-0 pl-0 mt-30 text-xs-xs">
                              <li className="text-md mb-5"><i className="icon icon-checkmark4 text-cta mr-5"></i><em>Check you're providing a high-quality customer service.</em></li>
                              <li className="text-md mb-5"><i className="icon icon-checkmark4 text-cta mr-5"></i><em>Check you’re remaining legal & above board.</em></li>
                              <li className="text-md mb-5"><i className="icon icon-checkmark4 text-cta mr-5"></i><em>Check your staff are doing a great job.</em></li>
                              <li className="text-md mb-5"><i className="icon icon-checkmark4 text-cta mr-5"></i><em>Check that relayed information is up to date.</em></li>
                              <li className="text-md mb-5"><i className="icon icon-checkmark4 text-cta mr-5"></i><em>Check to see if the customer <em>really</em> is right!</em></li>
                            </ul>

                            <p className="text-md mb-20 mt-30">
                              Of course, the above is a quick overview of how call recording can be used in your business. The reality is that having a record of each call could get you out of a sticky situation in respect of legal compliance and customer complaints. It can also be used as a tool to train staff and be analysed to gain insight into customer needs to increase sales and improve your service.
                            </p>

                            {!this.state.isHalfPrice ?
                              <p className="text-500 text-uppercase text-lg mb-10 mt-0 text-cta"><span className="underline">Unlimited for Life?</span> 🔥
                                <br className="d-none d-xs-block" /><span className="text-dark">Yes, Please!</span> </p> :
                              <p className="text-500 text-uppercase text-lg mb-10 mt-0 text-cta"><span className="underline">Half Price for Life?</span> 🔥
                                <br className="d-none d-xs-block" /><span className="text-dark">Yes, Please!</span> </p>

                            }

                            {this.state.isHalfPrice ?
                              <p className="lead text-500 mt-30">
                                We feel that call recording is essential in maintaining a successful and efficiently run business. So for a limited time only, we’re offering you...<br /><br />
                                <span className="text-500 text-cta">CALL RECORDING & STORAGE, HALF-PRICE - FOR LIFE!</span>
                              </p>
                              :
                              <p className="lead text-500 mt-30">
                                We feel that call recording is essential in maintaining a successful and efficiently run business, so we’re offering you for a <strong>brief time only...</strong><br /><br />
                                <span className="text-500 text-cta">UNLIMITED CALL RECORDING & STORAGE - FOR LIFE!</span>
                              </p>
                            }

                            <p>Choose your package &amp; payment frequency below</p>

                            <div className="row mb-30">
                              <div className="col-sm-3 col-md-4 col-xs-3 text-sm pt-10 col-xxs-12 text-xxs-center pb-xxs-10 text-400">
                                <em><span className="text-500">Step 1:</span> Choose package:</em>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-4">
                                <div className="bg-light brad-10 p-20 b-1-dark text-center mb-xs-20 p-xs-10">
                                  <div className="d-flex mt-neg-40 ml-neg-40">
                                    <div className="flash d-inline-block">
                                      <span>
                                        Most
                                        <br />
                                        popular
                                      </span>
                                    </div>
                                  </div>

                                  <h3 className="text-cta text-500 text-lg mt-neg-10">
                                    Unlimited
                                  </h3>
                                  <p className="text-md mt-10 text-500">
                                    {this.state.isHalfPrice ?
                                      <>
                                        <strike className="text-dullest">Was &pound;{this.state.price?.unlimited?.monthly}</strike><br />
                                        Now Just &pound;{this.state.price?.unlimited?.monthly / 2}<br />
                                      </> : <>
                                        Just &pound;{this.state.price?.unlimited?.monthly}<br />
                                      </>}
                                    <span className="text-400 text-sm">per month</span>
                                  </p>
                                  <Button variant={this.state.isUnlimited ? "cta" : "default"} size="sm" className="btn-block mb-xs-0" onClick={() => this.setState({ isUnlimited: true })}>
                                    {this.state.isUnlimited ? 'Selected' : 'Select'}
                                  </Button>
                                  <span className="text-xs text-400">Includes unlimited storage</span>
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="bg-light brad-10 p-20 b-1-dark text-center mb-xs-20 p-xs-10">
                                  <h3 className="text-cta text-500 text-lg">
                                    Standard
                                  </h3>
                                  <p className="text-md mt-10 text-500">
                                    {this.state.isHalfPrice ?
                                      <>
                                        <strike className="text-dullest">Was &pound;{this.state.price?.standard?.monthly}</strike><br />
                                        Now Just &pound;{this.state.price?.standard?.monthly / 2}<br />
                                      </> : <>
                                        Just &pound;{this.state.price?.standard?.monthly}<br />
                                      </>}
                                    <span className="text-400 text-sm">per month</span>
                                  </p>
                                  <Button variant={this.state.isUnlimited ? "default" : "cta"} size="sm" className="btn-block" onClick={() => this.setState({ isUnlimited: false })}>
                                    {this.state.isUnlimited ? 'Select' : 'Selected'}
                                  </Button>
                                  <span className="text-xs text-400">Plus, 1p per minute storage</span>
                                </div>
                              </div>
                            </div>

                            <div className="row mt-20">
                              <div className="col-md-10 col-sm-10">

                                {!this.state.isUnlimited
                                  ? <>
                                    <h3 className="text-600">
                                      Standard Call Recording
                                    </h3>
                                    <p className="text-md mb-30 mt-10">
                                      {this.state.isHalfPrice &&
                                        <strike className="text-dullest mr-10">Was &pound;{this.state.price?.standard?.monthly}</strike>
                                      }
                                      <span className="text-uppercase text-cta text-500 underline mr-10">
                                        <em>{this.state.isHalfPrice && "Now "}Just &pound;{this.state.isHalfPrice ? this.state.price?.standard?.monthly / 2 : this.state.price?.standard?.monthly} per month.</em>
                                      </span><br />
                                      Storage costs 1p per min, per month.
                                    </p>
                                  </>
                                  : <>
                                    <h3 className="text-600">
                                      <span className="text-cta">Unlimited</span> Call Recording
                                    </h3>
                                    <p className="text-md mb-30 mt-10">
                                      {this.state.isHalfPrice &&
                                        <strike className="text-dullest mr-10">Was &pound;{this.state.price?.unlimited?.monthly}</strike>
                                      }
                                      <span className="text-uppercase text-cta text-500 underline mr-10">
                                        <em>{this.state.isHalfPrice && "Now "}Just &pound;{this.state.isHalfPrice ? this.state.price?.unlimited?.monthly / 2 : this.state.price?.unlimited?.monthly} per month.</em>
                                      </span><br />
                                      Record &amp; store all your calls forever.
                                    </p>
                                  </>
                                }

                              </div>
                            </div>


                            <div className="row mb-30 mt-0">
                              <div className="col-sm-4 col-md-4 col-xs-12 text-sm pt-10 col-xxs-12 text-xxs-center pb-xxs-10 text-400">
                                <em><span className="text-500">Step 2:</span> Choose payment plan:</em>
                              </div>
                              <div className="col-sm-8 col-md-8 col-xs-12 col-xxs-12 text-xxs-center pt-5">
                                <span className={`mr-10 text-500 ${!this.state.isAnnual ? 'active' : 'text-muted'}`}>Pay Monthly</span>
                                <Toggle
                                  onClick={() => this.setState({ isAnnual: this.state.isAnnual !== true })}
                                  on=""
                                  off=""
                                  offstyle="default"
                                  onstyle="success"
                                  width="null"
                                  height="null"
                                  size="sm"
                                  className="toggle-secondary rounded h-35px w-60px"
                                  active={this.state.isAnnual}
                                />

                                <span className={`ml-10 text-500 ${!this.state.isAnnual ? 'text-muted' : 'active'}`}>
                                  Pay Annually
                                </span>
                                <p className="pl-20 pl-xs-0 mt-10 text-sm">Total payment today: <span className="underline">&pound;{totalToday} +VAT.</span></p>
                              </div>
                            </div>

                            {this.state.isAnnual
                              ? <span className="bg-light brad-10 p-20 text-400 d-block mt-10 mb-30">
                                Includes FREE earbuds - <em className="text-cta text-500">Worth &pound;35</em>
                              </span>
                              : <span className="bg-light brad-10 p-20 text-400 d-block mt-10 mb-30">
                                Switch to <span className="text-cta text-500">Annual Payments</span> &amp; get FREE bluetooth earbuds - <em className="text-cta text-500">Worth &pound;35</em>
                              </span>
                            }

                            <div className="mb-20 mt-30">
                              {this.state.isPurchaseInProgress
                                ? <LoadingNotification loadingText={'Please wait...'} textColor="text-dark" isLoading={true} width={50} height={50} />
                                : <>
                                  <Button variant="cta" size="lg" className="btn-block" onClick={() => this.setState({ showTranscription: true })}>Enable Call Recording Now</Button>

                                  <p className="text-sm mb-0 p-0 mt-10 text-center">
                                    {peopleAddedThis}  people added <u>Call Recording</u> in the last hour
                                  </p>
                                </>
                              }
                            </div>

                            <div className="text-center mt-40">
                              <p className="lead text-400">
                                ACT TODAY AS THIS OFFER ENDS SOON!
                              </p>

                              <span className="p-20 bg-darker mb-20 d-inline-block brad-10 mt-0">
                                <Countdown date={moment().endOf('month').format('yyyy-MM-DDTHH:mm:ss')} renderer={renderCountdown} />
                              </span>
                            </div>

                            <div className="button-bar d-block d-xs-none">
                              {this.state.isPurchaseInProgress
                                ? <LoadingNotification loadingText={'Please wait...'} textColor="text-dark" isLoading={true} width={50} height={50} />
                                : <>
                                  <Button variant="cta" size="lg" className="btn-block-sm" onClick={() => this.setState({ showTranscription: true })}>Enable Call Recording Now</Button>

                                  <p className="text-sm mb-0 p-0 mt-10 text-center">
                                    {peopleAddedThis}  people added <u>Call Recording</u> in the last hour
                                  </p>
                                </>
                              }
                            </div>

                            <Modal show={this.state.showTranscription} onHide={e => this.setState({ showTranscription: false })}>
                              <Modal.Header closeButton className="bb-0 p-0">
                              </Modal.Header>
                              <Modal.Body>
                                <p className="text-lg text-500">
                                  Add Call Transcription, Keyword Triggers <br className="d-none d-xs-block" />&amp; Real-time Alerts
                                </p>
                                <p className="text-500">Use our Conversation Search feature to transform &amp; elevate your business.</p>
                                <p>Gone are the days when you and your staff have to make notes during each call to ensure important details are taken down. With CallSearch Pro, every word is recorded and transcribed into easy-to-read text.
                                </p>
                                <p>
                                  And it doesn&apos;t just stop there. Now you can easily search for specific words or phrases that have been said, or not said.
                                </p>
                                <p>
                                  Plus, you can set custom alerts for specific words or phrases mentioned or omitted from conversations.
                                </p>
                                <p>
                                  Automatically transcribe your call recordings into readable, searchable text.
                                </p>
                                <span className="d-block text-md">
                                </span>
                                <ul className="fancy text-start cta text-400 mt-20 pl-40">
                                  <li className="pb-10">
                                    <strong>Boost staff productivity,</strong> by ensuring employees spend less time on admin & more time on engaging with customers.
                                  </li>
                                  <li className="pb-10">
                                    <strong>Easily analyse data,</strong> &amp; keep up-to-date with your customer's needs, preferences & pain points to deliver consistent & exceptional customer service.
                                  </li>
                                  <li className="pb-10">
                                    <strong>Enhance your decision-making,</strong> by uncovering valuable insights within your call data to optimise your communication &amp; marketing strategies.
                                  </li>
                                  <li className="pb-10">
                                    <strong>Remain compliant,</strong> by easily checking that staff are passing on essential information or terms &amp; conditions to your customers.
                                  </li>
                                  <li className="pb-10">
                                    <strong>Simplify training & quality control,</strong> by revisiting past calls to highlight best practices, identify areas for improvement, or educate new team members.
                                  </li>
                                </ul>
                                <em className="d-block text-sm mt-30 mb-0 text-400">With no fixed monthly charges and a budget-friendly rate of <span className="underline">5p per minute</span> of transcribed calls, Conversation Search is the perfect addition to your call management system.</em>

                              </Modal.Body>
                              <Modal.Footer>
                                <Button variant="cta" size="lg" onClick={() => this.setState({ showCheckout: true, showTranscription: false, addTranscription: true })}>Continue with Transcription</Button>
                                <Button variant="link" className="underline text-dark mt-20" onClick={() => this.setState({ showCheckout: true, showTranscription: false })}>No thanks, I just want Call Recording.</Button>
                              </Modal.Footer>
                            </Modal>

                            <Modal className="address-modal" size="sm" show={this.state.showCheckout} onHide={e => this.setState({ showCheckout: false })} backdrop="static">
                              <Modal.Body>
                                <span className="close-modal" onClick={e => this.setState({ showCheckout: false })}>
                                  <span>&times;</span>
                                </span>
                                {this.state.isAnnual &&
                                  <>
                                    <div className="direction-block dir-down bb-1 mb-40 pt-0 pb-30">
                                      <AddressLookup
                                        showDeliveryOnly={true}
                                        showDeliveryAddress={true}
                                        padding="p-0"
                                        deliveryAddress={this.props.delivery}
                                        handleUpdateAddress={(e) => this.handleUpdateAddress(e)}
                                      />
                                    </div>

                                    <div className="direction-block dir-down bb-1 mb-40 pt-0 pb-30">
                                      <FloatingLabel
                                        label="Notes (i.e. Leave in reception)"
                                        className="mb-3 text-lg d-flex"
                                      >
                                        <Form.Control
                                          value={this.state.notes}
                                          onChange={e => this.setState({ notes: e.target.value })}
                                          type="tel"
                                          placeholder='Notes' />
                                      </FloatingLabel>
                                    </div>
                                  </>
                                }

                                <div className="direction-block dir-down bb-1 mb-0 pt-0 pb-10">
                                  <span className="text-center m-0 ml-0 d-block mt-30 bg-dull p-20 brad-10 mb-30" style={{ backgroundColor: '#d6e8ef' }}>
                                    {this.state.isAnnual
                                      ? <p className="text-500 lead">Quick... Claim Your FREE Bluetooth Earbuds</p>
                                      : <>
                                        <p className="text-500 lead">Switch to Annual Payments &amp; Claim <br className="d-none d-xs-block" />Your <span className="text-cta underline">FREE Bluetooth Earbuds</span></p>
                                      </>
                                    }

                                    <div className="col-12 text-center">
                                      <div className="bg-light brad-10 p-20 mb-20">
                                        <span className={`mr-10 text-500 ${!this.state.isAnnual ? 'active' : 'text-muted'}`}>Pay Monthly</span>
                                        <Toggle
                                          onClick={() => this.setState({ isAnnual: this.state.isAnnual !== true })}
                                          on=""
                                          off=""
                                          offstyle="default"
                                          onstyle="success"
                                          width="null"
                                          height="null"
                                          size="sm"
                                          className="toggle-secondary rounded h-35px w-60px"
                                          active={this.state.isAnnual}
                                        />
                                        <span className={`ml-10 text-500 ${!this.state.isAnnual ? 'text-muted' : 'active'}`}>
                                          Pay Annually
                                        </span>
                                      </div>
                                    </div>

                                    <img className="" src="/images/campaigns/headphones/headphones.png" alt="Bluetooth Earbuds" style={{ maxHeight: '200px' }} />
                                    <p className="text-400 text-sm">🔥 Hurry, only {this.state.unitsLeft} in stock.</p>
                                  </span>
                                </div>

                              </Modal.Body>
                              <Modal.Footer className="bt-0 text-center pb-30">
                                <p className="text-500 mb-20">
                                  Total payment today: <span className="underline">&pound;{totalToday} +VAT.</span>
                                </p>

                                {this.state.isPurchaseInProgress
                                  ? <LoadingNotification loadingText={'Please wait...'} textColor="text-dark" isLoading={true} width={50} height={50} />
                                  : <>
                                    {(this.state.isAnnual && ((this.props.delivery.add1 === '' || this.props.delivery.add1 === undefined) || (this.props.delivery.pcode === '' || this.props.delivery.pcode === undefined))) &&
                                      <p className="text-sm text-light bg-danger p-10 brad-10">Please enter your delivery address before you can checkout.</p>
                                    }

                                    <Button variant="cta" size="lg" className="btn-block" onClick={() => this.handlePurchaseCallRecording()}
                                      disabled={(this.state.isAnnual && ((this.props.delivery.add1 === '' || this.props.delivery.add1 === undefined) || (this.props.delivery.pcode === '' || this.props.delivery.pcode === undefined)))}
                                    >
                                      Checkout &amp; Pay
                                    </Button>

                                    <p className="text-sm mt-10 cursor-pointer underline d-block w-100" onClick={() => this.setState({ showCheckout: false })}>Or, cancel order</p>
                                  </>
                                }

                              </Modal.Footer>
                            </Modal>

                          </>
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="bg-dull p-50 text-center">
            <sup>*</sup> Standard terms &amp; conditions apply. <Button variant="link" className="text-dark underline" onClick={() => this.setState({ showTsAndCs: true })}>Click here to find out more</Button>
          </div>
        </>
      }

      <div className="bg-light pt-50 pb-50">
        <h3 className="text-center text-500 mb-20 text-lg">Proud to work with leading UK businesses</h3>
        <Logos style="light" />
      </div>

      <div className="full-width bg-dull">
        <div className="container pl-xs-0 pr-xs-0">
          <CarouselWidget />
        </div>
      </div>

      <div className="full-width full-secondary p-20">
        <div className="row">
          <div className="col-md-12">
            <h3 className="text-center text-lightest mt-10 text-100 text-xl">
              <StatsBanner />
            </h3>
          </div>
        </div>
      </div>

      <div className="full-width bg-light">
        <div className="container">
          <div className="row">
            <div className="col-md-12 text-center">
              <h3 className="text-500">SwitchboardFREE</h3>
              <h6 className="text-400">The UK's favourite call management system</h6>
              <p className="text-400">Instant activation  &bull;  No long-term contracts  &bull;  Award-winning UK support</p>
            </div>
          </div>
        </div>
      </div>

      <Modal className="terms-modal" show={this.state.showTsAndCs} onHide={e => this.setState({ showTsAndCs: false })}>
        <Modal.Header closeButton>
          <Modal.Title className="text-500">Terms &amp; Conditions</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <TermsAndConditions />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={e => this.setState({ showTsAndCs: false })}>Close</Button>
        </Modal.Footer>
      </Modal>

      <ChoosePayment
        total={this.state.wpAmount ? calculateVat(this.state.wpAmount) : 0}
        clientId={this.props.clientId}
        purchaseData={this.state.key}
        returnRoute={`get-call-recording?t=${this.state.token}`}
        email={this.props.userDetails.email}
        name={`${this.props.userDetails.fName} ${this.props.userDetails.sName}`}
        mandate={!this.props.services.hasAgreement}
      />

      <Modal size="sm" show={this.state.showLogin}>
        <Modal.Header>
          <Modal.Title className="mx-auto">Log into your account</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-md-12 text-center pb-30">
              <form name="form" className="text-left" onSubmit={e => this.props.login({
                userName: this.props.userName,
                password: this.props.password,
                event: e,
                history: this.props.history,
                persist: document.getElementById('persistLogin').checked,
                route: `/deal/call-recording?t=${this.state.token}`
              })}>
                <div className="row">
                  <div className="col-xs-12">
                    <p className="lead text-400">Oops, it looks like your token has expired, or is not valid. Please log into your account below.</p>
                  </div>
                  <div className="col-xs-12">
                    <label>Email</label>
                    <input className="form-control" placeholder="Your login email..." type='email' value={this.props.userName} onChange={e => this.props.updateUsername(e.target.value)} />
                  </div>
                  <div className="col-xs-12 mt-20">
                    <label>Password</label>
                    <input className="form-control" placeholder="Your password..." type='password' value={this.props.password} onChange={e => this.props.updatePassword(e.target.value)} />
                  </div>
                </div>
                <div className="pt-10 pb-10">
                  <div className="col-xs-6 pl-0">
                    <input type="checkbox" id='persistLogin' defaultChecked={true} /> <label>Remember</label>
                  </div>
                  <div className="col-xs-6 text-right pr-0">
                    <Link to={'/forgot-password'} className="text-xs text-cta">Forgot password?</Link>
                  </div>
                </div>
                <div className="text-center pt-30">
                  {this.props.loggingIn && <p>Now logging you in, please wait ...</p>}

                  {this.props.loginFailed && <p className="alert alert-danger">There has been an error logging in, please check your username and password and try again. If that doesn't help, try <Link to={'/forgot-password'}>resetting your password</Link>.</p>}

                  <button className="btn btn-cta btn-lg btn-mobile-block">Log In</button>

                  <p className="lead text-center bt-1 mt-30 pt-30">Don&apos;t have an account with us yet?</p>
                  <p className="text-center"><Link to="/get-started" className="btn btn-secondary btn-sm">Create an account</Link></p>
                </div>
              </form>
            </div>
          </div>
        </Modal.Body>
      </Modal>

    </>
  }

  handleUpdateAddress(address) {
    this.props.handleDeliveryNameChange({ newValue: address.name })
    this.props.handleDeliveryAdd1Change({ newValue: address.add1 })
    this.props.handleDeliveryAdd2Change({ newValue: address.add2 })
    this.props.handleDeliveryCountyChange({ newValue: address.add3 })
    this.props.handleDeliveryTownChange({ newValue: address.add4 })
    this.props.handleDeliveryPcodeChange({ newValue: address.pcode })
  }

  handlePurchaseCallRecording() {
    const context = this
    const url = `/api/AccessToken/PurchaseCallRecording?t=${context.state.token}`

    let data = {
      ProductId: ProductIds.callRecordAll,
      billingOption: this.state.isHalfPrice ? this.state.isAnnual ? this.state.isUnlimited ? productBillingOption.halfPriceCallRecordingUnlimitedBillingOptionYearly : productBillingOption.halfPriceCallRecordingAllBillingOptionYearly : this.state.isUnlimited ? productBillingOption.halfPriceCallRecordingUnlimitedBillingOptionMonthly : productBillingOption.halfPriceCallRecordingAllBillingOptionMonthly : this.state.isAnnual ? this.state.isUnlimited ? productBillingOption.callRecordingUnlimitedBillingOptionYearly : productBillingOption.callRecordingAllBillingOptionYearly : this.state.isUnlimited ? productBillingOption.callRecordingUnlimitedBillingOptionMonthly : productBillingOption.callRecordingAllBillingOptionMonthly,
      IsUnlimited: false,
      //Discount: 50,
      bpu: this.state.isAnnual ? billingPeriod.YEAR : billingPeriod.MONTH,
      EnableTranscription: this.state.addTranscription,
      DeliveryDetails: {
        Name: this.props.delivery.name,
        Add1: this.props.delivery.add1,
        Add2: this.props.delivery.add2,
        Add3: this.props.delivery.add3,
        Add4: this.props.delivery.add4,
        Pcode: this.props.delivery.pcode
      }
    }

    context.setState({ isPurchaseInProgress: true }, () => {
      fetchHelper.postJson(url, data)
        .then(res => {
          if (res.data && (res.data.success === true || res.data.isSuccess === true)) {
            context.setState({ finished: true }, () => {
              context.enableCallRecording(context)
            })
          } else {
            context.setState({ wpAmount: res.data.amount, key: res.data.key, isPurchaseInProgress: false, clientId: res.data.clientId }, () => {
              localStorage.setItem('purchaseCallRecording', true)
              context.props.handleShowPaymentOptionsModal()
            })
          }
        }).catch(function (error) {
          context.setState({ isPurchaseInProgress: false })
          console.error(error)
        })
    })
  }

  componentDidUpdate() {
    if (this.props.isLoggedIn && this.state.showLogin == true) {
      this.setState({ showLogin: false }, () => {
        this.getPrice()
      })
    }
  }

  componentWillMount() {
    const context = this
    let token = localStorage.getItem('accessToken')
    const query = new URLSearchParams(this.props.location.search)
    if (!token && query) {
      // auth
      token = query.get('t')
    }

    context.setState({ token })
  }

  componentDidMount() {
    const context = this
    context.setState({ unitsLeft: ((Math.random() * 100) + 50).toFixed(0) })
    const purchasedCallRec = localStorage.getItem('purchaseCallRecording')

    if (!context.state.token && !this.props.isLoggedIn) {
      // Login
      this.setState({ showLogin: true })
    } else if (purchasedCallRec) {
      context.setState({ finished: true }, () => {
        context.enableCallRecording(context)
      })
    } else if (context.state.token) {
      fetchHelper.getJson(`/api/AccessToken/GetClientBillingDay?t=${context.state.token}`)
        .then(res => this.setState({ billingDay: res.data }))
        .catch(err => console.error(err));

      fetchHelper.getJson(`/api/AccessToken/CheckClientHasCallRecording?t=${context.state.token}`)
        .then(res => this.setState({ hasCallRecording: res.data }))
        .catch(err => console.error(err))
    } else if (!this.props.isLoggedIn) {
      // Login
      this.props.history.push('/login?redirect=/deal/call-recording')
    }

    context.props.getProductDetails([
      {
        productId: ProductIds.callRecordAll,
        monthlyOption: productBillingOption.callRecordingAllBillingOptionMonthly,
        yearlyOption: productBillingOption.callRecordingAllBillingOptionYearly
      },
      {
        productId: ProductIds.callRecordAll,
        monthlyOption: productBillingOption.callRecordingUnlimitedBillingOptionMonthly,
        yearlyOption: productBillingOption.callRecordingUnlimitedBillingOptionYearly
      },
      {
        productId: ProductIds.callRecordAll,
        monthlyOption: productBillingOption.halfPriceCallRecordingAllBillingOptionMonthly,
        yearlyOption: productBillingOption.halfPriceCallRecordingAllBillingOptionYearly
      },
      {
        productId: ProductIds.callRecordAll,
        monthlyOption: productBillingOption.halfPriceCallRecordingUnlimitedBillingOptionMonthly,
        yearlyOption: productBillingOption.halfPriceCallRecordingUnlimitedBillingOptionYearly }
    ])
  }

  enableCallRecording(context) {
    localStorage.removeItem('purchaseCallRecording')
    context.setState({ isPurchaseInProgress: false })
    context.props.notify({ message: 'Call Recording Has Been Added' })
    context.props.handleCallRecordingAllEnableChange(true)
  }

}

export default connect(
  state => {
    const deliveryData = state.sbfApp.deliveryData

    return {
      delivery: {
        name: deliveryData.deliveryName ? deliveryData.deliveryName.value : '',
        add1: deliveryData.deliveryAdd1 ? deliveryData.deliveryAdd1.value : '',
        add2: deliveryData.deliveryAdd2 ? deliveryData.deliveryAdd2.value : '',
        add3: deliveryData.deliveryTown ? deliveryData.deliveryTown.value : '',
        add4: deliveryData.deliveryCounty ? deliveryData.deliveryCounty.value : '',
        pcode: deliveryData.deliveryPcode ? deliveryData.deliveryPcode.value : ''
      },
      isLoggedIn: isLoggedIn(state),
      productDetails: state.home.productDetails.products,
      services: state.sbfApp.enabledServices,
      userDetails: state.sbfApp.authentication,
      userName: state.home.login.username,
      password: state.home.login.password,
      loggingIn: state.home.login.loggingIn,
      loginFailed: state.home.login.loginFailed
    }
  },
  {
    login: fromHome.handleLogin,
    updateUsername: fromHome.updateUsername,
    updatePassword: fromHome.updatePassword,
    getProductDetails: getProductPrice,
    notify: handleNotificationChange,
    handleShowCallbackForm,
    handleCallRecordingAllEnableChange,
    handleShowPaymentOptionsModal,
    handleDeliveryNameChange,
    handleDeliveryAdd1Change,
    handleDeliveryAdd2Change,
    handleDeliveryTownChange,
    handleDeliveryCountyChange,
    handleDeliveryPcodeChange,
    handleDeliveryNoteChange
  }
)(CallRecordingUpsell)
