import { type AxiosResponse } from 'axios'
import React, { useEffect, useState } from 'react'
import { Button, FloatingLabel, Form, InputGroup, OverlayTrigger, Popover } from 'react-bootstrap'
import { Link, type RouteProps } from 'react-router-dom'
import Select from 'react-select'
import { CarrierIds, MaxNumbersLoad, MultiNumberPrice, MultiNumberPrice0800, NumbersPremiumFilter, PhoneTypes, signupPath } from '../../constants/Constants'
import { fetchHelper } from '../../helpers/fetchHelper'
import { checkNumberType, getNumbersForPrefixUrl, getNumbersForRegionUrl, simplePhoneNumberFormat } from '../../helpers/utils'
import { isDigit, isValidEmailSyntax, isValidTelSyntax } from '../../helpers/validationHelpers'
import { type GeoRegions, type NumberType, type ValueLabelSelectOption } from '../../models/GenericTypes'
import { type Basket, type NumbersByPremiumDtoWrapper, type NumbersForAreaPosition, type SbfNumber } from '../../models/SignupTypes'
import LoadingNotification from '../LoadingNotification'
import TextBannerWidget from '../Reviews/TextBannerWidget'

const _ = require('lodash')

interface DdiSelectorNewProps {
  numberType: NumberType
  currentBasket: Basket
  hideLoadMore: boolean
  hideShowAll: boolean
  isSignup: boolean
  isSimple: boolean
  hidestars: boolean
  isModal: boolean
  isLoggedIn: boolean
  isEditing: boolean
  menuOpen: boolean
  ddiLid?: number
  handleUpdateBasket: (basket: Basket, number?: SbfNumber, removed?: boolean) => void
  citysOnly: boolean
  selectedGeoRegion?: ValueLabelSelectOption<string, string> | null;
  handleChangeGeoRegion?: (geoRegion: ValueLabelSelectOption<string, string>) => void
  handleChangeNumberType?: (numberType: NumberType) => void
  handleClearGeoRegion?: () => void
  hideRegions?: boolean | undefined
	premiumOnly?: boolean | undefined
	hideReviews?: boolean | undefined
	isIframe?: boolean | undefined
}

interface DdiSelectorNewState {
  showInfo: boolean
  numberCount: number
  premium: NumbersPremiumFilter
  // newPremium: number;
  numbersToShow?: any[]
  searchNumber: string
  loadingNumbers: boolean
  numbers: SbfNumber[]
  aLimit: number
  error: any
  capacityReached: any
  totalRecords: number
  position: any
  geo: any
  loadingRegions: boolean
  regions: any
  popular: any
  focusSearch: boolean
  value: string
  selected: any
  selectedGeoRegion: ValueLabelSelectOption<string, string> | null
  fullName: string
  emailAddress: string
  phoneNumber: string
  emailError: boolean
  phoneError: boolean
  isSubmittingGeoRequest: boolean
  requestSuccess: boolean
  isSearching: boolean
}

const DdiSelectorNew: React.FunctionComponent<DdiSelectorNewProps & RouteProps> = (props) => {
  const [state, setState] = useState<DdiSelectorNewState>({
    selected: undefined,
    focusSearch: false,
    aLimit: 0,
    capacityReached: undefined,
    error: undefined,
    geo: undefined,
    loadingNumbers: false,
    loadingRegions: false,
    // newPremium: undefined,
    numberCount: 0,
    numbers: [],
    numbersToShow: undefined,
    popular: undefined,
    position: undefined,
    premium: NumbersPremiumFilter.all,
    regions: undefined,
    searchNumber: '',
    showInfo: true,
    totalRecords: 0,
    value: '',
    selectedGeoRegion: null,
    fullName: '',
    emailAddress: '',
    phoneNumber: '',
    emailError: false,
    phoneError: false,
    isSubmittingGeoRequest: false,
    requestSuccess: false,
    isSearching: false
  })

  const [regionNumberType, setRegionNumberType] = useState<string>('')
  const [isRegion, setIsRegion] = useState<boolean>(false)

  const isNumberTypeRegional = props.numberType === '01'
  const isHasEmptyGeoRegion = state.selectedGeoRegion === null && (props.selectedGeoRegion === null || !props.selectedGeoRegion)
  const isHasSelectedRegion = !isHasEmptyGeoRegion
	const isShouldShowRegions = isNumberTypeRegional && isHasEmptyGeoRegion && !props.ddiLid
  const isShouldHideRegions = !isShouldShowRegions
	const isMobile = (/Android|iPhone/i.test(navigator.userAgent))

  function handleClearGeoRegion () {
    setState({ ...state, selectedGeoRegion: null, numbersToShow: undefined, numbers: [] })
  }

  function handleRegionChange (e: ValueLabelSelectOption<string, string>) {
	const numberType = e?.label.split('(')[1].split(')')[0] ?? ''
	setRegionNumberType(numberType)
    setState({ ...state, selectedGeoRegion: e, numbersToShow: undefined, numbers: [] })

    getNumbersForRegion()
  }

  useEffect(getNumbersForNonRegionalNumberType, [props.numberType])
  useEffect(getNumbersForRegion, [state.selectedGeoRegion])
  useEffect(getNumbersForRegion, [props.ddiLid])

	function getNumbersForRegion(isLoadMore?: boolean) {
    if (!props.ddiLid && !isLoadMore && (state.selectedGeoRegion === null || !state.selectedGeoRegion.value)) { return }

    setState({ ...state, loadingNumbers: true })

    const numbers = isLoadMore !== true ? undefined : state.numbers
    const url = getNumbersForRegionUrl(
      numbers,
      props.ddiLid ? props.ddiLid : state.selectedGeoRegion?.value,
      MaxNumbersLoad,
      NumbersPremiumFilter.all,
      props.premiumOnly === true
    )

    fetchHelper
      .getJson<NumbersForAreaPosition>(url)
      .then((res) => {
        const newNumbers = res.data.numbers
        const totalRecords = res.data.totalRecords
				const capacityReached = res.data.capacityReached

				let numbersAppended: SbfNumber[] = [];

				if (numbers && numbers.length > 0) {
					numbersAppended = [...numbers, ...(newNumbers || [])]
				} else {
					numbersAppended = [...(newNumbers || [])]
				}

        numbersAppended = numbersAppended.sort((a, b) => {
          if (a.number < b.number) return 1
          if (a.number > b.number) return -1
          return 0
        })

        if (state.numbersToShow === null) {
          setState({ ...state, numbersToShow: numbersAppended })
        }
		setIsRegion(true)
        setState({
          ...state,
          loadingNumbers: false,
          numbers: numbersAppended,
          capacityReached,
          totalRecords,
          numbersToShow: numbersAppended
        })
      })
      .catch(function (error) {
        console.error(error)
      })
  }

  function getNumbersForNonRegionalNumberType (isLoadMore?: boolean) {
		if ((!props.numberType || isNumberTypeRegional) && !isLoadMore) { return }

    setState({ ...state, loadingNumbers: true })

    const numbers = isLoadMore !== true ? undefined : state.numbers
    const url = getNumbersForPrefixUrl(
      numbers,
      props.numberType
        ? props.numberType
        : props.isLoggedIn
          ? '0203'
          : props.currentBasket.package?.numberType,
      props.numberType && props.numberType.startsWith('084') ? MaxNumbersLoad : MaxNumbersLoad,
      NumbersPremiumFilter.all,
      props.premiumOnly === true
    )

    fetchHelper
      .getJson<NumbersByPremiumDtoWrapper>(url)
      .then((res) => {
        const newNumbers = res.data.numbers
        const totalRecords = res.data.totalRecords
				const capacityReached = res.data.capacityReached

				let numbersAppended: SbfNumber[] = [];

				if (numbers && numbers.length > 0) {
					numbersAppended = [...numbers, ...(newNumbers || [])]
				} else {
					numbersAppended = [...(newNumbers || [])]
				}

        numbersAppended = numbersAppended.sort((a, b) => {
          if (a.number < b.number) return 1
          if (a.number > b.number) return -1
          return 0
        })

        // if (!state.numbersToShow) {
        //   setState({ ...state, numbersToShow: numbersAppended });
        // }

        setState({
          ...state,
          loadingNumbers: false,
          numbers: numbersAppended,
          numbersToShow: numbersAppended,
          capacityReached,
          totalRecords,
          searchNumber: ''
        })

		setIsRegion(false)
      })
      .catch(function (error) {
        console.error(error)
      })
  }

  useEffect(
    function fetchGeoRegionsWhenRegionalNumberTypeIsSelected () {
      if (isShouldHideRegions) return

      const getGeoRegionsUrl = '/api/Purchase/GetGeoRegions'

      setState({ ...state, loadingRegions: true })

      const data = {
        CarrierIds,
        CitysOnly: props.citysOnly
      }

      fetchHelper
        .postJson<GeoRegions, { CarrierIds: number[], CitysOnly: boolean }>(
        getGeoRegionsUrl,
        data
      )
        .then((res) => {
          setState({
            ...state,
            loadingRegions: false,
            regions: res.data.regions,
            popular: res.data.popularRegions
          })
        })
        .catch(function (error) {
          console.error(error)
        })
    },
    [isShouldHideRegions]
  )

  const isLoading = state.loadingRegions || state.loadingNumbers
  const numberCount = state.numbers ? state.numbers.length : 0
  const areMoreNumbersAvailable = numberCount < state.totalRecords

  let disableBasketButton = false
  if (
    props.numberType &&
		props.numberType.startsWith('080') &&
		props.currentBasket &&
		!_.isEmpty(props.currentBasket) &&
		props.currentBasket.numbers.some(
		  (s: { number: string }) =>
		    s.number.startsWith('01') ||
				s.number.startsWith('02') ||
				s.number.startsWith('03') ||
				s.number.startsWith('084')
		)
  ) { disableBasketButton = true } else if (
    props.numberType &&
		props.numberType.startsWith('084') &&
		props.currentBasket &&
		!_.isEmpty(props.currentBasket) &&
		props.currentBasket.numbers.some(
		  (s: { number: string }) =>
		    s.number.startsWith('01') ||
				s.number.startsWith('02') ||
				s.number.startsWith('03') ||
				s.number.startsWith('080')
		)
  ) { disableBasketButton = true } else if (
    props.numberType &&
		(props.numberType.startsWith('01') ||
			props.numberType.startsWith('02') ||
			props.numberType.startsWith('03')) &&
		props.currentBasket &&
		!_.isEmpty(props.currentBasket) &&
		props.currentBasket.numbers.some((s: { number: string }) =>
		  s.number.startsWith('08')
		)
  ) { disableBasketButton = true }

  let extraNumberPrice = MultiNumberPrice
  if ((props.currentBasket?.numbers?.length ?? 0 > 0) && props.currentBasket.numbers.some((s: { number: string }) =>
    s.number.startsWith('080')
  )) { extraNumberPrice = MultiNumberPrice0800 }

  function renderNumbers (numbers: any[], hidestars: boolean) {
    return numbers.map((number: SbfNumber, i) => {
      function isActive (number: any) {
        return false
      }

      if (hidestars) {
        return (
					<div
						className={`ddi-box text-400 scale-up-ver-top ${isActive(number.number) ? 'selected' : ''
							} ${isInBasket(number.ddiid) ? 'in-basket' : ''}`}
						onClick={() => {
						  isInBasket(number.ddiid)
						    ? handleRemoveFromBasket(number)
						    : handleAddToBasket(number)
						}}
					>
						<span className="number">
							{simplePhoneNumberFormat(number.number)}
						</span>
					</div>
        )
      } else {
        return (
					<OverlayTrigger
						trigger={['hover', 'focus']}
						placement="top"
						overlay={!isMobile ? renderPopover(
						  i,
						  number.ddipremium,
						  isInBasket(number.ddiid)
						) : <span />}
					>
						<div
							className={`ddi-box text-400 scale-up-ver-top 
                                    ${isActive(number.number) ? 'selected' : ''
								} ${isInBasket(number.ddiid) ? 'in-basket' : ''
								}`}
							onClick={() => {
							  if (isInBasket(number.ddiid)) {
							    handleRemoveFromBasket(number)
							  } else {
							    handleAddToBasket(number)
							    // scrollTo("currentBasket");
							  }
							}}
						>
							<span className="number">
								{simplePhoneNumberFormat(number.number)}
							</span>
							{renderPremium(number.ddipremium)}
							<>
								{isInBasket(number.ddiid)
								  ? (
									<span className="add-number bg-success">
										<span className="text-dullest">
											<span className="icon icon-checkmark4 text-lightest text-sm" />
										</span>
									</span>
								    )
								  : (
									<span className="add-number bg-dull">
										<span className="text-dullest">+</span>
									</span>
								    )}
							</>
						</div>
					</OverlayTrigger>
        )
      }
    })
  }

  function renderGeoRegionSelectBox () {
    return (
			<Select
				id="geoLocalRegionSelect"
				className="form-control select-menu text-start control-lg mb-20"
				classNamePrefix="custom"
				placeholder="Search city / town"
				onChange={(e: ValueLabelSelectOption<string, string>) => {
				  handleRegionChange(e)
				}}
				options={state.regions}
				name="selectedRegion"
				value={state.selectedGeoRegion}
				isSearchable={true}
				isLoading={state.loadingRegions}
				isClearable={true}
				defaultMenuIsOpen={true}
			/>
    )
  }

	function handleNumberSearch(val: string) {
		console.log(props) 
    const numbers = state.numbers
    // Check val is a number
		if (isDigit(val) || val === '') {
			setState({ ...state, isSearching: true })
			const url = `api/Purchase/SearchNumbersForPrefix/${isRegion ? regionNumberType : props.numberType}/${val}`
			fetchHelper.getJson<NumbersByPremiumDtoWrapper>(url)
				.then(res => {
					setState({
						...state,
						searchNumber: val,
						numbersToShow: _.filter(
							[...numbers, ...res.data.numbers],
							function (item: { number: string | string[] }) {
								return item.number.includes(val)
							}
						)
					})
				}).catch(err => { console.error(err) })
		} else {
			setState({ ...state, searchNumber: val })
		}
  }

  function handlePremiumChanged (numberPremiumSelected: number) {
    // if the new premium value is the same as what is already set in state, then remove the filter
    if (numberPremiumSelected === state.premium) {
      setState({ ...state, premium: NumbersPremiumFilter.all, numbersToShow: state.numbers })
    } else {
      const numbers = state.numbers
      const numbersToShow = _.filter(numbers, function (item: SbfNumber) {
        return item.ddipremium === numberPremiumSelected
      })
      setState({ ...state, premium: numberPremiumSelected, numbersToShow })
    }
  }

  function renderStars (premium: number) {
    if (premium === 0) {
      return (
				<React.Fragment>
					{/* <span className="icon icon-star-full text-cta text-xxs d-inline-block"></span> */}
				</React.Fragment>
      )
    } else if (premium > 0 && premium < 10) {
      return (
				<>
					<span className="icon icon-star-full text-cta text-xxs d-inline-block"></span>
					<span className="icon icon-star-full text-cta text-xxs d-inline-block"></span>
				</>
      )
    } else {
      return (
				<>
					<span className="icon icon-star-full text-cta text-xxs d-inline-block"></span>
					<span className="icon icon-star-full text-cta text-xxs d-inline-block"></span>
					<span className="icon icon-star-full text-cta text-xxs d-inline-block"></span>
				</>
      )
    }
  }

  function handleRemoveFromBasket (sbfNumber: SbfNumber): void {
    const basket = { ...props.currentBasket }

    if (props.isModal && basket.numbers.length > 1) {
      const numbers = basket.numbers.filter((num) => num.number !== sbfNumber.number)

      props.handleUpdateBasket({
        ...basket,
        numbers
      }, sbfNumber, true)
    } else if (!props.isModal) {
      const numbers = basket.numbers.filter((num) => num.number !== sbfNumber.number)

      props.handleUpdateBasket({
        ...basket,
        numbers
      }, sbfNumber, true)
    }
  }

  function renderPremium (premium: number): JSX.Element {
    if (premium === 0) {
      // Business
      return (
				<span className="premium premium-1">
					{/* <i className="icon icon-star-full" /> */}
				</span>
      )
    } else if (premium === 5) {
      // Professional
      return (
				<span className="premium premium-2">
					<i className="icon icon-star-full" />
					<i className="icon icon-star-full" />
				</span>
      )
    } else if (premium === 10) {
      // Gold
      return (
				<span className="premium premium-3">
					<i className="icon icon-star-full" />
					<i className="icon icon-star-full" />
					<i className="icon icon-star-full" />
				</span>
      )
    } else {
      // Platinum
      return (
				<span className="premium premium-4">
					<i className="icon icon-star-full" />
					<i className="icon icon-star-full" />
					<i className="icon icon-star-full" />
					<i className="icon icon-star-full" />
				</span>
      )
    }
  }

  function isInBasket (ddiid: number): boolean {
    if (!_.isEmpty(props.currentBasket)) {
      return props.currentBasket?.numbers?.some((num: { ddiid: number }) => {
        return num.ddiid === ddiid
      })
    }
    return false
  }

	function handleAddToBasket(sbfNumber: SbfNumber) {
    const basket: Basket = props.currentBasket

    let selectedNumbers = [...basket.numbers]
    const selectedPackage = basket.package
    const selectedHardware = [...basket.hardware]

		if (props.isEditing) {
			selectedNumbers = [sbfNumber]
		} else {
			selectedNumbers = [...selectedNumbers, sbfNumber]
		}

    const newBasket: Basket = {
      ...basket,
      numbers: selectedNumbers,
      package: selectedPackage,
      hardware: selectedHardware
    }

    props.handleUpdateBasket(newBasket, sbfNumber)
  }

	function renderPopover(id: number, premium: number, isInBasket: boolean) {
    if (isInBasket) {
      return (
				<Popover id={'popover'.concat(String(id))}>
					<p className="mb-0">Already in your basket. Click to remove.</p>
				</Popover>
      )
    } else {
      if (premium === 0) {
        // Business
        return (
					<Popover id={'popover'.concat(String(id))}>
						<span className="text-400">
							Bronze number.
							{props.currentBasket &&
								!_.isEmpty(props.currentBasket) &&
								props.currentBasket.numbers.length > 0 && (
									<span className="text-500 text-sm ml-5 mr-5">
										+&pound;{extraNumberPrice.toFixed(2)}pm.
									</span>
							)}
							<span className="ml-5">Click to add.</span>
						</span>
					</Popover>
        )
      } else if (premium === 5) {
        // Professional
        return (
					<Popover id={'popover'.concat(String(id))}>
						<span className="text-400">
							Silver number.
							<span className="text-500 text-sm ml-5">
								+&pound;
								{props.currentBasket &&
									!_.isEmpty(props.currentBasket) &&
									props.currentBasket.numbers.length > 0
								  ? `${(premium + extraNumberPrice).toFixed(2)}pm.`
								  : `${premium}pm.`}
							</span>
							<span className="ml-5">Click to add.</span>
						</span>
					</Popover>
        )
      } else if (premium === 10) {
        // Gold
        return (
					<Popover id={'popover'.concat(String(id))}>
						<span className="text-400">
							Gold number.
							<span className="text-500 text-sm ml-5">
								+&pound;
								{props.currentBasket &&
									!_.isEmpty(props.currentBasket) &&
									props.currentBasket.numbers.length > 0
								  ? `${(premium + extraNumberPrice).toFixed(2)}pm.`
								  : `${premium}pm.`}
							</span>
							<span className="ml-5">Click to add.</span>
						</span>
					</Popover>
        )
      } else {
        // Platinum
        return (
					<Popover id={'popover'.concat(String(id))}>
						<span className="text-400">
							Super-rare number.
							<span className="text-500 text-sm ml-5">
								+&pound;
								{props.currentBasket &&
									!_.isEmpty(props.currentBasket) &&
									props.currentBasket.numbers.length > 0
								  ? `${(premium + extraNumberPrice).toFixed(2)}pm`
								  : `${premium}pm`}
							</span>
							<span className="ml-5">Click to add.</span>
						</span>
					</Popover>
        )
      }
    }
  }

  function canShowPremiumNumberSelector () {
    const numberType = props.numberType
    const startsWith01or02 =
			numberType &&
			(numberType.startsWith('01') || numberType.startsWith('02'))
    const selectedDdiPrefix = state.selectedGeoRegion?.value
    return (
      ((startsWith01or02 && (state.selectedGeoRegion !== null) && selectedDdiPrefix) ||
				(numberType && !numberType.startsWith('01'))) &&
			(state.numbersToShow !== null && state.numbersToShow !== undefined) &&
			(state.numbersToShow.length > 0
			  ? state.numbersToShow[0].number.startsWith(numberType) ||
				state.numbersToShow[0].number.startsWith('02')
			  : state.numbersToShow.length === 0)
    )
  }

  function renderReviewsWidgets () {
    return (
			<>
				<div className="bg-light box-shadow shadow-lighter brad-20 p-20 mb-20 text-center pt-15 trust-pilot">
					<a href="https://uk.trustpilot.com/review/switchboardfree.co.uk" target="_blank" rel="noopener noreferrer" className="d-block">
						<img src="images/trustpilot-reviews.png" alt="Trust Pilot Reviews" />
					</a>
				</div>
				<div className="bg-light box-shadow shadow-lighter brad-20 p-20 reviews-box">
					<TextBannerWidget className="text-dark" textDark={true} />
				</div>
			</>
    )
  }

  function renderSelectedNumbers () {
    const b =
			props.currentBasket &&
			!_.isEmpty(props.currentBasket) &&
			props.currentBasket.numbers.length > 0
    if (b) {
      return (
				<>
					{
						<div
							className={'selected-numbers text-center pl-20 pr-20 pt-20 pb-20 mt-0 mt-xs-30 mb-20 col-md-12 bg-light brad-20 box-shadow shadow-lighter'}
						>
							<>
								<p className="text-500 pb-10">
									Your number{props.currentBasket.numbers.length > 1 && 's'}
								</p>
								{props.currentBasket.numbers.map((num: SbfNumber) => (
									<span
										className={`number-selected scale-up bg-light text-dark brad-20 text-500 text-center mr-10  ${props.isModal ? 'p-5 pl-10 pr-10' : 'p-10 pl-20 pr-10'
											}`}
									>
										<span className="pr-10 pr-xxs-5 float-start">
											{simplePhoneNumberFormat(num.number)}
										</span>

										<span className="mt-neg-3 float-start">
											{renderStars(num.ddipremium)}
										</span>

										{props.currentBasket.numbers.length > 1 && props.isModal && (
											<span
												className="remove-selection icon icon-cancel-circle2 link img-rounded text-duller float-start ml-20 mt-3"
												onClick={() => {
												  handleRemoveFromBasket(num)
												}}
											/>
										)}

										{!props.isModal && (
											<span
												className="remove-selection icon icon-cancel-circle2 link img-rounded text-duller float-start ml-20 mt-3"
												onClick={() => {
												  handleRemoveFromBasket(num)
												}}
											/>
										)}
									</span>
								))}
								<div className="row">
									<div className="col-xs-12">
										<p className="mt-15 bg-light text-500 text-xs d-inline-block pl-20 pr-20 pt-10 pb-10 brad-10 b-1">
											<span className="text-cta">Add extra numbers</span>{' '}
											<br className="d-block d-xs-none" />
											from{' '}
											<span className="text-500">
												just &pound;{((props.premiumOnly === true ? 5 : 0) + extraNumberPrice).toFixed(2)}pm
											</span>{' '}
											each
										</p>
									</div>
								</div>
								{!props.isSimple && !props.isSignup && props.currentBasket && (
									<>
										<div className="row mt-10">
											<div className="col-xs-12 text-center">
												<div className="text-center">
													{props.isIframe === true ?
														<Button variant="cta" className="btn-md" onClick={() => window.parent.location.href=`https://www.switchboardfree.co.uk${signupPath}` }>
															Checkout <span className="icon icon-arrow-right8 text-xs ml-5" />
														</Button>
														:
														<Link
															to={`${props.isLoggedIn
																? '/customer/numbers/new/'
																: signupPath
																}`}
															className="btn btn-cta btn-md"
														>
															Checkout <span className="icon icon-arrow-right8 text-xs ml-5" />
														</Link>
														}
												</div>
											</div>
										</div>
									</>
								)}
							</>
						</div>
					}
				</>
      )
    } else {
      if (props.isSignup) {
        return (
					<>
						{
							<div
								className="selected-numbers text-center pl-20 pr-20 pt-20 pb-20 mt-30 mb-20 col-md-12 bg-light brad-20 box-shadow shadow-lighter">
								<p className="text-500 pb-10">Your selected numbers</p>
								<p className="mb-0">No numbers selected...</p>
							</div>
						}
					</>
        )
      } else {
        return (
					<>
						{
							<div
								className="selected-numbers text-center pl-20 pr-20 pt-20 pb-20 mb-20 col-md-12 bg-light brad-20 box-shadow shadow-lighter">
								<p className="text-500 pb-10">Your selected numbers</p>
								<p>
									No numbers selected...
								</p>
							</div>
						}
					</>
        )
      }
    }
  }

  function handleRequestNumbers (email: string, phone: string) {
    setState({ ...state, isSubmittingGeoRequest: true })

    const isEmailValid = getValidateEmailPromise(email)
    const isPhoneValid = getValidatePhonePromise(phone)

    Promise.all([isEmailValid, isPhoneValid]).then((values: boolean[]) => {
      // update the validation state for all the fields
      const emailValidationStateResult = values[0]
      const phoneValidationStateResult = values[1]

      setState({
        ...state,
        emailError: !emailValidationStateResult,
        phoneError: !phoneValidationStateResult,
        isSubmittingGeoRequest: false
      })

      if (emailValidationStateResult && phoneValidationStateResult) {
        // submit to SBF team
        const url = 'api/Home/NumberRequest'
        const data = {
          Name: state.fullName,
          Email: state.emailAddress,
          Phone: state.phoneNumber,
          Number: (state.selectedGeoRegion !== null) ? state.selectedGeoRegion.label : props.numberType
        }

        fetchHelper.postJson(url, data)
          .then(res => { setState({ ...state, requestSuccess: res.data === true }) })
      }
    })
  }

  async function getCheckValidTelephonePromise (tel: string): Promise<AxiosResponse<boolean>> {
    const url = 'api/Users/isValidTelephone'
    const numberType = checkNumberType(tel)
    return await fetchHelper.postJson(url, { phoneNumber: tel, landline: numberType === PhoneTypes.landline })
  }

  async function getValidatePhonePromise (phone: string): Promise<boolean> {
    return await new Promise((resolve, reject) => {
      if (phone) {
        const validPhoneSyntax = isValidTelSyntax(phone)
        if (validPhoneSyntax) {
          const isPhoneNumberValid = getCheckValidTelephonePromise(phone).then(response => {
            if (response.data) {
              resolve(true)
            } else {
              resolve(false)
            }
          }).catch(error => { resolve(false) })
        } else {
          resolve(false)
        }
      } else {
        resolve(false)
      }
    })
  }

  async function getValidateEmailPromise (email: string): Promise<boolean> {
    return await new Promise((resolve, reject) => {
      if (email) {
        const validEmailSyntax = isValidEmailSyntax(email)
        if (validEmailSyntax) {
          resolve(true)
        } else {
          resolve(false)
        }
      } else {
        resolve(false)
      }
    })
  }

  function renderNotInStockMessage () {
    return (
      state.requestSuccess
        ? <div className="text-center p-30 w-100">
					<h4 className="mt-0">Thanks, {state.fullName}</h4>
					<p className="mb-0">We've received your request for a {(state.selectedGeoRegion !== null) ? state.selectedGeoRegion.label : props.selectedGeoRegion !== null ? props.selectedGeoRegion?.label : props.numberType}{' '} number &amp; one of our team will be in touch shortly.</p>
				</div>
        :				<div className="text-center p-30 w-100 out-of-stock">
					<h5 className="mt-0 text-500">Request a {(state.selectedGeoRegion !== null) ? state.selectedGeoRegion.label : props.selectedGeoRegion !== null ? props.selectedGeoRegion?.label : props.numberType}{' '} number</h5>
					<p className="mb-0">We're currently out of stock.<br />However, we can order these numbers in and get you set up in just a few hours.</p>

					<div className="row">
						<div className="col-xs-12 col-md-4">
							<FloatingLabel
								label="Your name"
								className="mb-3 text-lg d-flex mt-20"
							>
								<Form.Control
									value={state.fullName}
									onChange={e => { setState({ ...state, fullName: e.target.value }) }}
									type="text"
									placeholder='Your name'
									size="sm"
								/>

							</FloatingLabel>
						</div>
						<div className="col-xs-12 col-md-4">
							<FloatingLabel
								label="Your email"
								className="mb-3 text-lg d-flex mt-20"
							>
								<Form.Control
									value={state.emailAddress}
									onChange={e => { setState({ ...state, emailAddress: e.target.value, emailError: false }) }}
									type="text"
									placeholder='Your email'
									size="sm"
									isInvalid={state.emailError}
								/>
							</FloatingLabel>
						</div>
						<div className="col-xs-12 col-md-4">
							<FloatingLabel
								label="Your phone"
								className="mb-3 text-lg d-flex mt-20"
							>
								<Form.Control
									value={state.phoneNumber}
									onChange={e => { setState({ ...state, phoneNumber: e.target.value, phoneError: false }) }}
									type="text"
									placeholder='Your phone'
									size="sm"
									isInvalid={state.phoneError}
								/>
							</FloatingLabel>
						</div>
					</div>

					{state.isSubmittingGeoRequest
					  ? <LoadingNotification
							message={'Please wait...'}
							isLoading={true}
							textColor="text-dark"
							height={50}
						/>
					  :						<Button
							variant="cta"
							className='btn d-inline ml-neg-5 mt-20'
							onClick={() => { handleRequestNumbers(state.emailAddress, state.phoneNumber) }}
						>
							Request {(state.selectedGeoRegion !== null) ? state.selectedGeoRegion.label : props.numberType} numbers <span className="icon icon-arrow-right8 text-light text-sm ml-10" />
						</Button>
					}

					{props.numberType && props.numberType.startsWith('0800') && (
						<div className="bg-dull p-20 brad-5 mt-20">
							<p>
								<span
									className="pt-5 pb-5 pl-20 pr-20 brad-10 text-caps text-500 text-xs text-lightest box-shadow mr-10"
									style={{
									  whiteSpace: 'nowrap',
									  background:
											'linear-gradient(to right, rgba(92,184,92,1) 0%,rgba(119,209,119,1) 100%)'
									}}
								>
									NEW!
								</span>
							</p>
							<h4>0808 Freephone Numbers</h4>
							<p className="mb-30">
								Alternatively, you can view our range of{' '}
								<Link to="/0808-numbers">0808 freephone numbers</Link>.
							</p>
							<Link
								to="/0808-numbers"
								className="text-uppercase btn-rounded btn btn-cta btn-sm"
							>
								View 0808 Numbers
							</Link>
						</div>
					)}
				</div>
    )
  }

  function renderNoSearchResultsMessage () {
    return (
			<div className="text-center p-40 w-100">
				<h4 className="text-400">Sorry...</h4>
				<p>
					We don&apos;t have any{' '}
					{(state.selectedGeoRegion !== null)
					  ? state.selectedGeoRegion.label
					  : props.numberType}{' '}
					numbers that match your search criteria at present.
					<br />
					Please try another search, or choose from our list of numbers.
				</p>
			</div>
    )
  }

  function renderFoundNoNumbersAvailableForNumberType () {
    return state.searchNumber && state.searchNumber.length > 0
      ? renderNoSearchResultsMessage()
      : renderNotInStockMessage()
  }

  return (
		<>
			{isNumberTypeRegional && props.isSignup && isShouldShowRegions && (
				<div className="pb-20 w-400px center-block mx-auto">
					{renderGeoRegionSelectBox()}
				</div>
			)}

			<div
				className={`pt-0 p-xxs-0 text-start ${!props.isSignup
					? props.isSimple
						? ''
						: 'clearfix direction-block'
					: ' clearfix direction-block'
					}`}
			>
				{isLoading ? (
					<LoadingNotification
						message={'Loading numbers...'}
						isLoading={isLoading}
						textColor="text-dark"
					/>
				) : (
					<>
						{disableBasketButton && (
							<div className="bg-danger brad-20 w-400px center-block">
								<div className="text-light p-20 text-center text-sm">
									<p>
										Unfortunately, due to the costs involved we're unable to mix
										0800 or 084 numbers, with other number types at present.
									</p>
									<p>
										Please remove the items from your basket below if you want
										to add an {props.numberType} number instead.
									</p>
								</div>
							</div>
						)}

						{isNumberTypeRegional &&
							!props.isSignup &&
							!disableBasketButton &&
							isShouldShowRegions &&
							!props.hideRegions && (
								<div className="bg-light box-shadow shadow-lighter brad-20 p-30 mb-30 geo-selector">
									<div className="w-400px center-block">
										{renderGeoRegionSelectBox()}
									</div>
								</div>
						)}

						{(!disableBasketButton &&
							!isLoading &&
							(((state.numbersToShow !== null) && !state.searchNumber) ||
								state.searchNumber) && !isShouldShowRegions) && (
								<div
									className={`bg-light brad-20 box-shadow shadow-lighter numbers-wrapper mb-20 ${((state.numbersToShow?.length ?? 0) === 0 && props.premiumOnly === true) ? 'w-100' : ''}`}
								>
									{canShowPremiumNumberSelector() && props.premiumOnly !== true && (
										<div
											id="chooseNumber"
											className={'bb-2 bb-xs-0'}
										>
											<div
												className={`row number-info ${!props.isSignup ? 'text-center' : 'text-center'
													} ${props.isSimple ? '' : ''}`}
											>
												<div
													id={`BronzeNumberFilter${state.premium === NumbersPremiumFilter.business? 'Active' : ''}`}
													className={'col-4 pl-0 pr-0 '.concat(
													  state.premium ===
															NumbersPremiumFilter.business
													    ? 'active'
													    : ''
													)}
													onClick={() => { handlePremiumChanged(0) }}
												>
													{state.showInfo && (
														<>
															<p
																className={'text-sm premium-1 pt-20 pb-20 pl-10 pr-10 brad-0 link text-sm-center pr-0 '.concat(
																  props.isSignup ? 'bg-light' : ''
																)}
															>
																{/* <span className="icon icon-star-full text-xs text-cta" /> */}
																<span className="text-500 ml-10 mr-5">
																	Bronze number
																</span>{' '}
																{props.isModal && <br />}
																<i>Standard</i>{' '}
																<span className="ml-10 ml-sm-5 display-sm-block">
																	Included
																</span>
															</p>
														</>
													)}
												</div>
												<div
													id={`SilverNumberFilter${state.premium === NumbersPremiumFilter.professional? 'Active' : ''}`}
													className={'col-4 pl-0 pr-0 '.concat(
													  state.premium ===
															NumbersPremiumFilter.professional
													    ? 'active'
													    : ''
													)}
													onClick={() => { handlePremiumChanged(5) }}
												>
													{state.showInfo && (
														<>
															<p
																className={'text-sm premium-2 pt-20 pb-20 pl-10 pr-10 brad-0 link text-sm-center pl-0 pr-0 '.concat(
																  props.isSignup ? 'bg-light' : ''
																)}
															>
																<span className="icon icon-star-full text-cta text-xs" />
																<span className="icon icon-star-full text-cta text-xs" />
																<span className="text-500 ml-10 mr-5">
																	Silver number
																</span>{' '}
																{props.isModal && <br />}
																<i>Memorable</i>{' '}
																<span className="ml-10 ml-sm-5 display-sm-block">
																	+&pound;5pm
																</span>
															</p>
														</>
													)}
												</div>
												<div
													id={`GoldNumberFilter${state.premium === NumbersPremiumFilter.golden? 'Active' : ''}`}
													className={'col-4 pl-0 pr-0 '.concat(
													  state.premium ===
															NumbersPremiumFilter.golden
													    ? 'active'
													    : ''
													)}
													onClick={() => { handlePremiumChanged(10) }}
												>
													{state.showInfo && (
														<>
															<p
																className={'text-sm premium-3 pt-20 pb-20 pl-10 pr-10 brad-0 link text-sm-center pl-0 '.concat(
																  props.isSignup ? 'bg-light' : ''
																)}
															>
																<OverlayTrigger trigger={['hover', 'focus']} placement="top" overlay={
																	<Popover>
																		<p>
																			Golden numbers are highly desired because they are easy for callers to remember.
																			Gold numbers are usually configured with sequential digits, a repeating digit.
																			These characteristics make gold numbers a good choice for a variety of personal and business uses.
																		</p>
																	</Popover>

																}>
																	<>
																		<span className="icon icon-star-full text-cta text-xs" />
																		<span className="icon icon-star-full text-cta text-xs" />
																		<span className="icon icon-star-full text-cta text-xs" />
																		<span className="text-500 ml-10 mr-5">
																			Gold number
																		</span>{' '}
																		{props.isModal && <br />}
																		<i>Unforgettable</i>{' '}
																		<span className="ml-10 ml-sm-5 display-sm-block">
																			+&pound;10pm
																		</span>
																	</>
																</OverlayTrigger>
															</p>
														</>
													)}
												</div>
											</div>
										</div>
									)}

									<div className="ddi-search bb-2 clearfix w-100">
										<Form>
											<Form.Group
												className={`float-start mb-0 text-start animated form-group ${state.focusSearch ||
													(state.searchNumber && state.searchNumber.length > 0)
													? 'focused'
													: ''
													}
													${areMoreNumbersAvailable && !isLoading && (state.numbersToShow !== null && state.numbersToShow !== undefined) && state.numbersToShow.length > 0 && !props.hideLoadMore ? ' w-70' : ' w-100 aaa'}
													`}
											>
												<Form.Label>Search numbers</Form.Label>
												<InputGroup>
													<Form.Control
														className="text text-md b-0 brad-10"
														value={state.searchNumber}

														onChange={(e: { target: { value: string } }) => { state.isSearching ? null : handleNumberSearch(e.target.value) }
														}
														onBlur={() => { setState({ ...state, focusSearch: false }) }
														}
														onFocus={() => { setState({ ...state, focusSearch: true }) }
														}
													/>

													{state.isSearching &&
														<InputGroup.Text className="bg-light b-0">
															<LoadingNotification
																height="30"
																hideText={true}
																isLoading={true}
															/>
														</InputGroup.Text>
													}

												</InputGroup>
											</Form.Group>
										</Form>

										{areMoreNumbersAvailable && !isLoading && (state.numbersToShow !== null && state.numbersToShow !== undefined) && state.numbersToShow.length > 0 && !props.hideLoadMore && (
											<div className="float-end mt-15 mr-20">
												<Button
													onClick={() => {
														(state.selectedGeoRegion === undefined || state.selectedGeoRegion === null) ? getNumbersForNonRegionalNumberType(true) : getNumbersForRegion(true)
													}}
													className="text-uppercase btn-primary btn-xs"
												>
													Load More{' '}
													<span className="d-none d-md-inline-block"> Numbers</span>
												</Button>
											</div>
										)}
									</div>

									{state.capacityReached && (
										<div className="p-15 text-center">
											<h4 className="text-500">Capacity Reached</h4>
											<p>
												You have already reached the limit for free 084 numbers.
											</p>
										</div>
									)}

									<div className="ddi-wrapper flex">
										{!state.capacityReached && (
											<>
												{(state.numbersToShow !== null && state.numbersToShow !== undefined) && state.numbersToShow.length > 0
												  ? renderNumbers(state.numbersToShow, props.hidestars)
												  : renderFoundNoNumbersAvailableForNumberType()}
												{isLoading && (
													<LoadingNotification
														message={'Loading ...'}
														isLoading={true}
													/>
												)}
											</>
										)}
									</div>
								</div>
						)}

						<div
							id="currentBasket"
							className={
								!props.isSignup && disableBasketButton ? 'is-error' : 'x'
							}
						>

							{(!isShouldShowRegions && (state.numbersToShow?.length ?? 0) > 0) &&
								renderSelectedNumbers()
							}

							{(!props.isSignup && !props.hideReviews) &&
								renderReviewsWidgets()
							}
						</div>

							{isNumberTypeRegional && isHasSelectedRegion && !props.hideRegions && (
							<div className="w-100 text-center clearfix">
								<Button
									variant="secondary"
									className="btn-xs"
									onClick={() => { handleClearGeoRegion() }}
								>
									Choose a different area
								</Button>
							</div>

						)}

					</>
				)}

				{state.capacityReached && (
					<div className="row mt-30">
						<div className="col-xs-12 text-center">
							<p>
								SwitchboardFREE 084 numbers are free to use however they are
								limited to {state.aLimit}. If you require additional 084 numbers
								then please contact support on support@switchboardfree.co.uk or
								call <a href="tel:02079657575">02079657575</a>
							</p>
						</div>
					</div>
				)}
			</div>
		</>
  )
}
export default DdiSelectorNew
