import moment from 'moment'
import React, { useState } from 'react'
import { Button, FloatingLabel, Form, InputGroup, Modal, OverlayTrigger, Popover, Row, Col } from 'react-bootstrap'
import Toggle from 'react-bootstrap-toggle'
import { Helmet } from 'react-helmet'
import { Link, type RouteComponentProps } from 'react-router-dom'
import { scroller } from 'react-scroll'
import AddressLookup from '../../components/AddressLookup'
import DisplayMainHeader from '../../components/DisplayMainHeader'
import DisplayNoFooter from '../../components/DisplayNoFooter'
import DisplaySimpleHeaderV2 from '../../components/DisplaySimpleHeaderV2'
import LoadingNotification from '../../components/LoadingNotification'
import ProgressSteps from '../../components/ProgressSteps'
import ChoosePayment from '../../components/purchase/ChoosePayment'
import DdiSelectorNew from '../../components/purchase/DdiSelectorNew'
import NumberTypeChooser from '../../components/purchase/NumberTypeChooser'
import PurchasePhones from '../../components/purchase/PurchasePhones'
import ScrollToTopOnMount from '../../components/ScrollToTopOnMount'
import SelectPackage from '../../components/SelectPackage'
import SvgRender from '../../components/SvgRender'
import Validator from '../../components/Validator'
import VoiPTerms from '../../components/VoiPTerms'
import {
  UnlimitedOutboundPlan, BasicOutboundPlan,
  AnnualDiscount, AppOnlyPrice,
  AppOnlyPrice0800, BasketItemType,
  billingPeriod,
  DeliveryType,
  ExpressDeliveryCharge, is0800Offer, MultiBuyDiscount,
  MultiNumberPrice,
  MultiNumberPrice0800,
  numberOfferDuration, PaygPlan, PhoneTypes,
  productBillingOption,
  ProductIds, signupPath, validationStates,
  VoipHandsetDiscount, WhatsAppBusinessPackageId, type LoginDetails, type SignupStep
} from '../../constants/Constants'
import { fetchHelper } from '../../helpers/fetchHelper'
import LocalStorageHelper from '../../helpers/LocalStorageHelper'
import {
  calculateHandsetPricee, calculateRemainingMonth, calculateVat,
  checkNumberType,
  getFirstNameFromName,
  simplePhoneNumberFormat
} from '../../helpers/utils'
import {
  type AddressDto, type NumberType, type ProductPriceDto,
  type ProductPriceOptionsQuery,
  type ValueLabelSelectOption
} from '../../models/GenericTypes'
import {
  SbfPackage, type Basket, type BillingDetailsDto, type EditBasketParams, type EssentialInfo,
  type Hardware, type MarketingDataDto, type NewNumberPurchaseDto, type NewSendQuoteDto, type NewSignupValidationDto,
  type OptionalInfo, type PackageResultDto, type PurchaseNumberDto,
  type SbfNumber, type SignupContactsDto,
  type SignupResultDto, type StripeDeclineReasonDto
} from '../../models/SignupTypes'
import { fetchProductPrices } from '../../services/ProductService'
import PrivacyPolicy from '../../Text/PrivacyPolicy'
import TermsAndConditions from '../../Text/TermsAndConditions'
import EarbudsModal from './Modals/EarbudsModal'
import CallTranscriptionBenefitsList from '../../components/Text/CallTranscriptionBenefitsList'


declare global {
  interface Window {
    dataLayer: Array<Record<string, any>>
    gtag: any
    ga: any,
    LOQ: any
  }
}

const _ = require('lodash')

interface SignupCheckoutProps extends RouteComponentProps {
  marketingData?: MarketingDataDto
  isPorting?: boolean
  isQuote?: boolean
  isInternal: boolean
  isNewCustomer: boolean
  signupKey: string
  selectedNumberType: string
  handleChangeNumberType: (numberType: NumberType) => void
  billingPeriod: billingPeriod
  handleUpdateSignupTranscription: (enabled: boolean) => void
  handleUpdateSignupCallRecording: (enabled: boolean) => void
  handleUpdateSignupTopup: (amount: number) => void
  handleChooseBillingPeriod: (billingPeriod: billingPeriod, freebies: boolean, freeBluetooth?: boolean) => void
  callRecording: boolean
  transcription: boolean
  topupCredit: number
  handleDecrementUsers: () => void
  handleIncrementUsers: () => void
  handleBack: (step?: SignupStep) => void
  handleUpdateBasket: (basket: Basket) => void
  handleSetPackage: (sbfPackage: SbfPackage) => void
  handleRemoveFromBasket: (e: EditBasketParams) => void
  currentBasket: Basket
  isLoggedIn: boolean
  signupValidation: {
    pcodeValidationState: validationStates
    townValidationState: validationStates
    add1ValidationState: validationStates
    nameValidationState: validationStates
    billing: {
      nameValidationState: validationStates
      add1ValidationState: validationStates
      townValidationState: validationStates
      pcodeValidationState: validationStates
      cardNumberValidationState: validationStates
      cardExpiryValidationState: validationStates
      cardCodeValidationState: validationStates
    }
    delivery: {
      nameValidationState: validationStates
      add1ValidationState: validationStates
      townValidationState: validationStates
      pcodeValidationState: validationStates
    }
  }
  signupInfo: {
    user: {
      name: string
      email: string
      phone: string
      company: string
    }
    delivery: AddressDto
    billing: {
      address: AddressDto
      cardNumber: { value: string }
      cardExpiry: { value: string }
      cardCode: { value: string }
    }
  }
  isStep2Visible: string
  isStep2Reached: boolean
  productDetails: ProductPriceDto[]
  userDetails: LoginDetails
  handleLogin: (param: LoginDetails) => void
  handleClearGeoRegion: () => void
  handleUpdateAddress: (data: AddressDto, isBilling: boolean) => void
  handleRemoveBoltOn: () => void
  handleApplyDiscount: () => void
  handleRemoveDiscount: () => void
  billingDay?: number
  hasCallBlocking?: boolean
  callBlocking?: boolean
  handleUpdateSignupCallBlocking: (enabled: boolean) => void
}

interface SignupData {
  optionalInfo: OptionalInfo
  essentials: EssentialInfo
  billingDetails: BillingDetailsDto | undefined
}

interface SignupCheckoutState {
  showBillingPeriodOptionsModal: boolean
  showTermsWarning: boolean
  acceptedTerms: boolean
  quoteError: boolean
  showQuoteSentModal: boolean
  signupError: boolean
  signupMsg: string
  showNumNotAvailable: boolean
  showEditDevices: boolean
  showEditNumbers: boolean
  editNumber: boolean
  showEditUsers: boolean
  showEditPlan: boolean
  showOutbound: boolean
  showVoipTerms: boolean
  showTsAndCs: boolean
  showPrivacy: boolean
  isCreating: boolean
  showDeliveryInfo: boolean
  showFeatures: boolean
  showEarbuds: boolean
  key: any
  signupData: SignupData
  deliveryAdd: any
  signupInProgress: boolean
  showWPNotice: boolean
  showManualAddressEntry: boolean
  showDeliveryAddress: boolean
  sameAddress: boolean
  showCallRecordWarning: boolean
  showTranscribeInfo: boolean
  buyUmr: boolean
  deliveryMethod: number
  enableTopup: boolean
  optedOut: boolean
  showCancelModal: boolean
  redirectToLandline: boolean
  redirectToMobile: boolean
  ipAddress: string
  prefix: string
  showBillingInfo: boolean
  isProcessing: boolean
  checkingOut: boolean
  canCheckout: boolean
  handsetAdded: boolean
  showGenericError: boolean
  genericErrorMessage: string
  totalToday: number
  purchaseInProgress: boolean
  showTopupWarning: boolean
  topupCredit: number
  productDetails: ProductPriceDto[]
  clientId?: number
  showPaymentOptionsModal: boolean
  changingType: boolean
  displayBillingWarning?: boolean
  showCancelWarning?: boolean
  showDiscountBox: boolean
  discountCode: string
  discountError?: boolean
  displayAnnualWarning?: boolean
  displayStripeError?: boolean
  stripeErrorMessage?: string
  showCallBlockingWarning: boolean
  displayPaygUpgrade?: boolean
  hasAgreement?: boolean,
  hasCallRecordingAll?: boolean,
  autoTopup?: boolean
}

const SignupCheckout: React.FunctionComponent<SignupCheckoutProps> = (
  props: SignupCheckoutProps
): JSX.Element => {
  const selectedNumberType = props.selectedNumberType
  const basket = props.currentBasket
  const pack = basket.package!
  const numberType = props.isLoggedIn && pack === undefined ? basket.numbers[0].number.substring(0, 4) : pack.numberType
  const userQty = pack?.users?.qty ?? 0
  const contactsDto: SignupContactsDto = { landline: '', mobile: '' }
  const essentialInfo: EssentialInfo = {
    contacts: contactsDto,
    email: '',
    name: ''
  }

  const optionalInfo: OptionalInfo = {
    analyticsId: '',
    company: '',
    marketingSource: '',
    referrer: '',
    topupCredit: 0,
    utm_campaign: '',
    utm_medium: '',
    utm_source: '',
    callRecording: true
  }

  const signupDetails: SignupData = {
    essentials: essentialInfo,
    optionalInfo,
    billingDetails: undefined
  }

  const initialState: SignupCheckoutState = {
    showBillingPeriodOptionsModal: false,
    showTermsWarning: false,
    acceptedTerms: false,
    quoteError: false,
    showQuoteSentModal: false,
    showPaymentOptionsModal: false,
    purchaseInProgress: false,
    totalToday: 0,
    genericErrorMessage: '',
    showGenericError: false,
    handsetAdded: false,
    canCheckout: false,
    signupInProgress: false,
    showWPNotice: false,
    showManualAddressEntry: false,
    showDeliveryAddress: false,
    sameAddress: false,
    showCallRecordWarning: false,
    showTranscribeInfo: false,
    buyUmr: false,
    deliveryMethod: 0,
    enableTopup: false,
    optedOut: false,
    showCancelModal: false,
    redirectToLandline: false,
    redirectToMobile: false,
    ipAddress: '',
    prefix: '',
    showBillingInfo: false,
    isProcessing: false,
    checkingOut: false,
    signupError: false,
    signupMsg: '',
    showNumNotAvailable: false,
    showEditDevices: false,
    showEditNumbers: false,
    editNumber: false,
    showEditUsers: false,
    showEditPlan: false,
    showOutbound: false,
    showVoipTerms: false,
    showTsAndCs: false,
    showPrivacy: false,
    isCreating: false,
    showDeliveryInfo: false,
    showFeatures: false,
    showEarbuds: false,
    key: undefined,
    signupData: signupDetails,
    deliveryAdd: undefined,
    showTopupWarning: false,
    topupCredit: 20,
    productDetails: [],
    changingType: false,
    showDiscountBox: false,
    discountCode: '',
    stripeErrorMessage: '',
    displayStripeError: false,
    showCallBlockingWarning: false,
    hasAgreement: false,
    hasCallRecordingAll: false,
    displayBillingWarning: false,
    autoTopup: false
  }

  const isMobile = (/Android|iPhone/i.test(navigator.userAgent))

  React.useEffect(() => {
    window.dataLayer.push({
      event: 'signupCheckout',
      transactionTotal: amountExcVAT
    })
    if (props.isLoggedIn) {
      handleGetUserServices()
    }

  }, []);


  function handleGetUserServices() {
    const url = 'api/Users/GetServices'

    fetchHelper.getJson<any>(url)
      .then(res => {
        if (res.data) {
          setState({
            ...state,
            hasAgreement: res.data.hasBillingAgreement,
            hasCallRecordingAll: res.data.hasCallRecordingAll,
            displayBillingWarning: res.data.displayBillingWarning && !props.isNewCustomer,
            autoTopup: res.data.autoTopup
          })
        }
      })
  }

  const [showPhoneApp, setShowPhoneApp] = useState(false);
  const handleClosePhoneApp = () => setShowPhoneApp(false);
  const handleShowPhoneApp = () => setShowPhoneApp(true);

  const [showAdminApp, setShowAdminApp] = useState(false);
  const handleCloseAdminApp = () => setShowAdminApp(false);
  const handleShowAdminApp = () => setShowAdminApp(true);

  const [showSoftphonePopup, setShowSoftphonePopup] = useState(false);
  const handleCloseSoftphonePopup = () => setShowSoftphonePopup(false);
  const handleShowSoftphonePopup = () => setShowSoftphonePopup(true);

  const [state, setState] = React.useState<SignupCheckoutState>(initialState)
  function renderComponent() {
    return (
      <>
        <ScrollToTopOnMount />
        <DisplayNoFooter />
        <DisplaySimpleHeaderV2 />
        {renderHeaders()}
        <Helmet>
          <body
            className={'body-simple bg-dull body-signup checkout-page'}
          />
        </Helmet>
        {renderProgressSteps()}
        {renderMain()}

        {renderModals()}

      </>
    )
  }

  function renderUnavailablePopover(num) {
    return (
      <Popover title="Number already taken">
        <p>
          Phone number <strong>{simplePhoneNumberFormat(num)}</strong> is no
          longer available. Please choose another number.{' '}
        </p>
      </Popover>
    )
  }

  function getProdDetails() {
    const newVar: ProductPriceOptionsQuery[] = [
      {
        productId: ProductIds.callRecordAll,
        monthlyOption:
          productBillingOption.callRecordingAllBillingOptionMonthly
      },
      {
        productId: ProductIds.umr,
        monthlyOption: productBillingOption.umrBillingOptionMonthly
      },
      {
        productId: ProductIds.endpoint,
        monthlyOption: productBillingOption.endpointBillingOptionMonthly
      },
      {
        productId: ProductIds.callBlocking,
        monthlyOption: productBillingOption.callBlockingBillingOptionMonthly
      }
    ]

    fetchProductPrices(newVar, (prices) => {
      setState({ ...state, productDetails: prices })
    })
  }

  function renderNumberPrem(num) {
    if (num.ddipremium === 0) {
      // Business
      return (
        <span className="premium premium-1">
        </span>
      )
    } else if (num.ddipremium === 5) {
      // Professional
      return (
        <span className="premium premium-2">
          <i className="icon icon-star-full text-cta text-xs" />
          <i className="icon icon-star-full text-cta text-xs" />
        </span>
      )
    } else if (num.ddipremium === 10) {
      // Gold
      return (
        <span className="premium premium-3">
          <i className="icon icon-star-full text-cta text-xs" />
          <i className="icon icon-star-full text-cta text-xs" />
          <i className="icon icon-star-full text-cta text-xs" />
        </span>
      )
    } else {
      // Platinum
      return (
        <span className="premium premium-4">
          <i className="icon icon-star-full text-cta text-xs" />
          <i className="icon icon-star-full text-cta text-xs" />
          <i className="icon icon-star-full text-cta text-xs" />
        </span>
      )
    }
  }

  function handleCancelSignup() {
    setState({
      ...state,
      showBillingInfo: false,
      isProcessing: false,
      checkingOut: false,
      displayAnnualWarning: false,
      displayPaygUpgrade: false
    })
  }

  function scrollTo(ele) {
    scroller.scrollTo(ele, {
      duration: 1500,
      delay: 100,
      smooth: true,
      offset: -125
    })
  }

  function handleApplyDiscount() {
    if (state.discountCode.toLowerCase() === 'welcome10') {
      setState({ ...state, showDiscountBox: false })
      props.handleApplyDiscount()
    } else {
      setState({ ...state, discountError: true })
    };
  }

  function handleRemoveDiscount() {
    setState({ ...state, showDiscountBox: false, discountCode: '' })
    props.handleRemoveDiscount()
  }

  // Sanity check UMR
  if (
    state.buyUmr &&
    !basket.numbers.some((num) => num.number.startsWith('084'))
  ) { setState({ ...state, buyUmr: false }) }

  let amountExcVAT = 0
  let amountIncVAT = 0
  const callBlockingPayment = { firstPayment: 0, repeatPayment: 0 }
  const umrPayment = { firstPayment: 0, repeatPayment: 0 }
  const callRecordPayment = { firstPayment: 0, repeatPayment: 0 }
  const endpointPrice = { firstPayment: 0, repeatPayment: 0, partialPayment: 0 }
  let deviceDiscountEligible = VoipHandsetDiscount
  let handsetQty = 0
  let subtotal = 0
  let annualPrice: number = 0
  const hardwarePrice = { monthly: 0, oneoff: 0 }
  let additionalNumberPrice = 0
  let freeExpressDelivery = false
  let ddipremiums = 0
  let isUnlimited = false
  let offerPrice = 0
  let isWhatsApp = false
  let monthlyPrice = 0
  const is0800 = basket?.numbers?.length > 0 ? basket.numbers[0].number?.startsWith('080') : false
  let monthlyUserPrice = 0
  // let isNewCustomer = LocalStorageHelper.getItem<boolean>("isNewCustomer")?.value ?? false;
  let priceBeforeDiscount = 0
  let isFreebies = false
  let isFreeBluetooth = false
  const extraNumberPrice = is0800 ? MultiNumberPrice0800 : MultiNumberPrice
  const show0800Offer = (numberType.startsWith('080') && is0800Offer && basket.isOutbound)

  if (props.productDetails) {
    const umrPaymentDetails = state.productDetails?.find(
      (prod) => prod.productId === ProductIds.umr
    )
    const callRecordPaymentDetails = state.productDetails?.find(
      (prod) => prod.productId === ProductIds.callRecordAll
    )
    const callBlockingPaymentDetails = state.productDetails?.find(
      (prod) => prod.productId === ProductIds.callBlocking
    )

    if (umrPaymentDetails !== null) {
      umrPayment.firstPayment =
        umrPaymentDetails?.monthlyPrice.firstPaymentAmount ?? 0
      umrPayment.repeatPayment =
        umrPaymentDetails?.monthlyPrice.repeatPaymentAmount ?? 0
    }
    if (callRecordPaymentDetails !== null) {
      callRecordPayment.firstPayment =
        callRecordPaymentDetails?.monthlyPrice.firstPaymentAmount ?? 0
      callRecordPayment.repeatPayment =
        callRecordPaymentDetails?.monthlyPrice.repeatPaymentAmount ?? 0
    }

    if (callBlockingPaymentDetails !== null) {
      callBlockingPayment.firstPayment =
        callBlockingPaymentDetails?.monthlyPrice.firstPaymentAmount ?? 0
      callBlockingPayment.repeatPayment =
        callBlockingPaymentDetails?.monthlyPrice.repeatPaymentAmount ?? 0
    }

    if (callRecordPayment.repeatPayment === 0 || umrPayment.repeatPayment === 0 || callBlockingPayment.repeatPayment === 0) { getProdDetails() }
  }

  if (basket) {
    if (state.buyUmr) {
      if (
        basket.numbers &&
        basket.numbers.length > 0 &&
        !basket.numbers[0].number.startsWith('084')
      ) { setState({ ...state, buyUmr: false }) }
    }

    if (!props.selectedNumberType && basket.numbers.length > 0 && !state.changingType) {
      const firstNumber = basket.numbers[0]
      if (firstNumber.number.startsWith('01')) {
        props.handleChangeNumberType(firstNumber.number.substring(0, 2))
      } else if (firstNumber.number.startsWith('084')) {
        props.handleChangeNumberType(firstNumber.number.substring(0, 3))
      } else {
        props.handleChangeNumberType(firstNumber.number.substring(0, 4))
      }
    }

    basket.numbers.forEach(function (num) {
      subtotal += num.ddipremium
      ddipremiums += num.ddipremium
    })

    if (basket.numbers.length > 1) {
      additionalNumberPrice += (basket.numbers.length - 1) * extraNumberPrice
      subtotal += additionalNumberPrice
    }

    if (pack && pack.rental) {
      if (show0800Offer) {
        endpointPrice.firstPayment = pack.fullPrice
        endpointPrice.repeatPayment = pack.fullPrice
      } else {
        endpointPrice.firstPayment = pack.rental
        endpointPrice.repeatPayment = pack.rental
      }
      subtotal += pack.rental

      if (basket.isOutbound && pack.name.toLowerCase().includes('unlimited')) {
        isUnlimited = true
      }
      if (pack.name.toLowerCase().includes('whatsapp')) {
        isWhatsApp = true
        // if (basket.isOutbound !== false) {
        //   setState({ ...state, isOutbound: false });
        // }
      }

      if (((basket.package?.packageId === BasicOutboundPlan || basket.package?.packageId === UnlimitedOutboundPlan) && (basket.isOutbound || props.isLoggedIn))) {
        isFreebies = true
      }

      if (basket.package?.packageId === BasicOutboundPlan) {
        isFreeBluetooth = true
      }
    } else if ((!props.isLoggedIn || (props.isLoggedIn && props.isNewCustomer)) && basket.isOutbound && props.productDetails) {
      const endpointProd = props.productDetails.find(
        (prod) => prod.productId === ProductIds.endpoint
      )
      endpointPrice.firstPayment =
        endpointProd?.monthlyPrice.firstPaymentAmount ?? 0
      endpointPrice.repeatPayment =
        endpointProd?.monthlyPrice.repeatPaymentAmount ?? 0
    }

    if (userQty) {
      if (show0800Offer) {
        subtotal += (pack.users.qty - 1) * pack.rental
      } else {
        subtotal += (pack.users.qty - 1) * (pack.users.monthlyPrice > 0 ? pack.users.monthlyPrice : pack.rental)
      }
      monthlyUserPrice = (pack.users.qty - 1) * (pack.users.monthlyPrice > 0 ? pack.users.monthlyPrice : pack.rental)
    }

    if (basket.hardware && basket.hardware.length > 0) {
      basket.hardware.forEach(function (item: Hardware) {
        handsetQty += item.qty
        hardwarePrice.oneoff +=
          calculateHandsetPricee(
            item.amount,
            pack?.users ?? 0 > 0 ? pack.users.qty : 1,
            basket
          ) * item.qty
      })

      if (
        (pack?.users ?? 0 > 0) &&
        pack.users.qty > 2 &&
        handsetQty >= pack.users.qty &&
        MultiBuyDiscount > VoipHandsetDiscount
      ) { deviceDiscountEligible = MultiBuyDiscount }
    }

    if (pack) {
      annualPrice =
        show0800Offer
          ? (subtotal - (pack.rental * pack.users.qty)) * 12 +
          (pack.fullPrice * pack.users.qty) * (12 - numberOfferDuration) +
          (pack.rental * pack.users.qty) * numberOfferDuration
          : subtotal * 12
    }

    if (props.billingPeriod === billingPeriod.YEAR) {
      freeExpressDelivery = annualPrice + hardwarePrice.oneoff >= 150

      amountExcVAT = annualPrice + hardwarePrice.oneoff
    } else if (props.billingPeriod === billingPeriod.BIANNUAL) {
      freeExpressDelivery = annualPrice + hardwarePrice.oneoff >= 150
      if (
        show0800Offer
      ) {
        amountExcVAT =
          (pack.rental * pack.users.qty) + (pack.fullPrice * 5) + (monthlyUserPrice * 5) + (ddipremiums * 6) +
          hardwarePrice.oneoff
      } else {
        amountExcVAT = (pack.rental * 6) + (monthlyUserPrice * 6) + (ddipremiums * 6) + (additionalNumberPrice * 6) + hardwarePrice.oneoff
      }
    } else {
      freeExpressDelivery =
        subtotal + hardwarePrice.oneoff >= 150

      amountExcVAT =
        amountExcVAT + subtotal + hardwarePrice.oneoff
    }

    if (props.callBlocking === true) {
      amountExcVAT += callBlockingPayment.firstPayment;
      annualPrice += callBlockingPayment.firstPayment;
    }

    if (
      state.deliveryMethod === DeliveryType.express &&
      !freeExpressDelivery && hardwarePrice.oneoff > 0
    ) { amountExcVAT += ExpressDeliveryCharge }

    if (
      pack &&
      basket.numbers &&
      basket.numbers.length > 0 &&
      show0800Offer
    ) {
      // amountExcVAT = parseFloat((amountExcVAT - pack.rental) + 0.99);
      offerPrice = (amountExcVAT - pack.rental) + (0.99 + (pack.rental > AppOnlyPrice0800 ? (pack.rental - AppOnlyPrice0800) : 0))
    }

    if ((props.isLoggedIn && !props.isNewCustomer) && _.isEmpty(pack)) amountExcVAT += extraNumberPrice

    monthlyPrice = Number((subtotal).toFixed(2))
    if (isUnlimited) { annualPrice = annualPrice - (annualPrice * AnnualDiscount) }

    if (basket?.setupFee ?? 0 > 0) { amountExcVAT += basket?.setupFee ?? 0 }

    if (basket.discountApplied ?? 0 > 0) {
      priceBeforeDiscount = amountExcVAT

      if (state.discountCode !== 'welcome10') { setState({ ...state, discountCode: 'welcome10' }) }

      if (props.billingPeriod === billingPeriod.YEAR) {
        const monthly = annualPrice / 12
        amountExcVAT = annualPrice - (monthly * 0.1)
      } else if (props.billingPeriod === billingPeriod.BIANNUAL) {
        const monthly = amountExcVAT / 6
        amountExcVAT = priceBeforeDiscount - (monthly * 0.1)
      } else {
        amountExcVAT = priceBeforeDiscount - (priceBeforeDiscount * 0.1)
      }
    }

    if (
      !basket.isOutbound &&
      show0800Offer && (!props.isLoggedIn || (props.isLoggedIn && props.isNewCustomer))
    ) {
      amountIncVAT = calculateVat(_.isEmpty(pack) ? extraNumberPrice : offerPrice)
    } else {
      amountIncVAT = calculateVat(
        props.billingPeriod === billingPeriod.YEAR ? (annualPrice + hardwarePrice.oneoff) : amountExcVAT
      )
    }

    // Pro-rata
    if (props.isLoggedIn && !props.isNewCustomer && props.billingPeriod === billingPeriod.MONTH) {
      amountExcVAT = amountExcVAT > 0 ? (calculateRemainingMonth((amountExcVAT - hardwarePrice.oneoff), props.billingDay ?? 1) + hardwarePrice.oneoff): 0 
      amountIncVAT = calculateVat(amountExcVAT)
    }
  }

  if (
    props.callRecording &&
    state.hasCallRecordingAll === true
  ) {
    props.handleUpdateSignupCallRecording(false)
  }

  // Double check we haven't ended up with annual 084
  if ((pack?.numberType.startsWith('084') || basket.numbers.some((num) => num.number.startsWith('084'))) && props.billingPeriod !== billingPeriod.MONTH) { props.handleChooseBillingPeriod(billingPeriod.MONTH, false) }

  const canCheckout = !!basket.isOutbound

  function renderPricingToggle() {
    function renderBiannualPaymentOption() {
      return <Button
        variant="lightest"
        size="sm"
        className={`brad-10 p-5 btn-radio mr-10 bg-light mr-20 btn-block mb-10 b-1 no-shadow ${props.billingPeriod === billingPeriod.BIANNUAL
          ? 'active'
          : ''
          }`}
        onClick={() => { props.handleChooseBillingPeriod(billingPeriod.BIANNUAL, isFreebies, isFreeBluetooth) }}
      >
        <div className="row">
          
          <div className={`text-start ${(isUnlimited || isFreebies || isFreeBluetooth) ? 'col-xs-9 col-8' : 'col-12'}`}>
            <div className="row">
              <div className="col-12">
                <span className="radio-circle">
                  <span className="radio-checked"></span>
                </span>
                <span className="radio-text heading">
                  <span className="text-500 pr-10">Bi-Annual</span>
                </span>
              </div>
              <div className="col-12 pl-xs-40 pl-55">
                <span className="radio-text">
                  <span className="text-italic text-sm ">
                    {show0800Offer ? (
                        <span>
                          Pay every 6-months
                          {isUnlimited && <> +
                            get Bluetooth Earbuds & a VoIP Deskphone <span className="text-500">FREE</span> -{' '}
                            <span className="text-cta text-500">
                              Worth &pound;35
                            </span>{' '}
                            -{' '}
                            <span className="text-500">
                              Most customers choose this
                            </span></>}.
                        </span>
                      )
                      : (
                        <span>
                          Pay every
                          6-months {isUnlimited && <> + get Bluetooth Earbuds & a VoIP Deskphone <span className="text-500">FREE</span> -{' '}
                            <span className="text-cta text-500">
                              Worth &pound;90
                            </span>{' '}
                            {' '}
                            <span className="text-500">
                              (Most customers choose this)
                            </span></>}.

                          {isFreeBluetooth && <> + get Bluetooth Earbuds <span className="text-500">FREE</span> -{' '}
                            <span className="text-cta text-500">
                              Worth &pound;35
                            </span>{' '}
                            <span className="text-500">
                              (Most customers choose this)
                            </span></>}
                        </span>
                      )}
                  </span>
                </span>
              </div>
            </div>

          </div>
          {((isUnlimited || isFreebies) && !isFreeBluetooth) &&
            <div className="col-4 col-xs-3">
              {isUnlimited &&
                <img
                  src="/images/freebies.jpg"
                  alt="Free Earbuds + Phone"
                />
              }
            </div>}

          {isFreeBluetooth &&
            <div className="col-4 col-xs-3">
              <img
                src="/images/freebies-bluetooth.jpg"
                alt="Free Earbuds"
              />
            </div>}
        </div>
      </Button>
    }

    function renderAnnualPaymentOption() {
      return <Button
        variant="lightest"
        size="sm"
        className={`brad-10 p-5 btn-radio mr-10 bg-light mr-20 btn-block b-1 no-shadow ${props.billingPeriod === billingPeriod.YEAR ? 'active' : ''
          }`}
        onClick={() => { props.handleChooseBillingPeriod(billingPeriod.YEAR, isFreebies) }}
      >
        <div className="row">
          
          <div className={`text-start ${(isUnlimited || isFreebies) ? 'col-xs-9 col-8' : 'col-12'}`}>
            <div className="row">
              <div className="col-12">
                <span className="radio-circle">
                  <span className="radio-checked"></span>
                </span>
                <span className="radio-text heading">
                  <span className="text-500 pr-10">
                    Annually
                    {isUnlimited &&
                      <span className="text-cta ml-10">
                        Save 5%!
                      </span>
                    }
                  </span>
                </span>
              </div>
              <div className="col-12 pl-xs-40 pl-55">
                <span className="radio-text">
                  <span className="text-italic text-sm ">
                    {show0800Offer ? (
                      <span>
                        Pay every year,{' '}
                        {isUnlimited &&
                          <span className="text-cta text-500">
                            save {AnnualDiscount * 100}%
                          </span>
                        }
                        {(isUnlimited || isFreebies) && <>+ get Bluetooth Earbuds & a VoIP Deskphone <span className="text-500">FREE</span> for each user -{' '}
                          <span className="underlined">per user</span>.{' '}
                          <span className="text-cta text-500">
                            Worth &pound;35
                          </span></>}.
                      </span>
                    ) : (
                      <span>
                        Pay every year,{' '}
                        {isUnlimited &&
                          <span className="text-cta text-500">
                            save {AnnualDiscount * 100}%
                          </span>
                        }
                        {(isUnlimited || isFreebies) && <>+ get Bluetooth Earbuds & a VoIP Deskphone <span className="text-500">FREE</span> for each user -{' '}
                          {/* <span className="underlined">per user</span>.{" "} */}
                          <span className="text-cta text-500">
                            Worth &pound;90
                          </span></>}.
                      </span>
                    )}
                  </span>
                </span>
              </div>
            </div>
          </div>
          {(isUnlimited || isFreebies) &&
            <div className="col-4 col-xs-3">
              <img
                src="/images/freebies.jpg"
                alt="Free Earbuds + Phone"
              />
            </div>}
        </div>
      </Button>
    }

    function renderMonthlyPaymentOption() {
      return <Button
        variant="lightest"
        size="sm"
        className={`brad-10 p-5 btn-radio mr-10 bg-light mr-20 btn-block mb-10 b-1 no-shadow ${props.billingPeriod === billingPeriod.MONTH ? 'active' : ''
          }`}
        onClick={() => { props.handleChooseBillingPeriod(billingPeriod.MONTH, isFreebies) }}
      >
        <div className="row">
         
          <div className="col-xs-8 col-xs-9 text-start">
            <div className="row">
              <div className="col-12">
                <span className="radio-circle">
                  <span className="radio-checked"></span>
                </span>
                <span className="radio-text heading">
                  <span className="text-500 pr-10">Monthly</span>
                </span>
              </div>
              <div className="col-12 pl-xs-40 pl-55">
                <span className="radio-text">
                  <span className="text-italic text-sm ">
                    {show0800Offer ? (
                        <span>
                          Pay on the{' '}
                          {moment().date() > 28
                            ? '28th'
                            : moment().format('Do')}{' '}
                          of each month.
                        </span>
                      )
                      : (
                        <span>
                          Pay on the{' '}
                          {moment().date() > 28
                            ? '28th'
                            : moment().format('Do')}{' '}
                          of each month. No additional benefits.
                        </span>
                      )}
                  </span>
                </span>
              </div>
            </div>
          </div>
          {(isUnlimited || isFreebies) &&
            <div className="col-4 col-xs-3 d-none d-xs-block"></div>}
        </div>
      </Button>
    }

    return (
      <>
        <div className="row">
          <div className="col-xs-12">
            <div className="bg-cta-faded brad-10 p-20 mb-20 mt-0 text-left">
              <p className="text-500 text-md pb-0 text-cta">
                How would you like to pay?
              </p>
              <p className="text-sm mb-20">
                {isUnlimited
                  ? <>
                    78% of our customers choose{' '}
                    <em className="text-cta text-500">
                      Annual payments.
                    </em> for this plan.
                  </>
                  : <>
                    83% of our customers choose{' '}
                    <em className="text-cta text-500">
                      Annual payments.
                    </em> for this plan.
                  </>
                }
              </p>

              {renderMonthlyPaymentOption()}
              {renderBiannualPaymentOption()}
              {renderAnnualPaymentOption()}
            </div>
          </div>
        </div>
      </>
    )
  }

  function renderTotalToday() {
    return (
      <p className="text-500 mb-0">
        Total Payment Today:
        <span className="ml-10">
          {priceBeforeDiscount > 0 &&
            <span className="text-dullest mr-10" style={{ textDecoration: 'line-through' }}>
              &pound;{priceBeforeDiscount.toFixed(2)}
            </span>}

          {(show0800Offer && (!props.isLoggedIn || (props.isLoggedIn && props.isNewCustomer)) &&
            (numberType.startsWith('080') ||
              (basket &&
                basket.numbers &&
                basket.numbers.length > 0 &&
                is0800)))
            ? (
              props.billingPeriod === billingPeriod.YEAR
                ? (
                  <>
                    <span>
                      {(!basket.discountApplied || basket.discountApplied === 0) && isUnlimited &&
                        <del className="mr-10 text-dullest">
                          &pound;{amountExcVAT.toFixed(2)}
                        </del>
                      }
                      &pound;{basket.discountApplied ?? 0 > 0 ? amountExcVAT.toFixed(2) : annualPrice.toFixed(2)}{!props.callRecording && <sup>*</sup>}
                    </span>
                  </>
                )
                : (
                  <>
                    &pound;{(_.isEmpty(pack) ? extraNumberPrice : offerPrice).toFixed(2)}
                    {!props.callRecording && <sup>*</sup>}
                  </>
                )
            )
            : props.billingPeriod === billingPeriod.YEAR
              ? (
                <>
                  <span>
                    {(!basket.discountApplied || basket.discountApplied === 0) && isUnlimited &&
                      <del className="mr-10 text-dullest">
                        &pound;{amountExcVAT.toFixed(2)}
                      </del>
                    }
                    &pound;{((basket.discountApplied ?? 0 > 0 ? amountExcVAT : annualPrice) + hardwarePrice.oneoff).toFixed(2)}
                  </span>
                </>
              )
              : (<>
                £{basket.numbers.length === 1 &&
                  !_.isEmpty(pack) &&
                  !isWhatsApp &&
                  !pack.name.includes('084')
                  ? amountExcVAT.toFixed(2)
                  : amountExcVAT.toFixed(2)
                } {basket?.setupFee !== undefined && <sup>*</sup>}</>
              )}
        </span>

      </p>
    )
  }

  function renderMain() {

    function renderBasket() {

      function renderEditPlanClickableSpan() {
        return (
          <span
            className="text-400 cta-hover text-secondary cursor-pointer text-sm ml-15 d-inline-block underline"
            onClick={() => {
              setState({
                ...state,
                showEditPlan: true
              })
            }
            }
          >
            Edit
          </span>
        )
      }

      function renderPlanHeaderForOutbound() {
        return (
          <>
            <p className="text-400 float-start mb-0">{pack.name} Plan</p>
            {renderEditPlanClickableSpan()}
          </>
        )
      }

      function renderAddPackageClickableSpanForOutbound() {
        return (
          <>
            <span
              className="text-500 cta-hover text-secondary cursor-pointer text-sm"
              onClick={() => {
                setState({
                  ...state,
                  showEditPlan: true
                })
              }
              }
            >
              ADD PACKAGE
            </span>
          </>
        )
      }

      function renderAddPackageClickableSpanForInbound() {
        return (
          <span
            className="text-400 cta-hover text-secondary cursor-pointer text-sm ml-10 d-inline-block underline"
            onClick={() => {
              setState({
                ...state,
                showEditPlan: true
              })
            }
            }
          >
            Edit
          </span>
        )
      }

      function renderNumberWithMinutesPlan() {
        return (
          <>
            <p className="text-400 float-start mb-0">{pack.name}</p>
            {renderAddPackageClickableSpanForInbound()}
            <span className="float-end text-400">
              {show0800Offer
                ? (
                  <span>99p</span>
                )
                : (
                  <span>
                    &pound;
                    {pack.rental ? pack.rental.toFixed(2) : 0}
                  </span>
                )}
            </span>
          </>
        )
      }

      function renderNumberOnlyPlan() {
        return (
          <>
            <p className="text-400 mn-10 mr-10 float-start mb-0">Number Only Plan</p>
            {renderAddPackageClickableSpanForInbound()}
            <span className="float-end text-400">
              &pound;{extraNumberPrice}
            </span>
          </>
        )
      }

      function renderAddInboundPackage() {
        return (
          <>
            <div className="row mb-5">
              <div className="col-9 text-start">
                <p className="text-400 float-start mb-0">
                  {pack?.rental > (is0800 ? AppOnlyPrice0800 : AppOnlyPrice)
                    ? pack.name
                    : isWhatsApp
                      ? 'WhatsApp Business Plan'
                      : 'Unlimited to App Plan'}

                </p>
                {renderAddPackageClickableSpanForInbound()}
              </div>
              <div className="col-3 text-end">
                <p className="text-400 mb-0">
                  {basket.numbers &&
                    basket.numbers.length > 0 &&
                    is0800
                    ? (
                      show0800Offer
                        ? (
                          <span>99p</span>
                        )
                        : (
                          <span>&pound;{pack.rental}</span>
                        )
                    )
                    : isWhatsApp
                      ? (
                        <span>&pound;{pack.rental}</span>
                      )
                      : (
                        <span>&pound;{(pack.rental ? pack.rental : 0)}</span>
                      )}
                </p>
              </div>
            </div>
          </>
        )
      }

      function renderNumberNotAvailableOverlay() {
        return (
          <OverlayTrigger
            trigger={['hover', 'focus']}
            placement="top"
            overlay={renderUnavailablePopover(basket.numbers[0].number)}
          >
            <span className="text-400 d-inline-block mb-5 text-danger text-sm">
              <span className="icon icon-warning2 mr-5"></span>
              {simplePhoneNumberFormat(basket.numbers[0].number)}
              {/* {renderNumberPrem(basket.numbers[0])} */}
              <span
                className="text-400 cta-hover text-secondary cursor-pointer text-xs ml-10 underline"
                onClick={() => {
                  setState({
                    ...state,
                    changingType: true,
                    showEditNumbers: true,
                    editNumber: true
                  })
                }
                }
              >
                Edit
              </span>
            </span>
          </OverlayTrigger>
        )
      }

      function renderEditNumbers() {
        return (
          <div className="w-100 d-inline-block">
            <span className="text-400 d-inline-block mb-5 text-sm">
              <span>
                {simplePhoneNumberFormat(basket.numbers[0].number)}{' '}
                {renderNumberPrem(basket.numbers[0])}
                <span
                  className="text-400 cta-hover text-secondary cursor-pointer ml-10 underline"
                  onClick={() => {
                    setState({
                      ...state,
                      changingType: true,
                      showEditNumbers: true,
                      editNumber: true
                    })
                  }
                  }
                >
                  Edit
                </span>
              </span>
            </span>
            <span className="float-end text-400">
              {basket.numbers[0].ddipremium > 0 &&
                <>
                  &pound;{(basket.numbers[0].ddipremium).toFixed(2)}
                </>
              }
            </span>
          </div>

        )
      }

      function renderAddNumber() {
        return (
          <span
            className="text-500 cta-hover text-secondary cursor-pointer text-sm underline"
            onClick={() => {
              setState({
                ...state,
                changingType: true,
                showEditNumbers: true,
                editNumber: false
              })
            }
            }
          >
            ADD NUMBER
          </span>
        )
      }

      function renderFeaturesBlurb() {
        return (
          <>
            <div className="w-100 d-inline-block">
              <span className="text-400 d-inline-block mb-5">
                20+ free features{' '}
                <span
                  className="text-400 cta-hover text-secondary cursor-pointer text-sm ml-10 underline"
                  onClick={() => { setState({ ...state, showFeatures: true }) }}
                >
                  More info
                </span>
              </span>
            </div>
            <div className="w-100 d-inline-block">
              <span className="text-400 d-inline-block mb-5">
                <span className="text-500 text-success">FREE Training &amp; Onboarding{' '}</span>
              </span>
              <span className="float-end text-500 text-sm d-none d-sm-block text-success pt-5">Worth &pound;30</span>
            </div>
          </>
        )
      }

      function renderExtraPhoneNumberInBasket() {
        return (
          <>
            <div className="row">
              <div className="col-12">
                {basket.numbers.slice(1).map((num, i) => (
                  <>
                    <div className="w-100 d-inline-block">
                      <span className="text-400 d-inline-block mb-5 text-sm">
                        {num.available === false
                          ? (
                            <OverlayTrigger
                              trigger={['hover', 'focus']}
                              placement="top"
                              overlay={renderUnavailablePopover(num.number)}
                            >
                              <span className="text-danger">
                                <span className="icon icon-warning2 mr-5"></span>
                                {simplePhoneNumberFormat(num.number)}{' '}
                                {renderNumberPrem(num)}
                                <span
                                  className="cta-hover text-sm text-secondary ml-10 text-400 cursor-pointer underline"
                                  onClick={() => {
                                    props.handleRemoveFromBasket({
                                      removeAll: false,
                                      item: num,
                                      itemType: BasketItemType.phoneNumber,
                                      editing: false
                                    })
                                  }
                                  }
                                >
                                  Remove
                                </span>
                              </span>
                            </OverlayTrigger>
                          )
                          : (
                            <span>
                              {simplePhoneNumberFormat(num.number)}{' '}
                              {renderNumberPrem(num)}
                              <span
                                className="cta-hover text-secondary text-sm ml-10 text-400 cursor-pointer underline"
                                onClick={() => {
                                  props.handleRemoveFromBasket({
                                    removeAll: false,
                                    item: num,
                                    itemType: BasketItemType.phoneNumber,
                                    editing: false
                                  })
                                }
                                }
                              >
                                Remove
                              </span>
                            </span>
                          )}
                      </span>
                      <span className="float-end text-400">
                        &pound;{(num.ddipremium + extraNumberPrice).toFixed(2)}
                      </span>
                    </div>
                  </>
                ))}
              </div>
            </div>
          </>
        )
      }

      function renderExtraUsers() {
        return (
          <div className="row">
            <div className="col-12 mb-5">
              <p className="text-400 float-start mb-0">
                Extra Users x {pack.users.qty - 1}
                <span
                  className="text-400 cta-hover text-secondary cursor-pointer text-sm ml-10 underline"
                  onClick={() => { setState({ ...state, showEditUsers: true }) }}
                >
                  Edit
                </span>
              </p>
              <span className="float-end text-400">
                {(show0800Offer)
                  ? <>
                    &pound;
                    {((pack.users.qty - 1) * pack.rental).toFixed(2)}
                  </>
                  : <>
                    &pound;
                    {((pack.users.qty - 1) * (pack.users.monthlyPrice > 0 ? pack.users.monthlyPrice : pack.rental)).toFixed(2)}
                  </>
                }
              </span>
              <span className="clearfix" />
            </div>
          </div>
        )
      }

      function renderAddAnotherNumberSpan() {
        return (
          <span
            className="text-400 cta-hover text-secondary cursor-pointer text-sm underline"
            onClick={() => {
              setState({
                ...state,
                changingType: true,
                showEditNumbers: true,
                editNumber: false
              })
            }
            }
          >
            Add number
          </span>
        )
      }

      function renderHardwareInBasket() {
        return (
          basket.hardware &&
          basket.hardware.length > 0
          && basket.hardware.map((item, i) => (
            <>
              <div className="row mb-20">
                <div className="col-2">
                  <div className="bg-light brad-10">
                    <img
                      src={`/images/product-shots/ecom/${item.model?.replace(' ', '')}.jpg`}
                      alt={item.name}
                      style={{ width: '70px', maxWidth: '100%' }}
                    />
                  </div>
                </div>
                <div className="col-8 text-500">
                  <span>
                    {item.name}{' '}
                    {VoipHandsetDiscount > 0
                      ? (
                        <span className="text-cta">
                          {VoipHandsetDiscount * 100}% OFF
                        </span>
                      )
                      : (
                        ''
                      )}
                    {item.qty > 0 && `x ${item.qty}`}
                    <br />
                    {item.amount > 0
                      ? (
                        <>
                          <span className="text-sm text-400">
                            {item.monthlyPayments === true
                              ? `Monthly @ £${item.monthlyPrice.toFixed(2)} each`
                              : `One-off @ £${calculateHandsetPricee(
                                item.amount,
                                pack?.users?.qty ?? 1,
                                basket
                              )} each`}
                          </span>
                          <span
                            className="cta-hover text-xs text-secondary ml-10 text-400 cursor-pointer underline"
                            onClick={() => {
                              props.handleRemoveFromBasket({
                                removeAll: false,
                                item,
                                itemType: BasketItemType.hardware,
                                editing: false
                              })
                            }
                            }
                          >
                            Remove
                          </span>
                        </>
                      )
                      : (
                        <>
                          <span className="text-sm text-300">
                            FREE With Plan
                          </span>
                          <span
                            className="cta-hover text-xs text-secondary ml-10 text-400 cursor-pointer"
                            onClick={() => {
                              props.handleRemoveFromBasket({
                                removeAll: false,
                                item,
                                itemType: BasketItemType.hardware,
                                editing: false
                              })
                            }
                            }
                          >
                            Remove
                          </span>
                        </>
                      )}
                  </span>
                </div>
                <div className="col-2 text-400 text-end text-sm">
                  {item.monthlyPayments
                    ? (
                      <span>
                        &pound;{(item.monthlyPrice * item.qty).toFixed(2)}
                      </span>
                    )
                    : item.amount > 0
                      ? (
                        `£${calculateHandsetPricee(item.amount, pack?.users?.qty ?? 1, basket) *
                        item.qty
                        }`
                      )
                      : (
                        'Free'
                      )}
                </div>
              </div>

              <hr className="mt-30 mb-30 bt-1-dark m-0" />
            </>
          ))
        )
      }

      function renderPhoneAppsStuff() {
        return (
          <>
            <div className="row mb-5 mt-20">
              <div className="col-9 text-500 text-xs-sm">
                <span>
                  Phone App <em className="text-sm text-400" style={{position:"relative"}}>
                    iOS &amp; Android <span className='icon icon-info2 ml-5 text-dark-lighter cursor-pointer' onClick={handleShowPhoneApp}></span></em>
                </span>
                <Modal show={showPhoneApp} onHide={handleClosePhoneApp}>
                  <Modal.Header closeButton>
                    <Modal.Title>SwitchboardFREE Phone app</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                            <p>
                                Our free SwitchboardFREE Phone app is ideal for making and receiving calls on the go.
                            </p>
                            <p>
                              Here are some of the things you can do with this app:
                            </p>
                            <ul className="styled-list cta rounded text-400 pr-30 mb-30 ml-0 pl-0 pt-30 mb-xs-0 mb-sm-0 pt-xs-0">
                                <li className="pb-xs-10 pt-xs-10 brad-5" key={1}>
                                Make &amp; receive calls
                                </li>
                                <li className="pb-xs-10 pt-xs-10 brad-5" key={2}>
                                Choose the number you want to make calls with
                                </li>
                                <li className="pb-xs-10 pt-xs-10 brad-5" key={3}>
                                Transfer calls to any of your team at the tap of a button
                                </li>
                                <li className="pb-xs-10 pt-xs-10 brad-5" key={4}>
                                Put callers on hold with a single tap
                                </li>
                                <li className="pb-xs-10 pt-xs-10 brad-5" key={5}>
                                Easily access your existing contacts
                                </li>
                                <li className="pb-xs-10 pt-xs-10 brad-5" key={6}>
                                Crystal clear audio quality
                                </li>
                                <li className="pb-xs-10 pt-xs-10 brad-5" key={7}>
                                  Available on iOS &amp; Android
                              </li>
                            </ul>

                    <p className='text-italic p-10 brad-10 b-2 bg-dull'> <span className='icon icon-info2 mr-5 text-dark-lighter text-sm'></span> App download links will be sent to your email after you successfully create your account.</p>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button size="sm" variant="secondary" onClick={handleClosePhoneApp}>
                      Close
                    </Button>
                  </Modal.Footer>
                </Modal>
              </div>
              <div className="col-3 text-400 text-end text-sm pr-0">
                <span>Free</span>
              </div>
            </div>
            <div className="row mb-5">
              <div className="col-9 text-500 text-xs-sm">
                <span>
                  Admin App <em className="text-sm text-400">iOS &amp; Android <span className='icon icon-info2 ml-5 text-dark-lighter cursor-pointer' onClick={handleShowAdminApp}></span></em>
                </span>
                <Modal show={showAdminApp} onHide={handleCloseAdminApp}>
                  <Modal.Header closeButton>
                    <Modal.Title>SwitchboardFREE Admin App</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                  <p>
                       Our free to use and download <i>SwitchboardFREE Admin app</i> is perfect for updating your account and number settings on the go.
                    </p>
                    <p className='pt-20'>
                      Here are some of the things you can control:
                    </p>
 
                      <ul className="styled-list cta rounded text-400 pr-30 mb-30 ml-0 pl-0 pt-20 mb-xs-0 mb-sm-0 pt-xs-0">
                          <li className="pb-xs-10 pt-xs-10 brad-5"  key={1}>
                              See a list of recent calls or listen to your voicemails
                          </li>
                          <li className="pb-xs-10 pt-xs-10 brad-5" key={8}>
                              Open/close your departments
                          </li>
                          <li className="pb-xs-10 pt-xs-10 brad-5" key={9}>
                              Change your lines opening and closing hours
                          </li>
                          <li className="pb-xs-10 pt-xs-10 brad-5" key={10}>
                              Change your redirect numbers
                          </li>
                          <li className="pb-xs-10 pt-xs-10 brad-5" key={11}>
                              Record and upload your custom prompts
                          </li>
                          <li className="pb-xs-10 pt-xs-10 brad-5" key={12}>
                              Change your on hold music
                          </li>
                          <li className="pb-xs-10 pt-xs-10 brad-5" key={13}>
                              Sync you existing phone contacts with your SwitchboardFREE contacts
                          </li>
                      </ul>
       
                    <p className='text-italic p-10 brad-10 b-2 bg-dull'> <span className='icon icon-info2 mr-5 text-dark-lighter text-sm'></span> App download links will be sent to your email after you successfully create your account.</p>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button size="sm" variant="secondary" onClick={handleCloseAdminApp}>
                      Close
                    </Button>
                  </Modal.Footer>
                </Modal>
              </div>
              <div className="col-3 text-400 text-end text-sm pr-0">
                <span>Free</span>
              </div>
            </div>
            <div className="row mb-5">
              <div className="col-9 text-500 text-xs-sm">
                <span>
                  Softphone <em className="text-sm text-400">Windows &amp; Mac <span className='icon icon-info2 ml-5 text-dark-lighter cursor-pointer' onClick={handleShowSoftphonePopup}></span></em>
                </span>
                <Modal show={showSoftphonePopup} onHide={handleCloseSoftphonePopup}>
                  <Modal.Header closeButton>
                    <Modal.Title>SwitchboardFREE Softphone for Windows &amp; Mac</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <p>
                      If you have a computer with Windows or Mac operating system installed, our free Softphone you will give you the ability to make and receive calls using your SwitchboardFREE numbers.
                    </p>
                    <ul className="styled-list cta rounded text-300 pr-30 mb-30 ml-0 pl-0 pt-20 text-400">
                      <li className="pb-xs-10 pt-xs-10 brad-5" key={14}>
                        Make HD voice calls over IP for crisp and clear communication.
                      </li>
                      <li className="pb-xs-10 pt-xs-10 brad-5"  key={15}>
                        Choose the number you want to make calls with.
                      </li>
                      <li className="pb-xs-10 pt-xs-10 brad-5"  key={16}>
                        Call history panel with access to call details.
                      </li>
                      <li className="pb-xs-10 pt-xs-10 brad-5"  key={17}>
                        Integrate with your SwitchboardFREE Contact Book.
                      </li>
                    </ul>
                    <p className='text-italic p-10 brad-10 b-2 bg-dull'> <span className='icon icon-info2 mr-5 text-dark-lighter text-sm'></span> Softphone download links will be sent to your email after you successfully create your account.</p>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button size="sm" variant="secondary" onClick={handleCloseSoftphonePopup}>
                      Close
                    </Button>
                  </Modal.Footer>
                </Modal>
              </div>
              <div className="col-3 text-400 text-end text-sm pr-0">
                <span>Free</span>
              </div>
            </div>
          </>
        )
      }

      function renderHardwareDeliveryOptions() {
        return (
          <>
            {hardwarePrice.oneoff > 0 && (
              <>
                <div className="row delivery-options">
                  <div className="col-12">
                    <h5 className="text-500 mb-30">
                      Delivery Options
                    </h5>
                  </div>
                  <div className="col-12">
                    <Button
                      variant="lightest"
                      className={`btn-radio mr-10 bg-light mr-20 btn-block brad-10 ${state.deliveryMethod === DeliveryType.standard
                        ? 'active'
                        : ''
                        }`}
                      onClick={() => {
                        setState({
                          ...state,
                          deliveryMethod: DeliveryType.standard
                        })
                      }
                      }
                    >
                      <span className="radio-circle">
                        <span className="radio-checked"></span>
                      </span>
                      <span className="radio-text d-inline-block pt-5">
                        <span className="text-500 pr-10">Standard</span>
                        <span className="text-italic text-sm">
                          3-5 working days
                        </span>
                      </span>
                      <span className="text-end float-end pt-5">FREE</span>
                    </Button>
                  </div>
                  <div className="col-12 mt-10 mb-20">
                    <Button
                      variant="lightest"
                      className={`btn-radio mr-10 bg-light mr-20 btn-block brad-10 ${state.deliveryMethod === DeliveryType.express
                        ? 'active'
                        : ''
                        }`}
                      onClick={() => {
                        setState({
                          ...state,
                          deliveryMethod: DeliveryType.express
                        })
                      }
                      }
                    >
                      <span className="radio-circle">
                        <span className="radio-checked"></span>
                      </span>
                      <span className="radio-text d-inline-block pt-5">
                        <span className="text-500 pr-10">Express</span>
                        <span className="text-italic text-sm">
                          Next working day <sup>*</sup>{' '}
                          <img
                            src="/images/icons/checkout_delivery_icon.svg"
                            alt="Express Delivery"
                            style={{
                              height: '16px',
                              marginLeft: '10px',
                              float: 'right',
                              marginTop: '5px'
                            }}
                          />
                        </span>
                      </span>
                      <span className="text-end float-end pt-5">
                        {freeExpressDelivery ? 'FREE' : '£10.00'}
                      </span>
                    </Button>
                  </div>
                </div>
              </>
            )}
          </>
        )
      }

      function renderDiscount() {
        return (
          state.showDiscountBox
            ? <>
              <div className="brad-10 p-20 bg-light b-1 mb-30 clearfix">
                <p className="text-500">
                  Discount Code
                </p>
                <span className="text-400 mb-5 text-sm block col-12">
                  Have a discount code? Simply enter it below.
                </span>
                <InputGroup className="d-block">
                  <FloatingLabel
                    label="Discount code"
                    className="mb-3 text-lg d-flex mt-20 w-100"
                  >
                    <Form.Control
                      value={state.discountCode}
                      onChange={e => { setState({ ...state, discountCode: e.target.value, discountError: false }) }}
                      type="tel"
                      placeholder='Your number'
                      size="sm"
                      isInvalid={state.discountError === true} />

                    <Button
                      variant="cta"
                      className='btn d-inline ml-neg-5 btn-sm'
                      onClick={() => { handleApplyDiscount() }}
                    >
                      <span className="icon icon-checkmark4 text-light" />
                    </Button>
                  </FloatingLabel>
                </InputGroup>

                {state.discountError === true &&
                  <p className="text-sm text-400 text-danger">
                    Please enter a valid discount code.
                  </p>}

                <p className="text-sm underline cursor-pointer mb-0 mt-20 ml-2 float-start" onClick={() => { setState({ ...state, showDiscountBox: false }) }}>Cancel</p>
                {(basket.discountApplied !== undefined && basket.discountApplied > 0) &&
                  <p className="text-sm underline cursor-pointer mb-0 mt-20 ml-2 float-end text-danger" onClick={() => { handleRemoveDiscount() }}>Remove</p>
                }
              </div>
            </>
            : (basket?.discountApplied ?? 0 > 0)
              ? <>
                <div className="row">
                  <div className="col-8">
                    <p className="text-success text-500 mb-0">
                      <span className="icon icon-checkmark4 mr-5" /> 10% off first month applied.
                    </p>
                  </div>
                  <div className="col-4 text-end">
                    <p className="underline cursor-pointer text-sm text-primary mb-0" onClick={() => { setState({ ...state, showDiscountBox: true }) }}>
                      Edit
                    </p>
                  </div>
                  <div className="col-12">
                    <hr className="mt-20 mb-20 bt-1-dark" />
                  </div>
                </div>
              </>
              : <>
                <p className="underline cursor-pointer text-sm text-primary" onClick={() => { setState({ ...state, showDiscountBox: true }) }}>
                  I have a discount code
                </p>
                <hr className="mt-10 mb-20 bt-1-dark" />
              </>
        )
      }

      function renderPricingTotals() {
        return (
          <div className="col-12 col-md-6 checkout-box mt-xs-30">
            {!isMobile && (amountExcVAT > 0 && basket.isOutbound && basket?.setupFee === undefined && basket.package?.packageId !== WhatsAppBusinessPackageId && !pack?.name?.includes('084') && pack?.name) &&
              renderPricingToggle()}

            <div className="row mb-10">
              <div className="col-12">
                {renderDiscount()}
                {renderTotalToday()}
              </div>
              <div className="col-12">
                <p className="text-sm text-400 mb-0">
                  {(props.isLoggedIn && !props.isNewCustomer && props.billingPeriod === billingPeriod.MONTH) &&
                    'Pro-rata payment until your next billing day.'}

                  {basket?.setupFee !== undefined && `* Includes £${basket?.setupFee} one-off fee. `}
                  {(show0800Offer) && `* Your first month is just £${pack.rental}. Then, £${pack.fullPrice} per month.`}

                </p>
                <hr className="mt-20 mb-20 bt-1-dark" />
              </div>
            </div>

            {(!props.isLoggedIn || (props.isLoggedIn && props.isNewCustomer)) &&
              <div className="row mb-20">
                <div className="col-2 col-xs-1">
                  <span
                    className="styled-checkbox checkbox-light float-start"
                  >
                    <Form.Check
                      inline
                      checked={state.optedOut}
                      onChange={(e) => {
                        setState({
                          ...state,
                          optedOut: e.target.checked
                        })
                      }
                      }
                    />
                  </span>
                </div>
                <div className="col-10 col-xs-11 pl-0">
                  <p className="m-0" style={{ fontSize: '0.9em' }}>
                    <label className="small text-400 m-0">
                      <span className="text-500 mr-5">
                        How will we use your data?
                      </span>
                      Read our{' '}
                      <span
                        className="underline cursor-pointer"
                        onClick={() => {
                          setState({
                            ...state,
                            showPrivacy: true
                          })
                        }
                        }
                      >
                        privacy policy
                      </span>
                      , or opt-out of ongoing communications from us.
                    </label>
                  </p>
                </div>
              </div>
            }

            <div className="row mb-20">
              <div className="col-2 col-xs-1">
                <span className="styled-checkbox checkbox-light float-start">
                  <Form.Check inline
                    checked={props.topupCredit > 0}
                    onChange={e => { handleToggleTopup(e) }} />
                </span>
              </div>
              <div className="col-10 col-xs-11 pl-0">
                <p className="m-0" style={{ fontSize: '0.9em' }}>
                  <span className="small pl-0 text-400 m-0 inline-block">
                    <span className="text-500 mr-5">
                      Add Topup Credit?
                    </span>
                    Add a one-off topup now and we'll add an <span className="text-cta text-500">extra £5 for FREE!<sup>*</sup></span>

                    <span className="text-400 cta-hover text-secondary cursor-pointer text-sm ml-10 underline" onClick={() => { setState({ ...state, showTopupWarning: true }) }}>More info</span>
                  </span>
                </p>
              </div>
            </div>

            {props.isQuote &&
              <div className="row mb-20">
                <div className="col-1">
                  <span className="styled-checkbox checkbox-light float-start">
                    <Form.Check inline
                      checked={state.acceptedTerms}
                      onChange={e => { setState({ ...state, acceptedTerms: e.target.checked }) }} />
                  </span>
                </div>
                <div className="col-11 pl-0">
                  <p className="m-0" style={{ fontSize: '0.9em' }}>
                    <span className="small pl-0 text-400 m-0 inline-block">
                      By continuing with this service, you agree
                      to our <span className="text-400 cta-hover text-secondary cursor-pointer text-sm ml-5 underline"
                        onClick={e => {
                          setState({
                            ...state,
                            showTsAndCs: true
                          })
                        }}>terms &amp; conditions</span>

                    </span>
                  </p>
                </div>
              </div>
            }

            <div className="row mb-10">
              <div className="col-12 text-center pt-10">
                {basket.numbers.length === 0
                  ? (
                    <div className="text-center text-500 text-danger mb-20">
                      <Button
                        variant="link"
                        className="text-danger underline text-500"
                        onClick={() => {
                          setState({
                            ...state,
                            changingType: true,
                            showEditNumbers: true,
                            editNumber: false
                          })
                        }
                        }
                      >
                        Please add a number
                      </Button>{' '}
                      to your basket before you can checkout.
                    </div>
                  )
                  : (
                    (!props.isLoggedIn || (props.isLoggedIn && props.isNewCustomer)) &&
                    basket.isOutbound &&
                    _.isEmpty(pack) && (
                      <div className="text-center text-400 text-danger mb-20">
                        <Button
                          className="text-danger underline"
                          onClick={() => {
                            setState({
                              ...state,
                              showEditPlan: true
                            })
                          }
                          }
                        >
                          Please add a package
                        </Button>{' '}
                        to your basket before you can checkout.
                      </div>
                    )
                  )}
                {state.signupError && (
                  <p className="text-400 text-danger">
                    {state.signupMsg
                      ? state.signupMsg
                      : 'There was a problem creating your account. Please try again, or get in touch with us'}
                  </p>
                )}
                {state.checkingOut
                  ? (
                    <LoadingNotification
                      className="mt-30"
                      message={'Processing...'}
                      isLoading={true}
                    />
                  )
                  : (
                    <>
                      {props.isInternal
                        ? <>
                          <Button
                            variant="cta"
                            size="lg"
                            className="btn-block text-lg"
                            onClick={() => { handleSendQuote() }}
                            disabled={
                              ((!props.isLoggedIn || (props.isLoggedIn && props.isNewCustomer)) && _.isEmpty(pack)) ||
                              state.isProcessing ||
                              basket.numbers.length === 0 ||
                              state.showNumNotAvailable
                            }
                          >
                            Send quote
                          </Button>
                          <p className="mt-10">or</p>
                          <Button
                            variant="outline"
                            size="lg"
                            className="btn-block text-lg"
                            onClick={() => { handleCheckout(amountExcVAT, hardwarePrice, false) }}
                            disabled={
                              ((!props.isLoggedIn || (props.isLoggedIn && props.isNewCustomer)) && _.isEmpty(pack)) ||
                              state.isProcessing ||
                              basket.numbers.length === 0 ||
                              state.showNumNotAvailable
                            }
                          >
                            Create customer account
                          </Button>
                        </>
                        : <Button
                          variant="cta"
                          size="lg"
                          className="btn-block text-lg"
                          onClick={() => { (props.isQuote === true && !state.acceptedTerms) ? setState({ ...state, showTermsWarning: true }) : handleCheckout(amountExcVAT, hardwarePrice, false, isMobile) }}
                          disabled={
                            ((!props.isLoggedIn || (props.isLoggedIn && props.isNewCustomer)) && _.isEmpty(pack)) ||
                            state.isProcessing ||
                            basket.numbers.length === 0 ||
                            state.showNumNotAvailable
                          }
                        >
                          {props.isLoggedIn ? 'Add to account' : 'Create my account'}
                        </Button>
                      }
                    </>
                  )}
              </div>


              {basket.numbers &&
                basket.numbers.some((s) => s.available === false) && (
                  <div className="col-12">
                    <Validator
                      validation={true}
                      customClass="form-group has-error text-center mb-0 mt-30"
                    >
                      <>
                        {basket.numbers && basket.numbers.length > 1
                          ? (
                            <>
                              One or more of your numbers are no longer available.{' '}
                              <span
                                className="text-400 cta-hover text-secondary cursor-pointer ml-10"
                                onClick={() => {
                                  setState({
                                    ...state,
                                    changingType: true,
                                    showEditNumbers: true
                                  })
                                }
                                }
                              >
                                Please choose another number
                              </span>
                            </>
                          )
                          : (
                            <>
                              Your number is no longer available.{' '}
                              <span
                                className="text-400 cta-hover text-secondary cursor-pointer ml-10"
                                onClick={() => {
                                  setState({
                                    ...state,
                                    changingType: true,
                                    showEditNumbers: true,
                                    editNumber: true
                                  })
                                }
                                }
                              >
                                Please choose another number
                              </span>
                              :<br />
                            </>
                          )}
                        {basket.numbers &&
                          basket.numbers.length > 1 &&
                          basket.numbers
                            .filter((num) => num.available === false)
                            .map((num, i) => {
                              <span>
                                {num.number}
                              </span>; /* eslint-disable-line */
                            })}
                      </>
                    </Validator>
                  </div>
                )}

              <div className="col-12 text-center">
                <img
                  src="images/credit-cards/stripe-badge-transparent.png"
                  className="center-block mt-20"
                  style={{ maxWidth: '100%' }}
                />
              </div>

              <div className="col-12 mt-30 text-center">
                {hardwarePrice.oneoff > 0 && (
                  <p className="text-xs">
                    <sup>*</sup> Orders complete before 12pm Mon-Thurs will be
                    available to next working day deliveries.
                  </p>
                )}

                {props.callRecording && (
                  <p className="text-xs">
                    Call Recording will be billed on a monthly basis and may be subject to additional storage fees.
                    See{' '}
                    <span
                      className="underline cursor-pointer"
                      onClick={() => {
                        setState({
                          ...state,
                          showTsAndCs: true
                        })
                      }
                      }
                    >
                      terms and conditions
                    </span>{' '}
                    for more info.
                  </p>
                )}

                {props.topupCredit > 0 &&
                  <p className="text-400 mt-20 text-xs">
                    <sup>*</sup> Free credit applied to top ups of &pound;20 or more.
                    {state.enableTopup &&
                      <>
                        <br /> You'll be charged for your &pound;{props.topupCredit} topup credit when you signup for your new plan.
                      </>
                    }
                  </p>
                }

                <p className="text-xs">All prices quoted are excluding VAT.</p>
              </div>
            </div>
          </div>
        )
      }

      function renderPlanOverview() {
        return (
          <>
            <div className="col-xs-12 col-md-6 checkout-box">
              <div className="bg-light brad-10 p-30 pl-xxs-15 pr-xxs-15 pt-xs-15 b-1">
                <div className="row">
                  <div className="col-12">
                    {/* <p className="text-500 mb-0 lead">Review Your Order</p>
                    <hr className="mt-10 mb-15 bt-1-dark m-0" /> */}

                      <Row className='mb-20'>
                        <Col xs={12}>
                          <h5 className='text-500 m-0'>Your Plan</h5>
                        </Col>
                      </Row>

                    {basket.isOutbound
                      ? (
                        <>
                          <div className="w-100 d-inline-block mb-5">
                            {pack && pack.name
                              ? renderPlanHeaderForOutbound()
                              : renderAddPackageClickableSpanForOutbound()}

                            <span className="float-end text-400">
                              {state.buyUmr
                                ? (
                                  `£${umrPayment.repeatPayment}`
                                )
                                : (
                                  <>
                                    {show0800Offer
                                      ? (
                                        <span>99p</span>
                                      )
                                      : (
                                        <span>
                                          &pound;
                                          {pack?.rental ? pack.rental.toFixed(2) : 0}
                                        </span>
                                      )}
                                  </>
                                )}
                            </span>
                          </div>

                          {basket && !_.isEmpty(pack) && pack?.users.qty > 1 && renderExtraUsers()}

                        </>
                      )
                      : (props.isLoggedIn && !props.isNewCustomer)
                        ? (
                          pack?.name
                            ? (
                              renderNumberWithMinutesPlan()
                            )
                            : (
                              renderNumberOnlyPlan()
                            )
                        )
                        : (
                          renderAddInboundPackage()
                        )}

                    {(!props.isLoggedIn || (props.isLoggedIn && state.hasCallRecordingAll !== true)) &&
                      <div className="w-100 d-inline-block">
                        <span className="text-400 d-inline-block mb-5">
                          Call Recording <em className="text-400 text-sm ml-5">
                            <span className="d-none d-sm-inline">1st month free.</span>
                            <span className="d-sm-none d-inline">1st month free.</span>
                          </em>
                          {props.callRecording
                            ? <span className="text-400 cta-hover text-secondary cursor-pointer text-sm ml-15 inline-block underline"
                              onClick={() => { setState({ ...state, showCallRecordWarning: true }) }}>Edit</span>
                            : <span className="text-400 cta-hover text-secondary cursor-pointer text-sm ml-15 inline-block underline"
                              onClick={() => { props.handleUpdateSignupCallRecording(true) }}>Add now</span>
                          }
                        </span>

                        <span className="float-end text-500 text-cta">
                          {props.callRecording
                            ? <span className="text-success text-sm ml-10 text-500">Added</span>
                            : <span className="text-danger text-sm ml-10 text-500">Not Added</span>
                          }
                        </span>
                      </div>
                    }

                    {(!props.isLoggedIn || props.isNewCustomer) &&
                      <div className="w-100 d-inline-block">
                        {renderFeaturesBlurb()}
                      </div>
                    }

                    <hr className="mt-30 mb-30 bt-1-dark m-0" />

                    <Row className='mb-20'>
                        <Col xs={6}>
                          <h5 className='text-500 m-0'>Your Number{basket.numbers && basket.numbers.length > 1 && 's'}</h5>
                        </Col>
                        <Col xs={6} className='text-end pt-5'>
                          {renderAddAnotherNumberSpan()}
                        </Col>
                      </Row>


                    {basket.numbers && basket.numbers.length > 0
                      ? basket.numbers[0].available === false
                        ? renderNumberNotAvailableOverlay()
                        : renderEditNumbers()
                      : renderAddNumber()}

                    {basket.numbers &&
                      basket.numbers.length > 1 &&
                      renderExtraPhoneNumberInBasket()}

                    <hr className="mt-30 mb-30 bt-1-dark m-0" />

                      <Row className='mb-30'>
                        <Col xs={6}>
                          <h5 className='text-500 m-0'>Your Phones</h5>
                        </Col>
                        <Col xs={6} className='text-end pt-5'>
                          <span className="cta-hover text-sm text-400 cursor-pointer underline text-secondary" onClick={() => { setState({ ...state, showEditDevices: true }) }}>
                            Add a phone
                          </span>
                        </Col>
                      </Row>


                    {renderHardwareInBasket()}

                    {/* {basket && pack && renderPhoneAppsStuff()} */}

                    {basket.hardware &&
                      basket.hardware.length > 0 &&
                      renderHardwareDeliveryOptions()}

                    <hr className="mt-30 mb-30 bt-1-dark m-0" />

                    <Row className='mb-0'>
                      <Col xs={12}>
                        <h5 className='text-500 m-0'>Your Apps</h5>
                      </Col>
                      {basket && pack && renderPhoneAppsStuff()}
                    </Row>

                  </div>
                </div>

              </div>

            </div>

            {renderPricingTotals()}
          </>
        )
      }

      return renderPlanOverview()
    }

    function renderNoBasketWarning() {
      return (
        <div className="col-sm-6 mx-auto">
          <div className="text-center p-40 bg-light brad-10 b-1-dark">
            <h4 className="text-500 pb-00 mt-0 text-xs-lg">
              Your basket is empty...
            </h4>
            <p className="mb-30">Please choose your package first.</p>
            <Link className="btn btn-cta btn-lg" to={signupPath}>
              Get Started
            </Link>
          </div>
        </div>
      )
    }

    function renderCheckoutHeader() {
      return (
        <>
          {basket
            ? (
              <div className="row">
                <div className="col-12 text-center fixed-container pb-50 pb-xxs-20">
                  <h3 className="text-500 mt-0">Checkout</h3>
                  <p>
                    You're almost there! Review &amp; complete your order below.
                  </p>
                </div>
              </div>
            )
            : (
              ''
            )}
        </>
      )
    }

    return (
      <div className="full-width">
        <div className="container">
          {renderCheckoutHeader()}

          <div className="row">
            <div className="col-12 fixed-container">
              <div className="row">
                {basket ? renderBasket() : renderNoBasketWarning()}
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  function renderHeaders() {
    return (
      <>
        {props.isLoggedIn && !props.isNewCustomer
          ? (
            <>
              <DisplayMainHeader />
            </>
          )
          : (
            <>
              <DisplaySimpleHeaderV2 />
            </>
          )}
      </>
    )
  }

  function renderProgressSteps() {
    return (
      <div className="full-width progress-container">
        <div className="row">
          <div className="col-md-12 text-center pl-0 pr-0">
            <ProgressSteps
              current={props.isLoggedIn ? 3 : 4}
              steps={props.isLoggedIn ? 3 : 4}
              loggedIn={props.isLoggedIn}
              changeStep={step => { props.handleBack(step) }}
            />
          </div>
        </div>
      </div>
    )
  }

  function getExtraUsersQuantity(): number {
    // A package always includes 1 user
    // So when displaying the number of 'extra' users we need to subtract 1 to get the correct number to display
    let displayUserQuantity = userQty - 1
    // if somehow the value is negative, set it to 0 instead
    if (displayUserQuantity < 0) {
      displayUserQuantity = 0
    }
    return displayUserQuantity
  }

  function renderModals() {
    return (
      <>
        {
          <>
            <Modal size="sm" show={state.showBillingPeriodOptionsModal}>
              <Modal.Body className="p-10">
                {renderPricingToggle()}

                <div className="bb-1 text-center pb-20 mb-10 w-80 mx-auto mt-10">
                  {renderTotalToday()}
                </div>

                <div className="p-30 pt-20 text-center">
                  <Button variant="cta" className="btn-block text-md btn-xl" onClick={() => {
                    setState({ ...state, showBillingPeriodOptionsModal: false })
                    handleCheckout(amountExcVAT, hardwarePrice, true)
                  }}>
                    Continue with {props.billingPeriod === billingPeriod.MONTH ? 'monthly' : props.billingPeriod === billingPeriod.BIANNUAL ? 'bi-annual' : 'annual'}
                  </Button>
                  <p className="text-sm mt-20">
                    <Button variant="link" className="text-darker" onClick={() => {
                      setState({ ...state, showBillingPeriodOptionsModal: false })
                    }}>
                      Cancel
                    </Button>
                  </p>
                </div>
              </Modal.Body>
            </Modal>


            <Modal size="sm" show={state.showTermsWarning}>
              <Modal.Header>
                <Modal.Title>Terms &amp; Conditions</Modal.Title>
              </Modal.Header>
              <Modal.Body className="p-0">
                <div className="direction-block dir-down bb-1 mb-20 p-40">
                  To continue creating your account, it's important that you read &amp; agree with our terms &amp; conditions.<br />
                  <span className="text-underline cursor-pointer" onClick={() => { setState({ ...state, showTsAndCs: true }) }}>Click here to view our latest terms &amp; conditions.</span>
                </div>

                <div className="p-30 pt-20 text-center">
                  <Button variant="cta" className="btn-block text-lg btn-xl" onClick={() => {
                    setState({ ...state, showTermsWarning: false })
                    handleCheckout(amountExcVAT, hardwarePrice, false)
                  }}>
                    Ok, Let's Continue
                  </Button>
                  <p className="text-sm mt-20">
                    <Link to="/">Cancel checkout</Link>
                  </p>
                </div>
              </Modal.Body>
            </Modal>

            <Modal size="sm" show={state.displayPaygUpgrade && basket.package?.packageId === PaygPlan} onHide={() => { setState({ ...state, displayPaygUpgrade: false }) }} backdrop="static">
              <Modal.Body className="text-center p-40">
                <div className="row text-center">
                  <p className="text-lg text-500">
                    Upgrade to Standard
                  </p>
                  <p>
                    <span className="text-500">Planning to make more than 1 call per day?</span><br />
                    Switch to our Standard plan for just &pound;5 more.
                  </p>
                  <ul className="unstyled cta text-400 ml-xs-0 ml-0 pl-0 mt-10 text-xs-xs mb-0">
                    <li className="mb-5"><i className="icon icon-checkmark4 text-cta mr-5"></i>On-hold music - <em className="text-sm">Including top charts, updated weekly</em></li>
                    <li className="mb-5"><i className="icon icon-checkmark4 text-cta mr-5"></i>Multiple departments - <em className="text-sm">Press 1 for support, 2 for sales</em></li>
                    <li className="mb-5"><i className="icon icon-checkmark4 text-cta mr-5"></i>Get your first month of <span className="text-500">call recording FREE</span></li>
                    <li className="mb-5"><i className="icon icon-checkmark4 text-cta mr-5"></i>Includes 400 UK outbound minutes </li>
                  </ul>

                  <Button variant="cta" className="mt-30" onClick={() => { handleContinueWithStandard(pack.users.qty) }}>Continue with Standard</Button>
                  <Button variant="link" size="sm" className="underline mt-10 " onClick={() => { handleCheckout(amountExcVAT, hardwarePrice, true) }}>
                    No thanks, I'll continue with Economy
                  </Button>
                </div>
              </Modal.Body>
            </Modal>

            <Modal show={(state.displayAnnualWarning === true)} onHide={() => { setState({ ...state, displayAnnualWarning: false }) }} className="w-900px" backdrop="static">
              <Modal.Body className="text-center p-40">
                <div className="row">
                  <div className="col-md-5">
                    <div className="bg-dull brad-10 p-20 d-none d-md-block" style={{ backgroundColor: '#d6e8ef' }}>
                      <img className="" src="/images/freebies-big.png" style={{ maxWidth: '100%' }} />
                    </div>
                  </div>
                  <div className="col-md-7 text-start pl-10">
                    <p className="text-lg text-500">
                      Get &pound;90 worth of accessories completely free.
                    </p>
                    <p>
                      Switch to annual billing today &amp; get a free VoIP handset and bluetooth earbuds for each user.
                    </p>
                    <ul className="unstyled cta text-400 ml-xs-0 ml-0 pl-0 mt-30 text-xs-xs">
                      <li className="mb-5"><i className="icon icon-checkmark4 text-cta mr-5"></i><em>Make calls presenting your {simplePhoneNumberFormat(basket.numbers[0].number)} number</em></li>
                      <li className="mb-5"><i className="icon icon-checkmark4 text-cta mr-5"></i><em>Create "Teams" so all phones ring at once</em></li>
                      <li className="mb-5"><i className="icon icon-checkmark4 text-cta mr-5"></i><em>Transfer calls between users </em></li>
                      <li className="mb-5"><i className="icon icon-checkmark4 text-cta mr-5"></i><em>Free Handset &amp; Bluetooth Earbuds - <em className="text-cta text-500">Worth &pound;90</em></em></li>
                    </ul>

                    <Button variant="cta" className="mt-30" onClick={() => { handleContinueWithAnnual() }}>Continue with annual payments</Button>
                    <Button variant="link" size="sm" className="underline mt-10 text-start" onClick={() => { handleCheckout(amountExcVAT, hardwarePrice, true) }}>
                      {props.billingPeriod === billingPeriod.MONTH
                        ? 'No thanks, I\'ll continue with monthly'
                        : 'No thanks, I\'ll continue with bi-annual'
                      }
                    </Button>
                  </div>
                </div>
              </Modal.Body>
            </Modal>

            <Modal show={state.displayBillingWarning === true} onHide={() => { setState({ ...state, displayBillingWarning: false }) }} size="sm">
              <Modal.Header closeButton>
                <Modal.Title>Sorry, we need your payment details</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <p>Before you can add more products to your account we need you to setup a billing agreement.</p>
                <p>Don't panic though, we've saved your selected package & number(s) so you can continue where you left off.</p>
                <p className="text-start mt-20 d-flex justify-content-center">
                  <Link to="/customer/profile/billing" className="btn btn-cta">
                    Add Billing Details
                  </Link>
                </p>
              </Modal.Body>
              <Modal.Footer>
                <p className="text-sm text-center">
                  Alternatively, you can get in touch with us on <br className="d-none d-md-block" />
                  <a href="tel:02031891213" className="text-cta underline">
                    0203 189 1213
                  </a>{' '}
                  or{' '}
                  <a className="text-cta underline" href="javascript:window.lhnJsSdk.openHOC('c');">
                    live chat
                  </a>
                </p>
              </Modal.Footer>
            </Modal>

            <Modal show={state.showCancelWarning} onHide={() => { setState({ ...state, showCancelWarning: false }) }} className="w-900px" backdrop="static">
              <Modal.Header className="bb-0">
                <Modal.Title className="text-500 h4 mt-10 mx-auto">Are you sure?</Modal.Title>
              </Modal.Header>
              <Modal.Body className="text-center">
                <p className="text-400 text-md">
                  You're about to leave your basket behind.
                </p>
                <p className="text-md">
                  You're just 1 step away from securing your new <span className="text-500">{simplePhoneNumberFormat(basket.numbers[0].number)}</span> <br />number with your <span className="text-500">{basket.package?.name} plan.</span><br />
                </p>
                <Button variant="cta" className="mt-30" onClick={() => { setState({ ...state, showCancelWarning: false }) }}>Continue checkout</Button>
                <Button variant="link" size="sm" className="underline mt-30" onClick={() => { handleCancelCheckout() }}>Cancel checkout</Button>
              </Modal.Body>
            </Modal>

            <Modal show={state.displayStripeError} onHide={() => { setState({ ...state, displayStripeError: false }) }} size="sm">
              <Modal.Header closeButton>
                <Modal.Title>Sorry, your card was declined</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <p>Unfortunately, your card provider has declined your payment. Please see below for the error message returned by your car issuer.</p>

                <p className="text-sm text-400 text-light bg-danger p-5 brad-5">{state.stripeErrorMessage}</p>

                <p>You can re-attempt this payment, or add alternative billing details to your account.</p>

                <p>
                  <Button variant="cta" className="mt-0"
                    onClick={() => { handleShowPaymentOptionsModal('', (state.clientId ?? 0) > 0 ? state.clientId : props.userDetails.clientId ?? 0) }}>
                    Add Alternative Billing Details
                  </Button>
                </p>
                <p className="text-sm cursor-pointer underline" onClick={() => { setState({ ...state, displayStripeError: false }) }}>
                  Or, try this card again.
                </p>
              </Modal.Body>
              <Modal.Footer>
                <p className="text-sm text-center">
                  Alternatively, you can get in touch with us on <br className="d-none d-md-block" />
                  <a href="tel:02031891213" className="text-cta underline">
                    0203 189 1213
                  </a>{' '}
                  or{' '}
                  <a className="text-cta underline" href="javascript:window.lhnJsSdk.openHOC('c');">
                    live chat
                  </a>
                </p>
              </Modal.Footer>
            </Modal>

            <Modal show={state.showQuoteSentModal} onHide={() => { setState({ ...state, showQuoteSentModal: false }) }} className="w-900px" backdrop="static">
              <Modal.Header className="bb-0">
                <Modal.Title className="text-500 h4 mt-10 mx-auto">{state.quoteError ? 'Oops!' : 'Good news!'}</Modal.Title>
              </Modal.Header>
              <Modal.Body className="text-center">
                <p className="text-400 text-md">
                  {state.quoteError ? 'There was a problem sending your quote. Please try again, or contact Tech!' : 'Your quote has been sent!'}
                </p>
                <Button variant="cta" className="mt-30" onClick={() => { setState({ ...state, showQuoteSentModal: false }) }}>Close</Button>
              </Modal.Body>
            </Modal>

            <ChoosePayment
              isRedirect={true}
              handleParentHideModal={() => { setState({ ...state, showCancelWarning: true }) }}
              showPaymentOptionsModelFromParent={state.showPaymentOptionsModal}
              total={Number(amountIncVAT)}
              basket={basket}
              returnRoute="signup/checkout/complete"
              purchaseData={state.key}
              email={
                props.isLoggedIn && !props.isNewCustomer
                  ? props.userDetails.email
                  : props.signupInfo.user.email
              }
              name={
                props.isLoggedIn && !props.isNewCustomer
                  ? `${props.userDetails.fName} ${props.userDetails.sName}`
                  : props.signupInfo.user.name
              }
              company={
                props.isLoggedIn && !props.isNewCustomer
                  ? ''
                  : props.signupInfo.user.company
              }
              phone={
                props.isLoggedIn && !props.isNewCustomer
                  ? ''
                  : props.signupInfo.user.phone
              }
              address={props.signupInfo.billing.address}
              mandate={
                !props.isLoggedIn ||
                (props.isLoggedIn && !state.hasAgreement)
              }
              clientId={(state.clientId ?? 0) > 0 ? state.clientId : props.userDetails.clientId}
              ipAddress={state.ipAddress}
              signupKey={props.signupKey}
              isNewCustomer={props.isNewCustomer}
            />

            <PurchasePhones
              handleUpdateHardware={(hardware) => { handleHandsetUpdated(hardware) }}
              currentBasket={basket}
              discount={deviceDiscountEligible}
              showEditDevices={state.showEditDevices}
              handleCloseDevicesModal={() => {
                setState({
                  ...state,
                  showEditDevices: false
                })
              }}
              purchaseUrl="/api/Purchase/PurchasePhones"
              isSignup={true} />

            <Modal
              className="extra-number full-screen"
              show={state.showEditNumbers}
              onHide={() => {
                setState({
                  ...state,
                  showEditNumbers: false,
                  editNumber: false
                })
              }
              }
            >
              <Modal.Body className="pl-sm-30 pr-sm-30 pl-xxs-10 pr-xxs-10 pb-0">
                <span
                  className="close-modal"
                  onClick={(e) => {
                    setState({
                      ...state,
                      showEditNumbers: false,
                      editNumber: false
                    })
                  }
                  }
                >
                  <span>&times;</span>
                </span>
                <div className="container">
                  <div className="text-center mb-50 mb-xxs-10">
                    <h3 className="text-500 mt-0">{state.editNumber ? "Edit numbers" : "Add additional numbers"}</h3>
                    <p>Select extra numbers to add to your account.</p>
                  </div>
                  {(state.changingType) &&
                    <>
                      <div className="row m-0">
                        <div className="col-md-12 bg-dull pt-30 pb-30 text-center number-types bt-1-dark bb-1-dark">
                          {pack?.numberType.startsWith('08')
                            ? <>
                              <NumberTypeChooser
                                isActive={props.selectedNumberType === '0800'}
                                numberTypeTitle="0800"
                                numberTypeDescription="Freephone numbers"
                                onClick={e => { props.handleChangeNumberType('0800') }}
                                disabled={basket && basket.numbers.some(num => (num.number.startsWith('01') || num.number.startsWith('02') || num.number.startsWith('03') || num.number.startsWith('084')))}
                              />
                              <NumberTypeChooser
                                isActive={props.selectedNumberType === '0808'}
                                numberTypeTitle="0808"
                                numberTypeDescription="Freephone numbers"
                                onClick={e => { props.handleChangeNumberType('0808') }}
                                disabled={basket && basket.numbers.some(num => (num.number.startsWith('01') || num.number.startsWith('02') || num.number.startsWith('03') || num.number.startsWith('084')))}
                              />
                            </>
                            : numberType === '084'
                              ? <NumberTypeChooser
                                isActive={props.selectedNumberType === '084'}
                                numberTypeTitle="084"
                                numberTypeDescription="National numbers"
                                onClick={e => { props.handleChangeNumberType('084') }}
                                disabled={basket && basket.numbers.some(num => (num.number.startsWith('01') || num.number.startsWith('02') || num.number.startsWith('03') || num.number.startsWith('080')))}
                                basicView={true}
                              />
                              : <>
                                <NumberTypeChooser
                                  isActive={props.selectedNumberType === '01'}
                                  numberTypeTitle="01/02"
                                  numberTypeDescription="Local"
                                  onClick={e => { props.handleChangeNumberType('01') }}
                                  disabled={basket && basket.numbers.some(num => (num.number.startsWith('084') || num.number.startsWith('080')))}
                                />
                                <NumberTypeChooser
                                  isActive={props.selectedNumberType === '0203'}
                                  numberTypeTitle="0203"
                                  numberTypeDescription="London"
                                  onClick={e => { props.handleChangeNumberType('0203') }}
                                  disabled={basket && basket.numbers.some(num => (num.number.startsWith('084') || num.number.startsWith('080')))}
                                />
                                <NumberTypeChooser
                                  isActive={props.selectedNumberType === '0207'}
                                  numberTypeTitle="0207"
                                  numberTypeDescription="London"
                                  onClick={e => { props.handleChangeNumberType('0207') }}
                                  disabled={basket && basket.numbers.some(num => (num.number.startsWith('084') || num.number.startsWith('080')))}
                                />
                                <NumberTypeChooser
                                  isActive={props.selectedNumberType === '0333'}
                                  numberTypeTitle="0333"
                                  numberTypeDescription="Mobile"
                                  onClick={e => { props.handleChangeNumberType('0333') }}
                                  disabled={basket && basket.numbers.some(num => (num.number.startsWith('084') || num.number.startsWith('080')))}
                                />
                              </>
                          }
                        </div>
                      </div>
                      <div className="direction-block "></div>
                    </>
                  }

                  <div className="row">
                    <div className="numbers-modal pad-top-30 mx-auto">
                      <DdiSelectorNew
                        isSignup={true}
                        numberType={selectedNumberType}
                        currentBasket={basket}
                        hideShowAll={true}
                        isModal={true}
                        isEditing={state.editNumber}
                        isSimple={true}
                        hideLoadMore={false}
                        hidestars={false}
                        isLoggedIn={false}
                        menuOpen={false}
                        handleUpdateBasket={function (basket: Basket): void {
                          props.handleUpdateBasket(basket)
                        }}
                        citysOnly={false}
                        handleChangeNumberType={function (
                          numberType: string
                        ): void {
                        }}
                        handleChangeGeoRegion={function (
                          geoRegion: ValueLabelSelectOption<string, string>
                        ): void {
                        }}
                        handleClearGeoRegion={function (): void {
                          props.handleClearGeoRegion()
                        }}
                      />
                    </div>
                  </div>
                  {basket.numbers.length === 0 && (
                    <div className="text-danger text-500 text-center">
                      Please add a number to your basket to continue.
                    </div>
                  )}
                </div>
              </Modal.Body>
              <Modal.Footer className="text-center mx-auto">
                <Button
                  variant="cta"
                  size="lg"
                  className="btn-xs-block mt-30"
                  onClick={(e) => {
                    setState({
                      ...state,
                      showEditNumbers: false,
                      editNumber: false
                    })
                  }
                  }
                  disabled={basket.numbers.length === 0}
                >
                  Continue Checkout
                </Button>
              </Modal.Footer>
            </Modal>

            <Modal
              className="users-modal"
              show={state.showEditUsers}
              onHide={() => { setState({ ...state, showEditUsers: false }) }}
            >
              <Modal.Body className="p-0">
                <div className="row">
                  <div className="col-xs-12">
                    <p className="text-secondary p-20 m-0 lead text-500 text-center">
                      Do you have a team? <br className="d-block d-xs-none" />
                      Need others to make &amp; receive calls?
                    </p>
                    <div className="bg-dull p-20 bt-1 clearfix text-xs-center">
                      <div className="col-md-12">
                        <div className="row">
                          <div className="col-xxs-12 col-xs-7 col-sm-8 col-md-8">
                            <h5 className="text-500">
                              Add Extra Users{' '}
                              <span className="text-cta">
                                (Just &pound;{props.currentBasket.package?.rental} Each)
                              </span>
                            </h5>
                            <p className="m-0 text-sm">
                              Add extra users to your team
                            </p>
                          </div>
                          <div className="col-xxs-12 col-xs-5 col-sm-4 col-md-4 text-500">
                            <form className="qty pull-xs-none mt-10">
                              <input
                                type="button"
                                className="btn btn-dull no-shadow"
                                onClick={() => { props.handleDecrementUsers() }}
                                value="-"
                                disabled={getExtraUsersQuantity() < 1}
                              />
                              <input
                                type="text"
                                id="number"
                                className="form-control"
                                value={getExtraUsersQuantity()}
                              />
                              <input
                                type="button"
                                className="btn btn-dull no-shadow"
                                onClick={() => { props.handleIncrementUsers() }}
                                value="+"
                              />
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer className="text-xs-center">
                <Button
                  variant="cta"
                  size="sm"
                  className="btn-xs-block"
                  onClick={(e) => { handleUpdateUsers(endpointPrice.repeatPayment) }
                  }
                >
                  Update your order
                </Button>
                <Button
                  variant="default"
                  size="sm"
                  className="float-none float-sm-start mt-xs-10"
                  onClick={(e) => { setState({ ...state, showEditUsers: false }) }}
                >
                  Close
                </Button>
              </Modal.Footer>
            </Modal>

            <Modal
              className="md edit-plan full-screen"
              show={state.showEditPlan}
              onHide={function () {
                handleChangePlanType()
                setState({ ...state, showEditPlan: false })
              }}
            >
              <Modal.Body className="pl-xs-0 pr-xs-0 bg-dull">
                <span
                  className="close-modal"
                  onClick={(e) => { setState({ ...state, showEditPlan: false }) }
                  }
                >
                  <span>&times;</span>
                </span>
                <div className="container">
                  <div className="text-center mb-50">
                    <h3 className="text-500 mt-0">Change your package</h3>
                    <p>Select your perfect package below.</p>
                  </div>

                  {(!props.isLoggedIn || (props.isLoggedIn && props.isNewCustomer)) && (
                    <div className="col-xs-12 text-center pb-30">
                      <p className="w-100 text-sm text-secondary text-500 mt-10">
                        I would like to:
                      </p>
                      <span
                        className={`mr-10 text-500 text-xs-xs ${basket.isOutbound ? 'text-secondary' : 'text-dullest'
                          }`}
                      >
                        Make &amp; Receive Calls
                      </span>
                      <Toggle
                        onClick={() => { handleChangePlanType() }}
                        on=""
                        off=""
                        offstyle="default"
                        onstyle="success"
                        width="null"
                        height="null"
                        size="sm"
                        className="toggle-secondary rounded h-35px w-60px"
                        active={!basket.isOutbound}
                      />
                      <span
                        className={`ml-10 text-500 mr-25 mr-xs-0 text-xs-xs ${!basket.isOutbound ? 'text-secondary' : 'text-dullest'
                          }`}
                      >
                        Receive Calls Only
                      </span>
                    </div>
                  )}

                  <SelectPackage
                    prefix={pack?.numberType === undefined ? selectedNumberType : pack?.numberType}
                    isSignup={props.location.pathname.includes('get-started')}
                    history={props.history}
                    userQty={pack?.users.qty}
                    isModal={true}
                    onClickSmallPrint={() => { setState({ ...state, showVoipTerms: true }) }}
                    loggedIn={props.isLoggedIn && !props.isNewCustomer}
                    showInbound={!basket.isOutbound && !props.isLoggedIn}
                    showFeaturesModal={() => { setState({ ...state, showFeatures: true }) }}
                    is99pOffer={false}
                    isUpgradeModal={false}
                    preventDefaultSelection={false}
                    continueWithoutBoltOn={function (): void {
                      throw new Error('Function not implemented.')
                    }}
                    isBasic={false}
                    noParentRowClass={false}
                    currentBasket={basket}
                    location={props.location}
                    match={props.match}
                    handleSelectPackage={function (packData: SbfPackage): void {
                      props.handleSetPackage(packData)
                      handleChangePackage()
                    }}
                    handleRemoveBoltOn={function (): void {
                      props.handleRemoveBoltOn()
                    }} />
                </div>
              </Modal.Body>

              {(props.isLoggedIn && !props.isNewCustomer) && (
                <Modal.Footer className="text-center mx-auto">
                  <p
                    className="pt-20 text-dark underline cursor-pointer text-sm"
                    onClick={(e) => { handleChangeToNumberOnly() }}
                  >
                    Remove plan &amp; checkout with Number Only - Just &pound;
                    {extraNumberPrice} per number, per month.
                  </p>
                </Modal.Footer>
              )}
            </Modal>
          </>
        }
        {
          <>
            <Modal
              className="terms-modal"
              show={state.showVoipTerms}
              onHide={() => { setState({ ...state, showVoipTerms: false }) }}
            >
              <Modal.Header closeButton>
                <Modal.Title className="text-500">
                  Terms &amp; Conditions
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <VoiPTerms />
              </Modal.Body>
              <Modal.Footer>
                <Button
                  variant="primary"
                  size="sm"
                  onClick={(e) => { setState({ ...state, showVoipTerms: false }) }}
                >
                  Close
                </Button>
              </Modal.Footer>
            </Modal>

            <Modal
              className="terms-modal"
              show={state.showTsAndCs}
              onHide={() => { setState({ ...state, showTsAndCs: false }) }}
            >
              <Modal.Header closeButton>
                <Modal.Title className="text-500">
                  Terms &amp; Conditions
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <TermsAndConditions />
              </Modal.Body>
              <Modal.Footer>
                <Button
                  variant="primary"
                  size="sm"
                  onClick={(e) => { setState({ ...state, showTsAndCs: false }) }}
                >
                  Close
                </Button>
              </Modal.Footer>
            </Modal>

            <Modal
              className="terms-modal"
              show={state.showPrivacy}
              onHide={() => { setState({ ...state, showPrivacy: false }) }}
            >
              <Modal.Header closeButton>
                <Modal.Title className="text-500">Privacy Policy</Modal.Title>
              </Modal.Header>
              <Modal.Body className="text-uppercase text-xs">
                <PrivacyPolicy />
              </Modal.Body>
              <Modal.Footer>
                <Button
                  variant="primary"
                  size="sm"
                  onClick={(e) => { setState({ ...state, showPrivacy: false }) }}
                >
                  Close
                </Button>
              </Modal.Footer>
            </Modal>

            <Modal className="address-modal" show={state.showBillingInfo}>
              <Modal.Body className="p-0">
                <div className="direction-block dir-down bb-1 mb-20">
                  <AddressLookup
                    isLoggedIn={props.isLoggedIn && !props.isNewCustomer}
                    handleUpdateAddress={function (data: AddressDto, isBilling?: boolean): void {
                      props.handleUpdateAddress(data, isBilling ?? false)
                    }}
                    showDeliveryAddress={
                      basket &&
                      basket.hardware &&
                      basket.hardware.length > 0 && hardwarePrice.oneoff > 0
                    }
                    deliveryAddress={props.signupInfo.delivery}
                    billingAddress={props.signupInfo.billing.address}
                  />

                </div>

                <div className="p-30 text-center">
                  {(state.isCreating || state.purchaseInProgress) ?
                    <LoadingNotification
                      className="mt-30"
                      message={'Please wait...'}
                      isLoading={true}
                    />
                    :
                    <Button
                      variant="cta"
                      size="lg"
                      className="btn-block text-lg"
                      onClick={() => {
                        props.isLoggedIn
                          ? handlePurchase(amountExcVAT, hardwarePrice)
                          : handleCreateAccount(amountExcVAT, hardwarePrice)
                      }
                      }
                      disabled={state.isCreating || state.purchaseInProgress}
                    >
                      Finish &amp; Pay
                    </Button>
                    }

                  <Button
                    variant="link"
                    className="mt-30 underline text-dark text-xs"
                    onClick={(e) => { handleCancelSignup() }}
                  >
                    Cancel checkout
                  </Button>
                </div>
              </Modal.Body>
            </Modal>

            <Modal
              show={state.showDeliveryInfo}
              onHide={() => { handleCancelSignup() }}
            >
              <Modal.Header closeButton>
                <Modal.Title>Please enter your delivery details</Modal.Title>
              </Modal.Header>
              <Modal.Body className="p-0">
                <div className="direction-block dir-down bb-1 mb-20">
                  <AddressLookup
                    isLoggedIn={props.isLoggedIn && !props.isNewCustomer}
                    handleUpdateAddress={function (data: AddressDto, isBilling?: boolean): void {
                      props.handleUpdateAddress(data, isBilling ?? false)
                    }}
                    showDeliveryOnly={true}
                    showDeliveryAddress={
                      basket &&
                      basket.hardware &&
                      basket.hardware.length > 0 && hardwarePrice.oneoff > 0
                    }
                    deliveryAddress={props.signupInfo.delivery}
                    billingAddress={props.signupInfo.billing.address}
                  />
                </div>

                <div className="p-30 text-center">
                  <Button
                    variant="cta"
                    size="lg"
                    className="btn-block text-lg"
                    onClick={() => {
                      props.isLoggedIn
                        ? handlePurchase(amountExcVAT, hardwarePrice)
                        : handleCreateAccount(amountExcVAT, hardwarePrice)
                    }
                    }
                    disabled={state.isCreating}
                  >
                    Finish &amp; Pay
                  </Button>
                </div>
              </Modal.Body>
            </Modal>

            <Modal
              // className="call-recording"
              show={state.showCallRecordWarning}
              onHide={() => { setState({ ...state, showCallRecordWarning: false }) }
              }
            >
              <Modal.Header closeButton>
                <Modal.Title className="text-500 mx-auto">Call Recording</Modal.Title>
              </Modal.Header>
              <Modal.Body className="p-40">

                <p className="text-400">
                  Record all your calls (inbound &amp; outbound) across all your SwitchboardFREE numbers, with 1 simple, <em className="text-cta text-400">great value</em> subscription.<br /><br />
                  Call recording is highly recommended for:
                </p>
                <ul className="fancy cta pl-25 pt-20 pb-20 text-400">
                  <li>Capturing missing details</li>
                  <li>Resolving conflicts</li>
                  <li>Training new staff</li>
                  <li>Improving customers experience</li>
                </ul>
                <p className="text-400">
                  Add it to your account now &amp; get your first month <span className="text-500">completely free</span>
                </p>
                <p className="pt-10 text-400">Switch off at any time.</p>

                <div className="pb-30">
                  <div className="bg-cta-faded p-20 text-center b-1-dark brad-20">
                    <p className="text-500 text-md pb-10">
                      Use call recording?
                    </p>
                    <div
                      className={`mr-10 mt-5 d-inline-block text-500 ${props.callRecording ? 'text-dark' : 'text-dullest'
                        }`}
                    >
                      Yes
                    </div>
                    <Toggle
                      on=""
                      off=""
                      size="sm"
                      offstyle="default"
                      onstyle="success"
                      width="null"
                      height="null"
                      className="toggle-sm toggle-secondary"
                      active={!props.callRecording}
                      onClick={(e) => { props.handleUpdateSignupCallRecording(!e) }}
                    />
                    <div
                      className={`ml-10 mt-5 text-xs-left d-inline-block text-500 ${props.callRecording ? 'text-dullest' : 'text-dark'
                        }`}
                    >
                      No
                    </div>
                    <p className="text-xs mt-20">
                      <sup>*</sup>Prices start from &pound;
                      {callRecordPayment.repeatPayment.toFixed(2)} per month
                      thereafter.
                    </p>
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer className="text-center">
                <Button
                  variant="cta"
                  size="lg"
                  className="btn-block"
                  onClick={() => { setState({ ...state, showCallRecordWarning: false }) }
                  }
                >
                  Save &amp; Back to Checkout
                </Button>
                <p
                  className="text-xs text-dark underline mt-30 mx-auto pb-20 cursor-pointer"
                  onClick={() => { setState({ ...state, showCallRecordWarning: false }) }
                  }
                >
                  Close this window.
                </p>
              </Modal.Footer>
            </Modal>

            <Modal
              // className="call-recording"
              show={state.showCallBlockingWarning}
              onHide={() => { setState({ ...state, showCallBlockingWarning: false }) }
              }
            >
              <Modal.Header closeButton>
                <Modal.Title className="text-500 mx-auto">Spam Call Blocker</Modal.Title>
              </Modal.Header>
              <Modal.Body className="p-40">
                <p className="text-400 mb-10">
                  Tired of the relentless ring of pesky telemarketers or the never-ending stream of cold calls? Reclaim your time with SwitchboardFREE's Spam Call Blocker.
                </p>

                <p className="text-400 mb-10">
                  Because at SwitchboardFREE, we believe in not just talking, but talking smarter. Say goodbye to call clutter, and hello to a streamlined communication experience.
                </p>

                <ul className="fancy cta pl-25 pt-20 pb-20 text-400">
                  <li className="mb-10"><span className="text-500">Effortless Blocking:</span> With the power to block specific numbers, international calls, or those sneaky withheld numbers, you're in control of who reaches your line.</li>
                  <li className="mb-10"><span className="text-500">Increased Productivity:</span> By filtering out irrelevant calls, your team can focus on what truly counts – growing your business.</li>
                  <li className="mb-10"><span className="text-500">Time Saver:</span> No more wasted time on nuisance calls. Just productive conversations that drive your business forward.</li>
                </ul>

                <p className="text-400">
                  Add it to your account now <span className="text-500">for just &pound;{callBlockingPayment.repeatPayment}</span> per month
                </p>
                <p className="pt-10 text-400">Switch off at any time.</p>

                <div className="pb-30">
                  <div className="bg-cta-faded p-20 text-center b-1-dark brad-20">
                    <p className="text-500 text-md pb-10">
                      Enable Call Blocking?
                    </p>
                    <div
                      className={`mr-10 mt-5 d-inline-block text-500 ${props.callBlocking ? 'text-dark' : 'text-dullest'
                        }`}
                    >
                      Yes
                    </div>
                    <Toggle
                      on=""
                      off=""
                      size="sm"
                      offstyle="default"
                      onstyle="success"
                      width="null"
                      height="null"
                      className="toggle-sm toggle-secondary"
                      active={!props.callBlocking}
                      onClick={(e) => { props.handleUpdateSignupCallBlocking(!e) }}
                    />
                    <div
                      className={`ml-10 mt-5 text-xs-left d-inline-block text-500 ${props.callBlocking ? 'text-dullest' : 'text-dark'
                        }`}
                    >
                      No
                    </div>
                  </div>
                </div>

              </Modal.Body>
              <Modal.Footer className="text-center">
                <Button
                  variant="cta"
                  size="lg"
                  className="btn-block"
                  onClick={() => { setState({ ...state, showCallBlockingWarning: false }) }
                  }
                >
                  Save &amp; Back to Checkout
                </Button>
                <p
                  className="text-xs text-dark underline mt-30 mx-auto pb-20 cursor-pointer"
                  onClick={() => { setState({ ...state, showCallBlockingWarning: false }) }
                  }
                >
                  Close this window.
                </p>
              </Modal.Footer>
            </Modal>

            <Modal
              show={state.showTranscribeInfo}
              onHide={() => { setState({ ...state, showTranscribeInfo: false }) }
              }
            >
              <Modal.Header closeButton>
                <Modal.Title className="text-500 mx-auto">Call Recording Transcription</Modal.Title>
              </Modal.Header>
              <Modal.Body className="p-40">

                <p className="text-400">
                  Automatically transcribe your call recordings into a readable chat-style format. Not only that, but transcriptions are searchable and you can create email alerts for specific words or phrases, too!
                </p>
                <CallTranscriptionBenefitsList />
              </Modal.Body>
              <Modal.Footer className="text-center">
                {props.transcription
                  ? <Button
                    variant="cta"
                    size="lg"
                    className="btn-block"
                    onClick={() => { setState({ ...state, showTranscribeInfo: false }) }
                    }
                  >
                    Back to Checkout
                  </Button>
                  : <Button
                    variant="cta"
                    size="lg"
                    className="btn-block"
                    onClick={() => {
                      props.handleUpdateSignupTranscription(true)
                      setState({ ...state, showTranscribeInfo: false })
                    }}>
                    Enable &amp; Checkout
                  </Button>
                }

                <p
                  className="text-xs text-dark underline mt-30 mx-auto pb-20 cursor-pointer"
                  onClick={() => { setState({ ...state, showTranscribeInfo: false }) }
                  }
                >
                  Close this window.
                </p>
              </Modal.Footer>
            </Modal>

            <Modal className="text-center" show={state.signupInProgress}>
              <Modal.Body>
                <LoadingNotification
                  className="mt-30"
                  message={'Please wait...'}
                  isLoading={true}
                />
                <p className="lead text-400">
                  We're just setting up your account & your new number.<br />Please wait...
                </p>
              </Modal.Body>
            </Modal>
            <Modal
              show={state.showCancelModal}
              onHide={() => { setState({ ...state, showCancelModal: false }) }}
            >
              <Modal.Body className="text-center">
                <div className="icon-wrapper text-center text-danger text-xxl">
                  <span className="icon icon-warning"></span>
                </div>

                <h4 className="text-danger mt-0 mb-10 text-400">
                  Your account setup was unsuccessful{' '}
                </h4>
                <p>
                  Please revisit the secure WorldPay website to add your billing
                  details to get started with SwitchboardFREE.
                </p>
                <p className="text-center mt-20 mb-20">
                  <Button
                    variant="cta"
                    size="lg"
                    onClick={() => { handleCheckout(amountExcVAT, hardwarePrice, true) }}
                  >
                    Continue Secure Checkout
                  </Button>
                </p>
                <p>
                  <img src="/images/icons/trust-icons-small.png" className="img-responsive mt-20" style={{ maxWidth: '500px' }} />
                  <br />
                </p>
              </Modal.Body>
              <Modal.Footer className="text-center">
                <p>
                  Unless you have an existing WorldPay account please ignore the
                  username and password section on the WorldPay site.
                </p>
                <p>
                  <strong>Need help?</strong>
                  <br />
                  Call us on{' '}
                  <a href="tel:02031891213" className="text-cta underline">
                    0203 189 1213
                  </a>
                  ,{' '}
                  <a href="#" className="open-live-chat text-cta underline">
                    chat to us
                  </a>{' '}
                  or{' '}
                  <a
                    href="mailto:support@switchboardfree.co.uk?subject=Adding%20Payment%20Details"
                    className="text-cta underline"
                  >
                    send us an email
                  </a>
                  .
                </p>
              </Modal.Footer>
            </Modal>

            <EarbudsModal
              show={state.showEarbuds}
              hide={() => { setState({ ...state, showEarbuds: false }) }}
              checkout={true}
            />

            <Modal size="sm" show={state.showTopupWarning} onHide={() => { setState({ ...state, showTopupWarning: false }) }}>
              <Modal.Header closeButton>
                <Modal.Title>{state.enableTopup ? 'Auto Topup Credit' : 'Are you sure?'}</Modal.Title>
              </Modal.Header>
              <Modal.Body className="p-40">
                <p>
                  We <span className="text-400 text-cta">strongly recommend</span> adding call credit to ensure your account is always available.</p>
                <p>
                  Call credit is used to cover things like International calls, premium outbound calls to 084 or 0870 numbers and occasional calls made to destinations outside your bundle.
                </p>
                <p className="text-500">Add credit now &amp; prevent unnecessary interruptions to your service</p>

                {state.enableTopup
                  ? <>
                    <div className="p-30 text-center">
                      <Button variant="link" className="underline" onClick={() => { setState({ ...state, showTopupWarning: false }) }}>Thanks, continue checkout</Button>
                    </div>
                  </>
                  : <>
                    <div className="row plans text-left row-flex flex-2-col flex-sm flex-nopad plans-topup mt-50">
                      <div className={`plan cursor-pointer p-20 ${props.topupCredit === 5 ? 'plan-selected' : ''}`}
                        onClick={() => { props.handleUpdateSignupTopup(5) }}>
                        <div className="row">
                          <div className="col-xs-4 text-center">
                            <span className="styled-checkbox checkbox-lg checkbox-rounded checkbox-light pull-left" style={{ margin: '0 -14px 0px 0' }}>
                              <Form.Check inline
                                checked={props.topupCredit === 5}
                                onChange={() => { props.handleUpdateSignupTopup(5) }} />
                            </span>
                          </div>
                          <div className="col-xs-8">
                            <h2 className="text-500 mt-3 mb-3"><sup className="text-xs pull-left mt-40 mr-5">&pound;</sup>5</h2>
                          </div>
                        </div>
                      </div>
                      <div className={`plan cursor-pointer p-20 ${props.topupCredit === 10 ? 'plan-selected' : ''}`}
                        onClick={() => { props.handleUpdateSignupTopup(10) }}>
                        <div className="row">
                          <div className="col-xs-4 text-center">
                            <span className="styled-checkbox checkbox-lg checkbox-rounded checkbox-light pull-left" style={{ margin: '0 -14px 0px 0' }}>
                              <Form.Check inline
                                checked={props.topupCredit === 10}
                                onChange={() => { props.handleUpdateSignupTopup(10) }} />
                            </span>
                          </div>
                          <div className="col-xs-8">
                            <h2 className="text-500 mt-3 mb-3"><sup className="text-xs pull-left mt-40 mr-5">&pound;</sup>10</h2>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row plans text-left row-flex flex-2-col flex-sm flex-nopad plans-topup mt-40">
                      <div className={`plan cursor-pointer p-20 plan-highlight popular ${props.topupCredit === 20 ? 'plan-selected' : ''}`}
                        onClick={() => { props.handleUpdateSignupTopup(20) }}>
                        <div className="row">
                          <div className="col-xs-4 text-center">
                            <span className="styled-checkbox checkbox-lg checkbox-rounded checkbox-light pull-left" style={{ margin: '0 -14px 0px 0' }}>
                              <Form.Check inline
                                checked={props.topupCredit === 20}
                                onChange={() => { props.handleUpdateSignupTopup(20) }} />
                            </span>
                          </div>
                          <div className="col-xs-8">
                            <h2 className="text-500 mt-3 mb-3"><sup className="text-xs pull-left mt-40 mr-5">&pound;</sup>20</h2>
                          </div>
                        </div>
                      </div>
                      <div className={`plan cursor-pointer p-20 ${props.topupCredit === 30 ? 'plan-selected' : ''}`}
                        onClick={() => { props.handleUpdateSignupTopup(30) }}>
                        <div className="row">
                          <div className="col-xs-4 text-center">
                            <span className="styled-checkbox checkbox-lg checkbox-rounded checkbox-light pull-left" style={{ margin: '0 -14px 0px 0' }}>
                              <Form.Check inline
                                checked={props.topupCredit === 30}
                                onChange={() => { props.handleUpdateSignupTopup(30) }} />
                            </span>
                          </div>
                          <div className="col-xs-8">
                            <h2 className="text-500 mt-3 mb-3"><sup className="text-xs pull-left mt-40 mr-5">&pound;</sup>30</h2>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="p-30 pl-xs-0 pr-xs-0 pt-xs-10 text-center">
                      <Button variant="cta" className="btn-block btn-lg mb-20" onClick={() => { setState({ ...state, showTopupWarning: false }) }}>
                        Save &amp; Back to Checkout
                      </Button>

                      <Button variant="link" className="underline" onClick={() => {
                        setState({ ...state, showTopupWarning: false })
                        props.handleUpdateSignupTopup(0)
                      }}>No thanks, I don&apos;t want credit</Button>
                    </div>
                  </>
                }
              </Modal.Body>
            </Modal>

          </>
        }
      </>
    )
  }

  function handleToggleTopup(e) {
    setState({ ...state, showTopupWarning: e.target.checked === false })
    if (e.target.checked === true) { props.handleUpdateSignupTopup(20) } else { props.handleUpdateSignupTopup(0) }
  }

  function handleChangeToNumberOnly() {
    setState({ ...state, showEditPlan: false })
  }

  function handleChangePlanType() {
    const newBasket = {
      ...basket,
      isOutbound: !basket.isOutbound
    }

    props.handleUpdateBasket(newBasket)
  }

  function handleUpdateUsers(userPrice) {
    setState({ ...state, showEditUsers: false })
  }

  function handleRemoveAllFromBasket(param: {
    itemType: BasketItemType
    removeAll: boolean
  }) {
    console.log('handleRemoveAllFromBasket', param)
  }

  function handleChangePackage() {
    // Check if we need to ditch numbers
    let selectedPlan = pack
    if (
      selectedPlan &&
      selectedPlan.numberType !== numberType &&
      (!props.isLoggedIn || (props.isLoggedIn && props.isNewCustomer)) &&
      basket.isOutbound
    ) {
      handleRemoveAllFromBasket({
        itemType: BasketItemType.phoneNumber,
        removeAll: true
      })
    }

    if (!basket.isOutbound && pack?.rental === (is0800 ? AppOnlyPrice0800 : AppOnlyPrice)) {
      handleRemoveAllFromBasket({
        itemType: BasketItemType.package,
        removeAll: true
      })
    }

    if (
      selectedPlan === undefined ||
      (selectedPlan.name.toLowerCase().includes('unlimited') &&
        (selectedPlan.rental === undefined || selectedPlan.rental === 0))
    ) {
      selectedPlan = new SbfPackage(
        'Unlimited to App',
        0,
        show0800Offer ? AppOnlyPrice0800 : AppOnlyPrice,
        is0800 ? AppOnlyPrice0800 : AppOnlyPrice,
        0,
        0,
        state.prefix,
        {
          qty: 1,
          monthlyPrice: 0
        }
      )
    }

    setState({
      ...state,
      showEditPlan: false,
      displayPaygUpgrade: false
    })
  }

  function handleHandsetUpdated(hardware: Hardware[] | undefined) {
    if (hardware === undefined) {
      handleRemoveAllFromBasket({
        itemType: BasketItemType.hardware,
        removeAll: true
      })
    } else {
      const freebies = basket.hardware.filter(f => f.amount === 0)
      let newBasket = { ...basket, hardware }

      if (freebies.length > 0) {
        newBasket = { ...basket, hardware: [...freebies, ...hardware] }
      }

      props.handleUpdateBasket(newBasket)
    }

    setState({ ...state, handsetAdded: true, canCheckout: true })
  }

  function handleContinueWithStandard(users: number) {
    const url = '/api/Purchase/GetPackages'

    fetchHelper
      .getJson<PackageResultDto>(url)
      .then((res) => {
        const dto = res.data
        const packages = [...dto.packages]
        const standard = packages.find(f => f.packageId === BasicOutboundPlan)

        if (standard !== undefined) {
          const packData = new SbfPackage(
            standard.packageName,
            standard.inboundSeconds.landlineSeconds + standard.inboundSeconds.mobileSeconds + (standard.outboundSeconds.landlineSeconds + standard.outboundSeconds.mobileSeconds),
            standard.monthlyPrice,
            standard.monthlyPrice,
            standard.packageId,
            0,
            standard.numberType,
            {
              qty: users,
              monthlyPrice: standard.monthlyPrice
            }
          )

          props.handleSetPackage(packData)
          handleChangePackage()

          handleCheckout(amountExcVAT, hardwarePrice, true)
        }
      })
      .catch((err) => { console.error(err) })
  }

  function handleContinueWithAnnual() {
    setState({ ...state, displayAnnualWarning: false })
    props.handleChooseBillingPeriod(billingPeriod.YEAR, isFreebies)
    handleCheckout(amountExcVAT, hardwarePrice, true)
  }

  function handleCheckout(totalToday, hardwarePrice, skipAnnualCheck, isMobile: boolean = false) {


    let canContinue = true
    setState({ ...state, isProcessing: true, showTermsWarning: false, displayAnnualWarning: false, displayPaygUpgrade: false, showBillingPeriodOptionsModal: false })

    if (isMobile === true && !skipAnnualCheck) {
      canContinue = false
      setState({ ...state, showBillingPeriodOptionsModal: true })
    } else if (!props.isLoggedIn && !skipAnnualCheck && isFreebies && (props.billingPeriod === billingPeriod.MONTH || (!isUnlimited && props.billingPeriod === billingPeriod.BIANNUAL))) {
      canContinue = false
      setState({ ...state, displayAnnualWarning: true })
    } else if (!props.isLoggedIn && !skipAnnualCheck && props.billingPeriod === billingPeriod.MONTH && basket.package?.packageId === PaygPlan) {
      canContinue = false
      setState({ ...state, displayPaygUpgrade: true })
    }

    if (canContinue) {
      fetchHelper.getJson('api/purchase/CheckIfIpIsWhiteListed').then(res => {
        if (res.data && res.data === true) {
          // ip address is blocked, display message to user
          alert('sorry, we\'re unable to create you account right now. please try again later')
          return
        }

        setState({ ...state, showCancelModal: false, displayAnnualWarning: false, displayPaygUpgrade: false, showBillingPeriodOptionsModal: false })

        localStorage.setItem('signupValue', totalToday)
        let canProceed = true

        const checkThatAllDdisAreAvailablePromises: Array<Promise<SbfNumber>> =
          basket.numbers.map(
            async (num) =>
              await new Promise((resolve, reject) => {
                if (num.ddiid !== 0) {
                  const url = `api/Purchase/CheckDdiidAvailable/${num.ddiid}`
                  fetchHelper.getJson<boolean>(url).then((res) => {
                    const isAvailable = res.data
                    if (isAvailable) {
                      canProceed = true
                      num.available = true
                    } else {
                      // Not available, show error
                      canProceed = false
                      num.available = false
                    }
                    resolve(num)
                  })
                } else {
                  canProceed = true
                  num.available = true
                  resolve(num)
                }
              })
          )

        Promise.all(checkThatAllDdisAreAvailablePromises).then((res) => {
          // Update localstorage Basket
          localStorage.setItem('Basket', JSON.stringify(basket))

          if (!canProceed) {
            // A number in basket is no longer available
            setState({
              ...state,
              checkingOut: false,
              showGenericError: true,
              genericErrorMessage:
                'One or more numbers in your basket is no longer available. Please remove them and try again.'
            })
          } else {
            setState({ ...state, checkingOut: true, displayAnnualWarning: false, displayPaygUpgrade: false, showBillingPeriodOptionsModal: false })

            setTimeout(() => {
              if (state.enableTopup && !state.autoTopup) {
                localStorage.setItem('enableTopup', String(true))
              } else {
                localStorage.removeItem('enableTopup')
              }

              if (props.isLoggedIn && !props.isNewCustomer) {
                if (totalToday > 0 && !state.hasAgreement) {
                  setState({
                    ...state,
                    showBillingInfo: true,
                    displayAnnualWarning: false,
                    displayPaygUpgrade: false,
                    checkingOut: false,
                    showBillingPeriodOptionsModal: false
                  })
                } else if (totalToday > 0 && state.hasAgreement) {
                  if (hardwarePrice.oneoff > 0) {
                    const deliveryUrl = 'api/Users/GetClientDeliveryAddress'
                    fetchHelper
                      .getJson<AddressDto>(deliveryUrl)
                      .then((res) => {
                        if (res.data) {
                          const add = res.data
                          setState({ ...state, deliveryAdd: add })

                          // update delivery address
                          props.handleUpdateAddress(
                            {
                              name: add.name,
                              add1: add.add1,
                              add2: add.add2,
                              add3: add.add3,
                              add4: add.add4,
                              pcode: add.pcode,
                              notes: add.notes
                            },
                            false)

                          setTimeout(() => {
                            const dv = props.signupValidation.delivery // Delivery Validation

                            if (
                              dv.nameValidationState === validationStates.VALID &&
                              dv.add1ValidationState === validationStates.VALID &&
                              dv.townValidationState === validationStates.VALID &&
                              dv.pcodeValidationState === validationStates.VALID
                            ) {
                              // Good to go
                              handlePurchase(totalToday, hardwarePrice)
                            } else {
                              // Something not right
                              // Show delivery address
                              setState({
                                ...state,
                                showDeliveryInfo: true,
                                checkingOut: false
                              })
                            }
                          }, 1500)
                        } else {
                          // Show delivery address
                          setState({
                            ...state,
                            showDeliveryInfo: true,
                            checkingOut: false
                          })
                        }
                      })
                  } else {
                    handlePurchase(totalToday, hardwarePrice)
                  }
                } else {
                  handlePurchase(totalToday, hardwarePrice)
                }
              } else {
                setState({ ...state, totalToday })

                // Check if we need billing details
                if (totalToday > 0) {
                  setState({
                    ...state,
                    showBillingInfo: true,
                    displayAnnualWarning: false,
                    displayPaygUpgrade: false,
                    showBillingPeriodOptionsModal: false
                  })
                } else {
                  handleCreateAccount(totalToday, hardwarePrice)
                }
              }
            }, 2000)
          }
        })
      })
    }
  }

  function handleCancelCheckout() {
    setState({ ...state, showPaymentOptionsModal: false, showCancelWarning: false })

    // Let's let support know & add a note to the client
    fetchHelper.getJson('api/Purchase/ClientCancelledPurchase')
      .catch(err => { console.error(err) })

    window.location.href = '/customer/dashboard'
  }

  function handleShowPaymentOptionsModal(ipAddress: string | undefined, clientId: number | undefined) {
    if (ipAddress === undefined) { ipAddress = LocalStorageHelper.getItem<string>('ipAddress')?.value ?? '' }

    if (clientId === undefined) { clientId = LocalStorageHelper.getItem<number>('clientId')?.value ?? 0 }

    if (!state.showPaymentOptionsModal) {
      setState({
        ...state,
        showPaymentOptionsModal: true,
        showBillingInfo: false,
        showDeliveryInfo: false,
        ipAddress,
        signupInProgress: false,
        clientId
      })

      window.LOQ = window.LOQ || []
      window.LOQ.push(['ready', async LO => {
        await LO.$internal.ready('events')
        LO.events.track('Payment Modal Appeared')
      }])

      window.dataLayer.push({
        event: 'showPayment',
        transactionTotal: amountExcVAT
      })
    }
  }

  function handlePurchase(total, hardwarePrice) {
    // Check if new customer / has billing deets
    if (state.displayBillingWarning === true || props.isNewCustomer) {
      const clientId = LocalStorageHelper.getItem<number>('clientId').value
      const ipAddress = LocalStorageHelper.getItem<string>('ipAddress').value
      LocalStorageHelper.setItem<Basket>('Basket', props.currentBasket)

      handleShowPaymentOptionsModal(ipAddress ?? '', clientId ?? 0)
    } else {
      setState({ ...state, checkingOut: true, displayAnnualWarning: false, displayPaygUpgrade: false, purchaseInProgress: true })

      const history = props.history
      let mobile = ''
      let landline = ''
      if (checkNumberType(props.signupInfo.user.phone) === PhoneTypes.mobile) {
        mobile = props.signupInfo.user.phone
        localStorage.setItem('mobileNumber', props.signupInfo.user.phone)
      } else {
        landline = props.signupInfo.user.phone
        localStorage.setItem('landlineNumber', props.signupInfo.user.phone)
      }

      const essentials: EssentialInfo = {
        name: props.signupInfo.user.name,
        email: props.signupInfo.user.email,
        contacts: {
          landline,
          mobile
        },
        optedOut: state.optedOut
      }

      const purchase = Object.assign(
        {},
        state,
        {
          essentials
        },
        {
          buyCallRecording: props.callRecording
        },
        {
          isNewCustomer: props.isNewCustomer
        },
        {
          basket: {
            billingPeriod: props.billingPeriod,
            hardware: basket.hardware,
            package: pack,
            numbers: basket.numbers
          }
        },
        {
          deliveryDetails: {
            Name: state.deliveryAdd
              ? state.deliveryAdd.name
              : props.signupInfo.delivery.name
                ? props.signupInfo.delivery.name
                : '',
            Add1: state.deliveryAdd
              ? state.deliveryAdd.address1
              : props.signupInfo.delivery.add1
                ? props.signupInfo.delivery.add1
                : '',
            Add2: state.deliveryAdd
              ? state.deliveryAdd.address2
              : props.signupInfo.delivery.add2
                ? props.signupInfo.delivery.add2
                : '',
            Add3: state.deliveryAdd
              ? state.deliveryAdd.address3
              : props.signupInfo.delivery.add3
                ? props.signupInfo.delivery.add3
                : '', // Town
            Add4: state.deliveryAdd
              ? state.deliveryAdd.address4
              : props.signupInfo.delivery.add4
                ? props.signupInfo.delivery.add4
                : '', // County
            Pcode: state.deliveryAdd
              ? state.deliveryAdd.postcode
              : props.signupInfo.delivery.pcode
                ? props.signupInfo.delivery.pcode
                : ''
          }
        },
        {
          billingDetails: {
            HasAgreement: state.hasAgreement,
            BillingAddress: {
              Name: props.signupInfo.billing.address.name
                ? props.signupInfo.billing.address.name
                : props.signupInfo.user.name,
              Add1: props.signupInfo.billing.address.add1
                ? props.signupInfo.billing.address.add1
                : '',
              Add2: props.signupInfo.billing.address.add2
                ? props.signupInfo.billing.address.add2
                : '',
              Add3: props.signupInfo.billing.address.add3
                ? props.signupInfo.billing.address.add3
                : '', // Town
              Add4: props.signupInfo.billing.address.add4
                ? props.signupInfo.billing.address.add4
                : '', // County
              Pcode: props.signupInfo.billing.address.pcode
                ? props.signupInfo.billing.address.pcode
                : ''
            }
          }
        }
      )

      const url = 'api/Purchase/PurchaseNumber/'
      fetchHelper
        .postJson<PurchaseNumberDto, NewNumberPurchaseDto>(url, purchase)
        .then((result) => {
          setState({ ...state, key: result.data.key })
          window.dataLayer.push({
            event: 'newNumberComplete',
            transactionTotal: parseFloat(total)
          })

          LocalStorageHelper.setItem<boolean>('IsPorting', props.isPorting === true)

          if (result.data.success) {
            history.push('/customer/number-setup-wizard/')
          } else {
            // Failed - Let's find out why (If it was Stripe)
            if (result.data.providerId === 9) {
              fetchHelper.getJson<StripeDeclineReasonDto>(`api/Purchase/GetStripeDeclineReason/${result.data.paymentRef}`)
                .then(res => {
                  if (res.data.type === 'card_error') {
                    // Card error, we can display the error to them
                    setState({
                      ...state,
                      stripeErrorMessage: res.data.message,
                      displayStripeError: true
                    })
                  } else {
                    if (total > 0) {
                      if (result.data.success) {
                        history.push('/customer/number-setup-wizard/')
                      } else {
                        setState({
                          ...state,
                          key: result.data.key,
                          ipAddress: result.data.ipAddress,
                          showBillingInfo: false,
                          showDeliveryInfo: false,
                          signupInProgress: false
                        })
                        // , () => {
                        localStorage.setItem('ipAddress', result.data.ipAddress)
                        handleShowPaymentOptionsModal(result.data.ipAddress, props.userDetails.clientId)
                      }
                    } else {
                      history.push('/customer/number-setup-wizard/')
                    }
                  }
                }).catch(err => { console.error(err) })
            } else {
              if (total > 0) {
                if (result.data.success) {
                  history.push('/customer/number-setup-wizard/')
                } else {
                  setState({
                    ...state,
                    key: result.data.key,
                    ipAddress: result.data.ipAddress,
                    showBillingInfo: false,
                    showDeliveryInfo: false,
                    signupInProgress: false
                  })
                  // , () => {
                  localStorage.setItem('ipAddress', result.data.ipAddress)
                  handleShowPaymentOptionsModal(result.data.ipAddress, props.userDetails.clientId)
                }
              } else {
                history.push('/customer/number-setup-wizard/')
              }
            }
          }
        })
        .catch((error) => {
          setState({ ...state, purchaseInProgress: false })
          console.error(error)
        })
    }
  }

  function handleCreateAccount(total, hardwarePrice) {
    setState({ ...state, isCreating: true, checkingOut: false })

    let mobile = ''
    let landline = ''
    if (checkNumberType(props.signupInfo.user.phone) === PhoneTypes.mobile) {
      mobile = props.signupInfo.user.phone
      localStorage.setItem('mobileNumber', props.signupInfo.user.phone)
    } else {
      landline = props.signupInfo.user.phone
      localStorage.setItem('landlineNumber', props.signupInfo.user.phone)
    }

    const reseller = localStorage.getItem('reseller')

    const isDeliveryAddDifferent = !_.isEqual(
      props.signupInfo.delivery,
      props.signupInfo.billing.address
    )

    const data = Object.assign(
      {},
      state,
      {
        essentials: {
          name: props.signupInfo.user.name,
          email: props.signupInfo.user.email,
          contacts: {
            landline,
            mobile
          },
          optedOut: state.optedOut,
          signupKey: localStorage.getItem('signupKey')
        }
      },
      {
        optionalInfo: {
          company: props.signupInfo.user.company,
          marketingSource: props.marketingData?.source ?? '',
          referrer: props.marketingData?.referrer ?? '',
          utm_source: props.marketingData?.source ?? '',
          utm_campaign: props.marketingData?.campaign ?? '',
          utm_medium: props.marketingData?.medium ?? '',
          resellerId: reseller !== null ? JSON.parse(reseller).ResellerID : 1,
          WhatsAppPlan: !!localStorage.getItem('buyWhatsApp'),
          analyticsId: localStorage.getItem('GaId'),
          topupCredit: 0
        }
      },
      {
        basket: props.currentBasket
      },
      {
        deliveryDetails: {
          Name: isDeliveryAddDifferent
            ? props.signupInfo.delivery.name
              ? props.signupInfo.delivery.name
              : props.signupInfo.user.name
            : props.signupInfo.billing.address.name
              ? props.signupInfo.billing.address.name
              : props.signupInfo.user.name,
          Add1: isDeliveryAddDifferent
            ? props.signupInfo.delivery.add1
              ? props.signupInfo.delivery.add1
              : ''
            : props.signupInfo.billing.address.add1
              ? props.signupInfo.billing.address.add1
              : '',
          Add2: isDeliveryAddDifferent
            ? props.signupInfo.delivery.add2
              ? props.signupInfo.delivery.add2
              : ''
            : props.signupInfo.billing.address.add2
              ? props.signupInfo.billing.address.add2
              : '',
          Add3: isDeliveryAddDifferent
            ? props.signupInfo.delivery.add3
              ? props.signupInfo.delivery.add3
              : ''
            : props.signupInfo.billing.address.add3
              ? props.signupInfo.billing.address.add3
              : '', // Town
          Add4: isDeliveryAddDifferent
            ? props.signupInfo.delivery.add4
              ? props.signupInfo.delivery.add4
              : ''
            : props.signupInfo.billing.address.add4
              ? props.signupInfo.billing.address.add4
              : '', // County
          Pcode: isDeliveryAddDifferent
            ? props.signupInfo.delivery.pcode
              ? props.signupInfo.delivery.pcode
              : ''
            : props.signupInfo.billing.address.pcode
              ? props.signupInfo.billing.address.pcode
              : ''
        }
      },
      {
        billingDetails: {
          // CardNumber: props.signupInfo.billing.cardNumber
          //	? props.signupInfo.billing.cardNumber.value
          //	: "",
          // CardExpiry: props.signupInfo.billing.cardExpiry
          //	? props.signupInfo.billing.cardExpiry.value
          //	: "",
          // CardCode: props.signupInfo.billing.cardCode
          //	? props.signupInfo.billing.cardCode.value
          //	: "",
          BillingAddress: {
            Name: props.signupInfo.billing.address.name
              ? props.signupInfo.billing.address.name
              : props.signupInfo.user.name,
            Add1: props.signupInfo.billing.address.add1
              ? props.signupInfo.billing.address.add1
              : '',
            Add2: props.signupInfo.billing.address.add2
              ? props.signupInfo.billing.address.add2
              : '',
            Add3: props.signupInfo.billing.address.add3
              ? props.signupInfo.billing.address.add3
              : '', // Town
            Add4: props.signupInfo.billing.address.add4
              ? props.signupInfo.billing.address.add4
              : '', // County
            Pcode: props.signupInfo.billing.address.pcode
              ? props.signupInfo.billing.address.pcode
              : ''
          }
        }
      }
    )

    if (total > 0) {
      // Check billing validation
      const bv = props.signupValidation.billing // Billing Validation
      if (
        bv.nameValidationState === validationStates.VALID &&
        bv.add1ValidationState === validationStates.VALID &&
        bv.townValidationState === validationStates.VALID &&
        bv.pcodeValidationState === validationStates.VALID
      ) {
        // All good, check if we need delivery validation too
        const dv = props.signupValidation
        if (
          (hardwarePrice.oneoff > 0) &&
          !_.isEqual(
            props.signupInfo.delivery,
            props.signupInfo.billing.address
          ) &&
          dv.nameValidationState === validationStates.VALID &&
          dv.add1ValidationState === validationStates.VALID &&
          dv.townValidationState === validationStates.VALID &&
          dv.pcodeValidationState === validationStates.VALID
        ) {
          // All good, let's create account
          createAccount(data, total)
        } else {
          // No delivery details needs, let's create account
          createAccount(data, total)
        }
      } else {
        // Something went wrong
        createAccount(data, total)
      }
    } else {
      // No additional validtion checks needed, it's a free account. Let's create account
      createAccount(data, total)
    }
  }

  // function handleChooseBillingPeriod(period) {
  // 	let context = this;
  // 	setState({ ...state, billingPeriod: period });
  // }

  function createAccount(data: SignupData, total: number) {
    setState({
      ...state,
      signupInProgress: true,
      showBillingInfo: false,
      showDeliveryInfo: false,
      isCreating: false
    })
    setTimeout(() => {
      LocalStorageHelper.setItem<boolean>('isNewCustomer', true)

      const url = 'api/Users/ValidateAndCreateNewUser/'

      const signupData: NewSignupValidationDto = Object.assign({}, state, {
        essentials: data.essentials,
        optionalInfo: data.optionalInfo,
        billingDetails: data.billingDetails,
        isPorting: props.isPorting === true
      })

      fetchHelper
        .postJson<SignupResultDto, NewSignupValidationDto>(url, signupData)
        .then((result) => {
          if (result.data.valid) {
            // All good
            setState({ ...state, clientId: result.data.clientId })

            // Let's login
            props.handleLogin({
              isAuthenticated: true,
              sName: '',
              fName: getFirstNameFromName(data.essentials.name),
              clientId: result.data.clientId,
              email: data.essentials.email,
              persist: true,
              password: result.data.password
            })

            window.dataLayer.push({
              event: 'signUpComplete',
              transactionTotal: total
            })

            if (props.isQuote === true) {
              window.dataLayer.push({
                event: 'quoteAccepted',
                transactionTotal: total
              })
            }

            // Identify in LO
            window.LOQ = window.LOQ || []
            window.LOQ.push(['ready', async LO => {
              await LO.$internal.ready('visitor')
              LO.visitor.identify({
                id: result.data.clientId
              })
            }])

            LocalStorageHelper.setItem<number>('clientId', result.data.clientId)
            LocalStorageHelper.setItem<string>('ipAddress', result.data.ipAddress)
            LocalStorageHelper.setItem<Basket>('Basket', props.currentBasket)

            handleShowPaymentOptionsModal(result.data.ipAddress, result.data.clientId)
          } else {
            setState({
              ...state,
              signupError: true,
              signupInProgress: false,
              signupMsg: result.data.msg
            })
          }
        })
        .catch((error) => {
          setState({ ...state, signupError: true, signupInProgress: false })
          console.error(error)
        })
    }, 2000)
  }

  function handleSendQuote() {
    setState({ ...state, checkingOut: true })

    const data: NewSendQuoteDto = {
      Key: props.signupKey
    }

    fetchHelper.postJson<boolean, NewSendQuoteDto>('api/Purchase/SendQuote', data)
      .then(res => {
        setState({ ...state, showQuoteSentModal: true, quoteError: !res.data, checkingOut: false })
      }).catch(err => { console.error(err) })
  }

  return renderComponent()
}

export default SignupCheckout
