import React from 'react'
import { Button } from 'react-bootstrap'
import Toggle from 'react-bootstrap-toggle'
import { Helmet } from 'react-helmet'
import { connect } from 'react-redux'
import LoadingNotification from '../../components/LoadingNotification'
import SvgRender from '../../components/SvgRender'
import { PhoneTypes } from '../../constants/Constants'
import { fetchHelper } from '../../helpers/fetchHelper'
import { checkNumberType } from '../../helpers/utils'
import { handleNotificationChange } from '../../store/SBF'

class AddContact extends React.Component {
  state = {
		isSaving: false,
		savedWithGreeting: false
  }

  constructor (props) {
    super(props)
  }

  render () {
    let numberType = PhoneTypes.mobile
    if (this.state.contact !== undefined) { numberType = checkNumberType(this.state.contact.number) }

    return <>
			<Helmet>
				<body id="contactBook" className={'body-simple bg-dull'} />
				<title>{this.state.isLoading ? 'Please wait...' : 'Add a new contact'}</title>
			</Helmet>

			<div className="container p-xs-0">
				<div className="row">
					<div className="col-md-4 col-sm-6 mx-auto">
						{this.state.contactAdded === true &&
							<div id="contactSuccess" className="bg-light p-30 p-xs-20 box-shadow shadow-lighter brad-20 mb-50 mt-50 w-500px center-block text-center hidden">
								<h5 className="text-center text-500 text-success mb-20">
									{this.state.savedWithGreeting ? 
										'Your new contact has been added & your A.I caller greeting is active!'
										:
										'Your new contact has been added!'
									}
								</h5>
								{this.state.savedWithGreeting === true &&
									<p>
										Next time your contact calls you from their mobile number we'll greet them by name! Amazing!
									</p>
								}
								<p>
									Log in to you&apos;re account to see and <br />manage your Contact Book.
								</p>

								<p className="mt-30 mb-10">
									<a href="/login" className="btn btn-lg btn-cta btn-rounded">Log In</a>
								</p>
							</div>
						}

						{this.state.contactAdded !== true &&
							<div id="addContactBox" className="bg-light p-30 p-xs-20 box-shadow shadow-lighter brad-20 mb-50 mt-50 mt-xs-20">
								<div className="row">
									<div className="col-md-12 text-center pb-20">
										<h5 className="text-center text-500">Add a new Contact</h5>
									</div>
									<div className="col-md-12">
										<div className={'form-group mb-0 ' + (this.state.contact === undefined || this.state.contact.description === undefined || this.state.contact.description === '' ? 'has-error' : '')}>
											<label className="control-label text-500">Callers name / description {(this.state.contact === undefined || this.state.contact.description === undefined || this.state.contact.description === '') && <span className="text-400 ml-10">Required</span>}</label>
											<input className="form-control" type='text'
												placeholder={'Caller\'s Description / Name'}
												value={this.state.contact === undefined ? '' : this.state.contact.description}
												onChange={e => {
													let value = e.target.value

													if (value.length > 50) {
														value = value.substring(0, 50)
													}

													this.setState({
														contact: {
															...this.state.contact,
															description: value
														}
													})
												}} />
										</div>
										<label className="pt-20 text-500">Company</label>
										<input className="form-control" type='text' placeholder={'Optional'}
											value={this.state.contact === undefined ? '' : this.state.contact.company}
											onChange={e => {
												let value = e.target.value

												if (value.length > 50) {
													value = value.substring(0, 50)
												}

												if (!e) {
													this.setState({ contact: { ...this.state.contact, company: '' } })
												} else {
													this.setState({ contact: { ...this.state.contact, company: value } })
												}
											}}
										/>

										<div className={'form-group ' + (this.state.validContactEntered ? '' : 'has-error')}>
											<label className="control-label pt-20 text-500">Phone Number {!this.state.validContactEntered && <span className="text-400 ml-10">Required</span>}</label>
											<input className="form-control" type='tel' placeholder={'Phone number'}
												value={this.state.contact === undefined ? '' : this.state.contact.number}
												onChange={e => {
													let value = e.target.value.replaceAll(' ', '')

													if (value.length > 50) {
														value = value.substring(0, 50)
													}

													if (!e) {
														this.setState({ contact: { ...this.state.contact, number: '' } })
													} else {
														this.setState({
															contact: {
																...this.state.contact,
																number: value
															}
														})
													}
													const isValid = this.isEmailOrNumberValid(value)

													this.setState({ validContactEntered: isValid })
												}} />
										</div>
										{numberType === PhoneTypes.mobile &&
											<div className="bg-dark p-30 brad-20 text-center mt-30">
												<SvgRender icon="hello" svgWidth="50px" svgClassName="mb-10" />
												<h6 className="text-500 text-lightest">Turn on A.I. Caller Greeting?</h6>
												<p className="text-italic text-500 text-lightest text-sm">
													Recognise &amp; greet mobile callers by name.
												</p>
												<div className="block">
													<Toggle
														onClick={(e) => { this.setState({ contact: { ...this.state.contact, ignorePersonalizedIntro: !e } }) }}
														on={<span>ON</span>}
														off={<span>OFF</span>}
														offstyle="default"
														onstyle="success"
														width="null"
														height="null"
														size="sm"
														className="rounded mt-10"
														active={this.state.contact === undefined ? '' : !this.state.contact.ignorePersonalizedIntro}
													/>
												</div>
											</div>
										}

									</div>
									<div className="col-xs-12 mt-30">
										{this.state.isSaving
											? <LoadingNotification loadingText={'Please wait...'} isLoading={true} width={50} height={50} />
											: <Button size="lg" variant="cta" className="btn-block" disabled={!this.state.validContactEntered || !this.state.contact || !this.state.contact.number || !this.state.contact.description}
												onClick={e => this.handleSaveNewContact()}>Save Contact</Button>
										}
									</div>
								</div>
							</div>							
						}
					</div>
				</div>
			</div>
		</>
  }

  componentDidMount () {
    const context = this
    const query = new URLSearchParams(this.props.location.search)
    if (query) {
      // auth
      const token = query.get('t')
      context.setState({ token })
    }

    if (localStorage.getItem('newContact')) {
      const contact = JSON.parse(localStorage.getItem('newContact'))
      var isValid = this.isEmailOrNumberValid(contact.number)

      context.setState({ contact, validContactEntered: isValid })
    } else {
      const query = new URLSearchParams(this.props.location.search)
      if (query) {
        var isValid = this.isEmailOrNumberValid(query.get('tel'))
        context.setState({
          contact: {
            number: query.get('tel'),
            description: query.get('name'),
						company: query.get('company'),
						ignorePersonalizedIntro: false
          },
          validContactEntered: isValid
        })
      }
    }
  }

	handleSaveNewContact() {
		const contact = this.state.contact
    this.setState({ isSaving: true }, () => {
      const url = `/api/AccessToken/AddContactBookEntry?t=${this.state.token}`

      fetchHelper.postJson(url, this.state.contact)
        .then(res => {
          if (res.data) {
            // Good
            this.props.notify({ message: 'Your new contact has been added.' })

						localStorage.removeItem('newContact')
						console.log('test', contact);
						this.setState({ contact: undefined, validContactEntered: false, isSaving: false, contactAdded: true, savedWithGreeting: !contact.ignorePersonalizedIntro })
          } else {
            // Oops
            localStorage.setItem('newContact', JSON.stringify(this.state.contact))
            this.props.history.push('/login?authError=true&redirect=/access/add-contact')
          }
        }).catch(function (error) {
          console.error(error)
        })
    })
  }

  isEmailOrNumberValid (value) {
    const validEmail = new RegExp('^[A-Za-z0-9]+@+[A-Za-z0-9]+\.+[A-Za-z0-9\.]*').test(value)
    const validNumber = new RegExp('^[0-9]{10,18}$').test(value)

    if (validEmail === false && validNumber === false) {
      return false
    } else {
      return true
    }
  }
}

export default connect(
  state => {
    return state
  },
  {
    notify: handleNotificationChange
  }
)(AddContact)
