import moment from 'moment'
import React from 'react'
import ReactAudioPlayer from 'react-audio-player'
import { Button, Form, Modal } from 'react-bootstrap'
import Toggle from 'react-bootstrap-toggle'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Popover from 'react-bootstrap/Popover'
import Tooltip from 'react-bootstrap/Tooltip'
import Datetime from 'react-datetime';
import Helmet from 'react-helmet'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import Select from 'react-select'
import CallTranscriptionActivation from '../components/CallTranscriptionActivation'
import DisplayMainFooter from '../components/DisplayMainFooter'
import DisplayMainHeader from '../components/DisplayMainHeader'
import CallRecordingPackages from '../components/purchase/CallRecordingPackages'
import ScrollToTopOnMount from '../components/ScrollToTopOnMount'
import CallTranscriptionBenefitsList from '../components/Text/CallTranscriptionBenefitsList'
import { fetchHelper } from '../helpers/fetchHelper'
import { formatDate, formatSeconds, simpleDateTimeFormat, simplePhoneNumberFormat } from '../helpers/utils'
import { isLoggedIn } from '../selectors'

// disabled future dates in the datepicker
const today = moment()
const disableFutureDt = current => {
  return current.isBefore(today)
}

const dateOptions = [
  { value: '7', label: 'Most recent' },
  { value: '1', label: 'Today' },
  { value: '2', label: 'Yesterday' },
  { value: '3', label: 'This week' },
  { value: '4', label: 'This month' },
  { value: '5', label: 'This year' },
  { value: '6', label: 'Older than 1 year' },
  { value: '8', label: 'Custom date range' }
]

const CallStatusOptions = [
  { value: '1', label: 'Any status' },
  { value: '2', label: 'Answered calls only' },
  { value: '3', label: 'Missed calls only' },
  { value: '5', label: 'Not answered calls only' },
  { value: '4', label: 'Voicemails only' }
]

const CallTypeOptions = [
  { value: '1', label: 'Any direction' },
  { value: '2', label: 'Outbound calls only' },
  { value: '3', label: 'Inbound calls only' }
]

const callToOption = [
  {
    label: 'Default',
    options: [
      {
        label: 'Any of my numbers or users',
        value: 0
      }
    ]
  }
]

const callDurationOptions = [
  { value: '1', label: 'Any duration' },
  { value: '2', label: 'Less than 1 minute' },
  { value: '3', label: '1 to 5 minutes' },
  { value: '4', label: '5 to 15 minutes' },
  { value: '5', label: 'Over 15 minutes' }
]

const customFilter = (candidate, input) => {
  if (input === '') {
    return true
  }

  input = input.toLowerCase()

  if (candidate.label === undefined) {
    return false
  }

  const lowerCaseLabel = JSON.stringify(candidate.label).toLowerCase()

  const lowerCaseInput = input.toLowerCase()

  const topLevelCheck = lowerCaseLabel.includes(lowerCaseInput)

  if (topLevelCheck) {
    return true
  }

  const labelCode = JSON.stringify(lowerCaseLabel).trim()

  if (labelCode.includes('"children":')) {
    const data = labelCode.split('"children":')
    const number = data[1].split('","')[0].substring(2)
    const description = data[2].split('"},"')[0].substring(1)

    return number.includes(lowerCaseInput) || description.includes(lowerCaseInput)
  }

  return false
}

class AllCallsPage extends React.Component {
  constructor (props) {
    super(props)

    this.onToggle = this.onToggle.bind(this)
    this.state = { closeAndShowSidebar: false }
    this.state = { sortingOptions: false }
    this.state = { advancedSearchOptions: false }
  }


  state = {
    calls: [],
    callInformation: undefined,
    selectedListEntry: undefined,
    isLoadingCallRecording: false,
    isDownloading: false,
    addingNewContactbookEntry: false,
    editingContactbookEntry: false,
    advancedSearchCallDateOption: undefined,
    advancedSearchCallStatusOption: undefined,
    advancedSearchCallTypeOption: undefined,
    advancedSearchCallMadeToOption: undefined,
    advancedSearchCallMadeByOption: undefined,
    advancedSearchCallDurationOption: undefined,
    advancedSearchCallIncludeWordsOption: undefined,
    advancedSearchCallDoesNotIncludeWordsOption: undefined,
    advancedSearchCallNoteContainsOption: undefined,
    advancedSearchCallTransOption: undefined,
    advancedSearchCallNumberContainsOption: '',
    advancedSearchCallMadeToValues: [],
    advancedSearchCallMadeByValues: [],
    advancedSearchStartDate: undefined,
    advancedSearchEndDate: undefined,
    isLoading: true,
    newContactName: '',
    newContactCompany: '',
    newContactNumber: '',
    newContactAiGreeting: false,
    loadingNextHundredRecords: false,
    showAddNote: false,
    newNote: '',
    editNumbers: [],
    voicemailLoaded: false,
    showCallRecordingPackages: false,
    showEnableCallRecordingAndCallTranscription: false,
    showCallTranscriptionActivatedConfirmation: false,
    showCallRecordingActivatedConfirmation: true,
    hasCallRecording: false,
    hasCallTranscription: false,
    showRedirectAttemptsForCallId: 0
  }

  callTranscriptionTurnedOn () {
    const callInformation = this.state.callInformation

    callInformation.hasCallTranscription = true

    this.setState({
      callInformation,
      showCallTranscriptionActivatedConfirmation: true,
      hasCallTranscription: true
    })
  }

  callRecordingTurnedOn () {
    const callInformation = this.state.callInformation

    callInformation.hasCallRecording = true

    this.setState({
      callInformation,
      showCallRecordingActivatedConfirmation: true,
      hasCallRecording: true
    })
  }

  loadNoteModal (callInformation) {
    this.setState({
      showAddNote: true,
      newNote: (callInformation.note !== '' && callInformation.note !== undefined) ? callInformation.note : undefined
    })
  }

  addOrUpdateNotePressed () {
    let note = (this.state.newNote === undefined || this.state.newNote.trim() === '') ? '' : this.state.newNote.trim()

    if (note === '') {
      note = ' '
    }

    const url = `/api/CallStats/AddOrUpdateNoteForCall/${this.state.selectedListEntry.callId}/${this.state.callInformation.isInboundCall ? 1 : 0}/${note}/`

    fetchHelper.postJson(url, {}).then((response) => {
      if (response.data === true) {
        const callInformation = this.state.callInformation
        callInformation.note = note

        this.setState({
          showAddNote: false,
          callInformation
        })
      } else {

      }
    })
  }

  loadNextHundredRecordsIfImageInView () {
    if (this.state.isLoading === false) {
      const myElement = document.getElementById('loading100Icon')
      if (myElement !== null && myElement !== undefined) {
        const bounding = myElement.getBoundingClientRect()
        if (bounding.top >= 0 && bounding.left >= 0 && bounding.right <= window.innerWidth && bounding.bottom <= window.innerHeight && this.state.calls !== undefined) {
          if (this.state.loadingNextHundredRecords === false || this.state.loadingNextHundredRecords === undefined) {
            this.setState({ loadingNextHundredRecords: true })
            this.doSearch(true)
          }
        }
      }
    }
  }

  onToggle () {
    this.setState({ newContactAiGreeting: (this.state.newContactAiGreeting ?? false) === false })
  }

  async callEntryClicked (call) {
    if (call !== this.state.selectedListEntry) {
      this.setState({ selectedListEntry: call })

      const url = '/api/CallStats/GetCallInformation/' + call.callId + '/' + (call.isInbound === true ? 1 : 0) + '/'
      await fetchHelper.getJson(url).then((response) => {
        const callInfo = response.data

        this.setState({
          callInformation: response.data,
          newNote: (callInfo.note !== undefined && callInfo.note !== '') ? callInfo.note : '',
          addingNewContactbookEntry: response.data.phoneBookContactId === 0 ? (callInfo.isInboundCall ? callInfo.initiatorNumber !== 'Anonymous' : callInfo.numberCalled !== 'Anonymous') : false,
          editingContactbookEntry: false,
          newContactName: '',
          newContactCompany: '',
          newContactNumber: callInfo !== undefined ? (callInfo.isInboundCall ? callInfo.initiatorNumber : callInfo.numberCalled) : '',
          showCallTranscriptionActivatedConfirmation: false,
          showCallRecordingActivatedConfirmation: false
        })

        this.loadCallRecording(response.data)
      })
    }
  }

  doSearch (loadNextHundred = false, callId = undefined, isInbound = undefined, ddiId = undefined) {
    let loadFirstCall = true

    function myFunction (x) {
      if (x.matches) {
        loadFirstCall = false
      } else {
        loadFirstCall = true
      }
    }

    const x = window.matchMedia('(max-width: 767px)')
    myFunction(x) // Call listener function at run time
    x.addListener(myFunction)

    const context = this
    const callDate = this.state.advancedSearchCallDateOption === undefined ? '1' : this.state.advancedSearchCallDateOption.value
    const callType = this.state.advancedSearchCallTypeOption === undefined ? '1' : this.state.advancedSearchCallTypeOption.value
    let callMadeTo = this.state.advancedSearchCallMadeToOption === undefined ? '1' : this.state.advancedSearchCallMadeToOption.value
    const callMadeBy = this.state.advancedSearchCallMadeByOption === undefined ? '1' : this.state.advancedSearchCallMadeByOption.value
    const callStatus = this.state.advancedSearchCallStatusOption === undefined ? '1' : this.state.advancedSearchCallStatusOption.value
    const callDuration = this.state.advancedSearchCallDurationOption === undefined ? '1' : this.state.advancedSearchCallDurationOption.value
    let includesText = this.state.advancedSearchCallIncludeWordsOption === undefined ? '' : this.state.advancedSearchCallIncludeWordsOption
    let includesNoteText = this.state.advancedSearchCallNoteContainsOption === undefined ? '' : this.state.advancedSearchCallNoteContainsOption
    let numberContains = this.state.advancedSearchCallNumberContainsOption === undefined ? '' : this.state.advancedSearchCallNumberContainsOption
    let doesNotIncludeText = this.state.advancedSearchCallDoesNotIncludeWordsOption === undefined ? '' : this.state.advancedSearchCallDoesNotIncludeWordsOption

    if(ddiId != undefined){
      callMadeTo = ddiId
    }

    let lastInboundCallId = 0
    let lastOutboundCallId = 0

    if (loadNextHundred) {
      // get lowest call id for inbound and outbound
      for (let i = 0; i < this.state.calls.length; i++) {
        const call = this.state.calls[i]
        if (this.state.calls[i].isInbound === true && (this.state.calls[i].callId < lastInboundCallId || lastInboundCallId === 0)) {
          lastInboundCallId = this.state.calls[i].callId
        } else if ((this.state.calls[i].isInbound === false) && (this.state.calls[i].callId < lastOutboundCallId || lastOutboundCallId === 0)) {
          lastOutboundCallId = this.state.calls[i].callId
        }
      }
    }

    if (includesText === '') {
      includesText = ' '
    }

    if (includesNoteText === '') {
      includesNoteText = ' '
    }

    if (numberContains === '') {
      numberContains = ' '
    }

    if (doesNotIncludeText === '') {
      doesNotIncludeText = ' '
    }

    let url = `/api/CallStats/GetListOfCallsAndFirstCallDto/${lastInboundCallId}/${lastOutboundCallId}/${callDate}/${callStatus}/${callType}/${callMadeTo}/${callMadeBy}/${callDuration}/${includesText}/${includesNoteText}/${numberContains}/${doesNotIncludeText}/`

    // if a custom date range
    if (Number(callDate) === 8) {
      const advancedSearchStartDate = this.state.advancedSearchStartDate
      const advancedSearchEndDate = this.state.advancedSearchEndDate

      url = `/api/CallStats/GetListOfCallsAndFirstCallDtoForDateRange/${lastInboundCallId}/${lastOutboundCallId}/${callDate}/${callStatus}/${callType}/${callMadeTo}/${callMadeBy}/${callDuration}/${includesText}/${includesNoteText}/${numberContains}/${doesNotIncludeText}/${advancedSearchStartDate.format('DD')}/${advancedSearchStartDate.format('MM')}/${advancedSearchStartDate.format('YYYY')}/${advancedSearchStartDate.format('HH')}/${advancedSearchStartDate.format('mm')}/${advancedSearchEndDate.format('DD')}/${advancedSearchEndDate.format('MM')}/${advancedSearchEndDate.format('YYYY')}/${advancedSearchEndDate.format('HH')}/${advancedSearchEndDate.format('mm')}/`
    }

    if (callId !== undefined && callId !== null && isInbound !== undefined && isInbound !== null) {
      url = `/api/CallStats/GetSpecificCallAndFirstCallDto/${callId}/${isInbound}/`
    }

    if (loadNextHundred) {
      context.setState({
        isLoading: true
      })
    } else {
      context.setState({
        calls: [],
        selectedListEntry: undefined,
        callInformation: undefined,
        isLoading: true,
        addingNewContactbookEntry: false,
        editingContactbookEntry: false
      })
    }
    fetchHelper.getJson(url)
      .then(res => {
        const data = res.data

        const callInfo = data.callInformation === null ? undefined : data.callInformation

        if (loadNextHundred) {
          context.setState({
            calls: [...this.state.calls, ...data.listOfCalls],
            isLoading: false,
            loadingNextHundredRecords: false
          })
        } else {
          if (loadFirstCall && data.listOfCalls.length === 0) {
            loadFirstCall = false
          }

          if (loadFirstCall) {
            context.setState({
              calls: data.listOfCalls,
              callInformation: loadFirstCall ? callInfo : undefined,
              isLoading: false,
              loadingNextHundredRecords: false,
              addingNewContactbookEntry: loadFirstCall && callInfo !== undefined ? callInfo.phoneBookContactId === 0 : (callInfo.isInboundCall ? callInfo.initiatorNumber !== 'Anonymous' : callInfo.numberCalled !== 'Anonymous'),
              selectedListEntry: loadFirstCall && callInfo !== undefined > 0 ? data.listOfCalls[0] : undefined,
              newContactName: '',
              newContactCompany: '',
              newContactNumber: loadFirstCall && callInfo !== undefined ? (callInfo.isInboundCall ? callInfo.initiatorNumber : callInfo.numberCalled) : '',
              showCallTranscriptionActivatedConfirmation: false,
              showCallRecordingActivatedConfirmation: false,
              hasCallRecording: data.hasCallRecording,
              hasCallTranscription: data.hasCallTranscription
            })

            this.loadCallRecording(callInfo)
          } else {
            context.setState({
              calls: data.listOfCalls,
              selectedListEntry: undefined,
              callInformation: undefined,
              isLoading: false,
              loadingNextHundredRecords: false,
              addingNewContactbookEntry: false,
              newContactName: '',
              newContactCompany: '',
              newContactNumber: '',
              showCallTranscriptionActivatedConfirmation: false,
              showCallRecordingActivatedConfirmation: false,
              hasCallRecording: data.hasCallRecording,
              hasCallTranscription: data.hasCallTranscription
            })
          }
        }
      }).catch(err => console.error(err))
  }

  async loadCallRecording (callInformation) {
    const context = this
    const doc = document
    let audioPlayer = doc.getElementById('audioPlayer')

    if (audioPlayer !== null) {
      audioPlayer.src = ''
    }

    new Promise((resolve, reject) => {
      if (context.state.selectedListEntry.callStatus === 4) {
        const callId = this.state.selectedListEntry.callId

        context.setState({ isLoadingCallRecording: true, voicemailLoaded: false })
        fetchHelper.getBlob('api/reports/GetVoicemailAudio/' + callId).then(response => {
          const audioPlayer = document.getElementById('audioPlayer')
          audioPlayer.pause()
          audioPlayer.currentTime = 0
          audioPlayer.src = window.URL.createObjectURL(response.data, { type: 'audio/wav' })
          context.setState({ isLoadingCallRecording: false, voicemailLoaded: true })
        }).catch(error => console.error(error))
      } else if (callInformation.callRecordingId > 0) {
        const downloadCallRecordingId = callInformation.callRecordingId

        const callRecordingUrl = '/api/CallRecording/DownloadCallRecordingAudio/' + downloadCallRecordingId

        context.setState({ isLoadingCallRecording: true, voicemailLoaded: false })

        fetchHelper.getBlob(callRecordingUrl).then(response => {
          if (context.state.callInformation.callRecordingId === downloadCallRecordingId) {
            context.setState({ isLoadingCallRecording: false })

            if (audioPlayer === null) {
              audioPlayer = doc.getElementById('audioPlayer')
            }

            audioPlayer.pause()
            audioPlayer.currentTime = 0
            audioPlayer.src = window.URL.createObjectURL(response.data, { type: 'audio/mpeg' })
          }
        }).catch(error => console.error(error))
      }

      resolve('Promise resolved successfully')
    })

    // if is voicemail
  }

  handleDownloadPressed () {
    if (this.state.isDownloading === false || this.state.isDownloading === undefined) {
      this.setState({
        isDownloading: true
      })

      if (this.state.selectedListEntry.callStatus === 4) {
        const callId = this.state.selectedListEntry.callId

        fetchHelper.getBlob('api/reports/GetVoicemailAudio/' + callId).then(response => {
          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')
          link.href = url
          const date = new Date()
          const year = date.getFullYear()
          const month = ('00' + (date.getMonth() + 1)).slice(-2)
          const day = ('00' + date.getDate()).slice(-2)

          const hour = ('00' + date.getHours()).slice(-2)
          const minute = ('00' + date.getMinutes()).slice(-2)
          const second = ('00' + date.getSeconds()).slice(-2)
          link.setAttribute('download', 'SwitchboardFREE_' + year + month + day + '_' + hour + minute + second + '_' + this.state.callInformation.callRecordingId + '.mp3')
          document.body.appendChild(link)
          link.click()

          this.setState({
            isDownloading: false
          })
        }).catch(error => console.error(error))
      } else {
        const callRecordingUrl = '/api/CallRecording/DownloadCallRecordingAudio/' + this.state.callInformation.callRecordingId

        fetchHelper.getBlob(callRecordingUrl).then(response => {
          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')
          link.href = url
          const date = new Date()
          const year = date.getFullYear()
          const month = ('00' + (date.getMonth() + 1)).slice(-2)
          const day = ('00' + date.getDate()).slice(-2)

          const hour = ('00' + date.getHours()).slice(-2)
          const minute = ('00' + date.getMinutes()).slice(-2)
          const second = ('00' + date.getSeconds()).slice(-2)
          link.setAttribute('download', 'SwitchboardFREE_' + year + month + day + '_' + hour + minute + second + '_' + this.state.callInformation.callRecordingId + '.mp3')
          document.body.appendChild(link)
          link.click()

          this.setState({
            isDownloading: false
          })
        }).catch(error => console.error(error))
      }
    }
  }

  startAudioPlayerAtSpecificTime (startTimeInSeconds) {
    const audioPlayer = document.getElementById('audioPlayer')
    audioPlayer.pause()
    audioPlayer.currentTime = startTimeInSeconds
    audioPlayer.play()
  }

  generateCallInformationHeader (callInfo) {
    if (callInfo === null) {
      return ''
    }
    if (callInfo.initiatorName === null) {
      if (callInfo.isInboundCall === true) {
        return simplePhoneNumberFormat(callInfo.initiatorNumber)
      } else {
        return simplePhoneNumberFormat(callInfo.initiatorNumber) + ' (' + callInfo.user + ')'
      }
    } else {
      if (callInfo.isInboundCall === true) {
        return callInfo.initiatorName
      } else {
        return this.state.selectedListEntry.numberOrName + ' (' + simplePhoneNumberFormat(callInfo.initiatorNumber) + ')'
      }
    }
  }

  async closeAdvancedSearchAndSetToDefault () {
    const params = new URLSearchParams(window.location.search)
    const callId = params.get('callId')
    const isInbound = params.get('isInbound')
    const ddiId = params.get('ddiId')

    this.setState({
      advancedSearchCallDateOption: dateOptions[0],
      advancedSearchCallStatusOption: CallStatusOptions[0],
      advancedSearchCallTypeOption: CallTypeOptions[0],
      advancedSearchCallMadeToOption: callToOption[0].options[0],
      advancedSearchCallMadeByOption: callToOption[0].options[0],
      advancedSearchCallDurationOption: callDurationOptions[0],
      advancedSearchCallIncludeWordsOption: '',
      advancedSearchCallDoesNotIncludeWordsOption: '',
      advancedSearchCallNoteContainsOption: '',
      advancedSearchCallNumberContainsOption: '',
      advancedSearchStartDate: undefined,
      advancedSearchEndDate: undefined,
      advancedSearchOptions: false,
      showContactSidebar: false,
      addingNewContactbookEntry: false,
      editingContactbookEntry: false
    }, async () => { 
      if (ddiId !== undefined && ddiId !== null) {
        this.doSearch(false, undefined, undefined, ddiId)
      } 
      else if (callId === undefined || callId === null || isInbound === undefined || isInbound === null) {
        this.doSearch(false)
      } else {
        this.doSearch(false, callId, isInbound)
      }
    })
  }

  quickDateValueChanged (value) {
    const id = Number(value.value)

    switch (id) {
      case 1: // today
        this.setState({
          advancedSearchCallDateOption: value,
          advancedSearchStartDate: moment().startOf('day').startOf('hour').startOf('minute').startOf('day'),
          advancedSearchEndDate: moment().endOf('day').endOf('hour').endOf('minute').endOf('day')
        })
        break
      case 2: // yesterday
        this.setState({
          advancedSearchCallDateOption: value,
          advancedSearchStartDate: moment().subtract(1, 'days').startOf('day').startOf('hour').startOf('minute').startOf('day'),
          advancedSearchEndDate: moment().subtract(1, 'days').endOf('day').endOf('hour').endOf('minute').endOf('day')
        })
        break
      case 3: // this week
        this.setState({
          advancedSearchCallDateOption: value,
          advancedSearchStartDate: moment().startOf('week').startOf('hour').startOf('minute').startOf('day'),
          advancedSearchEndDate: moment().endOf('week').endOf('hour').endOf('minute').endOf('day')
        })
        break
      case 4: // this month
        this.setState({
          advancedSearchCallDateOption: value,
          advancedSearchStartDate: moment().startOf('month').startOf('hour').startOf('minute').startOf('day'),
          advancedSearchEndDate: moment().endOf('month').endOf('hour').endOf('minute').endOf('day')
        })
        break
      case 5: // this year
        this.setState({
          advancedSearchCallDateOption: value,
          advancedSearchStartDate: moment().startOf('year').startOf('hour').startOf('minute').startOf('day'),
          advancedSearchEndDate: moment().endOf('year').endOf('hour').endOf('minute').endOf('day')
        })
        break
      case 6: // older than 1 year
        this.setState({
          advancedSearchCallDateOption: value,
          advancedSearchStartDate: null,
          advancedSearchEndDate: moment().startOf('year').subtract(1, 'days').endOf('hour').endOf('minute').endOf('day')
        })
        break
      case 7: // most recent
        this.setState({
          advancedSearchCallDateOption: value,
          advancedSearchStartDate: null,
          advancedSearchEndDate: null
        })
        break
      default: // custom
        this.setState({
          advancedSearchCallDateOption: value
        })
        break
    }
  }

  editingContactbookEntryPressed () {
    this.setState({
      editingContactbookEntry: !this.state.editingContactbookEntry,
      editNumbers: this.state.callInformation.phoneBookNumbers,
      newContactName: this.state.callInformation.phoneBookContactName,
      newContactCompany: this.state.callInformation.phoneBookCompany,
      newContactAiGreeting: this.state.callInformation.phoneBookAiGreeting
    })
  }

  changeEditNumber (value, index) {
    const numbers = [...this.state.editNumbers]

    numbers[index] = value

    this.setState({ editNumbers: numbers })
  }

  removeNumberFromEditNumbers (index) {
    const array = [...this.state.editNumbers]

    array.splice(index, 1)

    return array
  }

  handleShowRedirectAttemptsPressed(callId){

    const context = this;

    fetchHelper.getJson('/api/CallStats/GetRedirectAttemptsForCallId/' + callId).then((response) => {



      context.setState({showRedirectAttemptsForCallId: callId})
    })

  }

  render () {

    const startOfToday = moment().startOf('day');
    const endOfToday = moment().endOf('day');

    return <><ScrollToTopOnMount /><DisplayMainFooter /> <DisplayMainHeader />
            <Helmet>
                <body className={`calls-page logged-in ${this.state.callInformation === undefined ? ' ' : 'open ' + (this.state.callInformation.numberCalled === 'Anonymous' || this.state.callInformation.initiatorNumber === 'Anonymous' ? 'is-anon' : ' ')} ${this.state.advancedSearchOptions === true ? ' search-is-open ' : ''}`} />
                <title>Calls</title>
                <meta name="description" content="All your SwitchboardFREE calls in one place." />
            </Helmet>

            <Modal className="w-700px" show={this.state.showRedirectAttemptsForCallId} onHide={e => this.setState({ showRedirectAttemptsForCallId: 0 })}>
                <Modal.Header closeButton className="bb-0 p-0">
                </Modal.Header>
                <Modal.Body className="text-center row">
                    <div className="col-md-12">
                      <table className='table table-bordered table-striped'>
                          <thead>
                            <tr>
                              <td>
                                Date
                              </td>
                              <td>
                                Time
                              </td>
                              <td>
                                Forwarded To
                              </td>
                              <td>
                                Status
                              </td>
                              <td>
                                Duration
                              </td>
                            </tr>
                          </thead>  
                          {this.state.callInformation !== undefined && <tbody>
                            {this.state.callInformation.callDeliveryAttempts.map((e) =>{
                              return <tr>
                                <td>
                                  {formatDate(e.whenAttempted, true, true, true, false, false, false)}
                                </td>
                                <td>
                                  {formatDate(e.whenAttempted, false, false, false, true, true, true)}
                                </td>
                                <td>
                                  {e.forwardedTo}
                                </td>
                                <td>
                                  {e.status === 'A' ? 'Answered but not connected' : e.status === 'B' ? 'Busy' : e.status === 'C' ? 'Connected' : e.status === 'R' ? 'Rang out' : 'Unknown'}
                                </td>
                                <td>
                                  {formatSeconds(e.durationInSeconds)}
                                </td>
                              </tr>
                            })}
                          </tbody>}
                      </table>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div className="col-md-12">
                        <Button variant="default" size="xs" onClick={e => this.setState({ showRedirectAttemptsForCallId: 0 })}>Close</Button>
                    </div>
                </Modal.Footer>
            </Modal>

            {this.state.showAddNote && <Modal className="w-500px" show={this.state.showAddNote} onHide={e => this.setState({ showAddNote: false })}>
                <Modal.Header closeButton className="bb-0 p-0">
                </Modal.Header>
                <Modal.Body className="text-center row">
                    <div className="col-md-12">
                        <h4 className="text-500">Add call notes</h4>
                        <p>Describe this call. Once you add a note about the call, later you will be able to find it using the Search option.</p>
                    </div>

                    <div className="col-md-12 pb-20 pt-20">
                        <textarea rows="5" onChange={e => this.setState({ newNote: e.target.value })} value={this.state.newNote} className="form-control" placeholder="eg. Caller wanted to confirm the delivery date of his package." />
                        <Button variant="cta" size="lg" className="mt-30 btn-block" onClick={() => this.addOrUpdateNotePressed()}>{(this.state.callInformation.note !== '' && this.state.callInformation.note !== undefined) ? 'Update note' : 'Add note'}</Button>
                    </div>
                </Modal.Body>
                {/* <Modal.Footer>
                    <div className="col-md-12">
                        <Button variant="default" size="xs" onClick={e => this.setState({ showAddNote: false })}>Close</Button>
                    </div>
                </Modal.Footer> */}
            </Modal>}

            <Modal className="w-1100px" show={this.state.showCallRecordingPackages === true} onHide={() => this.setState({ showCallRecordingPackages: false })}>
                <Modal.Body className="text-center p-0">
                    <CallRecordingPackages
                        showTicks={true}
                        CallTranscriptionHeadingCustomClass="text-lightest"
                        ticksTextColour="text-lightest"
                        userServices={this.props.services}
                        className="p-20"
                        handleCallRecordingAllEnableChange={(active) => active ? this.callRecordingTurnedOn() : {}}
                        handleCallRecordingUnlimitedEnableChange={(active) => active ? this.callRecordingTurnedOn() : {}}

                    />
                </Modal.Body>
                <Modal.Footer>
                    <div className="col-md-12">
                        <Button variant="default" onClick={() => this.setState({ showCallRecordingPackages: false })}>
                            Close
                        </Button>
                    </div>
                </Modal.Footer>
            </Modal>
            <div className="container-fluid bg-dull pb-100 pb-xs-20">
                <div className="container pt-50 pt-md-30 pl-xs-0 pr-xs-0">
                    <div className="row">
                        <div className="col-12">
                            <div className="row">
                                {this.state.advancedSearchOptions &&
                                    <div className="col-12">
                                        <div className="search-options advanced-search b-1 pt-30 pb-30 pl-20 pr-20 brad-20 bg-light mb-40 box-shadow-v3">
                                            <OverlayTrigger placement="left" overlay={<Popover className='popover-small dark'>Close </Popover>}>
                                                <div className="close" onClick={() => this.setState({ advancedSearchOptions: false })}>&times;</div>
                                            </OverlayTrigger>

                                            <h6 className="mb-30 mt-0 pt-0 text-500">Search</h6>

                                            <div className="w-12 pr-10 float-start">
                                                 <OverlayTrigger placement="top" overlay={
                                                    <Popover>
                                                        <p className="mb-0">
                                                            Select the time period you want to display calls from.
                                                        </p>
                                                    </Popover>
                                                }>
                                                  <p className="mb-5 text-xs text-500">Quick date selection <span className="icon icon-info2 text-dark-lighter"></span></p>
                                                </OverlayTrigger>
                                                <Select
                                                    className="form-control select-menu"
                                                    isClearable={false}
                                                    isSearchable={false}
                                                    value={this.state.advancedSearchCallDateOption}
                                                    onChange={(value) => this.quickDateValueChanged(value)}
                                                    options={dateOptions}
                                                />
                                            </div>

                                            <div className="custom-date-ranges">
                                                <div className="w-12 pr-10 float-start custom-dates">
                                                    <p className="mb-5 text-xs text-500">Start date</p>
                                                    <Form.Group>
                                                      <Datetime
                                                          isValidDate={disableFutureDt}
                                                          closeOnSelect={true}
                                                          value={this.state.advancedSearchStartDate}
                                                          initialViewDate={startOfToday}
                                                          timeFormat={true}
                                                          dateFormat='DD/MM/YYYY'
                                                          isSearchable={false}
                                                          onChange={(e) => this.setState({ advancedSearchStartDate: e, advancedSearchCallDateOption: { value: '8', label: 'Custom' } })}
                                                      />
                                                    </Form.Group>
                                                </div>
                                                <div className="w-12 pr-10 float-start custom-dates" >
                                                    <p className="mb-5 text-xs text-500">End date</p>
                                                    <Form.Group>
                                                      <Datetime
                                                          isValidDate={disableFutureDt}
                                                          closeOnSelect={true}
                                                          value={this.state.advancedSearchEndDate}
                                                          initialViewDate={endOfToday}
                                                          timeFormat={true}
                                                          dateFormat='DD/MM/YYYY'
                                                          isSearchable={false}
                                                          onChange={(e) => this.setState({ advancedSearchEndDate: e, advancedSearchCallDateOption: { value: '8', label: 'Custom' } })}
                                                      />
                                                    </Form.Group>
                                                </div>
                                            </div>

                                            <div className="w-12 pr-10 float-start">
                                                 <OverlayTrigger placement="top" overlay={
                                                    <Popover>
                                                        <p className="mb-0">
                                                            Type of call...<br />Was it a missed call, answered or maybe a voicemail?
                                                        </p>
                                                    </Popover>
                                                }>
                                                   <p className="mb-5 text-xs text-500">Call status <span className="icon icon-info2 text-dark-lighter"></span>  </p>
                                                </OverlayTrigger>

                                                <Select
                                                    className="form-control select-menu"
                                                    isClearable={false}
                                                    isSearchable={false}
                                                    value={this.state.advancedSearchCallStatusOption}
                                                    onChange={(value) => this.setState({ advancedSearchCallStatusOption: value })}
                                                    options={CallStatusOptions}
                                                />
                                            </div>

                                            <div className="w-12 pr-10 float-start">
                                                 <OverlayTrigger placement="top" overlay={
                                                    <Popover>
                                                        <p className="mb-0">
                                                            Who made the call...
                                                            <br /><br />
                                                            <span className="text-500">Any type</span> - show both, inbound and outbound calls.
                                                            <br/><br/>
                                                            <span className="text-500">Outbound</span> - show calls made by me.
                                                            <br /><br />
                                                            <span className="text-500">Inbound</span> - show calls made to me.
                                                        </p>
                                                    </Popover>
                                                }>
                                                   <p className="mb-5 text-xs text-500">Call Direction <span className="icon icon-info2 text-dark-lighter"></span></p>
                                                </OverlayTrigger>

                                                <Select
                                                    className="form-control select-menu"
                                                    isClearable={false}
                                                    isSearchable={false}
                                                    value={this.state.advancedSearchCallTypeOption}
                                                    onChange={(value) => this.setState({ advancedSearchCallTypeOption: value })}
                                                    options={CallTypeOptions}
                                                />
                                            </div>

                                            <div className="w-12 pr-10 float-start">
                                                 <OverlayTrigger placement="top" overlay={
                                                    <Popover>
                                                        <p className="mb-0">
                                                           Your number or user that took part in the call.
                                                        </p>
                                                    </Popover>
                                                }>
                                                   <p className="mb-5 text-xs text-500">Your numbers or users involved <span className="icon icon-info2 text-dark-lighter"></span></p>
                                                </OverlayTrigger>

                                                <Select
                                                    className="form-control select-menu"
                                                    isClearable={false}
                                                    isSearchable={true}
                                                    filterOption={(candidate, input) => customFilter(candidate, input)}
                                                    value={this.state.advancedSearchCallMadeToOption} 
                                                    onChange={(value) => this.setState({ advancedSearchCallMadeToOption: value })}
                                                    options={this.state.advancedSearchCallMadeToValues}
                                                />
                                            </div>

                                            <div className="w-12 pr-10 float-start">
                                                 <OverlayTrigger placement="top" overlay={
                                                    <Popover>
                                                        <p className="mb-0">
                                                            If you don&apos;t remember the callers number, or the number you called, but you do remember that the number ended, for example with 222, simply enter 222.
                                                        </p>
                                                    </Popover>
                                                }>
                                                  <p className="mb-5 text-xs text-500">3rd party number ends with <span className="icon icon-info2 text-dark-lighter"></span></p>
                                                </OverlayTrigger>

                                                <input className="form-control" type="text" placeholder="eg. 144" value={this.state.advancedSearchCallNumberContainsOption} onChange={(e) => this.setState({ advancedSearchCallNumberContainsOption: e.target.value })} />
                                            </div>

                                            <div className="w-12 pr-10 float-start">
                                                 <OverlayTrigger placement="top" overlay={
                                                    <Popover>
                                                        <p className="mb-0">
                                                            Filter by the length of the call.
                                                        </p>
                                                    </Popover>
                                                }>
                                                    <p className="mb-5 text-xs text-500">Call duration <span className="icon icon-info2 text-dark-lighter"></span></p>
                                                </OverlayTrigger>

                                                <Select
                                                    className="form-control select-menu"
                                                    isClearable={false}
                                                    isSearchable={false}
                                                    value={this.state.advancedSearchCallDurationOption}
                                                    onChange={(value) => this.setState({ advancedSearchCallDurationOption: value })}
                                                    options={callDurationOptions}
                                                />
                                            </div>

                                            <div className="w-12 pr-10 float-start">
                                                 <OverlayTrigger placement="bottom" overlay={
                                                    <Popover>
                                                        <p>
                                                            Search for the note you've added about the call.
                                                        </p>
                                                        <p className="mb-0">
                                                            <span className="text-500 d-block">Adding notes</span>
                                                            For each call you can add a note. This way it might be easier for you to know what the call was about.
                                                            <br />
                                                            Very useful especially for long length calls.
                                                            <img src="/images/notes2.jpg" className="brad-10 mt-10" alt="Notes link screenshot" style={{ maxWidth: '100%' }} />
                                                        </p>
                                                    </Popover>
                                                }>
                                                   <p className="mb-5 text-xs text-500">Notes include words <span className="icon icon-info2 text-dark-lighter"></span></p>
                                                </OverlayTrigger>

                                                <input className="form-control" type="text" placeholder="eg. Mike" value={this.state.advancedSearchCallNoteContainsOption} onChange={(e) => this.setState({ advancedSearchCallNoteContainsOption: e.target.value })} />
                                            </div>

                                            <Modal className="w-600px" show={this.state.showEnableCallRecordingAndCallTranscription === true} onHide={() => this.setState({ showEnableCallRecordingAndCallTranscription: false })}>
                                                <Modal.Header closeButton className="bb-0"></Modal.Header>
                                                <Modal.Body className="text-center">
                                                    {this.state.hasCallRecording === false &&
                                                        <div>
                                                            <h4 className="text-500 mb-20 mt-0 pt-0">Call Recording &amp; Call Transcription</h4>
                                                            <p>Sorry, but to use the call transcription search you need to activate <br className="d-none d-sm-block" />Call Recording with Call Transcription.</p>
                                                            <span className="btn btn-cta btn-lg mb-60 mt-20" onClick={() => this.setState({ showCallRecordingPackages: true, showEnableCallRecordingAndCallTranscription: false })}>
                                                                Select your package
                                                            </span>
                                                        </div>
                                                    }

                                                    {this.state.hasCallRecording === true &&
                                                        <>

                                                            {this.state.hasCallTranscription === false &&
                                                                <>
                                                                    <div>
                                                                        <h4 className="text-500 mt-0 pt-0 mb-20">Search for words spoken or missing during the call with Call Transcription</h4>
                                                                        <p>Sorry, but to use the call transcription search, you need to activate Call Transcription. The cost is only 5p per minute.</p>
                                                                        <p className="text-start pl-40 pt-30">With Call Transcription you will benefit from...</p>
                                                                        <CallTranscriptionBenefitsList />
                                                                        <p className="text-500 pt-20">Ready to activate Call Transcription?</p>

                                                                        <CallTranscriptionActivation
                                                                            isBox={false}
                                                                            activateCallTranscriptionClicked={() => this.callTranscriptionTurnedOn()}
                                                                            customToggleWrapperClass="mt-30 mb-40 b-2 brad-20 p-30 w-300px center-block text-center box-shadow"
                                                                        />
                                                                    </div>

                                                                </>
                                                            }

                                                            {this.state.showCallTranscriptionActivatedConfirmation &&
                                                                <>
                                                                    <span className="circle-tick success center-block margin-bottom-20 slide-in-blurred-top scale-up1">
                                                                        <span className="icon icon-checkmark4 scale-up"></span>
                                                                    </span>
                                                                    <h4 className="text-500 mb-30 mt-20 text-success">Call transcription activated!</h4>
                                                                    <div className="text-start">
                                                                        <p>From now on, any new outbound or inbound calls will be automatically transcribed. Using the search option you will be able to search for specific words or phrases.</p>
                                                                        <p className="text-500">Make a quick test right now</p>
                                                                        <p>Make a call to or using your SwitchboardFREE number. Within minutes the call recording along with call transcription should appear on this page.</p>
                                                                    </div>
                                                                </>
                                                            }

                                                        </>

                                                    }
                                                </Modal.Body>
                                            </Modal>

                                            {this.state.hasCallRecording === false &&
                                                <>
                                                    <span className="call-recording-not-enabled1">
                                                        <div className="w-50 pr-10 float-start">
                                                            <p className="mb-5 text-xs text-500">Search for words spoken during call <OverlayTrigger placement="top" overlay={
                                                                <Popover>
                                                                    <p className="mb-0">
                                                                        Find the words that has been spoken during the call either by you or the caller.
                                                                    </p>
                                                                </Popover>
                                                            }>
                                                                <span className="icon icon-info2 text-dark-lighter"></span>
                                                            </OverlayTrigger>
                                                            </p>
                                                            <input className="form-control" type="text" placeholder="eg. John" onClick={() => this.setState({ showEnableCallRecordingAndCallTranscription: true })} />
                                                        </div>

                                                        <div className="w-50 pr-10 float-start">
                                                            <p className="mb-5 text-xs text-500">Search for words that weren't spoken during call <OverlayTrigger placement="top" overlay={
                                                                <Popover>
                                                                    <p>
                                                                        Find the call where specific words haven't been mentioned.
                                                                    </p>
                                                                    <p className="mb-0">
                                                                        For example, you might have a regulation where your team must ask the caller to agree to something.
                                                                    </p>
                                                                </Popover>
                                                            }>
                                                                <span className="icon icon-info2 text-dark-lighter"></span>
                                                            </OverlayTrigger>
                                                            </p>
                                                            <input className="form-control" type="text" placeholder="eg. Smith" onClick={() => this.setState({ showEnableCallRecordingAndCallTranscription: true })} />
                                                        </div>
                                                    </span>
                                                </>

                                            }

                                            {this.state.hasCallRecording === true &&
                                                <>

                                                    {this.state.hasCallTranscription === false
                                                      ? <>
                                                            <span className="call-recording-not-enabled1">
                                                                <div className="w-50 pr-10 float-start">
                                                                    <p className="mb-5 text-xs text-500">Call includes words <OverlayTrigger placement="top" overlay={
                                                                        <Popover>
                                                                            <p className="mb-0">
                                                                                Find the words that has been spoken during the call either by you or the caller.
                                                                            </p>
                                                                        </Popover>
                                                                    }>
                                                                        <span className="icon icon-info2 text-dark-lighter"></span>
                                                                    </OverlayTrigger></p>
                                                                    <input className="form-control" type="text" placeholder="eg. John" onClick={() => this.setState({ showEnableCallRecordingAndCallTranscription: true })} />
                                                                </div>

                                                                <div className="w-50 pr-10 float-start">
                                                                    <p className="mb-5 text-xs text-500">Call doesn't include words <OverlayTrigger placement="top" overlay={
                                                                        <Popover>
                                                                            <p>
                                                                                Find calls where specific words haven't been said.
                                                                            </p>
                                                                            <p className="mb-0">
                                                                                For example, you might have a regulation where your team must ask the caller to agree to something.
                                                                            </p>
                                                                        </Popover>
                                                                    }>
                                                                        <span className="icon icon-info2 text-dark-lighter"></span>
                                                                    </OverlayTrigger>
                                                                    </p>
                                                                    <input className="form-control" type="text" placeholder="eg. Smith" onClick={() => this.setState({ showEnableCallRecordingAndCallTranscription: true })} />
                                                                </div>
                                                            </span>
                                                        </>
                                                      : <>
                                                            <div className="w-12 pr-10 float-start">
                                                                <p className="mb-5 text-xs text-500">Transcription include words <OverlayTrigger placement="top" overlay={
                                                                    <Popover>
                                                                        <p className="mb-0">
                                                                            Find the words that has been spoken during the call either by you or the caller.
                                                                        </p>
                                                                    </Popover>
                                                                }>
                                                                    <span className="icon icon-info2 text-dark-lighter"></span>
                                                                </OverlayTrigger></p>
                                                                <input className="form-control" type="text" placeholder="eg. John" value={this.state.advancedSearchCallIncludeWordsOption} onChange={(e) => this.setState({ advancedSearchCallIncludeWordsOption: e.target.value })} />
                                                            </div>

                                                            <div className="w-12 pr-10 float-start">
                                                                <p className="mb-5 text-xs text-500">Transcription doesn't include words <OverlayTrigger placement="top" overlay={
                                                                    <Popover>
                                                                        <p>
                                                                            Find the call where specific words haven't been mentioned.
                                                                        </p>
                                                                        <p className="mb-0">
                                                                            For example, you might have a regulation where your team must ask the caller to agree to something.
                                                                        </p>
                                                                    </Popover>
                                                                }>
                                                                    <span className="icon icon-info2 text-dark-lighter"></span>
                                                                </OverlayTrigger>
                                                                </p>
                                                                <input className="form-control" type="text" placeholder="eg. Smith" value={this.state.advancedSearchCallDoesNotIncludeWordsOption} onChange={(e) => this.setState({ advancedSearchCallDoesNotIncludeWordsOption: e.target.value })} />
                                                            </div>
                                                        </>
                                                    }

                                                </>

                                            }

                                            <div className="w-100 pt-30 d-block clearfix">
                                                <Button variant="cta" size="md" onClick={() => this.doSearch()} disabled={this.state.isLoading || (this.state.advancedSearchCallDateOption.value === '8' && (this.state.advancedSearchStartDate === null || this.state.advancedSearchEndDate === null))}>Search</Button>
                                            </div>
                                            <div className="col-12 pt-20">
                                                <p className="text-dark underline text-sm mb-0 d-inline-block" onClick={() => this.closeAdvancedSearchAndSetToDefault()} style={{ cursor: 'pointer' }}>
                                                    Close &amp; reset to default
                                                </p>
                                            </div>
                                        </div>

                                    </div>
                                }

                                {/* <div className="col-12" style={{ display: "none" }}>
                                    <div className="search-options custom-filter b-1 pt-30 pb-30 pl-20 pr-20 brad-20 bg-light mb-40 box-shadow-v3">
                                        <OverlayTrigger placement="top" overlay={<Tooltip>Close & reset to default</Tooltip>}>
                                            <div className="close">&times;</div>
                                        </OverlayTrigger>

                                        <div className="inline-block sort-option active">
                                            Missed Calls with Chris Venables
                                            <OverlayTrigger placement="top" overlay={<Tooltip>Remove filter</Tooltip>}>
                                                <span className="remove-custom-filter">
                                                    <span className="position-absolute">&times;</span>
                                                </span>
                                            </OverlayTrigger>
                                        </div>
                                        <p className="pt-20 mb-0">
                                            <a href="#" className="text-dark underline text-sm">Close and remove all custom filters</a>
                                        </p>
                                    </div>
                                </div> */}
                            </div>

                            <div className="bg-light p-20 pt-xs-10 pb-xs-10 mb-1 brad-t-l-20 brad-t-r-20 inbox-options-bar">
                                <div className="search-area">{/*
                                    <OverlayTrigger placement="top" overlay={<Tooltip>Search for callers name, numbers, words spoken</Tooltip>}>
                                        <input type="text" className="form-control brad-10" placeholder="Search..." />
                                    </OverlayTrigger>
                                    <span className="btn btn-cta">search</span>
                                */}
                                </div>
                                <div className="calls-action-buttons">
                                  <OverlayTrigger placement="bottom" overlay={
                                          <Popover>
                                              <p className='mb-0'>Use the advanced search to find your calls.</p>
                                          </Popover>
                                          }>
                                      <Button className="mb-5 mr-10 text-lightest brad-30 b-0" style={{backgroundColor:"#2975ff"}} variant="secondary" size="sm" onClick={() => this.setState({ advancedSearchOptions: !this.state.advancedSearchOptions })} id="ToggleSearchButton">
                                        <span className="icon icon-search text-lightest mr-5" style={{verticalAlign:"text-top"}}></span> Search
                                    </Button>
                                  </OverlayTrigger>
                                    
                                </div>
                            </div>

                            <div
                                className={`calls-inbox ${this.state.callInformation === undefined ? ' ' : 'open ' + (this.state.callInformation.numberCalled === 'Anonymous' || this.state.callInformation.initiatorNumber === 'Anonymous' ? 'is-anon' : ' ')}`}

                            >
                                <div className={`calls-sidebar ${this.state.closeAndShowSidebar ? 'show' : ''}`}>
                                    <div className="row mr-0 ml-0">
                                        {this.state.calls !== [] && this.state.calls !== undefined && this.state.calls.map((call) => {
                                          return <div className={'col-12 item' + ((call === this.state.selectedListEntry) ? ' active ' : '')} onClick={() => this.callEntryClicked(call)}>
                                                {/* <OverlayTrigger placement="top" overlay={<Popover className='popover-small dark'> {call.callStatus === 2 ? 'Answered call' : call.callStatus === 3 ? 'Missed Call' : 'Voicemail'}</Popover>}> */}
                                                    {call.callStatus === 2
                                                      ? <span className="text-center call-status answered-call" title="Answered call"></span>
                                                      : (call.callStatus === 3 || call.callStatus === 5)
                                                          ? <span className="text-center call-status missed-call" title="Missed call"></span>
                                                          : <span className="text-center call-status voicemail-call" title="Voicemail"></span>
                                                    }
                                                    

                                                {/* </OverlayTrigger> */}

                                                <span className="caller-name pl-20">
                                                    {call.isName === true ? call.numberOrName : simplePhoneNumberFormat(call.numberOrName)}
                                                    {call.switchboardFreeNumberUsed !== '' ? <p style={{fontSize:10, paddingBottom:0, marginBottom:0}}> {simplePhoneNumberFormat(call.switchboardFreeNumberUsed)}</p> : <></>}
                                                </span>
                                                <span className="call-time float-end">
                                                    {/*
                                                         put formatting in js code to prevent the need to restart
                                                         the application everytime the data formating needs to be
                                                         changed
                                                    */}
                                                    {call.whenStarted.length !== 2
                                                      ? call.whenStarted
                                                      : call.whenStarted === 'mo'
                                                        ? 'mon'
                                                        : call.whenStarted === 'tu'
                                                          ? 'tue'
                                                          : call.whenStarted === 'we'
                                                            ? 'wed'
                                                            : call.whenStarted === 'th'
                                                              ? 'thu'
                                                              : call.whenStarted === 'fr'
                                                                ? 'fri'
                                                                : call.whenStarted === 'sa'
                                                                  ? 'sat'
                                                                  : 'sun'
                                                    }
                                                </span>

                                            </div>
                                        })}
                                        {this.state.isLoading === true
                                          ? <>
                                                <div className="col-12 item loading text-center bg-dull">
                                                    <img id="loading" src={'images/icons/loading.svg'} width="50" height="50" />
                                                    <p className="text-dark text-sm text-italic">Loading...</p>
                                                </div>
                                            </>
                                          : this.state.calls === [] || this.state.calls === undefined || this.state.calls === null || this.state.calls.length === 0
                                            ? <div className="col-12 item loading text-center bg-dull">
                                                    <p className="text-dark text-sm text-italic pt-15">You have not made or received any calls...</p>
                                                </div>
                                            : this.state.calls.length % 100 === 0 && <div className="col-12 item loading text-center bg-dull">
                                                    <img id="loading100Icon" src={'images/icons/loading.svg'} width="50" height="50" />
                                                    <p className="text-dark text-sm text-italic">Loading next 100 calls...</p>
                                                </div>}

                                    </div>
                                </div>

                                <div className="calls-details">

                                    {this.state.isLoading === true
                                      ? <>

                                        </>
                                      : this.state.calls === [] || this.state.calls === undefined || this.state.calls === null || this.state.calls.length === 0
                                        ? <div className="text-center">
                                                <h4 className="text-500">You have not made or received any calls yet...</h4>
                                                <p>Once a call has been made it usually appears here within minutes.</p>
                                            </div>
                                        : <></>}

                                    <span className="close-call" onClick={() => this.setState({ callInformation: this.state.callInformation ? undefined : false, showContactSidebar: false, editingContactbookEntry: this.state.editingContactbookEntry ? undefined : false })}></span>

                                    {this.state.callInformation !== undefined && this.state.callInformation !== null && <div className="caller-details">
                                        <h3 className="text-500 mb-0 pb-0">{this.generateCallInformationHeader(this.state.callInformation)}
                                            {this.state.callInformation.isInboundCall === true && this.state.callInformation.initiatorNumber !== 'Anonymous'
                                              ? this.state.callInformation.phoneBookContactId === 0 || this.state.callInformation.phoneBookCrmId !== 0
                                                ? <>
                                                    <Link
                                                    to={'#'}
                                                    className={`text-cta underline text-400 ml-10 add-to-contacts ${this.state.editingContactbookEntry ? 'show' : 'hide'}`}
                                                    style={{ fontSize: '12px' }}
                                                    // onClick={() => this.setState({ addingNewContactbookEntry: this.state.addingNewContactbookEntry ? false : true })}
                                                    onClick={() => this.setState({ showContactSidebar: !this.state.showContactSidebar })}
                                                    >Add to Contacts</Link>
                                                    </>
                                                : <Link
                                                        to={'#'}
                                                        className={`text-cta underline text-400 ml-10 add-to-contacts ${this.state.editingContactbookEntry ? 'show' : 'hide'}`}
                                                        style={{ fontSize: '12px' }}
                                                        onClick={() => this.editingContactbookEntryPressed() + this.setState({ showContactSidebar: !this.state.showContactSidebar })}
                                                    >
                                                        Edit
                                                    </Link>
                                              : ''

                                            }

                                            {/* <Link to={"#"} className={`text-cta underline text-400 ml-10 ${this.state.editingContactbookEntry ? 'show' : 'hide'}`} style={{fontSize:"12px"}} onClick={() => this.setState({ editingContactbookEntry: this.state.editingContactbookEntry ? false : true })}>Add to Contact Book</Link> */}
                                        </h3>
                                        <p className="pb-20">Called {this.state.callInformation.isInboundCall === true && 'your'} <a href={'tel:' + this.state.callInformation.numberCalled} className="text-cta underline">{simplePhoneNumberFormat(this.state.callInformation.numberCalled)}</a> {this.state.callInformation.numberDescription !== null ? '(' + this.state.callInformation.numberDescription + ')' : ''}
                                            {this.state.callInformation.isInboundCall === false
                                              ? this.state.callInformation.phoneBookContactId === 0 || this.state.callInformation.phoneBookCrmId !== 0
                                                ? <>
                                                    </>
                                                : <Link
                                                        to={'#'}
                                                        className={`text-cta underline text-400 ml-10 ${this.state.editingContactbookEntry ? 'show' : 'hide'}`}
                                                        style={{ fontSize: '12px' }}
                                                        onClick={() => this.editingContactbookEntryPressed() + this.setState({ showContactSidebar: !this.state.showContactSidebar })}
                                                    >
                                                        Edit
                                                    </Link>
                                              : ''

                                            }
                                        </p>
                                        <div className="bg-dull p-20 brad-5 mb-40 call-metadata">
                                            <div className="row">
                                                <div className="col-xs-12 col-lg-6">
                                                    <span className="d-block pb-5">
                                                        Call type: {this.state.callInformation.isInboundCall === true ? 'Inbound' : 'Outbound'}
                                                    </span>
                                                    <span className="d-block pb-5">
                                                        Call status: {this.state.callInformation.callStatus === 2 ? 'Answered call' : this.state.callInformation.callStatus === 3 ? 'Missed Call' : this.state.callInformation.callStatus === 5 ? 'Not Answered Call' : 'Voicemail'}
                                                    </span>
                                                    <span className="d-block pb-5">
                                                        Answered by: {this.state.callInformation.user !== null ? this.state.callInformation.user : 'N/A'}
                                                    </span>
                                                    <span className="d-block pb-5">
                                                        Call date: {simpleDateTimeFormat(this.state.callInformation.callDate)}
                                                    </span>
                                                    {this.state.callInformation.departmentSelected !== 0 && <span className="d-block pb-5">
                                                        Department selected: {this.state.callInformation.departmentSelected}
                                                    </span>}
                                                </div>
                                                <div className="col-xs-12 col-lg-6">
                                                    <span className="d-block pb-5">
                                                        Call lasted: {this.state.callInformation.callLength}
                                                    </span>
                                                    <span className="d-block pb-5">
                                                        Callers number:&nbsp;
                                                        {this.state.callInformation.initiatorNumber !== 'Anonymous'
                                                          ? this.state.callInformation.initiatorNumber !== 'Withheld'
                                                            ? <a href={'tel:' + this.state.callInformation.initiatorNumber} className="text-cta underline">{simplePhoneNumberFormat(this.state.callInformation.initiatorNumber)}</a>
                                                            : 'Withheld'
                                                          : 'Anonymous'

                                                        }
                                                    </span>
                                                    <span className="pb-5 show-1200px">
                                                        Notes:
                                                        {this.state.callInformation.note === ''
                                                          ? <>
                                                                <span className="text-cta underline cursor-pointer ml-5 mr-5" onClick={() => this.loadNoteModal(this.state.callInformation)}>{(this.state.callInformation.note !== undefined && this.state.callInformation.note.trim() !== '') ? '' : 'Add note'}</span>
                                                                {/* <span className="text-cta underline cursor-pointer ml-5" onClick={() => this.loadNoteModal(this.state.callInformation)}>{(this.state.callInformation.note !== undefined && this.state.callInformation.note.trim() !== '') ? 'aaa' : 'xxx'}</span> */}
                                                            </>
                                                          : <>
                                                                <span className="trim-230px call-notes ml-5 mr-5" style={{ verticalAlign: 'middle' }}>{this.state.callInformation.note}</span>
                                                                <span className="text-cta underline cursor-pointer" onClick={() => this.loadNoteModal(this.state.callInformation)}>{(this.state.callInformation.note !== undefined && this.state.callInformation.note.trim() !== '') ? 'View' : ''}</span>
                                                                {/* <span className="text-cta underline cursor-pointer" onClick={() => this.loadNoteModal(this.state.callInformation)}>{(this.state.callInformation.note !== undefined && this.state.callInformation.note.trim() !== '') ? 'bbb' : 'ccc'}</span> */}
                                                            </>
                                                        }

                                                    </span>
                                                    {this.state.hasCallRecording === true && (this.state.selectedListEntry.callStatus !== 3 && this.state.selectedListEntry.callStatus !== 5) && !(this.state.isLoadingCallRecording === false && this.state.callInformation.callRecordingId === 0 && (this.state.selectedListEntry.callStatus !== 4 || (this.state.voicemailLoaded === false && this.state.selectedListEntry.callStatus === 4))) &&
                                                    <span className="d-block pb-5">
                                                        Download recording: {this.state.isLoadingCallRecording === true ? 'Loading...' : <a className="text-cta underline" onClick={() => this.handleDownloadPressed()}>Download</a>}
                                                    </span>}
                                                    {this.state.callInformation.isInboundCall === true && <span className="d-block pb-5">
                                                       Call delivery attempts: <a className="text-cta underline" onClick={() => this.handleShowRedirectAttemptsPressed(this.state.selectedListEntry.callId)}>View</a>
                                                    </span>}
                                                </div>
                                            </div>

                                        </div>

                                        {this.state.hasCallRecording === false && this.state.selectedListEntry.callStatus !== 4
                                          ? <div className="bg-gradient-v1 text-center mb-20 mt-30 p-30 pb-50 brad-30 text-lightest">
                                                <h4 className="text-500 mb-30">Enable call recording!</h4>
                                                <div className="w-600px center-block text-start">
                                                <p>We could have recorded this call for you. With call recording you can:</p>
                                                <ul className="fancy pl-30 pt-20">
                                                    <li className="pb-10">
                                                        Perfect for staff training &amp; monitoring
                                                    </li>
                                                    <li className="pb-10">
                                                        Free 30 days storage
                                                    </li>
                                                    <li className="pb-10">
                                                        Add notes, download as mp3, listen online
                                                    </li>
                                                    <li className="pb-10">
                                                        Stay compliant, resolve customer conflicts
                                                    </li>
                                                    <li className="pb-10">
                                                        Call recording works across all your numbers
                                                    </li>
                                                    <li className="pb-10">
                                                        Inbound &amp; outbound calls are recorded
                                                    </li>
                                                    <li className="pb-10">
                                                        Enable or disable for specific number or user
                                                    </li>
                                                </ul>
                                                <p>With call recording you have the option for automatic transcription allowing you to search both sides of the conversation for words and phrases spoken or missing.</p>
                                                <div className="text-center">
                                                    <span className="btn btn-lg btn-lightest mt-20" onClick={() => this.setState({ showCallRecordingPackages: true })}>Activate Now</span>
                                                </div>
                                                </div>

                                            </div>
                                          : <>
                                                {this.state.isLoadingCallRecording === false && this.state.callInformation.callRecordingId === 0 && this.state.selectedListEntry.callStatus !== 4 && <div>
                                                    <h5 className="text-500">Listen to this call</h5>
                                                    <p className="text-500">No call recording</p>
                                                </div>}
                                                {this.state.isLoadingCallRecording === false && this.state.callInformation.callRecordingId === 0 && this.state.voicemailLoaded === false && this.state.selectedListEntry.callStatus === 4 && <div>
                                                    <h5 className="text-500">Listen to this voicemail</h5>
                                                    <p className="text-500">No voicemail found</p>
                                                </div>}
                                                {this.state.isLoadingCallRecording === true &&
                                                    <div style={{ display: this.state.Result !== 'Missed' && (this.state.callInformation.callRecordingId !== 0 || this.state.selectedListEntry.callStatus === 4) ? 'block' : 'none' }}>
                                                        <h5 className="text-500">Loading your {this.state.selectedListEntry.callStatus === 4 ? 'voicemail' : 'call recording'}...</h5>
                                                        <p><img src="/images/icons/loading.svg" alt="loading the recording" width="50" height="50" /></p>
                                                    </div>
                                                }

                                                <div style={{ display: (this.state.isLoadingCallRecording === true || (this.state.callInformation.callRecordingId === 0 && this.state.voicemailLoaded === false) || this.state.selectedListEntry.callStatus === 3 || this.state.selectedListEntry.callStatus === 5) ? 'none' : 'block', float: (this.state.isLoadingCallRecording === true || (this.state.callInformation.callRecordingId === 0 && this.state.voicemailLoaded === false) || (this.state.selectedListEntry.callStatus === 3 || this.state.selectedListEntry.callStatus === 5)) ? 'left' : 'none' }}>
                                                    <h5 className="text-500">Listen to this {Number(this.state.selectedListEntry.callStatus) === 4 ? 'voicemail' : 'call recording'}</h5>
                                                    <ReactAudioPlayer id="audioPlayer" className="brad-10 mb-40" controls />
                                                </div>

                                            </>
                                        }

                                        {this.state.callInformation.callStatus !== 4 && this.state.showCallTranscriptionActivatedConfirmation &&
                                            <div className="bg-gradient-v1 text-center mb-20 p-30 pb-50 brad-30 text-lightest">
                                                <div className="w-500px center-block">
                                                    <span className="circle-tick center-block margin-bottom-20 slide-in-blurred-top scale-up1">
                                                        <span className="icon icon-checkmark4 scale-up"></span>
                                                    </span>
                                                    <h4 className="text-500 mb-30 mt-20">Call transcription activated!</h4>
                                                    <div className="text-start">
                                                        <p>From now on, any new outbound or inbound calls will be automatically transcribed. Using the <span className="cursor-pointer underline" onClick={() => this.setState({ advancedSearchOptions: !this.state.advancedSearchOptions })}>search option</span> you will be able to search for specific words or phrases.</p>
                                                        <p className="text-500">Make a quick test right now</p>
                                                        <p>Make a call to or using your SwitchboardFREE number. Within minutes the call recording along with call transcription should appear on this page.</p>
                                                        <div className="text-center">
                                                            <span className="btn btn-outline-light btn-sm no-shadow text-center mt-30 mb-30" onClick={() => this.setState({ showCallTranscriptionActivatedConfirmation: false })}>Close</span>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        }

                                        {this.state.callInformation.callStatus !== 4 && this.state.showCallRecordingActivatedConfirmation && <div className="bg-gradient-v1 text-center mb-20 p-30 pb-50 brad-30 text-lightest">
                                            <div className="w-500px center-block">
                                                <h4 className="text-500 mb-30">Call recording activated</h4>
                                            </div>

                                        </div>}

                                        {(this.state.callInformation.callStatus === 4 || this.state.hasCallRecording === true) &&
                                            <>

                                                {this.state.callInformation.callStatus !== 4 && this.state.hasCallTranscription === false
                                                  ? <div className="bg-gradient-v1 text-center mb-20 p-30 pb-50 brad-30 text-lightest">
                                                        <div className="w-500px center-block">
                                                            <h4 className="text-500 mb-30">Call Search Pro</h4>
                                                            <p>In addition to recordings your calls, automatically transcribe them. <br/>Just 5p per minute.
                                                                <OverlayTrigger trigger={['hover', 'focus']} placement="top"
                                                                    overlay={
                                                                        <Popover>
                                                                            <CallTranscriptionBenefitsList />
                                                                        </Popover>
                                                                    }>
                                                                    <span className="underline cursor-pointer ml-5">See benefits</span>
                                                                </OverlayTrigger>

                                                            </p>

                                                           <CallTranscriptionActivation
                                                                isBox={false}
                                                                activateCallTranscriptionClicked={() => this.callTranscriptionTurnedOn()}
                                                                customToggleWrapperClass="mt-40 mb-20 b-1 brad-20 p-30 w-300px center-block text-center"
                                                            />
                                                        </div>

                                                    </div>
                                                  : <>
                                                        {this.state.callInformation.callStatus === 4
                                                          ? this.state.callInformation.callTranscription[0] !== undefined && this.state.callInformation.callTranscription[0].text === undefined && this.state.callInformation.callTranscription[0].text === ''
                                                            ? <h5 className="text-500 mb-30">No speech detected in voicemail</h5>
                                                            : <div className="call-transcription-section">
                                                                    <h5 className="text-500 mb-30">Transcription of this voicemail</h5>
                                                                    <div className="col-12 mb-20 chat-bubble">
                                                                        <div className="answerer" style={{ cursor: 'pointer' }} onClick={() => this.startAudioPlayerAtSpecificTime(Number(this.state.callInformation.callTranscription[0].startTimeInSeconds))}>

                                                                            <div className="p-20 brad-20 chat">
                                                                                <span> {this.state.callInformation.callTranscription[0] !== undefined && this.state.callInformation.callTranscription[0].text !== undefined ? this.state.callInformation.callTranscription[0].text : ''} </span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                          : this.state.callInformation.callTranscription !== undefined && this.state.callInformation.callTranscription !== null && this.state.callInformation.callTranscription.length > 1 ? <div className="row">
                                                                <div className="call-transcription-section">
                                                                    <h5 className="text-500 mb-30">Transcription of this call</h5>
                                                                    {this.state.callInformation.callTranscription.length > 1 && this.state.callInformation.callTranscription.map((x, index) => {
                                                                      return <>
                                                                            {x.text === '' ? <></>
                                                                              : <div className="col-12 mb-20 chat-bubble">
                                                                                    <div className={x.isCallInitiator ? 'caller' : 'answerer'} style={{ cursor: 'pointer' }} onClick={() => this.startAudioPlayerAtSpecificTime(Number(x.startTimeInSeconds))}>
                                                                                    {/* {this.generateCallInformationHeader(this.state.callInformation)} */}

                                                                                        {(index === 0 || (index !== 0 && x.isCallInitiator !== this.state.callInformation.callTranscription[index - 1].isCallInitiator)) && <div className="text-sm mb-5 text-end">

                                                                                            {x.isCallInitiator === true

                                                                                              ? this.state.callInformation.initiatorName !== null
                                                                                                ? this.state.callInformation.initiatorName
                                                                                                : simplePhoneNumberFormat(this.state.callInformation.initiatorNumber)
                                                                                              : <>
                                                                                                        {this.state.callInformation.numberDescription !== null
                                                                                                          ? <>
                                                                                                            {this.state.callInformation.numberDescription}
                                                                                                        </>
                                                                                                          : simplePhoneNumberFormat(this.state.callInformation.numberCalled)
                                                                                                        }
                                                                                                    </>
                                                                                            }

                                                                                             <span> at {x.textTimeSaid}</span>

                                                                                            {/* {x.isCallInitiator === true ? simplePhoneNumberFormat(this.state.callInformation.initiatorNumber) : simplePhoneNumberFormat(this.state.callInformation.numberCalled)} at {x.textTimeSaid} */}
                                                                                        </div>}
                                                                                        <div className="p-20 brad-20 chat">
                                                                                            <span> {x.text} </span>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>}
                                                                        </>
                                                                    })}
                                                                </div>
                                                            </div>
                                                            : <div className="row">

                                                                </div>

                                                        }
                                                    </>
                                                }

                                            </>}
                                    </div>}
                                </div>
                                <div className={`calls-about-user ${this.state.showContactSidebar ? 'show' : ''}`}>
                                    {this.state.callInformation !== undefined && this.state.callInformation !== null && (this.state.callInformation.phoneBookContactId !== 0 || this.state.addingNewContactbookEntry || this.state.editingContactbookEntry) && <>
                                        {this.state.addingNewContactbookEntry || this.state.editingContactbookEntry
                                          ? <div className="editing-user">

                                                <p className="text-500 text-dark-lighter text-uppercase mb-20 text-xs">{this.state.addingNewContactbookEntry ? <span>Add this caller to your Contact Book <OverlayTrigger placement="top" overlay={
                                                <Popover>
                                                    <p>See the caller&apos;s name in missed call alerts and calls list, making it easy to identify your regular callers.</p>
                                                    <p className='mb-0'>Turn on AI Caller Greeting &amp; our clever voice engine will welcome your caller, by their name when they call in, making them feel welcome and helping to creating a great impression of your company.</p>
                                                  </Popover>
                                                }>
                                            <span className="icon icon-info2 text-dark-lighter pl-5"></span>
                                        </OverlayTrigger></span> : 'Edit user info...'}</p>
                                                <div className="d-block pb-10">
                                                    <label>Full name</label>
                                                    <input type="text" className="form-control" disabled={this.state.editingContactbookEntry} value={this.state.newContactName} onChange={(event) => this.setState({ newContactName: event.target.value })} />
                                                </div>
                                                <div className="d-block pb-10">
                                                    <label>Company</label>
                                                    <input type="tel" className="form-control" disabled={this.state.editingContactbookEntry} value={this.state.newContactCompany} onChange={(event) => this.setState({ newContactCompany: event.target.value })} />
                                                </div>
                                                <div className={this.state.editingContactbookEntry ? 'd-block pb-10 contacts-numbers' : 'd-block pb-10'}>
                                                    <label>Numbers</label>
                                                    {this.state.editingContactbookEntry && this.state.editNumbers && this.state.editNumbers.map((number, index) => {
                                                      return <div className="d-block">
                                                            <input type="tel" className="form-control mb-10 float-start" value={number} onChange={(event) => this.changeEditNumber(event.target.value, index)} />
                                                            <OverlayTrigger placement="top" overlay={<Tooltip>Remove number</Tooltip>}>
                                                                <span className="icon icon-minus3 float-start" onClick={() => this.setState({ editNumbers: this.removeNumberFromEditNumbers(index) })}></span>
                                                            </OverlayTrigger>

                                                        </div>
                                                    })}

                                                    {this.state.addingNewContactbookEntry &&
                                                        <input type="tel" className="form-control" value={this.state.newContactNumber} disabled={this.state.addingNewContactbookEntry} />
                                                    }
                                                    {this.state.editingContactbookEntry &&
                                                        <div className="d-block pt-5 text-cta underline text-xs text-end cursor-pointer clearfix" onClick={() => this.setState({ editNumbers: [...this.state.editNumbers, ''] })}>Add new number</div>
                                                    }
                                                </div>

                                                <div className="d-block mb-10 bg-dull b-1 pl-10 pr-10 pt-20 pb-20 brad-10 text-center mt-30">
                                                    <h6 className="text-500 mt-0">AI Greeting <OverlayTrigger placement="top" overlay={
                                                <Popover>
                                                    <p className='mb-0'>Turn on AI Caller Greeting &amp; our clever voice engine will welcome your caller, by their name when they call in, making them feel welcome and helping to creating a great impression of your company.</p>
                                                  </Popover>
                                                }>
                                            <span className="icon icon-info2 text-dark-lighter pl-5"></span>
                                        </OverlayTrigger></h6>
                                                    <p className="text-sm">
                                                        Automatically recognise callers' numbers and greet them by name when they call from a mobile, <br />e.g <i>"Hi Chris..."</i>
                                                    </p>
                                                    <span className="text-500 text-sm pr-10">Off</span>
                                                    <Toggle
                                                        on={<span></span>}
                                                        off={<span></span>}
                                                        size="sm"
                                                        offstyle="default"
                                                        onstyle="success"
                                                        width="null"
                                                        height="null"
                                                        className="toggle-sm toggle-secondary"
                                                        onClick={() => this.onToggle()}
                                                        active={this.state.newContactAiGreeting ?? false}
                                                    />
                                                    <span className="text-500 text-sm pl-10">On</span>
                                                </div>
                                                <div className="d-block pb-10 mt-20 text-center">
                                                    <Button variant="cta" size="md" className="btn-block" onClick={() => this.state.addingNewContactbookEntry === true ? this.saveNewContactPressed() : this.saveContactChangesPressed()} disabled={this.state.newContactName === undefined || this.state.newContactName.trim() === ''}>Save changes</Button>
                                                    {this.state.editingContactbookEntry && <>
                                                        <p className="text-dark underline mt-20 text-sm cursor-pointer hide-1200px" onClick={() => this.setState({ addingNewContactbookEntry: false, editingContactbookEntry: false })}>Cancel edits</p>

                                                    </>}

                                                    {this.state.editingContactbookEntry && <>
                                                        <p className="text-dark underline mt-20 text-sm cursor-pointer show-1200px" onClick={() => this.setState({ showContactSidebar: false, editingContactbookEntry: false })}>Cancel edits</p>

                                                    </>}

                                                    {this.state.addingNewContactbookEntry && <>
                                                        <p className="text-dark underline mt-20 text-sm cursor-pointer show-1200px" onClick={() => this.setState({ showContactSidebar: false })}>Cancel edits</p>

                                                    </>}

                                                </div>
                                            </div>
                                          : this.state.callInformation.phoneBookContactName !== null
                                            ? <>
                                                    <div className="user-details">
                                                        <p className="text-500 text-dark-lighter text-uppercase mb-20 text-xs">More info about...</p>
                                                        <h4 className="text-500 mt-0 mb-0">
                                                            {this.state.callInformation.phoneBookContactName}
                                                        </h4>
                                                        <p className="text-sm pb-20 pt-0 mt-0">This user is in your Contact Book. {this.state.callInformation.phoneBookCrmId === 0 && <Link to={'#'} className="text-cta underline text-400" style={{ fontSize: '12px' }} onClick={() => this.editingContactbookEntryPressed()}>Edit</Link>}
                                                            {this.state.callInformation.phoneBookCrmId !== 0 && <>
                                                                {this.state.callInformation.phoneBookCrmId === 1 && <span>I&apos;s sync&apos;d from Capsule CRM&nbsp;
                                                                    <OverlayTrigger placement="top" overlay={<Tooltip>To edit this contact, login to Capsule CRM.</Tooltip>}>
                                                                        <span className="icon icon-info2 text-dark-lighter text-xs"></span>
                                                                    </OverlayTrigger>
                                                                </span>}
                                                                {this.state.callInformation.phoneBookCrmId === 3 && <span>I&apos;s sync&apos;d from HubSpot CRM&nbsp;
                                                                    <OverlayTrigger placement="top" overlay={<Tooltip>To edit this contact, login to HubSpot CRM.</Tooltip>}>
                                                                        <span className="icon icon-info2 text-dark-lighter text-xs"></span>
                                                                    </OverlayTrigger>
                                                                </span>}
                                                                {this.state.callInformation.phoneBookCrmId === 6 && <span>I&apos;s sync&apos;d from Pipedrive CRM&nbsp;
                                                                    <OverlayTrigger placement="top" overlay={<Tooltip>To edit this contact, login to Pipedrive CRM.</Tooltip>}>
                                                                        <span className="icon icon-info2 text-dark-lighter text-xs"></span>
                                                                    </OverlayTrigger>
                                                                </span>}
                                                            </>}
                                                        </p>

                                                        <div className="text-sm">
                                                            {this.state.callInformation.phoneBookNumbers.length > 0 && this.state.callInformation.phoneBookNumbers.map((number, index) => {
                                                              return <span className="d-block mb-5 bb-1-dotted pt-5 pb-5">
                                                                    <span className="text-500">Number:</span>
                                                                    <span className="float-end">
                                                                        <a href="tel:07311887879" className="text-cta underline">{number}</a>

                                                                        {/* <OverlayTrigger placement="top" overlay={<Tooltip>View all calls from this number</Tooltip>}>
                                                                                <span className="ml-5">
                                                                                    (<span className="underline text-cta cursor-pointer">1,455 calls</span>)
                                                                                </span>
                                                                            </OverlayTrigger> */}
                                                                    </span>
                                                                </span>
                                                            })}

                                                            {this.state.callInformation.phoneBookEmails.length > 0 && <span className="d-block mb-5 bb-1-dotted pt-5 pb-5">
                                                                {this.state.callInformation.phoneBookEmails.map((email) => {
                                                                  return <>
                                                                        <span className="text-500">Email:</span>
                                                                        <span className="float-end">
                                                                            <a href="mailto:chris@liquid11.co.uk" className="text-cta underline">{email}</a>

                                                                            {/* <OverlayTrigger placement="top" overlay={<Tooltip>View all calls from this number</Tooltip>}>
                                                                                <span className="ml-5">
                                                                                    (<span className="underline text-cta cursor-pointer">1,455 calls</span>)
                                                                                </span>
                                                                            </OverlayTrigger> */}
                                                                        </span>
                                                                    </>
                                                                })}
                                                            </span>}
                                                            {this.state.callInformation.phoneBookCompany !== null && this.state.callInformation.phoneBookCompany !== '' && this.state.callInformation.phoneBookCompany !== undefined && <span className="d-block mb-5 bb-1-dotted pt-5 pb-5">
                                                                <span className="text-500">Company:</span>
                                                                <span className="float-end">
                                                                    {this.state.callInformation.phoneBookCompany}
                                                                </span>
                                                            </span>}
                                                            <span className="d-block mb-5 bb-1-dotted pt-5 pb-5">

                                                                <OverlayTrigger placement="top" overlay={<Tooltip>Automatically recognise callers' numbers & greet them by name when they call from a mobile, e.g "Hi Chris..."</Tooltip>}>
                                                                    <span className="text-500">AI Greeting <span className="icon icon-info2 text-dark-lighter text-xs"></span></span>
                                                                </OverlayTrigger>
                                                                <span className="float-end">
                                                                    {this.state.callInformation.phoneBookAiGreeting ? 'Yes' : 'No'}
                                                                </span>
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <>
                                                        <p className="text-dark underline mt-20 text-sm cursor-pointer show-1200px text-center mt-40" onClick={() => this.setState({ showContactSidebar: false })}>Cancel &amp; close</p>
                                                    </>
                                                </>
                                            : <>
                                                </>
                                        }

                                    </>
                                    }

                                {this.state.callInformation !== undefined && this.state.callInformation !== null &&
                                    <div className={`mt-20 mb-20 pt-20 ${this.state.addingNewContactbookEntry || this.state.editingContactbookEntry ? 'bt-1' : ''}`}>
                                        <p className="text-500 text-dark-lighter text-uppercase mb-20 text-xs">
                                            Notes about this call
                                        <OverlayTrigger placement="top" overlay={<Popover>Once you add a note about the call, later you will be able to find it using the Search option.</Popover>}>
                                            <span className="icon icon-info2 text-dark-lighter pl-5"></span>
                                        </OverlayTrigger>
                                        </p>

                                        <>
                                            <textarea rows="5" onChange={e => this.setState({ newNote: e.target.value })} value={(this.state.newNote === undefined || this.state.newNote === null) ? this.state.callInformation.note : this.state.newNote} className="form-control" placeholder="eg. Caller wanted to confirm the delivery date of his package." />
                                            <div className="text-center">
                                                <Button variant="cta" size="sm" className="mt-20" onClick={() => this.addOrUpdateNotePressed()}>{(this.state.callInformation.note !== '' && this.state.callInformation.note !== undefined) ? 'Update note' : 'Add note'}</Button>
                                            </div>
                                        </>
                                    </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </>
  }

  componentWillUnmount () {
    if (this.state) {
      const id = this.state.intervalId
      clearInterval(id)
    }
  }

  componentDidMount () {
    document.querySelector('.calls-sidebar, .close-call').addEventListener('click', function () {
      document.querySelector('.calls-about-user').classList.remove('show')
    })

    fetchHelper.getJson('/api/CallStats/GetAdvancedSearchDropdownValues').then((response) => {
      const dropdownData = response.data.sbfNumberUsersAndTeams

      for (let i = 0; i < dropdownData[1].options.length; i++) {
        const values = dropdownData[1].options[i].label.split('{t}')
        dropdownData[1].options[i].label = <>{values[0]} <span className='text-sm d-block number-description'>{values[1]}</span></>
      }

      this.setState({
        advancedSearchCallMadeToValues: response.data.sbfNumberUsersAndTeams,
        advancedSearchCallMadeByValues: response.data.sbfNumberUsersAndTeams
      })
    })

    if (!sidebarToggle === null) {
      var sidebarToggle = document.getElementById('sidebarToggle')
      const aside = document.getElementById('aside')

      sidebarToggle.addEventListener('click', function () {
        aside.style.left = (aside.dataset.toggled ^= 1) ? '700' : '-380px'
        sidebarToggle.style.left = (sidebarToggle.dataset.toggled ^= 1) ? '380px' : '0'
      })
    }

    this.closeAdvancedSearchAndSetToDefault()

    const context = this

    const intervalId = setInterval(() => this.loadNextHundredRecordsIfImageInView(), 1000)

    this.setState({
      intervalId
    })
  }

  saveNewContactPressed () {
    const name = this.state.newContactName.trim()
    const company = this.state.newContactCompany.trim()
    const number = this.state.newContactNumber
    const aiGreetingOn = this.state.newContactAiGreeting ?? false

    const url = '/api/Contacts/SaveNewContactBookContact'

    const newContact = {
      company,
      description: name,
      number,
      ignorePersonalizedIntro: !aiGreetingOn
    }

    fetchHelper.postJson(url, newContact).then(res => {
      if (res.data === true) {
        const url = '/api/CallStats/GetCallInformation/' + this.state.selectedListEntry.callId + '/' + (this.state.selectedListEntry.isInbound === true ? 1 : 0) + '/'
        fetchHelper.getJson(url).then((response) => {
          this.setState({
            callInformation: response.data,
            addingNewContactbookEntry: false,
            editingContactbookEntry: false
          })
        })

        const newCalls = [...this.state.calls]

        for (let i = 0; i < newCalls.length; i++) {
          if (newCalls[i].numberOrName.trim() === number) {
            newCalls[i].isName = true
            newCalls[i].numberOrName = name
          }
        }
        this.setState({
          newContactName: '',
          newContactCompany: '',
          newContactNumber: '',
          ignorePersonalizedIntro: false,
          calls: newCalls,
          addingNewContactbookEntry: false
        })
      }
    }).catch(function (error) {
      console.error(error)
    })
  }

  saveContactChangesPressed () {
    const name = this.state.newContactName.trim()
    const numbers = this.state.editNumbers
    const aiGreetingOn = this.state.newContactAiGreeting ?? false

    const data = {
      name,
      contacts: []
    }

    for (let x = 0; x < numbers.length; x++) {
      data.contacts.push({
        contactId: 0,
        dataType: 1,
        type: 3,
        value: numbers[x]
      })
    }

    const url = `/api/Contacts/UpdateContactBookEntry/${this.state.callInformation.phoneBookContactId}`
    fetchHelper.postJson(url, data).then(res => {
      const url = '/api/CallStats/GetCallInformation/' + this.state.selectedListEntry.callId + '/' + (this.state.selectedListEntry.isInbound === true ? 1 : 0) + '/'
      fetchHelper.getJson(url).then((response) => {
        this.setState({
          callInformation: response.data,
          addingNewContactbookEntry: false,
          editingContactbookEntry: false
        })
      })

      fetchHelper.postJson('api/contacts/SetCustomerGreetingEnabled/' + this.state.callInformation.phoneBookContactId + '/' + aiGreetingOn, {}).then((res) => {

      })
    }).catch(function (error) {
      console.error(error)
    })
  }
}

export default connect(
  state => {
    return ({
      isLoggedIn: isLoggedIn(state)
    })
  },
  {}
)(AllCallsPage)
