import React from 'react'
import Countdown from 'react-countdown'
import LoadingNotification from '../../components/LoadingNotification'
import { Button, Modal, Form, FloatingLabel } from 'react-bootstrap'
import { Helmet } from 'react-helmet'
import { connect } from 'react-redux'
import { fetchHelper } from '../../helpers/fetchHelper'
import { handleNotificationChange, handleShowCallbackForm, handleCallRecordingAllEnableChange, handleShowPaymentOptionsModal, handleDeliveryNameChange, handleDeliveryAdd1Change, handleDeliveryAdd2Change, handleDeliveryTownChange, handleDeliveryCountyChange, handleDeliveryPcodeChange, handleDeliveryNoteChange } from '../../store/SBF'
import { ProductIds, productBillingOption, validationStates, billingPeriod } from '../../constants/Constants'
import { getProductPrice } from '../../store/Home'
import { isLoggedIn } from '../../selectors'
import { calculateVat, calculateRemainingMonth } from '../../helpers/utils'
import DisplayMainFooter from '../../components/DisplayMainFooter'
import Logos from '../../components/ClientLogos'
import TermsAndConditions from '../../Text/TermsAndConditions'
import CarouselWidget from '../../components/Reviews/CarouselWidget'
import StatsBanner from '../../components/StatsBanner'
import ChoosePayment from '../../components/purchase/ChoosePayment'
import moment from 'moment'
import Toggle from 'react-bootstrap-toggle'
import AddressLookup from '../../components/AddressLookup'
import { Link } from 'react-router-dom'
import * as fromHome from '../../store/Home'

const _ = require('lodash')

class TranscriptionUpsell extends React.Component {
  state = {
    isPurchaseInProgress: false
  }

  constructor(props) {
    super(props)
  }

  render() {
    let peopleAddedThis = 0;

    if (localStorage.getItem('peopleAdded')) {
      peopleAddedThis = localStorage.getItem('peopleAdded');
    } else {
      peopleAddedThis = ((Math.random() * 15) + 2).toFixed(0);
      localStorage.setItem('peopleAdded', peopleAddedThis);
    }

    return <><DisplayMainFooter />
      <Helmet>
        <body className={'body-simple bg-dull'} />
        <title>Enable Call Transcription On Your Account!</title>
        <style type="text/css">{`
						.button-bar {
							background: #fff;
							border-top: #ddd 1px solid;
							padding: 20px;
							position: fixed;
							bottom: 0;
							left: 0;
							right: 0;
							z-index: 900;
							text-align: center;
							padding-bottom: 10px;
						}
						.background {
							background-position: 95% 55% !important;
							background-size: 40% !important;
						}
						.background.smaller {
							background-position: 95% 95% !important;
							background-size: 40% !important;
						}
					}
					`}</style>
      </Helmet>

      <div className="p-50 bg-light">
        <div className="container p-xs-0">
          <div className="row mb-50 justify-content-center">
            <div className="col-md-10  text-center">
              <span className="bg-secondary p-10 pr-50 pl-50 pl-xs-10 pr-xs-10 text-light text-lg text-500 text-uppercase text-lightest text-xs-sm">
                Highly recommended feature
              </span>
              <p className="text-lg text-400 mt-30">
                <em>Discover The <span className="text-cta text-500"><u>Powerful Feature</u></span> No Company Should Be Without...</em>
              </p>
              <h1 className="text-600">
                Get Call Transcription - <em className="text-cta"><u>Including <br className="d-none d-xs-block" />Conversation Search & Keyword Triggers!</u></em>
              </h1>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-lg-7 col-md-8 col-sm-12">
              <div className="bg-dull p-50 p-sm-30 p-xs-20 brad-20 pr-100 pl-100 pr-sm-50 pl-sm-50 pr-xs-20 pl-xs-20 clearfix">
                {this.state.hasTranscription
                  ? <>
                    <p className="text-400 text-uppercase text-lg mb-10 mt-0">Good news!</p>
                    <h3 className="text-500">
                      You've already got Transcription enabled on your account.
                    </h3>
                  </>
                  : this.state.finished
                    ? <>
                      <h3 className="text-500 mt-30">
                        All done!
                      </h3>
                      <p className="text-400 lead mb-10 mt-0">Congratulations, you can now start transcribing your calls.</p>
                      <p className="text-md mt-30">
                        We've successfully added Transcription to your account for you!
                      </p>
                      <p className="mt-30">
                        <Button variant="cta" size="lg" className="pl-100 pr-100 pl-xs-20 pr-xs-20" onClick={() => this.props.history.push('/customer/dashboard')}>Go to my dashboard</Button>
                      </p>
                    </>
                    : <>
                      <div className="row mt-30">
                        <div className="col-md-10 col-sm-10">
                          <p className="text-400 text-uppercase text-lg mb-10 mt-0">Transcribing & searching your calls has just got easier!</p>
                          <h3 className="text-600">
                            Transcribe &amp; Revise With <span className="text-cta">Conversation Search</span>
                          </h3>
                          <p className="text-md mb-30 mt-10">
                            <span className="text-uppercase text-cta text-500 underline mr-10">
                              <em>Just 5p per min.</em>
                            </span>
                            And, no fixed monthly fees.
                          </p>
                        </div>
                      </div>

                      <p className="text-lg text-500 mt-0">
                        Get Call Transcription, Keyword Triggers <br className="d-none d-xs-block" />&amp; Real-time Alerts
                      </p>
                      <p className="text-500">Use our Conversation Search feature to transform &amp; elevate your business.</p>
                      <p>Gone are the days when you and your staff have to make notes during each call to ensure important details are taken down. With CallSearch Pro, every word is recorded and transcribed into easy-to-read text.
                      </p>
                      <p>
                        And it doesn&apos;t just stop there. Now you can easily search for specific words or phrases that have been said, or not said.
                      </p>
                      <p>
                        Plus, you can set custom alerts for specific words or phrases mentioned or omitted from conversations.
                      </p>
                      <p>
                        Automatically transcribe your call recordings into readable, searchable text.
                      </p>
                      <span className="d-block text-md">
                      </span>
                      <ul className="fancy text-start cta text-400 mt-20 pl-40">
                        <li className="pb-10">
                          <strong>Boost staff productivity,</strong> by ensuring employees spend less time on admin & more time on engaging with customers.
                        </li>
                        <li className="pb-10">
                          <strong>Easily analyse data,</strong> &amp; keep up-to-date with your customer's needs, preferences & pain points to deliver consistent & exceptional customer service.
                        </li>
                        <li className="pb-10">
                          <strong>Enhance your decision-making,</strong> by uncovering valuable insights within your call data to optimise your communication &amp; marketing strategies.
                        </li>
                        <li className="pb-10">
                          <strong>Remain compliant,</strong> by easily checking that staff are passing on essential information or terms &amp; conditions to your customers.
                        </li>
                        <li className="pb-10">
                          <strong>Simplify training & quality control,</strong> by revisiting past calls to highlight best practices, identify areas for improvement, or educate new team members.
                        </li>
                      </ul>
                      <em className="d-block text-sm mt-30 mb-0 text-400">With no fixed monthly charges and a budget-friendly rate of <span className="underline">just 5p per minute</span> of transcribed calls, Conversation Search is the perfect addition to your call management system.</em>

                      <div className="mb-20 mt-30">
                        {this.state.isPurchaseInProgress
                          ? <LoadingNotification loadingText={'Please wait...'} textColor="text-dark" isLoading={true} width={50} height={50} />
                          : <>
                            <Button variant="cta" size="lg" className="btn-block" onClick={() => this.handlePurchase()}>Enable Call Transcription Now</Button>
                            <p className="text-sm mb-0 p-0 text-center mt-10">
                              {peopleAddedThis} people added <u>Call Transcription</u> in the last hour
                            </p>
                          </>
                        }
                      </div>

                      <div className="button-bar d-block d-xs-none">
                        {this.state.isPurchaseInProgress
                          ? <LoadingNotification loadingText={'Please wait...'} textColor="text-dark" isLoading={true} width={50} height={50} />
                          : <>
                            <Button variant="cta" size="lg" className="btn-block-sm" onClick={() => this.handlePurchase()}>Enable Call Transcription Now</Button>

                            <p className="text-sm mb-0 p-0 text-center mt-10">
                              {peopleAddedThis} people added <u>Call Transcription</u> in the last hour
                            </p>
                          </>
                        }
                      </div>

                    </>
                }
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-dull p-50 text-center">
        <sup>*</sup> Standard terms &amp; conditions apply. <Button variant="link" className="text-dark underline" onClick={() => this.setState({ showTsAndCs: true })}>Click here to find out more</Button>
      </div>

      <div className="bg-light pt-50 pb-50">
        <h3 className="text-center text-500 mb-20 text-lg">Proud to work with leading UK businesses</h3>
        <Logos style="light" />
      </div>

      <div className="full-width bg-dull">
        <div className="container pl-xs-0 pr-xs-0">
          <CarouselWidget />
        </div>
      </div>

      <div className="full-width full-secondary p-20">
        <div className="row">
          <div className="col-md-12">
            <h3 className="text-center text-lightest mt-10 text-100 text-xl">
              <StatsBanner />
            </h3>
          </div>
        </div>
      </div>

      <div className="full-width bg-light">
        <div className="container">
          <div className="row">
            <div className="col-md-12 text-center">
              <h3 className="text-500">SwitchboardFREE</h3>
              <h6 className="text-400">The UK's favourite call management system</h6>
              <p className="text-400">Instant activation  &bull;  No long-term contracts  &bull;  Award-winning UK support</p>
            </div>
          </div>
        </div>
      </div>

      <Modal className="terms-modal" show={this.state.showTsAndCs} onHide={e => this.setState({ showTsAndCs: false })}>
        <Modal.Header closeButton>
          <Modal.Title className="text-500">Terms &amp; Conditions</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <TermsAndConditions />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={e => this.setState({ showTsAndCs: false })}>Close</Button>
        </Modal.Footer>
      </Modal>

      <Modal size="sm" show={this.state.showLogin}>
        <Modal.Header>
          <Modal.Title className="mx-auto">Log into your account</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-md-12 text-center pb-30">
              <form name="form" className="text-left" onSubmit={e => this.props.login({
                userName: this.props.userName,
                password: this.props.password,
                event: e,
                history: this.props.history,
                persist: document.getElementById('persistLogin').checked,
                route: `/get/call-transcription?t=${this.state.token}`
              })}>
                <div className="row">
                  <div className="col-xs-12">
                    <p className="lead text-400">Oops, it looks like your token has expired, or is not valid. Please log into your account below.</p>
                  </div>
                  <div className="col-xs-12">
                    <label>Email</label>
                    <input className="form-control" placeholder="Your login email..." type='email' value={this.props.userName} onChange={e => this.props.updateUsername(e.target.value)} />
                  </div>
                  <div className="col-xs-12 mt-20">
                    <label>Password</label>
                    <input className="form-control" placeholder="Your password..." type='password' value={this.props.password} onChange={e => this.props.updatePassword(e.target.value)} />
                  </div>
                </div>
                <div className="pt-10 pb-10">
                  <div className="col-xs-6 pl-0">
                    <input type="checkbox" id='persistLogin' defaultChecked={true} /> <label>Remember</label>
                  </div>
                  <div className="col-xs-6 text-right pr-0">
                    <Link to={'/forgot-password'} className="text-xs text-cta">Forgot password?</Link>
                  </div>
                </div>
                <div className="text-center pt-30">
                  {this.props.loggingIn && <p>Now logging you in, please wait ...</p>}

                  {this.props.loginFailed && <p className="alert alert-danger">There has been an error logging in, please check your username and password and try again. If that doesn't help, try <Link to={'/forgot-password'}>resetting your password</Link>.</p>}

                  <button className="btn btn-cta btn-lg btn-mobile-block">Log In</button>

                  <p className="lead text-center bt-1 mt-30 pt-30">Don&apos;t have an account with us yet?</p>
                  <p className="text-center"><Link to="/get-started" className="btn btn-secondary btn-sm">Create an account</Link></p>
                </div>
              </form>
            </div>
          </div>
        </Modal.Body>
      </Modal>

    </>
  }

  handlePurchase() {
    const context = this
    const url = `/api/AccessToken/EnableTranscription?t=${context.state.token}`

    context.setState({ isPurchaseInProgress: true }, () => {
      fetchHelper.getJson(url)
        .then(res => {
          if (res.data && res.data === true) {
            context.setState({ finished: true })
          }
        }).catch(function (error) {
          context.setState({ isPurchaseInProgress: false })
          console.error(error)
        })
    })
  }

  componentWillMount() {
    const context = this
    let token = localStorage.getItem('accessToken')
    const query = new URLSearchParams(this.props.location.search)
    if (!token && query) {
      // auth
      token = query.get('t')
    }

    context.setState({ token })
  }

  componentDidMount() {
    const context = this

    if (!context.state.token && !this.props.isLoggedIn) {
      // Login
      this.setState({ showLogin: true })
    } else if (context.state.token) {
      fetchHelper.getJson(`/api/AccessToken/CheckClientHasTranscription?t=${context.state.token}`)
        .then(res => this.setState({ hasTranscription: res.data }))
        .catch(err => console.error(err))
    } else if (!this.props.isLoggedIn) {
      // Login
      this.props.history.push('/login?redirect=/get/call-transcription')
    }
  }
}

export default connect(
  state => {
    return {
      isLoggedIn: isLoggedIn(state),
      productDetails: state.home.productDetails.products,
      services: state.sbfApp.enabledServices,
      userDetails: state.sbfApp.authentication,
      userName: state.home.login.username,
      password: state.home.login.password,
      loggingIn: state.home.login.loggingIn,
      loginFailed: state.home.login.loginFailed
    }
  },
  {
    login: fromHome.handleLogin,
    updateUsername: fromHome.updateUsername,
    updatePassword: fromHome.updatePassword,
    notify: handleNotificationChange,
    handleShowCallbackForm,
  }
)(TranscriptionUpsell)
