import { fetchHelper } from '../helpers/fetchHelper'
import LocalStorageHelper from '../helpers/LocalStorageHelper'
import { type UserAuthenticationDto } from '../models/SignupTypes'

export enum userService {
  login,
  logout,
  // register,
  // getAll,
  // getById,
  // update,
  // delete: _delete
};

export async function login (username: string, password: string, persist: boolean) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ username, password })
  }

  return await fetchHelper.postJson('api/users/authenticate', { Username: username, Password: password })
    .then((res: any) => {
      const user = res.data

      if (user === null || user === undefined) {
        return null
      }
      fetchHelper.init(user.token)
      // login successful if there's a jwt token in the response
      if (user && user.token && persist) {
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        LocalStorageHelper.setItem<UserAuthenticationDto>('user', user)

        // Identify in LO
        window.LOQ = window.LOQ || []
        window.LOQ.push(['ready', async LO => {
          await LO.$internal.ready('visitor')
          LO.visitor.identify({
            email: user.email,
            name: `${user.fName} ${user.sName}`,
            id: `${user.clientId}`
          })
        }])
      }

      if (user && user.token) {
        LocalStorageHelper.setItem<boolean>('billingRestricted', user.isBillingRestricted)
      }

      if (!persist) {
        LocalStorageHelper.removeItem('user')
        LocalStorageHelper.removeItem('userDetails')
      }

      return user
    })
}

export function logout () {
  // Move data to session storage
  sessionStorage.setItem('user', JSON.stringify(LocalStorageHelper.getItem<UserAuthenticationDto>('user')))

  LocalStorageHelper.removeItem('user')
  LocalStorageHelper.removeItem('userDetails')
  return true
}

async function handleResponse (response) {
  await new Promise<void>((resolve, reject) => {
    if (response.ok) {
      // return json if it was returned in the response
      const contentType = response.headers.get('content-type')

      if (!String.prototype.includes) {
        String.prototype.includes = function (search, start) {
          'use strict'
          if (typeof start !== 'number') {
            start = 0
          }

          if (start + search.length > this.length) {
            return false
          } else {
            return this.indexOf(search, start) !== -1
          }
        }
      }

      if (contentType && contentType.includes('application/json')) {
        response.json().then(json => { resolve(json) })
      } else {
        resolve()
      }
    } else {
      // return error message from response body
      response.text().then(text => { reject(text) })
    }
  })
}

async function handleError (error) {
  return await Promise.reject(error && error.message)
}
