import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import React from 'react'
import { Button, Modal } from 'react-bootstrap'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import LoadingNotification from '../../components/LoadingNotification'
import { PaymentProviders, stripePublicKey, MultiNumberPrice } from '../../constants/Constants'
import { fetchHelper } from '../../helpers/fetchHelper'
import LocalStorageHelper from '../../helpers/LocalStorageHelper'
import { getFirstNameFromName, getLastNameFromName } from '../../helpers/utils'
import {
  type Basket,
  type GoCardlessBillingRequestDataDto,
  type GoCardlessFlowResultDto,
  type PaymentSuccessDto,
  type ReduxState,
  type SignupProfile,
  type StripeIntentDto,
  type StripeIntentResult,
  type StripeSecretDto,
  type TransactionProductsDto
} from '../../models/SignupTypes'
import { isLoggedIn } from '../../selectors'
import {
  handleHidePaymentOptionsModal,
  handleHidePaymentPostponedModal, handleShowPaymentOptionsModal
} from '../../store/SBF'
import StripeCheckoutForm from './StripeCheckoutForm'

interface ChoosePaymentState {
  billingAdd: any
  total: number
  loading: boolean
  route: string
  provider: number
  stripeSecret: string
  stripeError: boolean
  gettingSecret: boolean
}

interface ChoosePaymentProps {
  address: any
  customerEmail: any
  ipAddress: any
  loggedIn: any
  mc_clientid: any
  mobile: any
  name: any
  newAgreement: boolean
  purchaseData: string
  showPaymentOptionsModal: boolean
  showPaymentOptionsModelFromParent: boolean
  total: number
  email: string
  phone: string
  clientId: number
  isLoggedIn: boolean
  showPaymentPostponedModal: boolean
  handleHidePaymentOptionsModal: () => void
  handleHidePaymentPostponedModal: () => void
  handleShowPaymentOptionsModal: () => void
  handleParentHideModal: () => void
  paymentSuccess: () => void,
  paymentFailure: () => void,
  basket: Basket
  mandate: boolean
  company: string
  inline?: boolean | undefined
  debtGuid?: string | undefined
  signupKey?: string | undefined
  description?: string | undefined
  isInDebt?: boolean | undefined
  isRedirect?: boolean | undefined
  isNewCustomer: boolean
  hardware?: boolean | undefined
  returnRoute: string
  failed?: boolean | undefined
}

class ChoosePayment extends React.Component<ChoosePaymentProps, ChoosePaymentState> {
  constructor(props) {
    super(props)
  }

  state: ChoosePaymentState = {
    route: '',
    billingAdd: undefined,
    loading: false,
    total: 0,
    provider: PaymentProviders.Stripe,
    stripeSecret: '',
    stripeError: false,
    gettingSecret: false
  }

  handleRenderStripe() {
    const stripePromise = loadStripe(stripePublicKey, { betas: ['paypal_pm_beta_1'] })

    const stripeOptions = {
      clientSecret: this.state.stripeSecret
    }

    return (
      <>
        {this.state.stripeError
          ? <p className="text-danger text-400 text-sm">
            Something went wrong.<br />
            Please try an alternative payment method, or contact our UK support team on <a href="tel:02031891213">0203 189 1213</a>.
          </p>
          : <Elements stripe={stripePromise} options={stripeOptions}>
            <StripeCheckoutForm
              address={this.props.address}
              name={this.props.name}
              tel={this.props.phone}
              email={this.props.email}
              amount={(this.props.total > 0.3 || this.props.newAgreement) ? this.props.total : 0.30}
              postCode={this.props.address?.pcode}
              hardware={this.props.hardware}
              handlePostPayment={(transId, pmId) => { this.handleChooseStripe(transId, pmId) }}
              secret={this.state.stripeSecret}
            />
          </Elements>
        }
      </>
    )
  }

  handleRenderPaymentOptions() {
    return (
      <>
        {this.props.hardware === true
          ? <>
            {(this.state.stripeSecret || this.state.stripeError)
              ? this.handleRenderStripe()
              : <LoadingNotification className="mt-30" message={'Loading, please wait...'} isLoading={true} />}
          </>
          : <>
            <div
              className={`radio-div mr-10 p-0 mr-20 btn-block brad-10 bg-dull cursor-pointer b-1 mb-20 ${this.state.provider === PaymentProviders.Stripe
                ? 'active'
                : ''
                }`}
              onClick={() => {
                this.setState({
                  provider: PaymentProviders.Stripe
                })
              }
              }
            >
              <span className="p-20 pl-xs-10 pr-xs-10 d-block">
                <span className="radio-circle">
                  <span className="radio-checked"></span>
                </span>
                <span className="radio-text d-inline-block pt-5">
                  <span className="text-500 pr-10">Credit / Debit Card</span>
                </span>
                <span className="text-end float-end pt-5">
                  <img src="/images/credit-cards/visa.png" className="pr-10" width="50"
                    alt="Visa" />
                  <img src="/images/credit-cards/mastercard.png" className="pr-10" width="50"
                    alt="Mastercard" />
                  <span className="d-none d-sm-inline-block">
                    <img src="/images/credit-cards/maestro.png" className="pr-10" width="50"
                      alt="Maestro" />
                    <img src="/images/credit-cards/visaElectron.png" className="pr-10"
                      width="50" alt="Visa Electron" />
                  </span>

                </span>
              </span>

              {this.state.provider === PaymentProviders.Stripe &&
                <div className="bg-light brad-t-0 p-20 brad-b-10 p-xs-10">
                  <div className="row">
                    <div className="col-sm-12 col-md-7">
                      <h3 className="text-lg text-500 mb-0 mt-20">Instant Payments With</h3>
                      <h2 className="text-cta text-xl text-500 mb-10">Stripe Card Payments</h2>
                      <p>Simply enter your credit/debit card details below.</p>
                    </div>
                    {this.props.signupKey &&
                      <div className="col-sm-12 col-md-5">
                        <div className="brad-10 bg-dull p-20 mb-xs-20" style={{ backgroundColor: '#d6e8ef' }}>
                          <ul className="unstyled text-400 ml-0 pl-0 mt-10 text-sm">
                            <li className="mb-10 text-sm">
                              <span className="icon icon-checkmark-circle text-success mr-10" />
                              <span className="text-500">Instant account setup.</span> Start using your new number in minutes.
                            </li>
                          </ul>
                        </div>
                      </div>
                    }

                    <div className="col-12">
                      <div className="bg-dull brad-10 p-30 text-center p-xs-10">
                        {(this.state.stripeSecret || this.state.stripeError)
                          ? this.handleRenderStripe()
                          : <LoadingNotification className="mt-30" message={'Loading, please wait...'} isLoading={true} />}

                        {this.props.total !== undefined &&
                          this.props.total > 0 &&
                          this.props.total < 0.3 && (
                            <p className="text-xs mt-10 mb-0 text-center">
                              Please note: Credit/Debit Card payments are subject to a minimum charge of &pound;0.30
                            </p>
                          )}

                      </div>
                    </div>

                  </div>
                </div>
              }
            </div>

            <div
              className={`radio-div mr-10 p-0 mr-20 btn-block brad-10 bg-dull cursor-pointer b-1 ${this.state.provider === PaymentProviders.GoCardless
                ? 'active'
                : ''
                }`}
              onClick={() => {
                this.setState({
                  provider: PaymentProviders.GoCardless
                })
              }
              }
            >
              <span className="p-20 pl-xs-10 pr-xs-10 d-block">
                <span className="radio-circle">
                  <span className="radio-checked"></span>
                </span>
                <span className="radio-text d-inline-block pt-5">
                  <span className="text-500 pr-10">Direct Debit</span>
                </span>
                <span className="text-end float-end pt-5">
                  <img src="/images/credit-cards/direct-debit.png" className="pr-10"
                    height="25" alt="Direct Debit" />
                </span>
              </span>

              {this.state.provider === PaymentProviders.GoCardless &&
                <div className="bg-light brad-t-0 p-20 brad-b-10 p-xs-10">
                  <div className="row">
                    <div className="col-sm-12 col-md-7">
                      <h3 className="text-lg text-500 mb-0 mt-20">Instant Payments With</h3>
                      <h2 className="text-cta text-xl text-500 mb-10">GoCardless Direct Debits</h2>
                      <p>Simply follow the instructions to setup an instant payment and Direct Debit agreement with SwitchboardFREE.</p>
                      {this.props.total !== undefined &&
                        this.props.total > 0 &&
                        this.props.total < 1 && (
                          <p className="text-xs mt-10 mb-0">
                            Please note: Direct Debit payments are<br className="hidden-xs" />subject
                            to a minimum charge of &pound;1
                          </p>
                        )}
                    </div>
                    <div className="col-sm-12 col-md-5">
                      <div className="brad-10 bg-dull p-20" style={{ backgroundColor: '#d6e8ef' }}>
                        <ul className="unstyled text-400 ml-0 pl-0 mt-10 text-sm">
                          {this.props.signupKey &&
                            <li className="mb-10 text-sm">
                              <span className="icon icon-checkmark-circle text-success mr-10" />
                              <span className="text-500">Instant account setup.</span> Start using your new number in minutes.
                            </li>
                          }
                          <li className="mb-10 text-sm">
                            <span className="icon icon-checkmark-circle text-success mr-10" />
                            <span className="text-500">Uninterrupted service.</span> No need to update expired cards etc.
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="col-12 text-center">
                      {this.handleRenderContinueButton()}
                    </div>
                  </div>
                </div>
              }
            </div>
          </>
        }

      </>
    )
  }

  handleRenderContinueButton() {
    let buttonText = 'Payment'

    switch (this.state.provider) {
      case PaymentProviders.GoCardless:
        buttonText = 'Direct Debit'
        break
      case PaymentProviders.Stripe:
        buttonText = 'Card Payment'
        break
    }

    return (
      <div className="text-center">
        <Button
          variant="cta"
          className="mb-10 mt-20"
          onClick={() => { this.handleContinuePayment() }}>
          {this.props.total === 0 ? 
            <>
            Update Payment Details
            </> :
            <>
              Continue with {buttonText} <span className="icon icon-arrow-right8 text-sm ml-10"></span>
            </>
            }
        </Button>
      </div>)
  }

  render() {
    // this will be updated when the props changes as changing the props triggers a re-render
    let total = (this.props.total > 0.3 || this.props.newAgreement) ? this.props.total : 0.30
    if (total === undefined && LocalStorageHelper.hasItem('addedToAccount')) total = LocalStorageHelper.getItem<number>('addedToAccount').value ?? 0

    // don't render if total === 0
    if (!this.props.showPaymentOptionsModal && Number(total) === 0 && !this.props.newAgreement && !this.props.inline) {
      return <></>
    } else {
      return (
        <>
          {this.props.inline
            ? <>
              {this.handleRenderPaymentOptions()}
            </>
            : <Modal className="w-900px" backdrop="static" show={this.props.showPaymentOptionsModelFromParent || this.props.showPaymentOptionsModal}>
              <Modal.Header>
                <Modal.Title className="text-500 h4 mt-10 mx-auto">Choose your payment method</Modal.Title>
              </Modal.Header>
              <Modal.Body className="p-30 pb-50 p-xs-10">
                {this.props.failed === true &&
                  <p className="alert alert-danger">
                    There was a problem with your payment.<br />
                    Please try again, or try a new payment method.
                  </p>
                }
                {this.state.loading
                  ? (
                    <LoadingNotification className="mt-30" message={'Processing...'} isLoading={true} />
                  )
                  : (
                    this.handleRenderPaymentOptions()
                  )}
              </Modal.Body>
              <Modal.Footer>
                {this.props.isInDebt === true &&
                  <p className="text-400 text-sm w-100 text-center mb-20">
                    Please note: you currently have an outstanding balance on your account, totaling &pound;{this.props.total} (inc VAT).<br />
                    This amount will be billed when adding a new payment agreement.
                  </p>
                }

                <Button size="sm" variant="link" className="text-darker" onClick={() => {
                  this.props.handleHidePaymentOptionsModal()
                  if (this.props.handleParentHideModal != null) {
                    this.props.handleParentHideModal();
                  }


                }}>
                  Cancel Payment
                </Button>

              </Modal.Footer>
            </Modal>
          }

          <Modal size="sm" className="question-modal" show={this.props.showPaymentPostponedModal}
            onHide={() => { this.props.handleHidePaymentPostponedModal() }}>
            <Modal.Body className="p-30 text-center">
              <div className="icon-wrapper mt-20 mb-20 icon-success">
                <span className="icon icon-checkmark" />
              </div>
              <p className="lead text-400">We've successfully added your new product.</p>
              {total !== undefined
                ? <p>As you do not have an active credit card agreement with us, we've
                  added &pound;{total} to your next monthly payment.</p>
                : <p>As you do not have an active credit card agreement with us, we've added your charge
                  to your next monthly payment.</p>}
              <p>
                If you have any questions,{' '}
                <Link to={'/contact'} className="underlined">
                  please get in touch
                </Link>{' '}
                with our UK-based support team.
              </p>
            </Modal.Body>
            <Modal.Footer className="text-center">
              <Link className="btn btn-cta w-100" to="/customer/profile/billing">
                Add a credit card
              </Link>
              <Button variant="link" className="cursor-pointer underline text-sm mt-20"
                onClick={() => { this.props.handleHidePaymentPostponedModal() }}>
                Close this window
              </Button>
            </Modal.Footer>
          </Modal>
        </>
      )
    }
  }

  handleContinuePayment() {
    switch (this.state.provider) {
      case PaymentProviders.GoCardless:
        this.handleChooseDirectDebit()
        break
    }
  }

  handleGa4Tracking() {
    const context = this
    const firstName = getFirstNameFromName(context.props.name)
    const lastName = getLastNameFromName(context.props.name)

    const productsArr: TransactionProductsDto[] = [];
    if (context.props.signupKey || (!context.props.debtGuid && context.props.basket)) {
      productsArr.push(
        {
          item_name: `${context.props.basket.package?.name} Plan`,
          quantity: 1,
          price: context.props.basket.package?.rental ?? 0
        }
      )

      if ((context.props.basket.package?.users?.qty ?? 0) > 1) {
        // Extra users 
        productsArr.push(
          {
            item_name: 'Extra Users',
            quantity: context.props.basket.package!.users.qty,
            price: context.props.basket.package?.rental ?? 0
          }
        )
      }

      if (context.props.basket.numbers.length > 1) {
        // Extra numbers
        productsArr.push(
          {
            item_name: 'Extra Phone Numbers',
            quantity: context.props.basket.numbers.length - 1,
            price: MultiNumberPrice
          }
        )
      }

      if (context.props.basket.numbers.filter(f => f.ddipremium > 0)) {
        // Bought some premium numbers
        context.props.basket.numbers.filter(f => f.ddipremium > 0).forEach((ddi) => productsArr.push(
          {
            item_name: ddi.ddipremium > 5 ? 'Gold Number' : 'Silver Number',
            quantity: 1,
            price: ddi.ddipremium
          }
        ))
      }

      if (context.props.basket.hardware.filter(f => f.amount > 0)) {
        // Bought some phones
        context.props.basket.hardware.filter(f => f.amount > 0).forEach((hardware, i) => productsArr.push(
          {
            item_name: hardware.name ?? '',
            quantity: hardware.qty,
            price: hardware.amount
          }
        ))
      }
    }

    if (context.props.signupKey) {
      window.dataLayer.push({
        event: 'signupPaid',
        customerEmail: context.props.email,
        customerFirstName: firstName,
        customerLastName: lastName,
        customerPhone: context.props.phone,
        customerPostcode: context.state.billingAdd ? context.state.billingAdd.postcode : !context.props.address ? '' : context.props.address.pcode,
        transactionTotal: context.props.total,
        transactionId: context.props.signupKey,
        transactionProducts: productsArr
      })
    } else if ((!context.props.debtGuid && context.props.basket)) {
      window.dataLayer.push({
        event: 'newNumberPaid',
        customerEmail: context.props.email,
        customerFirstName: firstName,
        customerLastName: lastName,
        customerPhone: context.props.phone,
        customerPostcode: context.state.billingAdd ? context.state.billingAdd.postcode : !context.props.address ? '' : context.props.address.pcode,
        transactionTotal: context.props.total,
        transactionId: context.props.clientId,
        transactionProducts: productsArr
      })
    }

  }

  componentDidUpdate() {
    if (
      ((this.props.showPaymentOptionsModelFromParent || this.props.showPaymentOptionsModal) || this.props.inline === true) &&
      !this.state.stripeError &&
      ((!this.state.stripeSecret || this.state.stripeSecret === '') && !this.state.gettingSecret) &&
      (this.props.newAgreement || this.props.total > 0)) {
      const dto = LocalStorageHelper.getItem<StripeSecretDto>('stripeSecret')?.value

      if (dto !== null && dto.amount === this.props.total) {
        // We've already got a Stripe Secret
        this.setState({ stripeSecret: dto?.secret ?? '' })
      } else {
        // We don't have a Stripe secret yet
        // Or, the amount has changed
        const stripeData: StripeIntentDto = {
          Amount: Math.trunc(((this.props.total > 0.3 || this.props.newAgreement) ? this.props.total : 0.30) * 100), // Double check we don't have any decimals
          Description: this.props.basket ? `${this.props.basket.package?.name} Plan` : this.props.description !== undefined ? this.props.description : 'New Payment Agreement',
          ClientId: this.props.clientId,
          Email: this.props.email,
          Name: this.props.name,
          Phone: this.props.phone,
          Address: {
            Line1: this.props.address?.add1,
            Line2: this.props.address?.add2,
            City: this.props.address?.add3,
            PostCode: this.props.address?.pcode
          },
          DebtGuid: this.props.debtGuid,
          Hardware: this.props.hardware,
          PaymentData: this.props.purchaseData,
          SignupData: this.props.signupKey
        }

        this.setState({ gettingSecret: true })

        fetchHelper.postJson<StripeIntentResult, StripeIntentDto>('api/Purchase/CreateStripeIntent', stripeData)
          .then(res => {
            if (res.data.success) {
              this.setState({ stripeSecret: res.data.secret, gettingSecret: false })

              // Let's store the secret in localStorage to avoid multiple Payment Intents for the same customer/payment
              const exp = new Date(); exp.setDate(exp.getDate() + 1) // Expire in 24h
              const newDto = {
                amount: (this.props.total > 0.3 || this.props.newAgreement) ? this.props.total : 0.30,
                secret: res.data.secret
              }
              LocalStorageHelper.setItem<StripeSecretDto>('stripeSecret', newDto, exp)
            } else {
              this.setState({ stripeError: true })
            }
          })
      }
    }
  }

  componentDidMount() {

    // this is specific to new accounts signup... if returnRoute is set, once the signup get's redirected
    // to "/signup/checkout/complete" a check is done of this localStorage value and user redirected
    // from there is neccesary.
    LocalStorageHelper.setItem<string>("returnRoute",
      JSON.stringify(this.props.returnRoute !== undefined ? this.props.returnRoute : "customer/dashboard"));


    if (!LocalStorageHelper.getItem<number>("clientId"))
      LocalStorageHelper.setItem<number>("clientId", this.props.clientId);

    // pre-populate stripe interface with billing address information we have for this customer
    if (this.props.isLoggedIn && !this.props.isNewCustomer) {
      fetchHelper.getJson('api/Users/GetClientBillingAddress').then(res => {
        if (res.data) {
          const add = res.data
          this.setState({ billingAdd: add })
        }
      })
    }

    this.setState({ gettingSecret: false })
  }

  handleChooseStripe(transId, paymentMethodId) {
    const context = this
    context.setState({ loading: true }, () => {

      const data: PaymentSuccessDto = {
        desc: context.props.basket ? `${context.props.basket.package?.name} Plan` : context.props.description !== undefined ? context.props.description : 'New Payment Agreement',
        transStatus: 'Y',
        transId,
        futurePayId: paymentMethodId,
        postcode: context.state.billingAdd ? context.state.billingAdd.postcode : !context.props.address ? '' : context.props.address.pcode,
        address1: context.state.billingAdd ? context.state.billingAdd.address1 : !context.props.address ? '' : context.props.address.add1,
        address2: context.state.billingAdd ? context.state.billingAdd.address2 : !context.props.address ? '' : context.props.address.add2,
        town: context.state.billingAdd ? context.state.billingAdd.address3 : !context.props.address ? '' : context.props.address.town,
        mc_newCustomer: this.props.isNewCustomer ? 'Yes' : '',
        mc_ipAddress: this.props.ipAddress,
        mc_paymentProvider: PaymentProviders.Stripe,
        mc_signupKey: context.props.signupKey,
        mc_debtGuid: context.props.debtGuid,
        mc_key: context.props.purchaseData,
        mc_clientid: context.props.clientId,
        amount: (context.props.total > 0.3 || context.props.newAgreement) ? context.props.total : 0.30,
        name: context.props.name,
        email: context.props.email,
        tel: context.props.phone
      }

      LocalStorageHelper.setItem<PaymentProviders>('paymentProvider', PaymentProviders.Stripe)
      LocalStorageHelper.setItem<boolean>('isUpdating', true)

      // push event to Google Tag Manager for sign-up tracking purposes
      this.handleGa4Tracking()

      // Lets send this success to backend
      // And redirect to the confirmation page
      fetchHelper.postJson<boolean, PaymentSuccessDto>('/api/Purchase/PaymentSuccess', data)
        .then(res => {
          if (res.data) {
            if (context.props.isRedirect !== true) {

              // Let's just handle the success here.
              if (context.props.handleHidePaymentOptionsModal != null) {
                context.props.handleHidePaymentOptionsModal();
              }

              if (context.props.handleParentHideModal != null) {
                context.props.handleParentHideModal();
              }

              if (context.props.paymentSuccess != null) {
                context.props.paymentSuccess();
              }


            } else {
              // @ts-expect-error
              window.location = '/signup/checkout/complete'
            }
          } else {
            // Fail
            context.props.paymentFailure();
          }
        })
    })
  }

  handleChooseDirectDebit() {
    const context = this
    context.setState({ loading: true }, () => {
      const userDetails: SignupProfile = {
        name: context.props.name,
        email: context.props.email,
        phone: context.props.phone,
        company: context.props.company
      }

      let url = 'api/Purchase/CreateGoCardlessBillingFlow'
      if (!context.props.total || (context.props.total && context.props.total === 0) || context.props.newAgreement) url = 'api/Purchase/CreateGoCardlessRedirectFlow'
      const firstName = getFirstNameFromName(context.props.name)
      const lastName = getLastNameFromName(context.props.name)
      const data: GoCardlessBillingRequestDataDto = {
        FirstName: firstName,
        LastName: lastName,
        Email: context.props.email,
        Telephone: context.props.phone,
        Company: context.props.company,
        Address: context.state.billingAdd ? `${context.state.billingAdd.address1} ${context.state.billingAdd.address2 ? context.state.billingAdd.address2 : ''}` : !context.props.address ? '' : `${context.props.address.add1} ${context.props.address.add2 ? context.props.address.add2 : ''}`,
        Town: context.state.billingAdd ? (context.state.billingAdd.address3 ? context.state.billingAdd.address3 : '') : !context.props.address ? '' : context.props.address.town ? context.props.address.town : '',
        Postcode: context.state.billingAdd ? context.state.billingAdd.postcode : !context.props.address ? '' : context.props.address.pcode,
        Description: context.props.basket ? `${context.props.basket.package?.name} Plan` : context.props.description !== undefined ? context.props.description : 'New Payment Agreement',
        Mandate: context.props.mandate,
        Amount: context.props.total,
        ClientId: context.props.clientId,
        Data: context.props.purchaseData,
        SignupKey: context.props.signupKey,
        DebtGuid: context.props.debtGuid
      }

      LocalStorageHelper.setItem<SignupProfile>('userDetails', userDetails)
      LocalStorageHelper.setItem<PaymentProviders>('paymentProvider', PaymentProviders.GoCardless)
      LocalStorageHelper.setItem<number>('addedToAccount', context.props.total)
      LocalStorageHelper.setItem<boolean>('isUpdating', true)

      // push event to Google Tag Manager for sign-up tracking purposes
      this.handleGa4Tracking()

      if (context.props.purchaseData) {
        LocalStorageHelper.setItem<string>('purchaseData', JSON.stringify(context.props.purchaseData))
      }

      fetchHelper
        .postJson<GoCardlessFlowResultDto, GoCardlessBillingRequestDataDto>(url, data)
        .then(res => {
          LocalStorageHelper.setItem<string>('goCardlessId', res.data.goCardlessId)

          // @ts-expect-error
          window.location = res.data.redirectUrl
        })
        .catch(err => { console.error(err) })
    })
  }
}

export default connect(
  (state: ReduxState) => {
    const loggedIn = isLoggedIn(state)
    return {
      isLoggedIn: loggedIn,
      showPaymentOptionsModal: state.sbfApp.visibility.showPaymentOptionsModal,
      showPaymentPostponedModal: state.sbfApp.visibility.showPaymentPostponedModal,
      services: state.sbfApp.enabledServices,
      userDetails: state.sbfApp.authentication
    }
  },
  {
    handleHidePaymentOptionsModal,
    handleShowPaymentOptionsModal,
    handleHidePaymentPostponedModal
  }
)(ChoosePayment)
