import React from 'react'
import { Button, Modal, OverlayTrigger, Popover } from 'react-bootstrap'
import { Helmet } from 'react-helmet'
import connect from 'react-redux/es/connect/connect'
import { Redirect } from 'react-router'
import { Link } from 'react-router-dom'
import DisplayNoFooter from '../components/DisplayNoFooter'
import DisplaySimpleHeaderV2 from '../components/DisplaySimpleHeaderV2'
import LoadingNotification from '../components/LoadingNotification'
import ChoosePayment from '../components/purchase/ChoosePayment'
import ScrollToTopOnMount from '../components/ScrollToTopOnMount'
import { fetchHelper } from '../helpers/fetchHelper'
import { calculateVat, getQueryStringFromProps } from '../helpers/utils'
import { handleHidePaymentOptionsModal, handleNotificationChange, handleShowPaymentOptionsModal } from '../store/SBF'

class UpdateBillingDetails extends React.Component {
  state = {
    loadingData: true,
    wpData: {
      clientid: 0,
      key: null,
      amount: 0,
      showPaymentSuccess: false,
      showPaymentCancelled: false,
      showWPNotice: false
    }
  }

  constructor (props) {
    super(props)
    this.numberWithCommas = this.numberWithCommas.bind(this)
  }

  numberWithCommas (x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  }

  render () {
    if (this.state.data === '') {
      // if not data is returned, assume guid was invalid and redirect to login screen
      return (
				<Redirect to="/login" />
      )
    } else {
      // only display this section if data has been received and the guid is valid
      return (
				<>
					{(this.state.loadingData !== true && this.state.clientId > 0) &&
						<ChoosePayment
							description="Settle outstanding balances"
							total={this.state.wpData.amount}
							clientId={this.state.clientId}
							purchaseData={this.state.wpData.key}
							email={this.state.data.email}
							name={this.state.data.accountHolderName}
							mandate={!this.props.services.hasAgreement}
							phone={this.state.data.mobile}
							returnRoute={`/ubd/${this.state.uniqueId}&paymentsuccess=true`}
							newAgreement={!!(((this.state?.data?.amountOwedTotal ?? 0) === 0))}
							isRedirect={false}
							handleParentHideModal={() => this.handlePostPayment()}
							debtGuid={this.state.uniqueId}
						/>
					}
					<ScrollToTopOnMount />
					<DisplayNoFooter />
					<DisplaySimpleHeaderV2 />
					<Helmet>
						<body className="body-simple bg-dull pb-0 " />
						{(this.state.loadingData !== true && this.state.clientId > 0)
						  ? this.state.showPaymentSuccess
						    ? <title>Thanks, we've received your payment</title>
						    :								<title>{this.state.data.accountHolderName + ', update your payment details'}</title>
						  :							<title>{'Update your payment details'}</title>
						}
						<style type="text/css">{`
                            #lhnHocButton { display: none !important; }
                            table.table td, table.table th { border-left: none !important; border-right: none !important; border-bottom: #ddd 1px solid !important; padding: 20px !important; }
                            .loading-page { color: #333 }
                            @media screen and (max-width: 1100px){
                                table.table th {
                                    display: block !important;
                                }
                            }
                            table.table tr td:last-child { box-shadow: none !important; }
                            table.table tr { border-left: none; border-right: none; }
                        `}</style>
					</Helmet>

					<div className="container mt-50 mb-50 mt-xxs-30 p-xs-0 mt-xs-15">
						<div className={`${(((this.state?.data?.amountOwedTotal ?? 0) === 0) ? 'col-lg-8' : 'col-lg-4')} mx-auto col-md-6 col-sm-10 col-xs-12  col-md-offset-3 col-sm-offset-1`}>
							<div className={`bg-light brad-20 b-1 ${(((this.state?.data?.amountOwedTotal ?? 0) === 0) ? 'p-50' : 'pt-30 pb-30')}`}>
								<div className="box-content pt-0 pr-0 pl-0">
									{(this.state.loadingData === true)
									  ? <div className="pt-10 text-darker text-center">
											<LoadingNotification className="mt-30" message={'Loading data...'} isLoading={true} />
										</div>
										: ((this.state?.data?.amountOwedTotal ?? 0) === 0 && this.state.clientId > 0) &&
										<>
											{this.state.cardExpiring === true
											  ? <>
													<h4 className="text-500 text-center mb-20">Your card expires soon</h4>
													<p className="text-center mb-30">
														The card used to pay your monthly subscription is expiring soon.<br /> Please update your payment details below to ensure there's no disruptions to your service.
													</p>
												</>
											  :												this.state.cardExpired === true
											    ? <>
														<h4 className="text-500 text-center mb-20">Your card has expired</h4>
														<p className="text-center mb-30">
															The card used to pay your monthly subscription has expired.<br /> To ensure there's no disruption to your service, please update your payment details below.
														</p>
													</>
											    :													<>
														<h4 className="text-500 text-center mb-20">Update your payment details</h4>
														<p className="text-center mb-30">
															You currently have no outstanding payments. <br />However, you can use this page to quickly update your billing details. <br />Simply choose an option below to add a new payment method.
														</p>
													</>
											}
											<ChoosePayment
												total={this.state.wpData.amount}
												clientId={this.state.clientId}
												purchaseData={this.state.wpData.key}
												email={this.state.data.email}
												name={this.state.data.accountHolderName}
												mandate={!this.props.services.hasAgreement}
												phone={this.state.data.mobile}
												returnRoute={`/ubd/${this.state.uniqueId}&paymentsuccess=true`}
												inline={!!(((this.state?.data?.amountOwedTotal ?? 0) === 0))}
												newAgreement={!!(((this.state?.data?.amountOwedTotal ?? 0) === 0))}
												isRedirect={false}
												handleParentHideModal={() => this.handlePostPayment()}
												debtGuid={this.state.uniqueId}
											/>

										</>
									}
									{((this.state?.data?.amountOwedTotal ?? 0) > 0 && this.state.clientId > 0) &&
										!this.state.showPaymentSuccess &&
										<>
											<div className="direction-block dir-down bb-1 mb-25 pt-0 pb-30">
												<div className="row">
													<div className="col-12">
														<p className="lead text-400 m-0 lead text-danger text-center pl-30 pr-30"><i className="icon icon-warning2 mr-5"></i>There is an overdue payment on your account!</p>
													</div>
													<div className="col-12 text-center pt-20">
														<span className="alert alert-danger p-10 mt-20 text-400">
															Amount Owed: £{this.numberWithCommas(this.state.data.amountOwedTotal.toFixed(2))}
															<OverlayTrigger trigger={['hover', 'focus']} placement="top" overlay={
																<Popover title="Breakdown">
																	<p>
																		{this.state.data.amountOwedGeo !== 0 &&
																			<>
																				Phone Numbers: £{this.numberWithCommas(this.state.data.amountOwedGeo.toFixed(2))}
																				<br />
																			</>
																		}

																		{this.state.data.amountOwedNegativeCredit !== 0 &&
																			<>
																				Call Charges: £{this.numberWithCommas(this.state.data.amountOwedNegativeCredit.toFixed(2))}
																				<br />
																			</>
																		}

																		{this.state.data.amountOwedSubscription !== 0 &&
																			<>
																				Invoices: £{this.numberWithCommas(this.state.data.amountOwedSubscription.toFixed(2))}
																				<br />
																			</>
																		}

																		{this.state.data.amountOwedDebtEsculations !== 0 &&
																			<>
																				Escalated Debt: £{this.numberWithCommas(this.state.data.amountOwedDebtEsculations.toFixed(2))}
																				<br />
																			</>
																		}
																	</p>
																</Popover>
															}>
																<i className="icon icon-info2 text-xs ml-10"></i>
															</OverlayTrigger>
														</span>
													</div>
												</div>
											</div>

											<div className="direction-block dir-down bb-1 mb-40 pt-0 pb-25">
												<div className="row text-center text-400">
													<div className="col-12 col-md-6">
														Account name:
													</div>
													<div className="col-12 col-md-6">
														{this.state.data.accountHolderName}
													</div>
												</div>
												<hr />
												<div className="row text-center text-400">
													<div className="col-12 col-md-6">
														Last payment:
													</div>
													<div className="col-12 col-md-6">
														{this.state.data.lastPayment}
													</div>
												</div>
											</div>

											<div className="text-center">
												<Button variant="cta" size="lg" onClick={e => this.state.data.hasAgreement === true
												  ? this.setState({ showChangeDetails: true })
												  : this.handleTakePaymentClicked()}>
													Settle Now <span className="icon icon-arrow-right4 ml-5 text-xs"></span>
												</Button>

												<p className="text-sm mt-10">Total payment including VAT will be <span className="text-500">&pound;{this.numberWithCommas(calculateVat(this.state.data.amountOwedTotal).toFixed(2))}</span></p>
											</div>

										</>
									}
								</div>
							</div>
						</div>
					</div>
					<div className="row">
						<div className="col-lg-4 col-md-6 col-sm-10 col-xs-12 mx-auto">
							<p className="text-xs text-center">
								&copy;2020 Liquid11, Sea Lake Road, Lowestoft, Suffolk, NR32 3LQ<br />
								Company number: 04404380 Regulated by Ofcom.

								<Link to="/privacy-policy" className="ml-10">Privacy policy</Link>
								<span className="pl-5 pr-5">/</span>
								<Link to="/terms-and-conditions" target="_blank">Terms and Conditions</Link>

							</p>
						</div>
					</div>

					{this.state.loadingData !== true &&
						<>
							{this.state.clientid > 0 &&
								<Modal className="text-center" size="sm" show={this.state.showPaymentCancelled}>
									<Modal.Body className="p-0">
										<div className="p-20">
											<h2 className="text-danger mt-0 mb-20 pb-10 bb-1">
												Payment cancelled
											</h2>
											<p className="lead">
												You still have an outstanding payment of <span className="text-400"><em>&pound;{this.state.data.amountOwedTotal.toFixed(2)}</em></span>
											</p>
											<p>
												Please update your billing details to prevent any service disruption.
											</p>
											<p className="mt-20">
												<Button variant="cta" className="btn-sm-block btn-xs-block" onClick={e => this.handleTakePaymentClicked()}>Add Payment Details</Button>
											</p>
										</div>
										<div className="p-20 text-center bg-dull">
											<img src="/images/credit-cards/worldPay.png" width="220" className="worldPay" alt="Payments powered by WorldPay" />
											<br />
											<img src="/images/credit-cards/visa.png" className="pr-10" width="60" alt="Visa" />
											<img src="/images/credit-cards/maestro.png" className="pr-10" width="60" alt="Maestro" />
											<img src="/images/credit-cards/mastercard.png" className="pr-10" width="60" alt="Mastercard" />
											<img src="/images/credit-cards/visaElectron.png" className="pr-10" width="60" alt="Visa Electron" />
										</div>
									</Modal.Body>
								</Modal>}

							<Modal className="text-center" size="sm" show={this.state.showPaymentSuccess}>
								<Modal.Body className="p-0">
									<div className="p-20">
										{this.state.updatedDetails
										  ? <>
												<h2 className="text-success mt-0 mb-20 pb-10 bb-1">
													Details updated
												</h2>
												<p className="lead">
													Thank you, your payment details have been updated.
												</p>
											</>
										  :											<>
												<h2 className="text-success mt-0 mb-20 pb-10 bb-1">
													Payment received
												</h2>
												<p className="lead">
													Thank you, your payment has been received and no further action is required.
												</p>
												<p>
													An invoice for this payment has been emailed to you.
												</p>
											</>
										}

										<p>
											If you require further assistance please call our UK support team on <a href="tel:02031891213">0203 189 1213</a>.
										</p>
									</div>
									<div className="p-20 text-center bg-dull">
										<Link to="/login" className="btn btn-cta btn-sm-block btn-xs-block">Login to your account</Link>
									</div>
								</Modal.Body>
							</Modal>

							<Modal className="text-center" size="small" show={!this.state.data && !this.state.showPaymentSuccess}>
								<Modal.Body className="p-0">
									<div className="p-20">
										<h2 className="text-success mt-0 mb-20 pb-10 bb-1">
											Link expired
										</h2>
										<p>
											The link you have used is either invalid or has expired.<br /><br />
											If you require further assistance please call our UK support team on <a href="tel:02031891213" className="text-cta text-underline">0203 189 1213</a>.
										</p>
									</div>
									<div className="p-20 text-center bg-dull">
										<Link to="/login" className="btn btn-cta btn-sm-block btn-xs-block">Login to your account</Link>
									</div>
								</Modal.Body>
							</Modal>

							<Modal className="text-center" size="sm" show={this.state.showWPNotice}>
								<Modal.Body>
									<LoadingNotification className="mt-30" message={'Loading...'} isLoading={true} />
									<p className="lead">
										Processing.<br /><br />Please wait...
									</p>
								</Modal.Body>
							</Modal>

							<Modal className="text-center" size="sm" show={this.state.showChangeDetails}>
								<Modal.Body className="p-0">
									<div className="p-20">
										<h2 className="mt-0 mb-20 pb-10 bb-1 text-xl text-500">
											How would you like to pay?
										</h2>
										<p className="mt-10 mb-30">
											It looks like you've got payment details associated with your account already. <br /><br />Would you like to pay with these details, or update your billing method?
										</p>
										<Button variant="cta" size="lg" className="btn-block mb-20" onClick={e => this.handleTakePaymentClicked()}>
											Settle Now <span className="icon icon-arrow-right4 ml-5 text-xs"></span>
										</Button>

										<Button variant="outline" onClick={e => this.handleTakePaymentClicked(true)}>
											Update Billing Details <span className="icon icon-arrow-right4 ml-5 text-xs"></span>
										</Button>

									</div>
								</Modal.Body>
								<Modal.Footer>
									<p className="text-sm">
										If you have any questions, or need assistance, please get in touch with our UK support team on <a href="tel:02031891213">0203 189 1213</a>, or email <a href="mailto:support@switchboardfree.co.uk">support@switchboardfree.co.uk</a>.
									</p>
								</Modal.Footer>
							</Modal>

						</>
					}
				</>

      )
    }
  }

  handlePostPayment () {
    this.props.handleHidePaymentOptionsModal()
    this.showSuccess()
  }

  handleTakePaymentClicked (isUpdating) {
    this.setState({
      showWPNotice: true,
      showChangeDetails: false
    })

    // 1) call to out API which will raise invoice for amount about to be billed, attempt payment and if unsuccesfull
    //      then setup details to forward to Stripe
    let uniqueId = this.props.match.params.uniqueId

    if (uniqueId === undefined) {
      uniqueId = null
    }

    const url = `api/Home/SettleOutstandingDebtGuidGenerateInvoice/${uniqueId}/${isUpdating === true}`

    fetchHelper.getJson(url).then(response => {
      if (response.data !== '') {
        if (response.data.isSuccess === true) {
          // We took all outstanding amounts sucesfully, show success to user and prevent them taking payment a second time
          this.showSuccess()
        } else {
          // We failed to take outstanding amounts, return informatio will contain key needed to send them off to WorldPay
          // in order to provide new payment details. They will be returned to this page where we will re-attempt payment
          this.setState({
            wpData: response.data,
            loadingData: false
          })

          this.props.handleShowPaymentOptionsModal()
        }
      }
    }).catch(error => console.error(error))
  }

  showSuccess () {
    // Remove billing restricted from localStorage so people can use website!
    localStorage.removeItem('billingRestricted')

    // hide payment button / debt details, show thank you and a button to login
    this.setState({
      showPaymentSuccess: true
    })
  }

  showCancelled () {
    // show warning message
    this.setState({
      showPaymentCancelled: true
    })
  }

  componentDidMount () {
    localStorage.removeItem('isNewCustomer')
    const updating = localStorage.getItem('isUpdating')
    if (updating) {
      this.setState({ updatedDetails: true }, () => {
        localStorage.removeItem('isUpdating')
      })
    }

    // if this is a returning user then check to see if payment was sucesfully made, if so
    //  then show thankyou message, if not then show failed and give oppurtunity to try again.
    const queryString = getQueryStringFromProps(this.props)

    if (queryString) {
      const params = queryString.toLowerCase()

      if (params.includes('paymentsuccess=true')) {
        // payment was succesfull
        this.showSuccess()
      } else if (params.includes('cancel=true')) {
        // visitor cancelled payment
        this.showCancelled()
      } else if (params.includes('expiring=true')) {
        this.setState({ cardExpiring: true })
      } else if (params.includes('expired=true')) {
        this.setState({ cardExpired: true })
      }
    }

    let uniqueId = this.props.match.params.uniqueId

    if (uniqueId === undefined) {
      uniqueId = null
    } else {
      this.setState({ uniqueId })
    }

    const url = `api/Home/GetUpdateBillingDetailsInfo/${uniqueId}`

    fetchHelper.getJson(url).then(response => {
      if (response.data === '') {
        const user = localStorage.getItem('user')

        if (user !== '') {
          const clientId = JSON.parse(user).clientId
          this.setState({ clientId })
          localStorage.setItem('clientId', clientId)
          fetchHelper.getJson('api/Home/GetUpdateBillingDetailsInfoWithClientId').then(response => {
            if (response.data !== '') {
              // re-redirect to page adding in newly generated / retrieved code
              window.location.replace('/ubd/' + response.data.code)
            }
          }).catch(error => console.error(error))
        }
      } else {
        this.setState({
          loadingData: false
        })

        if (response.data.clientId > 0) {
          localStorage.setItem('clientId', response.data.clientId)

          this.setState({
            data: response.data,
            clientId: response.data.clientId
          })
        }
      }
    }).catch(error => console.error(error))
  }
}

export default connect(
  state => {
    return {
      clientId: state.sbfApp.authentication.clientId,
      services: state.sbfApp.enabledServices,
      userDetails: state.sbfApp.authentication
    }
  },
  {
    notify: handleNotificationChange,
    handleShowPaymentOptionsModal,
    handleHidePaymentOptionsModal
  }
)(UpdateBillingDetails)
