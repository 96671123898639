import React from 'react'
import { Button, Form, Modal } from 'react-bootstrap'
import { connect } from 'react-redux'
import { fetchHelper } from '../../helpers/fetchHelper'
import { isLandlineOrMobile } from '../../helpers/utils'

class HandsetOrders extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      qty: 1,
      monthly: false
    }
  }

  handleChangeQty (isDecrement) {
    let qty = this.state.qty
    let price = parseFloat(this.state.totalPrice)
    let mins = this.state.totalMins

    if (isDecrement && qty > 1) {
      qty--
      price = price - 5
      mins = mins - 1500
    } else {
      qty++
      price = price + 5
      mins = mins + 1500
    }

    this.setState({ qty, totalPrice: price, totalMins: mins })
  }

  render () {
    let price = 0

    if (this.props.model && this.props.model.toLowerCase() === 'deskphone') {
      price = 84.99
    }

    if (this.props.model && this.props.model.toLowerCase() === 'wireless') {
      price = 104.99
    }

    if (this.props.model && this.props.model.toLowerCase() === 'headset') {
      price = 39.99
    }

    if (this.props.model && this.props.model.toLowerCase() === 'bee') {
      price = 24.99
    }

    if (this.props.model && this.props.model.toLowerCase() === 'dongle') {
      price = 9.99
    }

    if (this.props.model && this.props.model.toLowerCase() === 'earbuds') {
      price = 34.99
    }

    if (this.props.model && this.props.model.toLowerCase() === 'x3s') {
      price = 54.99
    }

    return <Modal className="fancy w-900px" show={this.props.showModal} onHide={this.props.onHide}>
            <Modal.Header className="bb-0 text-center" closeButton>
                {this.props.model && this.props.model.toLowerCase() === 'deskphone' &&
                    <img src="/images/product-shots/fanvil-x4u.png" alt="Fanvil X4U Deskphone" style={{ maxWidth: '100%', maxHeight: '300px', margin: '0 auto' }} />
                }
                {this.props.model && this.props.model.toLowerCase() === 'wireless' &&
                    <img src="/images/product-shots/yealink-wireless-w53p.png" alt="Yealink W53P Cordless Handset" style={{ maxWidth: '100%', maxHeight: '300px', margin: '0 auto' }} />
                }
                {this.props.model && this.props.model.toLowerCase() === 'headset' &&
                    <img src="/images/product-shots/headset.png" alt="Fanvil Headsets" style={{ maxWidth: '100%', maxHeight: '300px', margin: '0 auto' }} />
                }
                 {this.props.model && this.props.model.toLowerCase() === 'bee' &&
                    <img src="/images/product-shots/bee.png" alt="New Bee Multi-Adapter Headsets" style={{ maxWidth: '100%', maxHeight: '300px', margin: '0 auto' }} />
                }
                {this.props.model && this.props.model.toLowerCase() === 'dongle' &&
                    <img src="/images/product-shots/dongle.png" alt="USB Bluetooth 5.0 Dongle Adapter" style={{ maxWidth: '100%', maxHeight: '300px', margin: '0 auto' }} />
                }
                {this.props.model && this.props.model.toLowerCase() === 'earbuds' &&
                    <img src="/images/product-shots/earbuds.png" alt="Wireless Earbuds" style={{ maxWidth: '100%', maxHeight: '300px', margin: '0 auto' }} />
                }
                {this.props.model && this.props.model.toLowerCase() === 'x3s' &&
                    <img src="/images/product-shots/x3s.png" alt="VoIP Phone X3S" style={{ maxWidth: '100%', maxHeight: '300px', margin: '0 auto' }} />
                }
            </Modal.Header>
            <Modal.Body className="pl-60 pr-60 pr-xxs-30 pl-xxs-30">
                {this.state.showThanks === true
                  ? <>
                        <div className="text-center">
                            <h1 className="text-cta text-500">
                                Thanks! <br />
                                We’ve Received Your Order</h1>
                            <p className="pt-20">
                                One of the team will be in touch to confirm your purchase.
                            </p>
                            <p className="pt-20">
                                In the meantime, if there’s anything we can help with, please call us on <a href="tel:02031891213" className="text-cta underline">0203 189 1213</a> or email us at <a href="mailto:support@switchboardfree.co.uk" className="text-cta underline">support@switchboardfree.co.uk</a>
                            </p>
                            <p className="text-italic pt-20">
                                Have a great day!
                            </p>
                            <p className="pt-20">
                                <Button size="lg" variant="cta" onClick={this.props.onHide}>Back to site</Button>
                            </p>
                        </div>
                    </>
                  : <>
                        <div className="row">
                            <div className="col-md-12 text-center">
                                {this.props.model && this.props.model.toLowerCase() === 'deskphone' &&
                                    <h1 className="text-cta text-500">Desktop VoIP Handset</h1>
                                }
                                {this.props.model && this.props.model.toLowerCase() === 'wireless' &&
                                    <h1 className="text-cta text-500">Cordless VoIP Handset</h1>
                                }
                                {this.props.model && this.props.model.toLowerCase() === 'headset' &&
                                    <h1 className="text-cta text-500">VoIP Headset</h1>
                                }
                                {this.props.model && this.props.model.toLowerCase() === 'bee' &&
                                    <h1 className="text-cta text-500">New Bee Multi-Adapter Headsets</h1>
                                }
                                {this.props.model && this.props.model.toLowerCase() === 'dongle' &&
                                    <h1 className="text-cta text-500">USB Bluetooth 5.0 Dongle Adapter</h1>
                                }
                                {this.props.model && this.props.model.toLowerCase() === 'earbuds' &&
                                    <h1 className="text-cta text-500">Wireless Earbuds</h1>
                                }
                                {this.props.model && this.props.model.toLowerCase() === 'x3s' &&
                                    <h1 className="text-cta text-500">VoIP Phone</h1>
                                }

                                <h4 className="text-500">
                                    <em>Hassle-Free Setup <span className="text-cta">•</span> Free UK Support <span className="text-cta">•</span> Unrivalled Quality</em>
                                </h4>

                                {this.state.price &&
                                    <p className="text-400 lead text-cta">£{this.state.price}</p>
                                }
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-xs-12 text-center mb-30">
                                <div className="divider mt-40 mb-30">
                                    <span className="icon icon-arrow-down2"></span>
                                </div>
                                <p className="mt-30 mb-30"><span className="text-cta text-500">Step #1:</span> Select the number of items you’d like</p>
                                <form className="qty text-center mt-15 center">
                                    <input
                                        type="button"
                                        className="btn btn-default"
                                        onClick={() => this.handleChangeQty(true)}
                                        value="-"
                                        disabled={this.state.qty === 1}
                                    />
                                    <input
                                        type="text"
                                        id="number"
                                        className="form-control"
                                        value={this.state.qty}
                                    />
                                    <input
                                        type="button"
                                        className="btn btn-default"
                                        onClick={() => this.handleChangeQty()}
                                        value="+"
                                    />
                                </form>
                            </div>
                        </div>
                        <div className="row">
                            <div className="text-center">
                                <div className="divider mt-10 mb-20">
                                    <span className="icon icon-arrow-down2"></span>
                                </div>
                                <p className="pt-20 mb-30"><span className="text-cta text-500">Step #2:</span> Enter your details and hit the button below</p>
                                <div className="row">
                                    <div className="col-md-6 text-start mb-10">
                                        <Form.Group validationState={this.state.showNameError ? 'error' : ''}>
                                            <Form.Control
                                                type="text"
                                                value={this.state.name}
                                                placeholder="Your name"
                                                isInvalid={this.state.showNameError}
                                                onChange={e => this.setState({ name: e.target.value, showNameError: false })} />
                                            <Form.Control.Feedback type="invalid" className="text-400 pl-15">
                                                Please enter your name
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </div>
                                    <div className="col-md-6 text-start mb-10">
                                        <Form.Group validationState={this.state.showPhoneError ? 'error' : ''}>
                                            <Form.Control
                                                type="tel"
                                                value={this.state.phone}
                                                placeholder="Phone number"
                                                isInvalid={this.state.showPhoneError}
                                                onChange={e => this.setState({ phone: e.target.value, showPhoneError: false })} />
                                            <Form.Control.Feedback type="invalid" className="text-400 pl-15">
                                                {this.state.phoneErrorMsg
                                                  ? this.state.phoneErrorMsg
                                                  : 'Please enter a valid telephone'
                                                }
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </div>
                                    {this.state.showGenericError &&
                                        <div className="col-xs-12">
                                            <div className="alert alert-danger">
                                                {this.state.errorMsg
                                                  ? this.state.errorMsg
                                                  : <span>There was a problem submitting your details. Please try again, or call us on <a href="tel:02031891213">0203 189 1213</a>.</span>
                                                }
                                            </div>
                                        </div>
                                    }
                                    <div className="col-md-12 pt-30">
                                        <Button variant="cta" size="xl" onClick={() => this.handleOrder()} disabled={this.state.isSubmitting} className="btn-xxs-block">Place order</Button>
                                        <Button variant="link" size="sm" onClick={this.props.onHide} className="text-dark mt-20 underline pb-30 mb-30">Cancel &amp; Close</Button>
                                    </div>
                                    {/* <p className="text-xs text-italic pt-20">Prices shown exclude VAT</p> */}
                                </div>
                            </div>
                        </div>
                    </>
                }
            </Modal.Body>
        </Modal>
  }

  componentDidUpdate (prevProps, prevState) {
    if (this.props.modelId && prevProps.modelId !== this.props.modelId) {
      const url = `/api/Purchase/GetHardwarePrice/${this.props.modelId}`
      fetchHelper.getJson(url)
        .then(res => this.setState({ price: res.data }))
        .catch(err => console.error(err))
    }
  }

  handleOrder () {
    let product = 'Desktop VoIP Handset'

    if (this.props.model && this.props.model.toLowerCase() === 'wireless') { product = 'Cordless VoIP Phone' }

    if (this.props.model && this.props.model.toLowerCase() === 'headset') { product = 'Fanvil VoIP Headset' }

    if (this.props.model && this.props.model.toLowerCase() === 'bee') { product = 'New Bee Multi-Adapter Headsets' }

    if (this.props.model && this.props.model.toLowerCase() === 'dongle') { product = 'New USB Bluetooth 5.0 Dongle Adapter' }

    if (this.props.model && this.props.model.toLowerCase() === 'earbuds') { product = 'New Wireless Earbuds' }

    if (this.props.model && this.props.model.toLowerCase() === 'x3s') { product = 'x3s' }

    this.setState({ isSubmitting: true, errorMsg: '', showGenericError: false })
    if (!this.state.name) {
      this.setState({ showNameError: true, isSubmitting: false })
    } else if (!this.state.phone) {
      this.setState({ showPhoneError: true, isSubmitting: false })
    } else if (!isLandlineOrMobile(this.state.phone)) {
      this.setState({ showPhoneError: true, phoneErrorMsg: 'Please enter a mobile or landline number', isSubmitting: false })
    } else {
      // Looks good
      this.setState({
        showNameError: false,
        showPhoneError: false
      })

      const data = Object.assign(
        { Name: this.state.name },
        { Phone: this.state.phone },
        { SourceId: 68 },
        {
          Product: {
            Qty: this.state.qty,
            Product: this.props.model,
            PaymentMethod: 'One-off Payment'
          }
        }
      )

      const url = 'api/Home/SubmitNewLead'
      fetchHelper.postJson(url, data)
        .then(res => {
          this.setState({ isSubmitting: false })
          if (res.data.success === true) {
            this.setState({ showThanks: true })
          } else {
            this.setState({ showGenericError: true, errorMsg: res.data.msg })
          }
        }).catch(err => console.error(err))
    }
  }
}

export default connect(
  state => {
    return {
    }
  },
  {
  }
)(HandsetOrders)
