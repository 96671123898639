import React from 'react'
import { Button, Modal } from 'react-bootstrap'
import { Helmet } from 'react-helmet'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import Logos from '../../components/ClientLogos'
import DisplayMainFooter from '../../components/DisplayMainFooter'
import PurchasePhones from '../../components/purchase/PurchasePhones'
import CarouselWidget from '../../components/Reviews/CarouselWidget'
import StatsBanner from '../../components/StatsBanner'
import { fetchHelper } from '../../helpers/fetchHelper'
import LocalStorageHelper from '../../helpers/LocalStorageHelper'
import { getEmptyBasket } from '../../helpers/utils'
import { isLoggedIn } from '../../selectors'
import * as fromHome from '../../store/Home'
import { handleNotificationChange, handleShowCallbackForm } from '../../store/SBF'
import moment from 'moment'
import Countdown from 'react-countdown'

class VoIPUpsell extends React.Component {
  state = {
    isPurchaseInProgress: false,
    finished: false
  }

  constructor (props) {
    super(props)
  }

  render () {
		const renderCountdown = ({ days, hours, minutes, seconds, completed }) => {
			// Render a countdown
			return <>
				<p className="text-700 text-cta text-uppercase text-md mb-20">
					Ends in:
				</p>
				<div className="countdown-timer">
					<div className="timer-block">
						{days}<br /><span className="text-sm">days</span>
					</div>
					<div className="timer-block">
						{hours}<br /><span className="text-sm">hours</span>
					</div>
					<div className="timer-block">
						{minutes}<br /><span className="text-sm">minutes</span>
					</div>
					<div className="timer-block">
						{seconds}<br /><span className="text-sm">seconds</span>
					</div>
				</div>
			</>
		}

		let peopleAddedThis = 0;

		if (localStorage.getItem('peopleAdded')) {
			peopleAddedThis = localStorage.getItem('peopleAdded');
		} else {
			peopleAddedThis = ((Math.random() * 15) + 2).toFixed(0);
			localStorage.setItem('peopleAdded', peopleAddedThis);
		}

		const basket = getEmptyBasket()
    if (LocalStorageHelper.getItem('BasketHardware')?.value !== null) {
      basket.hardware = LocalStorageHelper.getItem('BasketHardware').value
    }

    return <><DisplayMainFooter />
			<Helmet>
				<body className={'body-simple bg-dull body-signup'} />
				<title>20% OFF All Handsets For Black Friday | SwitchboardFREE</title>
				<style type="text/css">{`
						.background {
							background: #000 url('/images/campaigns/blackfriday/bg.jpg') no-repeat;
						}
					}
					`}</style>
			</Helmet>
			<div className={'p-50 p-xs-20 background'}>
				<div className="container p-xs-0">
					<div className="row mb-50">
						<div className="col-md-10 mx-auto text-center">
							<p className="text-lg text-400 mt-30 text-lightest">
								<em><span className="text-cta text-500">HURRY!</span> Today ONLY!</em>
							</p>
							<h1 className="text-underline text-lightest">
								HUGE Black Friday Savings.<br className="d-none d-sm-block" />
								Get 20% OFF All VoIP Handsets
							</h1>
							<h5 className="text-500 pt-20 text-lightest">Ends Midnight TONIGHT!</h5>
							<>
								<span className="p-30 bg-darker mb-20 d-inline-block center-block brad-100 mt-50">
									<Countdown date={moment().endOf('day').format('yyyy-MM-DDTHH:mm:ss')} renderer={renderCountdown} />
								</span>
								<p className="text-sm mb-0 p-0 text-lightest">
									{peopleAddedThis} people purchased <span className="text-500">VoIP Handsets</span> in the last hour.
								</p>
							</>

						</div>
					</div>
					<div className="row">
						<div className="col-lg-12 col-md-10 col-sm-12 mx-auto ">
							<div className="bg-dull p-50 p-sm-30 p-xs-20 brad-20 pr-100 pl-100 pr-sm-50 pl-sm-50 pr-xs-20 pl-xs-20 clearfix">
								{this.state.finished
								  ? <>
										<h3 className="text-500 mt-30">
											All done!
										</h3>
										<p className="text-400 lead mb-10 mt-0">Congratulations, your new phone(s) have been ordered successfully.</p>
										<p className="text-md mt-30">
											Please allow 3-5 working days for delivery.
										</p>
										<p className="mt-30">
											<Button variant="cta" size="lg" className="pl-100 pr-100 pl-xs-20 pr-xs-20" onClick={() => this.props.history.push('/customer/dashboard')}>Go to my dashboard</Button>
										</p>
									</>
								  :									this.state.diallerSent === true
								    ? <>
											<h3 className="text-500 mt-30">
												Thanks!
											</h3>
											<p className="text-400 lead mb-10 mt-0">We'll give you a call shortly.</p>
											<p className="text-md mt-30">
												We've received your request and one of our team will call you shortly to answer any questions you may have and help you get setup.
											</p>
											<p className="mt-30">
												<Button variant="cta" size="lg" className="pl-100 pr-100 pl-xs-20 pr-xs-20" onClick={() => this.props.history.push('/customer/dashboard')}>Go to my dashboard</Button>
											</p>
										</>
								    :										<>
											<p className="text-400 text-uppercase text-lg mb-10 mt-0 text-center">
												CHOOSE YOUR BRAND NEW PHONES BELOW
											</p>

											<PurchasePhones
												discount={0.2} // 20%
												displayInline={true}
												handleUpdateHardware={(hardware) => handleHandsetUpdated(hardware)}
												currentBasket={basket}
												showEditDevices={false}
												handleCloseDevicesModal={() => setState({
												  ...state,
												  showEditDevices: false
												})}
												isSignup={false}
												hideFree={true}
												displayInstalments={true}
												purchaseUrl={`/api/AccessToken/PurchaseHardware?t=${this.state.token}`}
												returnRoute={`get/handsets?t=${this.state.token}&success=true`}
											/>

											<p className="text-center mt-10 text-sm mb-0">
												<Button variant="link" className="text-dark" onClick={() => this.handleSubmitToSupport()}>Or, request more information</Button>
											</p>

											{this.state.diallerSent === false &&
												<p className="text-400 text-danger text-center">
													Sorry, there was a problem submitting your request, please try again.
												</p>
											}
										</>
								}
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className="bg-light pt-50 pb-50">
				<h3 className="text-center text-500 mb-20 text-lg">Proud to work with leading UK businesses</h3>
				<Logos style="light" />
			</div>

			<div className="full-width bg-dull">
				<div className="container pl-xs-0 pr-xs-0">
					<CarouselWidget />
				</div>
			</div>

			<div className="full-width full-secondary p-20">
				<div className="row">
					<div className="col-md-12">
						<h3 className="text-center text-lightest mt-10 text-100 text-xl">
							<StatsBanner />
						</h3>
					</div>
				</div>
			</div>

			<div className="full-width bg-light">
				<div className="container">
					<div className="row">
						<div className="col-md-12 text-center">
							<h3 className="text-500">SwitchboardFREE</h3>
							<h6 className="text-400">The UK's favourite call management system</h6>
							<p className="text-400">Instant activation  &bull;  No long-term contracts  &bull;  Award-winning UK support</p>
						</div>
					</div>
				</div>
			</div>

			<Modal size="sm" show={this.state.showLogin}>
				<Modal.Header>
					<Modal.Title className="mx-auto">Log into your account</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div className="row">
						<div className="col-md-12 text-center pb-30">
							<form name="form" className="text-left" onSubmit={e => this.props.login({
							  userName: this.props.userName,
							  password: this.props.password,
							  event: e,
							  history: this.props.history,
							  persist: document.getElementById('persistLogin').checked,
							  route: `/get/free-voip-phone?t=${this.state.token}`
							})}>
								<div className="row">
									<div className="col-xs-12">
										<p className="lead text-400">Oops, it looks like your token has expired, or is not valid. Please log into your account below.</p>
									</div>
									<div className="col-xs-12">
										<label>Email</label>
										<input className="form-control" placeholder="Your login email..." type='email' value={this.props.userName} onChange={e => this.props.updateUsername(e.target.value)} />
									</div>
									<div className="col-xs-12 mt-20">
										<label>Password</label>
										<input className="form-control" placeholder="Your password..." type='password' value={this.props.password} onChange={e => this.props.updatePassword(e.target.value)} />
									</div>
								</div>
								<div className="pt-10 pb-10">
									<div className="col-xs-6 pl-0">
										<input type="checkbox" id='persistLogin' defaultChecked={true} /> <label>Remember</label>
									</div>
									<div className="col-xs-6 text-right pr-0">
										<Link to={'/forgot-password'} className="text-xs text-cta">Forgot password?</Link>
									</div>
								</div>
								<div className="text-center pt-30">
									{this.props.loggingIn && <p>Now logging you in, please wait ...</p>}

									{this.props.loginFailed && <p className="alert alert-danger">There has been an error logging in, please check your username and password and try again. If that doesn't help, try <Link to={'/forgot-password'}>resetting your password</Link>.</p>}

									<button className="btn btn-cta btn-lg btn-mobile-block">Log In</button>

									<p className="lead text-center bt-1 mt-30 pt-30">Don&apos;t have an account with us yet?</p>
									<p className="text-center"><Link to="/get-started" className="btn btn-secondary btn-sm">Create an account</Link></p>
								</div>
							</form>
						</div>
					</div>
				</Modal.Body>
			</Modal>

		</>
  }

  componentWillMount () {
    const context = this
    let token = localStorage.getItem('accessToken')
    const query = new URLSearchParams(this.props.location.search)
    if (!token && query) {
      // auth
      token = query.get('t')
    }

    if (query.get('success')) {
      this.setState({ finished: true })
    }

    context.setState({ token })
  }

  componentDidMount () {
    const context = this
    context.setState({ added: ((Math.random() * 18) + 2).toFixed(0), unitsLeft: ((Math.random() * 100) + 50).toFixed(0) })

    if (!context.state.token && !this.props.isLoggedIn) {
      // Login
      this.setState({ showLogin: true })
    }
  }

  handleSubmitToSupport () {
    const context = this

    context.setState({ submittingToDialler: true }, () => {
      const url = `/api/AccessToken/SubmitLeadToSupport?t=${context.state.token}&product=Handset%20Purchase`
      fetchHelper.getJson(url)
        .then(res => {
          context.setState({ diallerSent: res.data, submittingToDialler: false })
        }).catch(err => console.error(err))
    })
  }

  handleUpdateAddress (address) {
    this.props.handleDeliveryNameChange({ newValue: address.name })
    this.props.handleDeliveryAdd1Change({ newValue: address.add1 })
    this.props.handleDeliveryAdd2Change({ newValue: address.add2 })
    this.props.handleDeliveryCountyChange({ newValue: address.add3 })
    this.props.handleDeliveryTownChange({ newValue: address.add4 })
    this.props.handleDeliveryPcodeChange({ newValue: address.pcode })
  }
}

export default connect(
  state => {
    return {
      isLoggedIn: isLoggedIn(state),
      userName: state.home.login.username,
      password: state.home.login.password,
      loggingIn: state.home.login.loggingIn,
      loginFailed: state.home.login.loginFailed
    }
  },
  {
    login: fromHome.handleLogin,
    updateUsername: fromHome.updateUsername,
    updatePassword: fromHome.updatePassword,
    notify: handleNotificationChange,
    handleShowCallbackForm
  }
)(VoIPUpsell)
