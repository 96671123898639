import moment from 'moment'
import React from 'react'
import Helmet from 'react-helmet'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import DisplayNoFooter from '../components/DisplayNoFooter'
import DisplaySimpleHeader from '../components/DisplaySimpleHeader'
import ScrollToTopOnMount from '../components/ScrollToTopOnMount'
import { getQueryStringByParameter } from '../helpers/utils'
import * as fromHome from '../store/Home'
import * as fromApp from '../store/SBF'

class LoginPage extends React.Component {
  state = {
    redirect: undefined
  }

  constructor (props) {
    super(props)
  }

  render () {
    return (
            <div className="container-fluid">
                <DisplayNoFooter /><ScrollToTopOnMount /><DisplaySimpleHeader />
                <Helmet>
                    <body className={'body-simple login-page bg-light '.concat(localStorage.getItem('isBeta') ? 'beta-body' : '')} />
                    <style>
                        {
                            `
                            #lhnHocInvite, #lhnHelpOutCenter, #lhnHocButton {
                                display:none !important;
                            }
                            
                        `
                        }
                    </style>
                </Helmet>
                <div className="row">
                    <div className=" absolute">
                        <div className="text-center pt-50 pb-20">
                            <h4 className='text-400'>Log in to your account</h4>
                        </div>
                        <form name="form" onSubmit={e => this.props.login({
                          userName: this.props.userName,
                          password: this.props.password,
                          event: e,
                          history: this.props.history,
                          persist: document.getElementById('persistLogin').checked,
                          route: this.state.redirect
                        })}>
                            <div className="email-field">
                                <label className='text-400'>Email</label>
                                <input className="form-control" placeholder="Your login email..." type='email' name='email' autoComplete='username' value={this.props.userName} onChange={e => this.props.updateUsername(e.target.value)} id="LoginEmailField"/>
                            </div>
                            <div className="pass-field">
                                <label className="pt-30 text-400">Password</label>
                                <input className="form-control" placeholder="Your password..." type='password' autoComplete='current-password' name='current-password' value={this.props.password} onChange={e => this.props.updatePassword(e.target.value)} id="LoginPasswordField"/>
                            </div>
                            <div className="pt-10 pb-30 row pl-15 pr-15 options-wrapper">
                                <div className="col-xs-6 pl-0">
                                    <input type="checkbox" id='persistLogin' defaultChecked={true} /> <label>Remember</label>
                                </div>
                                <div className="col-xs-6 text-end pr-0">
                                    <Link to={'/forgot-password'} className="text-xs text-cta">Forgot password?</Link>
                                </div>
                            </div>
                            <div className="text-center pt-30 pt-sm-0">
                                {/* {this.props.loggingIn && <p>Now logging you in, please wait ...</p>} */}

                                <div className="alert alert-danger text-danger text-400 text-center" id='retryLoginMsg' style={{ display: 'none' }}><p className='text-sm pb-0 mb-0'>Your login credentials are wrong or have expired, please login again.</p></div>

                                {this.props.loginFailed && <p className="alert alert-danger text-danger text-sm" id="LoginErrorMessage">There has been an error logging in, please check your username and password and try again. If that doesn't help, try <Link to={'/forgot-password'} className="underline text-danger">resetting your password</Link>.</p>}

                                {this.props.loggingIn === true

                                // <p>Now logging you in, please wait ...</p>
                                  ? <img id="loading" src={'images/icons/loading.svg'} width="80" height="80" className="text-center center-block" />

                                  : <button className="btn btn-cta btn-lg btn-mobile-block" id="LoginButton">Log In</button>

                                }

                                {/* <button className="btn btn-cta btn-lg btn-mobile-block">Log In</button> */}

                        <div className="new-account">
                            <p className="lead text-center bt-1 mt-30 pt-30">Don&apos;t have an account?</p>
                            <p className="text-center"><Link to="/get-started" className="btn btn-secondary btn-sm">Create an account</Link></p>

                        </div>
                        <div className="text-center pt-30 mt-30 bt-1">
                                <p className="text-xs">
                                    &copy; {(new Date().getFullYear())} Liquid11, Sea Lake Road, Lowestoft, Suffolk, NR32 3LQ, Company number: 04404380 Regulated by Ofcom.
                                </p>
                            </div>

                            </div>
                        </form>

                    </div>

                    <div id="random" className="d-none d-sm-block full-img text-lightest">
                        {/* <div className="content text-start">
                                <span className="line1 text-500 text-uppercase" style={{letterSpacing:"-5px"}}>
                                    A.I. Caller<br/>Greeting<span className="text-xs text-300 absolute">&trade;</span>
                                </span>

                                <p className="line2 text-500 text-cta pt-20 text-italic">
                                Recognise &amp; Greet Mobile Callers By Name
                                </p>

                                <p className="line3 text-400 text-lightest pt-30">
                                    This powerful free feature will greet your mobile callers by name, <br />ensuring they feel special &amp; welcomed on every call.
                                 </p>

                                 <p className="line4 text-500 text-cta pt-20 underline">
                                    Log In Now To Try
                                </p>

                        </div> */}
                    </div>
                </div>
            </div>
    )
  }

  componentDidMount () {
    document.title = 'Login to SwitchboardFREE'
    const user = JSON.parse(localStorage.getItem('user'))
    if (user && user.token) {
      this.props.history.push('/customer/numbers')
    }

    const random = Math.floor(Math.random() * 2) + 0
    const bigSize = [
      "url('/images/backgrounds/login/login-1.jpg')",
      "url('/images/backgrounds/login/login-3.jpg')"
      // "url('/images/backgrounds/login/ai-login-bg.jpg')"
    ]
    moment().month() === 11
      ? document.getElementById('random').style.backgroundImage = "url('/images/backgrounds/login/xmas2.jpg')"
      : document.getElementById('random').style.backgroundImage = bigSize[random]

    const url = window.location.href
    const authFail = getQueryStringByParameter('authError', url)
    const email = getQueryStringByParameter('email', url)
    const redirect = getQueryStringByParameter('redirect', url)

    if (redirect) { this.setState({ redirect }) }

    if (email) { this.props.updateUsername(email) }

    if (authFail) {
      document.getElementById('retryLoginMsg').style.display = 'block'
    }
  }
}

export default connect(
  state => {
    return ({
      userName: state.home.login.username,
      password: state.home.login.password,
      loggingIn: state.home.login.loggingIn,
      loginFailed: state.home.login.loginFailed
    })
  },
  {
    login: fromHome.handleLogin,
    updateUsername: fromHome.updateUsername,
    updatePassword: fromHome.updatePassword,
    notifyOfFooterChange: fromApp.notifyOfFooterChange
  }
)(LoginPage)
