import React from 'react'
import { Button, FloatingLabel, Form, Modal, OverlayTrigger, Popover } from 'react-bootstrap'
import { connect } from 'react-redux'
import Select from 'react-select'
import AddressLookup from '../../components/AddressLookup'
import { AnnualDiscount, BasicOutboundPlan, billingPeriod, productBillingOption, ProductIds, UnlimitedOutboundPlan, validationStates } from '../../constants/Constants'
import { fetchHelper } from '../../helpers/fetchHelper'
import { calculateRemainingMonth } from '../../helpers/utils'
import { getConfigValidators } from '../../selectors'
import * as configurationActions from '../../store/Configuration'
import { getProductPrice } from '../../store/Home'
import { handleDeliveryAdd1Change, handleDeliveryAdd2Change, handleDeliveryCountyChange, handleDeliveryNameChange, handleDeliveryNoteChange, handleDeliveryPcodeChange, handleDeliveryTownChange, handleNotificationChange, handleShowPaymentOptionsModal, handleTPSProtectionEnabledChange } from '../../store/SBF'
import LoadingNotification from '../LoadingNotification'
import ChoosePayment from '../purchase/ChoosePayment'

const formatGroupLabel = data => (
	<div className="p-5 dropdown-group-label">
		<span>{data.label}</span>
		<span className="group-badge">{data.options.length}</span>
	</div>
)

const cliPopover = (
	<Popover>
		<p>This is the number that will be displayed when this user dials out from their phone.</p>
	</Popover>
)

const tpsPopover = (
	<Popover>
		<p>TPS Protection will protect your users from calling numbers that are registered on the TPS (Telephone Preference Service)</p>
	</Popover>
)

class AddUsers extends React.Component {
  state = {
    isLoading: true,
    billingPeriod: billingPeriod.MONTH,
    showForm: false,
    billingDay: 1,
    showAddress: false
  }

  constructor (props) {
    super(props)
  }

  componentDidMount () {
    const context = this
    this.setState({ isLoadingPlans: true, createEndpoint: true }, () => {
      // Get outbound plans
      const url = 'api/Purchase/GetPackages'
      fetchHelper.getJson(url)
        .then(res => {
          let packages = res.data.packages
          if (packages.length > 1) { packages = packages.filter(f => f.numberType !== '0800') }

          context.setState({
            userToAdd: {
              name: '',
              email: '',
              mobile: '',
              cli: ''
            },
            selectedPlan: packages[0],
            purchaseTpsProtection: false,
            isLoadingPlans: false,
            plans: packages.sort((a, b) => parseFloat(b.monthlyPrice) - parseFloat(a.monthlyPrice))
          })
        }).catch(err => console.error(err))
    })

    // Get customers billing day
    fetchHelper.getJson('api/Users/GetCustomersBillingDay').then(async (res) => {
      this.setState({ billingDay: res.data })
    })

    // Retrieve cli's for customer
    const url = 'api/Outbound/RetrieveOutboundClis'
    fetchHelper.getJson(url)
      .then(res => {
        this.setState({ clis: res.data })
      }).catch(err => console.error(err))

    this.props.getProductDetails([
      { productId: ProductIds.endpoint, monthlyOption: productBillingOption.endpointBillingOptionMonthly }
    ])
  }

  render () {
    const { validation } = this.props
    const totals = {
      today: this.state.billingPeriod === billingPeriod.MONTH
        ? calculateRemainingMonth(this.state.selectedPlan?.monthlyPrice ?? 0, this.state.billingDay)
        : this.state.billingPeriod === billingPeriod.BIANNUAL
          ? ((this.state.selectedPlan?.monthlyPrice ?? 0) * 5) + calculateRemainingMonth(this.state.selectedPlan?.monthlyPrice ?? 0, this.state.billingDay)
          : ((this.state.selectedPlan?.monthlyPrice ?? 0) * 11 + calculateRemainingMonth(this.state.selectedPlan?.monthlyPrice ?? 0, this.state.billingDay)) - ((this.state.selectedPlan?.monthlyPrice ?? 0) * 11 + calculateRemainingMonth(this.state.selectedPlan?.monthlyPrice ?? 0, this.state.billingDay)) * AnnualDiscount,
      monthly: this.state.selectedPlan?.monthlyPrice ?? 0,
      biannual: (this.state.selectedPlan?.monthlyPrice ?? 0) * 6,
      annually: ((this.state.selectedPlan?.monthlyPrice ?? 0) * 12 - ((this.state.selectedPlan?.monthlyPrice ?? 0) * 12 * AnnualDiscount))
    }

    if (this.state.freeHandset !== true && this.state.selectedPlan?.packageId === UnlimitedOutboundPlan && (this.state.billingPeriod === billingPeriod.YEAR || this.state.billingPeriod === billingPeriod.BIANNUAL)) {
      this.setState({ freeHandset: true })
    } else if (this.state.freeHandset !== true && this.state.selectedPlan?.packageId === BasicOutboundPlan && this.state.billingPeriod === billingPeriod.YEAR) {
      this.setState({ freeHandset: true })
    }

    return (
			<>
				{this.state.isLoadingPlans || this.state.isPurchaseInProgress &&
					<LoadingNotification loadingText={'Loading plans...'} isLoading={true} width={60} height={60} />}

				{(!this.state.isLoadingPlans && !this.state.isPurchaseInProgress && this.state.plans && this.state.plans.length > 0 && !this.state.showForm) &&
					<>
						<p className="text-500 text-sm mt-0">
							Step 1: Choose your perfect plan:
						</p>
						{this.state.plans.map((plan, i) => (
							<div className={'bg-dull p-20 mb-10 cursor-pointer zoom'} onClick={() => this.setState({ selectedPlan: plan })} style={{ backgroundColor: i === 0 ? '#d6e8ef' : '' }}>
								<div className="row">
									<div className="col-md-1 col-sm-2 col-xs-2">
										<span className="styled-checkbox checkbox-light float-start" style={{ margin: '-4px 0 0 0' }}>
											<Form.Check inline checked={this.state.selectedPlan && this.state.selectedPlan.packageId === plan.packageId} />
										</span>
									</div>
									<div className="col-md-11 col-sm-10 col-xs-10">
										<p className="text-500 m-0">
											{plan.packageName} &pound;{plan.monthlyPrice} / month {i === 0 && <span className="bg-cta text-light brad-5 p-5 text-sm float-end">Best Value</span>}
										</p>
										<p className="text-sm m-0">
											{plan.packageName.includes('Unlimited')
											  ? 'Includes unlimited minutes to UK landline and mobile'
											  :												(plan.outboundSeconds.landlineSeconds + plan.outboundSeconds.mobileSeconds) === 0
											      ? 'Calls from 3p per min'
											      :													`Includes ${((plan.outboundSeconds.landlineSeconds + plan.outboundSeconds.mobileSeconds) / 60).toFixed(0)} call minutes`
											}
										</p>
									</div>
								</div>
							</div>
						))}

						{!this.state.isLoadingPlans && !this.state.isPurchaseInProgress &&
							<div className="row">
								<div className="col-12">
									<hr className="bt-1-dark mt-0 mb-10" />
								</div>
								<div className="col-md-1 col-sm-2 col-xs-2">
									<span className="styled-checkbox checkbox-light float-start pt-10" style={{ margin: '-4px 0 0 0' }}>
										<Form.Check inline checked={this.state.purchaseTpsProtection} onChange={() => this.setState({ purchaseTpsProtection: !this.state.purchaseTpsProtection })} />
									</span>
								</div>
								<div className="col-md-11 col-sm-10 col-xs-10">
									<p className="text-500 m-0 pt-10">Enable TPS Protection {this.props.services.hasTPSProtection ? '(+£2/month)' : '(+£7/month)'}
										<OverlayTrigger trigger={['hover', 'focus']} placement="right" overlay={tpsPopover}>
											<span className="icon icon-info2 ml-5 text-dark-light text-xs"></span>
										</OverlayTrigger>
									</p>
								</div>
								<div className="col-12">
									<hr className="bt-1-dark mt-20 mb-20" />
								</div>
							</div>

						}

						<ul className="pl-0 tick-list text-start mb-30 mt-10 text-400 ml-25">
							<li>
								All plans include <em className="text-500">Unlimited calls</em> answered on our iOS/Android App, PC/Laptop Softphone or VoIP phones
							</li>
						</ul>

						<p className="text-500 text-sm mt-0">
							Step 2: Choose your payment option:
						</p>

						<div className="bg-cta-faded brad-10 p-20 mb-20 mt-0 text-left">
							<Button
								variant="lightest"
								size="sm"
								className={`brad-10 p-5 btn-radio mr-10 bg-light mr-20 btn-block mb-10 b-1 no-shadow ${this.state.billingPeriod === billingPeriod.MONTH ? 'active' : ''
									}`}
								onClick={() => this.setState({ billingPeriod: billingPeriod.MONTH })}
							>
								<div className="row">
									<div className="col-xs-8 col-xs-9 text-start">
										<div className="row">
											<div className="col-12">
												<span className="radio-circle">
													<span className="radio-checked"></span>
												</span>
												<span className="radio-text heading">
													<span className="text-500 pr-10">Monthly</span>
												</span>
											</div>
											<div className="col-12 pl-xs-40 pl-55">
												<span className="radio-text">
													<span className="text-italic text-sm ">
														<span>
															Pay &pound;{(totals.monthly).toFixed(2)} each month.
														</span>
													</span>
												</span>
											</div>
										</div>

									</div>
								</div>
							</Button>
							<Button
								variant="lightest"
								size="sm"
								className={`brad-10 p-5 btn-radio mr-10 bg-light mr-20 btn-block mb-10 b-1 no-shadow ${this.state.billingPeriod === billingPeriod.BIANNUAL ? 'active' : ''
									}`}
								onClick={() => this.setState({ billingPeriod: billingPeriod.BIANNUAL })}
							>
								<div className="row">
									{this.state.selectedPlan?.packageId === UnlimitedOutboundPlan &&
										<div className="col-4 col-xs-3">
											<img
												src="/images/freebies.jpg"
												alt="Free Earbuds + Phone"
											/>
										</div>
									}
									<div className="col-xs-8 col-xs-9 text-start">
										<div className="row">
											<div className="col-12">
												<span className="radio-circle">
													<span className="radio-checked"></span>
												</span>
												<span className="radio-text heading">
													<span className="text-500 pr-10">Bi-Annual</span>
												</span>
											</div>
											<div className="col-12 pl-xs-40 pl-55">
												<span className="radio-text">
													<span className="text-italic text-sm ">
														{this.state.selectedPlan?.packageId === UnlimitedOutboundPlan
														  ? <span>
																Pay &pound;{(totals.biannual).toFixed(2)}{' '}
																every 6-months + get Bluetooth Earbuds & a VoIP Deskphone <span className="text-500">FREE</span>
																<span className="text-cta text-500 ml-10">
																	(Worth &pound;90)
																</span>
															</span>
														  :															<span>
																Pay &pound;{(totals.biannual).toFixed(2)}{' '}
																every 6-months.
															</span>
														}

													</span>
												</span>
											</div>
										</div>

									</div>
								</div>
							</Button>
							<Button
								variant="lightest"
								size="sm"
								className={`brad-10 p-5 btn-radio mr-10 bg-light mr-20 btn-block mb-10 b-1 no-shadow ${this.state.billingPeriod === billingPeriod.YEAR ? 'active' : ''
									}`}
								onClick={() => this.setState({ billingPeriod: billingPeriod.YEAR })}
							>
								<div className="row">
									{(this.state.selectedPlan?.packageId === UnlimitedOutboundPlan || this.state.selectedPlan?.packageId === BasicOutboundPlan) &&
										<div className="col-4 col-xs-3">
											<img
												src="/images/freebies.jpg"
												alt="Free Earbuds + Phone"
											/>
										</div>
									}
									<div className="col-xs-8 col-xs-9 text-start">
										<div className="row">
											<div className="col-12">
												<span className="radio-circle">
													<span className="radio-checked"></span>
												</span>
												<span className="radio-text heading">
													<span className="text-500 pr-10">Annually</span>
												</span>
											</div>
											<div className="col-12 pl-xs-40 pl-55">
												<span className="radio-text">
													<span className="text-italic text-sm ">
														{(this.state.selectedPlan?.packageId === UnlimitedOutboundPlan || this.state.selectedPlan?.packageId === BasicOutboundPlan)
														  ? <span>
																Pay &pound;{(totals.annually).toFixed(2)}{' '}
																every year. Save {AnnualDiscount * 100}% + get Bluetooth Earbuds & a VoIP Deskphone <span className="text-500">FREE</span>
																<span className="text-cta text-500 ml-10">
																	(Worth &pound;90)
																</span>
															</span>
														  :															<span>
																Pay &pound;{(totals.annually).toFixed(2)}{' '}
																every year
															</span>
														}
													</span>
												</span>
											</div>
										</div>

									</div>
								</div>
							</Button>
						</div>

						<Button variant="cta" size="lg" className="btn-block mt-20 mb-30" onClick={() => this.setState({ showForm: true })}>
							Continue <span className="icon icon-arrow-right8 text-xs ml-5"></span>
						</Button>
					</>
				}

				{(this.state.userToAdd && !this.state.isPurchaseInProgress && this.state.showForm) &&
					<>
						<p className="text-500 text-sm mt-0 mb-0">
							Step 3: Enter your users details:
						</p>
						<div className="mt-10">
							<div className="form-group">
								<label className={''}>Name</label>
								<input type='text'
									className={'form-control mb-10'}
									value={this.state.userToAdd.name}
									onChange={e => this.setState({ userToAdd: { ...this.state.userToAdd, name: e.target.value } })}
								/>
							</div>

							<div className="form-group">
								<label className={`${validation && validation.userToAdd && validation.userToAdd.emailValid === validationStates.INVALID ? 'text-danger' : ''}`}>Email address</label>
								<input type='text'
									className={`form-control mb-10 ${validation && validation.userToAdd && validation.userToAdd.emailValid === validationStates.INVALID ? 'has-error' : ''}`}
									value={this.state.userToAdd.email}
									onChange={e => this.setState({ userToAdd: { ...this.state.userToAdd, email: e.target.value } })}
									onBlur={e => this.props.validateUserToAdd({
									  val: e.target.value,
									  type: 'email',
									  validation
									})}
								/>
								{validation && validation.userToAdd && validation.userToAdd.emailValid === validationStates.INVALID &&
									<Form.Text className="text-sm text-danger text-400">
										Please enter a valid email address
									</Form.Text>
								}
							</div>

							<div className="form-group">
								<label className={`${validation && validation.userToAdd && validation.userToAdd.mobileValid === validationStates.INVALID ? 'text-danger' : ''}`}>Mobile number</label>
								<input type='tel'
									className={`form-control mb-10 ${validation && validation.userToAdd && validation.userToAdd.mobileValid === validationStates.INVALID ? 'has-error' : ''}`}
									value={this.state.userToAdd.mobile}
									onChange={e => this.setState({ userToAdd: { ...this.state.userToAdd, mobile: e.target.value } })}
									onBlur={e => this.props.validateUserToAdd({
									  val: e.target.value,
									  type: 'mobile',
									  validation
									})}
								/>
								{validation && validation.userToAdd && validation.userToAdd.mobileValid === validationStates.INVALID &&
									<Form.Text className="text-sm text-danger text-400">
										Please enter a valid mobile number
									</Form.Text>
								}
							</div>

							<div className="form-group">
								<label htmlFor="cli">
									Cli
									<OverlayTrigger trigger={['hover', 'focus']} placement="right" overlay={cliPopover}>
										<span className="icon icon-info2 ml-5 text-dark-light text-xs"></span>
									</OverlayTrigger>
								</label>
								<Select
									id="cliSelect"
									className="form-control select-menu"
									classNamePrefix="custom"
									onChange={e => this.setState({ showCliError: false, userToAdd: { ...this.state.userToAdd, cli: e } })}
									options={this.state.clis}
									simpleValue
									name="cliSelect"
									value={this.state.userToAdd.cli}
									isLoading={!this.state.clis}
									formatGroupLabel={formatGroupLabel}
								/>
								{this.state.showCliError &&
									<div className="alert alert-danger mt-5 p-10">
										Please choose a number
									</div>
								}
							</div>

							<p className="text-center mt-30 lead">
								Total payment today: <span className="text-500">&pound;{totals.today.toFixed(2)}</span><br />
								<span className="text-xs text-400">
									Followed by &pound;{this.state.billingPeriod === billingPeriod.MONTH ? `${totals.monthly.toFixed(2)} per month` : this.state.billingPeriod === billingPeriod.BIANNUAL ? `${totals.biannual.toFixed(2)} every 6-months` : `${totals.annually.toFixed(2)} every year`} for your {this.state.selectedPlan.packageName} plan.
								</span>
							</p>

							<Button variant="cta" size="lg" className="btn-block mt-20" onClick={() => this.handleAddUserToAccount(validation)}
								disabled={this.state.userToAdd.name === '' || this.state.isPurchaseInProgress || (validation && validation.userToAdd && (validation.userToAdd.mobileValid !== validationStates.VALID || validation.userToAdd.emailValid !== validationStates.VALID) || !validation.userToAdd)}>
								Add To Account
							</Button>
							<Button variant="link" className="btn-block mt-20 mb-30" onClick={() => this.setState({ showForm: false })}>
								<span className="icon icon-arrow-left8 text-xs mr-5"></span> Change plan
							</Button>

						</div>
					</>
				}
				<ChoosePayment
					total={this.state.wpAmount}
					clientId={this.props.clientId}
					purchaseData={this.state.key}
					email={this.props.userDetails.email}
					name={`${this.props.userDetails.fName} ${this.props.userDetails.sName}`}
					mandate={!this.props.services.hasAgreement}
					returnRoute="/customer/outbound-management/"
				/>

				<Modal className="address-modal" size="sm" show={this.state.showAddress} onHide={e => this.setState({ showAddress: false })} backdrop="static">
					<Modal.Body>
						<span className="close-modal" onClick={e => this.setState({ showAddress: false })}>
							<span>&times;</span>
						</span>
						<h3 className="text-500 text-md text-center mb-30">
							Please enter your delivery details below
						</h3>
						<div className="direction-block dir-down bb-1 mb-40 pt-0 pb-30">
							<AddressLookup
								showDeliveryOnly={true}
								showDeliveryAddress={true}
								padding="p-0"
								deliveryAddress={this.props.delivery}
								handleUpdateAddress={(e) => this.handleUpdateAddress(e)}
							/>
						</div>

						<div className="direction-block dir-down bb-1 mb-40 pt-0 pb-30">
							<FloatingLabel
								label="Notes (i.e. Leave in reception)"
								className="mb-3 text-lg d-flex"
							>
								<Form.Control
									value={this.state.notes}
									onChange={e => this.setState({ notes: e.target.value })}
									type="tel"
									placeholder='Notes' />
							</FloatingLabel>
						</div>
					</Modal.Body>
					<Modal.Footer className="bt-0 text-center pb-30">
						{this.state.isPurchaseInProgress
						  ? <LoadingNotification loadingText={'Please wait...'} textColor="text-dark" isLoading={true} width={50} height={50} />
						  :							<>
								<Button variant="cta" size="lg" className="btn-block" onClick={() => this.handleUserPurchase()}
									disabled={((this.props.delivery.add1 === '' || this.props.delivery.add1 === undefined) || (this.props.delivery.pcode === '' || this.props.delivery.pcode === undefined))}
								>
									Finish &amp; Pay
								</Button>

								<p className="text-sm mt-10 cursor-pointer underline d-block w-100" onClick={() => this.setState({ showAddress: false })}>Or, cancel order</p>
							</>
						}
					</Modal.Footer>
				</Modal>

			</>
    )
  }

  handleUpdateAddress (address) {
    this.props.handleDeliveryNameChange({ newValue: address.name })
    this.props.handleDeliveryAdd1Change({ newValue: address.add1 })
    this.props.handleDeliveryAdd2Change({ newValue: address.add2 })
    this.props.handleDeliveryCountyChange({ newValue: address.add3 })
    this.props.handleDeliveryTownChange({ newValue: address.add4 })
    this.props.handleDeliveryPcodeChange({ newValue: address.pcode })
  }

  handleAddUserToAccount (validation) {
    const context = this
    const newUser = context.state.userToAdd

    context.setState({ isPurchaseInProgress: true })
    context.props.isPurchasing(true)
    // Check all is good
    if (validation && validation.userToAdd && (validation.userToAdd.mobileValid === validationStates.VALID && validation.userToAdd.emailValid === validationStates.VALID)) {
      // Good to go
      if (this.state.freeHandset === true) {
        // Let's get the address
        this.setState({ showAddress: true, isPurchaseInProgress: false })
      } else {
        this.handleUserPurchase()
      }
    } else {
      // Details missing or invalid
      context.setState({ isPurchaseInProgress: false })
    }
  }

  handleUserPurchase () {
    const context = this
    const newUser = context.state.userToAdd

    context.setState({ isPurchaseInProgress: true })

    if (!context.props.services.hasTPSProtection && context.state.purchaseTpsProtection) {
      const url = 'api/Purchase/PurchaseTPSProtection'
      const eps = []
      eps.push(this.state.clickedEndpointId)

      const data = {
        EndpointIds: eps
      }

      fetchHelper.postJson(url, data)
        .then(res => {
          if (!res.data.isSuccess) {
            context.props.notify({ message: 'Error purchasing TPS Protection', isError: true })
          } else {
            context.props.handleTPSProtectionEnabledChange(true)
            context.props.notify({ message: 'TPS Protection has been purchased' })
          }
        }).catch(err => console.error(err))
    }

    const url = 'api/Purchase/AddNewSipUserToClient'
    fetchHelper.postJson(url, {
      User: {
        Name: newUser.name,
        Mobile: newUser.mobile,
        Email: newUser.email,
        CliId: Number(newUser.cli.value),
        PurchaseTps: context.state.purchaseTpsProtection
      },
      PackageId: Number(context.state.selectedPlan.packageId),
      IsFreeHandset: this.state.freeHandset,
      BillingPeriod: this.state.billingPeriod,
      DeliveryDetails: this.props.delivery
    }).then(res => {
      if (res.data && (res.data.success === true || res.data.isSuccess === true)) {
        this.setState({ isPurchaseInProgress: false }, () => {
          context.props.isPurchasing(false)
          context.props.notify({ message: 'Your new user has been added.' })
          context.props.closeModal()
          context.props.reloadEndpoints()
          context.props.reloadDetails()
        })
      } else {
        context.setState({ key: res.data.key, wpAmount: res.data.amount })
        context.props.handleShowPaymentOptionsModal()
      }
    }).catch(err => console.error(err))
  }
}

export default connect(
  state => {
    const deliveryData = state.sbfApp.deliveryData

    return ({
      delivery: {
        name: deliveryData.deliveryName ? deliveryData.deliveryName.value : '',
        add1: deliveryData.deliveryAdd1 ? deliveryData.deliveryAdd1.value : '',
        add2: deliveryData.deliveryAdd2 ? deliveryData.deliveryAdd2.value : '',
        add3: deliveryData.deliveryTown ? deliveryData.deliveryTown.value : '',
        add4: deliveryData.deliveryCounty ? deliveryData.deliveryCounty.value : '',
        pcode: deliveryData.deliveryPcode ? deliveryData.deliveryPcode.value : ''
      },
      productDetails: state.home.productDetails.products,
      validation: getConfigValidators(state),
      clientId: state.sbfApp.authentication.clientId,
      services: state.sbfApp.enabledServices,
      userDetails: state.sbfApp.authentication
    })
  },
  {
    getProductDetails: getProductPrice,
    notify: handleNotificationChange,
    handleShowPaymentOptionsModal,
    validateUserToAdd: configurationActions.validateUserToAdd,
    handleDeliveryNameChange,
    handleDeliveryAdd1Change,
    handleDeliveryAdd2Change,
    handleDeliveryTownChange,
    handleDeliveryCountyChange,
    handleDeliveryPcodeChange,
    handleDeliveryNoteChange,
    handleTPSProtectionEnabledChange
  }
)(AddUsers)
