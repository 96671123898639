import React from 'react'
import { Button, Modal, FloatingLabel, Form } from 'react-bootstrap'
import { Helmet } from 'react-helmet'
import { connect } from 'react-redux'
import { fetchHelper } from '../../helpers/fetchHelper'
import { isLoggedIn } from '../../selectors'
import Logos from '../../components/ClientLogos'
import DisplayMainFooter from '../../components/DisplayMainFooter'
import ChoosePayment from '../../components/purchase/ChoosePayment'
import CarouselWidget from '../../components/Reviews/CarouselWidget'
import StatsBanner from '../../components/StatsBanner'
import * as fromHome from '../../store/Home'
import { Link } from 'react-router-dom'
import { getQueryStringFromProps } from '../../helpers/utils'
import { handleDeliveryNameChange, handleDeliveryAdd1Change, handleDeliveryAdd2Change, handleDeliveryTownChange, handleDeliveryCountyChange, handleDeliveryPcodeChange, handleDeliveryNoteChange } from '../../store/SBF'
import AddressLookup from '../../components/AddressLookup'
import LoadingNotification from '../../components/LoadingNotification'


class UpdateBilling extends React.Component {
  state = {
    showAddress: false,
    showError: false,
    finished: false
  }

  constructor(props) {
    super(props)
  }

  render() {
    return <><DisplayMainFooter />
      <Helmet>
        <body className={'body-simple bg-dull'} />
        <title>Claim Your Free Bluetooth Earbuds Today! - SwitchboardFREE</title>
        <style type="text/css">{`
						.button-bar {
							background: #fff;
							border-top: #ddd 1px solid;
							padding: 20px;
							position: fixed;
							bottom: 0;
							left: 0;
							right: 0;
							z-index: 900;
							text-align: center;
							padding-bottom: 10px;
						}
						.background {
							background-position: 95% 55% !important;
							background-size: 40% !important;
						}
						.background.smaller {
							background-position: 95% 95% !important;
							background-size: 40% !important;
						}
            .form-group.animated .form-control {
              height: 65px;
            }
            .form-group.animated .form-label {
              top: 21px;
            }
					}
					`}</style>
      </Helmet>
      <div className="p-50 background" style={{ background: '#fff url(/images/campaigns/headphones/headphones.png) no-repeat' }}>
        <div className="container p-xs-0">
          <div className="row mb-50">
            <div className="col-md-10 mx-auto text-center">
              <h1 className="text-600">
                We've Upgraded Our <br className="d-none d-xs-block" />Payment Provider
              </h1>
              <h2 className="text-500 h3 mt-30">
                Simply update your payment details today &amp; <br className="d-none d-xs-block" />
                claim your <span className="text-cta">FREE</span> Bluetooth Earbuds - <em className="text-cta text-500">worth &pound;35!</em>
              </h2>
              <p className="text-lg text-400 mt-30 mb-0">
                Available while stocks last.
              </p>

            </div>
          </div>
          <div className="row">
            <div className="col-lg-7 col-md-8 col-sm-12">
              <div className="bg-dull p-50 p-sm-30 p-xs-20 brad-20 pr-100 pl-100 pr-sm-50 pl-sm-50 pr-xs-20 pl-xs-20 clearfix">
                {this.state.finished
                  ? <>
                    <h3 className="text-500 mt-30">
                      All done!
                    </h3>
                    <p className="text-400 lead mb-10 mt-0">We'll get your free Bluetooth Earbuds sent out shortly.</p>
                    <p className="text-md mt-30">
                      If you require these to be sent to a different address to the one on your account, please email us at <a href="mailto:support@switchboardfree.co.uk" className="text-primary underline">support@switchboardfree.co.uk</a> as soon as possible.
                    </p>
                    <p className="mt-30">
                      <Button variant="cta" size="lg" className="pl-100 pr-100 pl-xs-20 pr-xs-20" onClick={() => this.props.history.push('/customer/dashboard')}>Go to my dashboard</Button>
                    </p>
                  </>
                  : this.state.showAddress ?
                    <>
                      <h3 className="text-500 mt-0">
                        Great, you're all updated!
                      </h3>
                      <p className="text-400 lead mb-10 mt-0">
                        To claim your FREE Bluetooth Earbuds, simply enter your delivery details below.
                      </p>

                      <div className="direction-block dir-down bb-1 mb-40 pt-0 pb-30">
                        <AddressLookup
                          showDeliveryOnly={true}
                          showDeliveryAddress={true}
                          padding="p-0"
                          deliveryAddress={this.props.delivery}
                          handleUpdateAddress={(e) => this.handleUpdateAddress(e)}
                        />
                      </div>

                      <div className="direction-block dir-down bb-1 mb-40 pt-0 pb-30">
                        <FloatingLabel
                          label="Notes (i.e. Leave in reception)"
                          className="mb-3 text-lg d-flex"
                        >
                          <Form.Control
                            value={this.state.notes}
                            onChange={e => this.setState({ notes: e.target.value })}
                            type="tel"
                            placeholder='Notes' />
                        </FloatingLabel>
                      </div>

                      {this.state.showError === true &&
                        <div className="alert alert-danger">
                          Oops, there was a problem. Please try again, or contact our UK-based support team on <a href="tel:02031891213">0203 189 1213</a>, or <a href="mailto:support@switchboardfree.co.uk">support@switchboardfree.co.uk</a>
                        </div>
                      }

                      {this.state.isLoading ?
                        <LoadingNotification loadingText={'Please wait...'} textColor="text-dark" isLoading={true} width={50} height={50} /> :
                        <Button variant="cta" size="lg" className="btn-block" onClick={() => this.handleClaimEarbuds()}>
                          Claim My Free Earbuds
                        </Button>
                      }

                    </> :
                    <>
                      <p className="text-500">
                        We've changed our payment provider!
                      </p>
                      <p className="text-md mb-20 mt-30">
                        And, because your time is precious, as a thank you for re-entering your payment details, we will send you a <span className="text-500">FREE</span> pair of Bluetooth earbuds, <em className="text-cta text-500">Worth &pound;35!</em>
                      </p>

                      <ChoosePayment
                        total={0}
                        clientId={this.state.clientId}
                        email={this.state.email}
                        name={this.state.name}
                        mandate={true}
                        returnRoute={`/deal/free-bluetooth-earbuds/${this.state.token}&paymentsuccess=true`}
                        inline={true}
                        newAgreement={true}
                        isRedirect={false}
                        handleParentHideModal={() => this.setState({ showAddress: true })}
                      />

                      <span className="bg-light brad-10 p-20 text-400 d-block mt-10 mb-30">
                        Update today &amp; claim your <span className="text-cta text-500">FREE bluetooth earbuds - <em className="text-cta text-500">Worth &pound;35</em>
                        </span>
                      </span>
                    </>
                }
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-light pt-50 pb-50">
        <h3 className="text-center text-500 mb-20 text-lg">Proud to work with leading UK businesses</h3>
        <Logos style="light" />
      </div>

      <div className="full-width bg-dull">
        <div className="container pl-xs-0 pr-xs-0">
          <CarouselWidget />
        </div>
      </div>

      <div className="full-width full-secondary p-20">
        <div className="row">
          <div className="col-md-12">
            <h3 className="text-center text-lightest mt-10 text-100 text-xl">
              <StatsBanner />
            </h3>
          </div>
        </div>
      </div>

      <div className="full-width bg-light">
        <div className="container">
          <div className="row">
            <div className="col-md-12 text-center">
              <h3 className="text-500">SwitchboardFREE</h3>
              <h6 className="text-400">The UK's favourite call management system</h6>
              <p className="text-400">Instant activation  &bull;  No long-term contracts  &bull;  Award-winning UK support</p>
            </div>
          </div>
        </div>
      </div>

      <Modal size="sm" show={this.state.showLogin}>
        <Modal.Header>
          <Modal.Title className="mx-auto">Log into your account</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-md-12 text-center pb-30">
              <form name="form" className="text-left" onSubmit={e => this.props.login({
                userName: this.props.userName,
                password: this.props.password,
                event: e,
                history: this.props.history,
                persist: document.getElementById('persistLogin').checked,
                route: `/deal/free-bluetooth-earbuds?t=${this.state.token}`
              })}>
                <div className="row">
                  <div className="col-xs-12">
                    <p className="lead text-400">Oops, it looks like your token has expired, or is not valid. Please log into your account below.</p>
                  </div>
                  <div className="col-xs-12">
                    <label>Email</label>
                    <input className="form-control" placeholder="Your login email..." type='email' value={this.props.userName} onChange={e => this.props.updateUsername(e.target.value)} />
                  </div>
                  <div className="col-xs-12 mt-20">
                    <label>Password</label>
                    <input className="form-control" placeholder="Your password..." type='password' value={this.props.password} onChange={e => this.props.updatePassword(e.target.value)} />
                  </div>
                </div>
                <div className="pt-10 pb-10">
                  <div className="col-xs-6 pl-0">
                    <input type="checkbox" id='persistLogin' defaultChecked={true} /> <label>Remember</label>
                  </div>
                  <div className="col-xs-6 text-right pr-0">
                    <Link to={'/forgot-password'} className="text-xs text-cta">Forgot password?</Link>
                  </div>
                </div>
                <div className="text-center pt-30">
                  {this.props.loggingIn && <p>Now logging you in, please wait ...</p>}

                  {this.props.loginFailed && <p className="alert alert-danger">There has been an error logging in, please check your username and password and try again. If that doesn't help, try <Link to={'/forgot-password'}>resetting your password</Link>.</p>}

                  <button className="btn btn-cta btn-lg btn-mobile-block">Log In</button>

                  <p className="lead text-center bt-1 mt-30 pt-30">Don&apos;t have an account with us yet?</p>
                  <p className="text-center"><Link to="/get-started" className="btn btn-secondary btn-sm">Create an account</Link></p>
                </div>
              </form>
            </div>
          </div>
        </Modal.Body>
      </Modal>

    </>
  }

  handleClaimEarbuds() {
    this.setState({ isLoading: true })
    let deliveryDetails = {
      Name: this.props.delivery.name,
      Add1: this.props.delivery.add1,
      Add2: this.props.delivery.add2,
      Add3: this.props.delivery.add3,
      Add4: this.props.delivery.add4,
      Pcode: this.props.delivery.pcode
    }

    const url = `/api/AccessToken/ClaimFreeEarbuds?t=${this.state.token}`

    fetchHelper.postJson(url, deliveryDetails)
      .then(res => res.data === true ?
        this.setState({ finished: true, isLoading: false }) :
        this.setState({ showError: true, isLoading: false }))
      .catch(err => console.error(err))
  }

  handleUpdateAddress(address) {
    this.props.handleDeliveryNameChange({ newValue: address.name })
    this.props.handleDeliveryAdd1Change({ newValue: address.add1 })
    this.props.handleDeliveryAdd2Change({ newValue: address.add2 })
    this.props.handleDeliveryCountyChange({ newValue: address.add3 })
    this.props.handleDeliveryTownChange({ newValue: address.add4 })
    this.props.handleDeliveryPcodeChange({ newValue: address.pcode })
  }

  componentDidUpdate() {
    if (this.props.isLoggedIn && this.state.showLogin == true) {
      this.setState({ showLogin: false }, () => {
        this.getPrice()
      })
    }
  }

  componentWillMount() {
    const context = this
    let token = localStorage.getItem('accessToken')
    const query = new URLSearchParams(this.props.location.search)
    if (!token && query) {
      // auth
      token = query.get('t')
    }

    context.setState({ token })
  }

  componentDidMount() {
    const context = this
    const queryString = getQueryStringFromProps(this.props)
    if (queryString) {
      const params = queryString.toLowerCase()

      if (params.includes('paymentsuccess=true')) {
        context.setState({ finished: true })
      }
    } else if (!context.state.token && !this.props.isLoggedIn) {
      // Login
      this.setState({ showLogin: true })
    } else if (context.state.token) {
      fetchHelper.getJson(`/api/AccessToken/GetDetailsForStripe?t=${context.state.token}`)
        .then(res => this.setState({
          name: res.data.name,
          email: res.data.email,
          clientId: res.data.clientId
        }))
        .catch(err => console.error(err))
    } else if (!this.props.isLoggedIn) {
      // Login
      this.props.history.push('/login?redirect=/deal/free-bluetooth-earbuds')
    }
  }
}

export default connect(
  state => {
    const deliveryData = state.sbfApp.deliveryData

    return {
      delivery: {
        name: deliveryData.deliveryName ? deliveryData.deliveryName.value : '',
        add1: deliveryData.deliveryAdd1 ? deliveryData.deliveryAdd1.value : '',
        add2: deliveryData.deliveryAdd2 ? deliveryData.deliveryAdd2.value : '',
        add3: deliveryData.deliveryTown ? deliveryData.deliveryTown.value : '',
        add4: deliveryData.deliveryCounty ? deliveryData.deliveryCounty.value : '',
        pcode: deliveryData.deliveryPcode ? deliveryData.deliveryPcode.value : ''
      },
      isLoggedIn: isLoggedIn(state),
      userDetails: state.sbfApp.authentication,
      userName: state.home.login.username,
      password: state.home.login.password,
      loggingIn: state.home.login.loggingIn,
      loginFailed: state.home.login.loginFailed
    }
  },
  {
    login: fromHome.handleLogin,
    updateUsername: fromHome.updateUsername,
    updatePassword: fromHome.updatePassword,
    handleDeliveryNameChange,
    handleDeliveryAdd1Change,
    handleDeliveryAdd2Change,
    handleDeliveryTownChange,
    handleDeliveryCountyChange,
    handleDeliveryPcodeChange,
    handleDeliveryNoteChange
  }
)(UpdateBilling)
