import moment from 'moment'
import React from 'react'
import { Button, Modal } from 'react-bootstrap'
import Countdown from 'react-countdown'
import { Helmet } from 'react-helmet'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import AddressLookup from '../../components/AddressLookup'
import Logos from '../../components/ClientLogos'
import DisplayMainFooter from '../../components/DisplayMainFooter'
import LoadingNotification from '../../components/LoadingNotification'
import ChoosePayment from '../../components/purchase/ChoosePayment'
import CarouselWidget from '../../components/Reviews/CarouselWidget'
import StatsBanner from '../../components/StatsBanner'
import { BasketItemType, J80Id, validationStates } from '../../constants/Constants'
import { fetchHelper } from '../../helpers/fetchHelper'
import { calculateVat } from '../../helpers/utils'
import { isLoggedIn } from '../../selectors'
import { handleNotificationChange, handleShowCallbackForm, handleShowPaymentOptionsModal, handleShowPaymentPostponedModal } from '../../store/SBF'
import TermsAndConditions from '../../Text/TermsAndConditions'

class Headphones extends React.Component {
  state = {
    isSaving: false,
    qty: 1,
    price: 0,
    discount: 10
  }

  constructor (props) {
    super(props)
  }

  handleChangeQty (isDecrement) {
    let qty = this.state.qty

    if (isDecrement && qty > 1) {
      qty--
    } else {
      qty++
    }

    this.setState({ qty })
  }

  render () {
    const renderCountdown = ({ days, hours, minutes, seconds, completed }) => {
      // Render a countdown
      return <>
				<p className="text-700 text-cta text-uppercase text-md mb-20">
					Price Increase in:
				</p>
				<div className="countdown-timer">
					<div className="timer-block">
						{days}<br /><span className="text-sm">days</span>
					</div>
					<div className="timer-block">
						{hours}<br /><span className="text-sm">hours</span>
					</div>
					<div className="timer-block">
						{minutes}<br /><span className="text-sm">minutes</span>
					</div>
					<div className="timer-block">
						{seconds}<br /><span className="text-sm">seconds</span>
					</div>
				</div>
			</>
    }

    let totalPrice = this.state.price
    if (this.state.offer && totalPrice === this.state.price) { totalPrice = parseFloat(this.state.price - this.state.discount).toFixed(2) }

    return <><DisplayMainFooter />
			<Helmet>
				<body className={'body-simple bg-dull'} />
				{this.state.offer
				  ? <title>{`Order Your Bluetooth Headphones. Get &pound;${this.state.discount} OFF + FREE Delivery!`}</title>
				  :					<title>{`Order Bluetooth Headphones. Get &pound;${this.state.discount} OFF + FREE Delivery!`}</title>
				}
			</Helmet>
			<div className="bg-secondary p-50 p-sm-30 p-xs-15 pt-xs-50 pt-100 pb-100">
				<div className="container p-xs-0 text-lightest">
					<div className="row mb-50">
						<div className="w-1000px center-block text-center">
							<span className="bg-cta p-10 pr-50 pl-50 pl-xs-10 pr-xs-10 text-light text-lg text-500 text-uppercase text-lightest text-xs-sm">
								{!this.state.offer
								  ? 'Premium Bluetooth Earphones'
								  : `${moment().format('MMMM')} Exclusive`
								}
							</span>
							{this.state.offer &&
								<p className="text-lg text-400 mt-30">
									<em><span className="text-cta text-500">HURRY!</span> This Month ONLY! Price will increase soon...</em>
								</p>
							}

							{this.state.offer
							  ? <h1 className="text-lightest">
									Order Bluetooth Headphones
									<br />
									Get &pound;{this.state.discount} OFF + FREE Delivery!
								</h1>
							  :								<h1 className="text-lightest">
									Order Your Bluetooth Headphones
									<br />
									Just &pound;{totalPrice} + FREE Delivery!
								</h1>
							}

							{this.state.offer &&
								<>
									<span className="p-30 bg-darker mb-20 d-inline-block center-block brad-100 mt-50">
										<Countdown date={moment().endOf('month').format('yyyy-MM-DDTHH:mm:ss')} renderer={renderCountdown} />
									</span>
									<p className="text-sm mb-0 p-0">
										{this.state.added} people purchased <span className="text-500">Bluetooth Headphones</span> in the last hour
									</p>
								</>
							}
						</div>
					</div>
					<div className="row">
						<div className={'col-lg-7 col-md-8 col-sm-12'}>
							<div className="bg-dull p-50 p-sm-30 p-xs-20 brad-20 pr-100 pl-100 pr-sm-50 pl-sm-50 pr-xs-20 pl-xs-20 clearfix">
								{this.state.diallerSent === true
								  ? <>
										<h3 className="text-500 mt-30">
											Thanks!
										</h3>
										<p className="text-400 lead mb-10 mt-0 text-dark">We'll give you a call shortly.</p>
										<p className="text-md mt-30 text-dark">
											We've successfully received your request and one of our team will call you shortly.
										</p>
										<p className="mt-30">
											<Button variant="cta" size="lg" className="pl-100 pr-100 pl-xs-20 pr-xs-20" onClick={() => this.props.history.push('/customer/dashboard')}>Go to my dashboard</Button>
										</p>
									</>
								  :									this.state.purchaseComplete === true
								    ? <>
											<h3 className="text-500 mt-30">
												Thanks!
											</h3>
											<p className="text-400 lead mb-10 mt-0 text-dark">Your order is complete.</p>
											<p className="text-md mt-30 text-dark">
												Your earphones will be posted out as soon as possible.
											</p>
										</>
								    :										<>
											{!this.state.offerEnded &&
												<p className="text-500 text-uppercase text-md mb-20 mt-0 text-dark">Price WILL Increase at <span className="text-cta">Midnight {moment().endOf('week').add(1, 'days').format('DD/MM/YY')}!</span></p>
											}

											<div className="row">
												<div className="col-md-12 col-sm-9">
													<>
														<h3 className="text-500 mt-0">
															{this.state.offer &&
																<span className="text-700 text-cta">&pound;{this.state.discount} OFF </span>
															}
															Bluetooth Headphones<br className="d-none d-sm-block" />
														</h3>
														{!this.state.offer
														  ? <>
																<p className="text-dullest text-600">
																	<span className="text-600 text-dark text-italic text-md">Just &pound;{totalPrice}... </span>
																	<span className="text-md">
																		<span className="text-cta text-italic">With FREE Delivery</span>
																	</span>
																</p>
															</>
														  :															<>
																<p className="text-dullest text-600">
																	<strike>Normally &pound;{this.state.price}</strike> - <span className="text-600 text-dark text-italic text-md">Now just &pound;{totalPrice}... </span>
																	<span className="text-md">
																		<span className="text-cta text-italic">SAVE &pound;{this.state.discount}</span> <span className="text-dark">+</span> <span className="text-cta text-italic">FREE Delivery</span>
																	</span>
																</p>
															</>
														}
													</>

													<p className="text-dark text-400 pt-20">
														Whether at work or at play our wireless bluetooth headphones, with the latest in high-resolution audio, offers a truly superior digital music experience and total audio freedom.
													</p>
												</div>
											</div>
											<p className="text-dark text-400 underline pt-20">
												Order today & you’ll enjoy:
											</p>
											<ul className="unstyled text-dark text-500 ml-xs-0 ml-0 pl-0 mt-30">
												<li className="text mb-5"><i className="icon icon-checkmark4 text-cta mr-5"></i><em>True bluetooth 5.1 wireless stereo smart headphones</em></li>
												<li className="text mb-5"><i className="icon icon-checkmark4 text-cta mr-5"></i><em>Digital noise reduction for clear, smooth, undelayed sound</em></li>
												<li className="text mb-5"><i className="icon icon-checkmark4 text-cta mr-5"></i><em>Secure, comfortable, &amp; easy to fit design</em></li>
												<li className="text mb-5"><i className="icon icon-checkmark4 text-cta mr-5"></i><em>Light &amp; compact body for effortless movement</em></li>
												<li className="text mb-5"><i className="icon icon-checkmark4 text-cta mr-5"></i><em>High-capacity polymer battery &amp; charging compartment for longer use</em></li>
												<li className="text mb-5"><i className="icon icon-checkmark4 text-cta mr-5"></i><em>Compatible with most major brands</em></li>
											</ul>
											{this.state.submittingToDialler
											  ? <LoadingNotification loadingText={'Please wait...'} textColor="text-dark" isLoading={true} width={50} height={50} />
											  :												<>

													<div className="row mb-20 mt-30">
														<div className="col-xxs-12 text-center text-xs-start col-sm-3">
															<form className="qty text-center mt-15 center">
																<input
																	type="button"
																	className="btn btn-default"
																	onClick={() => this.handleChangeQty(true)}
																	value="-"
																	disabled={this.state.qty === 1}
																/>
																<input
																	type="text"
																	id="number"
																	className="form-control"
																	value={this.state.qty}
																/>
																<input
																	type="button"
																	className="btn btn-default"
																	onClick={() => this.handleChangeQty()}
																	value="+"
																/>
															</form>
														</div>
														<div className="col-sm-9 text-center">
															<Button variant="cta" size="lg" className="pl-100 pr-100 pl-xs-20 pr-xs-20 btn-block" onClick={() => this.setState({ showDeliveryInfo: true })}>Order now</Button>
														</div>
													</div>

													{this.state.diallerSent === false &&
														<p className="text-400 text-danger text-center pt-20">
															Sorry, there was a problem submitting your request, please try again.
														</p>
													}
													<p className="text-xs text-dark pt-20">
														Works with Apple iPhone, Nextel, Samsung Universal, Sharp, Sony Ericsson, LG, Nokia, HTC, Blackberry, Motorola, Palm &amp; Chinese brands.
													</p>
													<p className="text-dark">
														Want to know more? Give us a call on <a href="tel:02031891213" className="text-500 text-dark">0203 189 1213</a> or, <Button variant="link" className="text-dark" onClick={() => this.handleAddToDialler()}>request a callback</Button>
													</p>
												</>
											}
										</>
								}
							</div>
						</div>
						<div className="col-lg-5 col-md-4 col-sm-12 text-center">
							{!this.state.offer
							  ? <img src="/images/campaigns/headphones/headphones-no-offer.png" className="w-100" />
							  :								<img src="/images/campaigns/headphones/headphones.png" className="w-100" />
							}
						</div>
					</div>
				</div>
			</div>

			<div className="bg-dull p-50 text-center">
				<h3 className="text-center text-500 mb-20 text-lg">Proud to work with leading UK businesses</h3>
				<Logos style="dull" />
			</div>

			<div className="bg-light pt-50 pb-50 text-center">
				<sup>*</sup> Standard terms &amp; conditions apply. <Button variant="link" className="text-dark underline" onClick={() => this.setState({ showTsAndCs: true })}>Click here to find out more</Button>
			</div>

			<div className="full-width bg-dull">
				<div className="container pl-xs-0 pr-xs-0">
					<CarouselWidget />
				</div>
			</div>

			<div className="full-width full-secondary p-20">
				<div className="row">
					<div className="col-md-12">
						<h3 className="text-center text-lightest mt-10 text-100 text-xl">
							<StatsBanner />
						</h3>
					</div>
				</div>
			</div>

			<div className="full-width bg-light">
				<div className="container">
					<div className="row">
						<div className="col-md-12 text-center">
							<h3 className="text-500">SwitchboardFREE</h3>
							<h6 className="text-400">The UK's favourite call management system</h6>
							<p className="text-400">Instant activation  &bull;  No long-term contracts  &bull;  Award-winning UK support</p>
						</div>
					</div>
				</div>
			</div>

			<Modal className="terms-modal" show={this.state.showTsAndCs} onHide={e => this.setState({ showTsAndCs: false })}>
				<Modal.Header closeButton>
					<Modal.Title className="text-500">Terms &amp; Conditions</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<TermsAndConditions />
				</Modal.Body>
				<Modal.Footer>
					<Button variant="primary" onClick={e => this.setState({ showTsAndCs: false })}>Close</Button>
				</Modal.Footer>
			</Modal>

			<Modal size="sm" show={this.state.showDeliveryInfo} onHide={() => this.setState({ showDeliveryInfo: false })}>
				<Modal.Header closeButton>
					<Modal.Title className="text-md">Please enter your delivery details</Modal.Title>
				</Modal.Header>
				<Modal.Body className="p-0">
					{this.state.isPurchasing
					  ? <LoadingNotification loadingText={'Please wait...'} textColor="text-dark" isLoading={true} width={50} height={50} />
					  :						<>
							<div className="direction-block dir-down bb-1 mb-20">
								<AddressLookup showDeliveryOnly={true} showDeliveryAddress={true} />
							</div>

							<div className="p-30 pt-0 text-center">
								<p className="text-500">
									Total payment today: &pound;{parseFloat(totalPrice * this.state.qty)} +VAT
								</p>
								<Button variant="cta" size="xl" className="btn-block text-lg" onClick={() => this.handlePurchase(J80Id, this.state.qty, totalPrice)}
									disabled={!this.props.delivery || (this.props.delivery && (this.props.delivery.pcode.valid !== validationStates.VALID || !this.props.delivery.add1.value || this.props.delivery.add1.value === undefined))}>
									Finish &amp; Pay
								</Button>
							</div>
						</>
					}
				</Modal.Body>
			</Modal>

			<ChoosePayment
				total={this.state.wpAmount ? calculateVat(this.state.wpAmount) : 0}
				clientId={this.props.clientId}
				purchaseData={this.state.key}
				returnRoute={`/get/bluetooth-earphones?t=${this.state.token}`}
				email={this.props.userDetails.email}
				name={`${this.props.userDetails.fName} ${this.props.userDetails.sName}`}
				mandate={!this.props.services.hasAgreement}
			/>

			<Modal size="sm" show={this.state.showLogin}>
				<Modal.Header>
					<Modal.Title>Log into your account</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div className="row">
						<div className="col-md-12 text-center pb-30">
							<form name="form" className="text-start" onSubmit={e => this.props.login({
							  userName: this.props.userName,
							  password: this.props.password,
							  event: e,
							  history: this.props.history,
							  persist: document.getElementById('persistLogin').checked,
							  route: `/get/bluetooth-earphones?${this.state.offer ? 'offer=true' : ''}`
							})}>
								<div className="row">
									<div className="col-xs-12">
										<p className="lead text-400">Oops, it looks like your token has expired, or is not valid. Please log into your account below.</p>
									</div>
									<div className="col-xs-12">
										<label>Email</label>
										<input className="form-control" placeholder="Your login email..." type='email' value={this.props.userName} onChange={e => this.props.updateUsername(e.target.value)} />
									</div>
									<div className="col-xs-12 mt-20">
										<label>Password</label>
										<input className="form-control" placeholder="Your password..." type='password' value={this.props.password} onChange={e => this.props.updatePassword(e.target.value)} />
									</div>
								</div>
								<div className="pt-10 pb-10">
									<div className="col-xs-6 pl-0">
										<input type="checkbox" id='persistLogin' defaultChecked={true} /> <label>Remember</label>
									</div>
									<div className="col-xs-6 text-end pr-0">
										<Link to={'/forgot-password'} className="text-xs text-cta">Forgot password?</Link>
									</div>
								</div>
								<div className="text-center pt-30">
									{this.props.loggingIn && <p>Now logging you in, please wait ...</p>}

									{this.props.loginFailed && <p className="alert alert-danger">There has been an error logging in, please check your username and password and try again. If that doesn't help, try <Link to={'/forgot-password'}>resetting your password</Link>.</p>}

									<button className="btn btn-cta btn-lg btn-mobile-block">Log In</button>

									<p className="lead text-center bt-1 mt-30 pt-30">Don&apos;t have an account with us yet?</p>
									<p className="text-center"><Link to="/get-started" className="btn btn-secondary btn-sm">Create an account</Link></p>
								</div>
							</form>
						</div>
					</div>
				</Modal.Body>
			</Modal>

		</>
  }

  componentDidUpdate () {
    if (this.props.isLoggedIn && this.state.showLogin === true) {
      this.setState({ showLogin: false }, () => {
        this.getPrice()
      })
    }
  }

  componentWillMount () {
    const context = this
    let token = localStorage.getItem('accessToken')
    const query = new URLSearchParams(this.props.location.search)
    if (query) {
      // auth
      if (query.get('offer')) { context.setState({ offer: true }) }

      if (!token) { token = query.get('t') }
    }

    context.setState({ token })
  }

  getPrice () {
    const context = this

    const url = `/api/AccessToken/GetPrice?t=${context.state.token}`
    const data = {
      Id: J80Id,
      Type: BasketItemType.hardware
    }

    fetchHelper.postJson(url, data)
      .then(res => {
        context.setState({ price: res.data })
      })
      .catch(err => console.error(err))
      .finally(() => {
        if (!context.state.price > 0 && !context.props.isLoggedIn) { this.setState({ showLogin: true }) }
      })
  }

  componentDidMount () {
    const context = this
    context.setState({ added: ((Math.random() * 18) + 2).toFixed(0) })

    if (!context.state.token && !context.props.isLoggedIn) {
      // Login
      this.setState({ showLogin: true })
    } else if (context.props.isLoggedIn) {
      this.setState({ showLogin: false })
    }

    context.getPrice()

    const purchased = localStorage.getItem('purchasedEarphones')
    if (purchased) {
      context.setState({ purchaseComplete: true })
      localStorage.removeItem('purchasedEarphones')
    }
  }

  handlePurchase (prodId, qty, amount) {
    const context = this
    const url = `/api/AccessToken/PurchaseHardware?t=${context.state.token}`
    const data = {
      DeliveryAddress: {
        Name: this.props.delivery.name && this.props.delivery.name.value,
        Add1: this.props.delivery.add1 && this.props.delivery.add1.value,
        Add2: this.props.delivery.add2 && this.props.delivery.add2.value,
        Add3: this.props.delivery.town && this.props.delivery.town.value,
        Add4: this.props.delivery.county && this.props.delivery.county.value,
        Pcode: this.props.delivery.pcode && this.props.delivery.pcode.value
      },
      HardwareDetails: [
        {
          ProductId: prodId,
          Qty: qty,
          Amount: amount
        }
      ]
    }

    context.setState({ isPurchasing: true }, () => {
      fetchHelper.postJson(url, data).then(res => {
        if (res.data && (res.data.success === true || res.data.isSuccess === true)) {
          context.setState({ showDeliveryInfo: false, purchaseComplete: true })
        } else if (res.data && res.data.postponed === true) {
          context.setState({ showDeliveryInfo: false, purchaseComplete: true }, () => {
            context.props.handleShowPaymentPostponedModal()
          })
        } else {
          localStorage.setItem('purchasedEarphones', true)
          context.setState({ showDeliveryInfo: false, wpAmount: res.data.amount, key: res.data.key, isPurchasing: false, clientId: res.data.clientId }, () => {
            context.props.handleShowPaymentOptionsModal()
          })
        }
      }).catch(function (error) {
        context.setState({ isPurchasing: false })
        console.error(error)
      })
    })
  }

  handleAddToDialler () {
    const context = this

    context.setState({ submittingToDialler: true }, () => {
      const url = `/api/AccessToken/SubmitClientToDialler?t=${context.state.token}&comments=Requested%20more%20information%20about%20%C2%A37%20per%20month%20outbound%20offer&listid=10500&campaign=4`
      fetchHelper.getJson(url)
        .then(res => {
          context.setState({ diallerSent: res.data, submittingToDialler: false })
        }).catch(err => console.error(err))
    })
  }
}

export default connect(
  state => {
    const deliveryData = state.sbfApp.deliveryData

    return {
      delivery: {
        name: deliveryData.deliveryName,
        add1: deliveryData.deliveryAdd1,
        add2: deliveryData.deliveryAdd2,
        town: deliveryData.deliveryTown,
        county: deliveryData.deliveryCounty,
        pcode: deliveryData.deliveryPcode
      },
      isLoggedIn: isLoggedIn(state),
      services: state.sbfApp.enabledServices,
      userDetails: state.sbfApp.authentication
    }
  },
  {
    notify: handleNotificationChange,
    handleShowCallbackForm,
    handleShowPaymentOptionsModal,
    handleShowPaymentPostponedModal
  }
)(Headphones)
