import React from 'react'
import Helmet from 'react-helmet'
import { connect } from 'react-redux'
import { Link, type RouteComponentProps } from 'react-router-dom'
import FeaturesList from '../../components/Competitors/FeaturesList'
import GetACallbackCta from '../../components/Competitors/GetACallbackCta'
import PageGetStartedCta from '../../components/Competitors/PageGetStartedCta'
import SideBarGetStartedCta from '../../components/Competitors/SideBarGetStartedCta'
import DisplayMainFooter from '../../components/DisplayMainFooter'
import DisplayMainHeader from '../../components/DisplayMainHeader'
import ScrollToTopOnMount from '../../components/ScrollToTopOnMount'
import { isLoggedIn } from '../../selectors'
import { handleShowCallbackForm } from '../../store/SBF'

interface bOnlineProps extends RouteComponentProps {
  handleShowCallbackForm: () => void
}

const bOnline = (props: bOnlineProps) => {
  return (
        <>
            <ScrollToTopOnMount />
            <DisplayMainFooter />
            <DisplayMainHeader />

            <Helmet>
                <title>bOnline vs SwitchboardFREE</title>
            </Helmet>
            <header style={{ backgroundImage: "url('/images/backgrounds/header-img.jpg')" }} className="full-width full-hero-small with-text">
                <h1>bOnline vs SwitchboardFREE</h1>
                <h2 className="subtitle">Compare the features of bOnline vs SwitchboardFREE</h2>
            </header>

            <div className="full-width bg-light pt-30 no-overflow">
                <div className="container">
                    <div className="row">
                        <div className="col-md-8">
                            <h2 className="text-500 text-darker text-xl mt-20 mb-30">Introduction</h2>
                            <p>bOnline has a great-looking, clear website with some very prominent accolades and strong customer reviews on display. This site shows you the upfront pricing and it’s very apparent from the outset what you’re getting. </p>
                            <p>However, you quickly find yourself unable to simply build your package yourself online and you’re directed to call a dedicated sales line. It turns out that there’s a very good reason why they want to talk to you. </p>
                            <p>Based on the <a href="https://uk.trustpilot.com/review/www.bonline.com?stars=1" target="_blank" id="TrustPilotReviews" rel="noreferrer">720, 1-star reviews on Trustpilot</a>, it appears that you’re given the hard sell and their overseas call centre will hound and pressure you until you eventually give in and commit to their service</p>
                            <p>bOnline like commitment so much, that based on some of the reviews, even leaving them seems to be an issue.</p>
                            <p>They’ll lock you into a minimum of a 12-month contract and in their <a href="https://assets.website-files.com/608a9a94f3d2f96ef8bbe85a/62e27428cbbc5d07c84fac09_(MASTER)%20Schedule%20of%20charges%20-%20July%202022.pdf" target="_blank" id="ScheduleOfCharges" rel="noreferrer">schedule of charges</a>, they also state that there’s £25 disconnection charge which applies even when you are out of contract. Some reviewers have corroborated this fact with some saying that they’ve been charged anywhere from £49 to £118 to disconnect the service!</p>
                            <p>Based on several other of these 1-star reviews, you may even get charged £2.49 a month for “fraud protection” which is also stated in their <a href="https://assets.website-files.com/608a9a94f3d2f96ef8bbe85a/62e27428cbbc5d07c84fac09_(MASTER)%20Schedule%20of%20charges%20-%20July%202022.pdf" target="_blank" id="ScheduleOfCharges" rel="noreferrer">schedule of charges</a> as well as an additional £5.00 per month if you don’t opt for Direct Debit. </p>

                            <PageGetStartedCta history={props.history} smallText="Experience the benefits of SwitchboardFREE" mainColouredText="Get started" mainText="today" />

                            <h2 className="text-500 text-darker text-xl mt-40 mb-30">Package Overview</h2>
                            <p>bOnline has many different offerings from phone systems and broadband to domains and emails. For the purpose of this comparison, we’ll focus on just the three main phone system packages as SwitchboardFREE deals only with business VoIP services:</p>

                            <p><span className="text-500 text-lg text-dark">bOnline Starter</span> (£6.00 plus VAT, per month, per user)</p>
                            <ul>
                                <li>Digital phone line and number</li>
                                <li>Unlimited inbound calls</li>
                                <li>100 outbound landline minutes</li>
                                <li>Mobile and desktop apps</li>
                                <li>Call forwarding</li>
                                <li>+40 more features</li>
                                <li>12-month minimum contract</li>
                            </ul>

                            <p><span className="text-500 text-lg text-dark">bOnline Unlimited Calling</span> (£13.95 plus VAT, per month, per user)</p>
                            <ul>
                                <li>As above plus Unlimited UK landline & mobile calls</li>
                            </ul>

                            <p><span className="text-500 text-lg text-dark">bOnline Unlimited Calling + Desk Phone</span> (£15.50 plus VAT, per month, per user)</p>
                            <ul>
                                <li>As above plus a Yealink T31P (Entry level phone)</li>
                            </ul>

                            <p>It’s worth noting that on their cheapest package (bOnline Starter), you don’t get access Call recording, Call reporting, or Call queues.</p>
                            <p>You may think this is the standard case of fewer features for less money, but these features are fundamental to any business phone system and are incidentally, the three most used features on our platform.</p>
                            <p>All of the SwitchboardFREE packages including the basic PAYG package gives you access to ALL of our features including Call recording, Call reporting, and Call queues.</p>

                            <PageGetStartedCta history={props.history} smallText="Take your business to the next level" mainColouredText="Get started" mainText="today" />

                            <h2 className="text-500 text-darker text-xl mt-40 mb-30">Package comparsion</h2>
                            <p>On the face of it, bOnline have very competitive prices and even including an entry-level desk phone, their Unlimited calling plan for £15.50 plus VAT a month looks very compelling.</p>
                            <p>In comparison, SwitchboardFREE also give you an included VoIP desk phone, plus a set of Bluetooth earbuds if you pay for the Bi-Annual Service.</p>
                            <p>If you factor in bOnline’s £25 disconnection fee, SwitchboardFree’s unlimited VoIP service is £1.58 cheaper per month and to get an included desk phone, you would only have to pay for 6 months of the service, not 12, and you’re still not tied into a contract, and you get a free set of Bluetooth earbuds.</p>
                            <p>All of SwitchboardFree’s inbound packages come with all these free features:</p>

                            <FeaturesList />

                            <PageGetStartedCta history={props.history} smallText="Transfer your number in as little as 7 days" mainColouredText="Get started" mainText="with your transfer today" />

                            <h2 className="text-500 text-darker text-xl mt-40 mb-30">Summary</h2>
                            <p>With prices being very comparable between bOnline and SwitchboardFREE, you’ll have to dig deep into the features to see what service suits you best, but 12 months is a long time to commit to a service provider especially if you make the wrong decision.</p>
                            <p>Based on our research, bOnline could very well be the “wrong decision” when you factor in the “stealth” charges as described in the reviews. When you take in to consideration the disconnection charge, possible fraud protection charge and the fee for not using Direct Debit, the real-world cost could be very different from the one advertised.</p>
                            <p>Also, SwitchboardFree’s support centre is based in the UK, not overseas, and you can talk to us on the phone whenever you need.</p>
                            <p>If you’re considering using bOnline for your VoIP service then please contact the friendly Welcome team at SwitchboardFREE via their contact page who will more than happy to give you a like-for-like comparison.</p>

                            <GetACallbackCta handleShowCallbackForm={props.handleShowCallbackForm} />

                            <p className="text-xs pt-30 mt-30 bt-1 mb-30">Please be advised that our findings were correct at the time of publication and we also suggest that you conduct your own research as features and prices may change over time. You can see bOnlines current offer and their terms and conditions by visiting their website <a className="text-dark underline" href="https://www.bonline.com" target="_blank" rel="noreferrer nofollow">here</a>.</p>

                            <div className="pt-30 pb-30 bt-1 mt-30 mb-30">
                                <Link className="btn btn-sm btn-default btn-xxs-block pl-10" to={'/competitors'}><span className="icon icon-arrow-left3 mr-5 text-sm" style={{ verticalAlign: 'base' }}></span>See all competitors</Link>
                            </div>
                        </div>

                        <div className="col-md-4">
                            <SideBarGetStartedCta mainColouredText="Get started" mainText="with SwitchboardFREE today" smallText="Discover the power of SwitchboardFREE for your business" history={props.history} />
                        </div>
                    </div>
                </div>
            </div>
        </>
  )
}

export default connect(
  state => {
    return ({
      isLoggedIn: isLoggedIn(state)
    })
  },
  {
    handleShowCallbackForm
  }
)(bOnline)
