import moment from 'moment'
import React from 'react'
import { Button, Modal } from 'react-bootstrap'
import Countdown from 'react-countdown'
import { Helmet } from 'react-helmet'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import Logos from '../../components/ClientLogos'
import DisplayMainFooter from '../../components/DisplayMainFooter'
import LoadingNotification from '../../components/LoadingNotification'
import ChoosePayment from '../../components/purchase/ChoosePayment'
import CarouselWidget from '../../components/Reviews/CarouselWidget'
import StatsBanner from '../../components/StatsBanner'
import { fetchHelper } from '../../helpers/fetchHelper'
import { calculateVat } from '../../helpers/utils'
import { isLoggedIn } from '../../selectors'
import * as fromHome from '../../store/Home'
import { handleNotificationChange, handleShowPaymentOptionsModal, handleShowPaymentPostponedModal } from '../../store/SBF'
import TermsAndConditions from '../../Text/TermsAndConditions'

class Headphones extends React.Component {
  state = {
    bundle: 0,
    bundles: [
      {
        calls: 10,
        price: 30.60,
        discount: 10
      },
      {
        calls: 20,
        price: 51,
        discount: 25
      },
      {
        calls: 40,
        price: 68,
        discount: 50
      }
    ]
  }

  constructor (props) {
    super(props)
  }

  render () {
    const renderCountdown = ({ days, hours, minutes, seconds, completed }) => {
      // Render a countdown
      return <>
				<p className="text-700 text-cta text-uppercase text-md mb-20">
					Ends in:
				</p>
				<div className="countdown-timer">
					<div className="timer-block">
						{days}<br /><span className="text-sm">days</span>
					</div>
					<div className="timer-block">
						{hours}<br /><span className="text-sm">hours</span>
					</div>
					<div className="timer-block">
						{minutes}<br /><span className="text-sm">minutes</span>
					</div>
					<div className="timer-block">
						{seconds}<br /><span className="text-sm">seconds</span>
					</div>
				</div>
			</>
    }

    return <><DisplayMainFooter />
			<Helmet>
				<body className={'body-simple bg-dull'} />
				<title>PAYG Call Answering Big-Bundle Discount!</title>
				<style type="text/css">{`
					.plan-options {
						transition: all .2s ease-in-out;
					}
					.plan-options .selected-plan{
						height:30px;
						width:30px;
						background-color:#ff9314;
						color:#fff;
						border-radius:50%;
						padding: 5px;
						position: absolute;
						top: 20px;
						left: 25px;
						display:none;
					}
					.plan-options .text-xl{
						font-size:60px !important;
						line-height:1;
					}
					.plan-options.active{
						border:1px solid #ff9314 !important;
					}
					.plan-options.active .selected-plan{
						display:block !important;
					}
					.plan-options:hover{
						cursor:pointer;
						border:1px solid #2c808b !important;

					}
					.main-body{
						background: #fff url('/images/campaigns/call-answering/header.png') no-repeat;
						background-position: 100% 100%;
						background-size: 1300px;
					}
					@media screen and (max-height:1100px) and (max-width:2000px) {
						.main-body{
							background-size: 900px;
						}
					}

					@media screen and (max-width:1920px) {
						.main-body{
							background-size: 900px;
						}
					}

					@media screen and (max-width:1500px) {
						.main-body{
							background-position: 130% 100%;
						}
					}
                    
                    `}</style>
			</Helmet>
			<div className="p-50 p-sm-30 p-xs-15 pt-xs-50 pt-100 pb-100 main-body" >
				<div className="container p-xs-0">
					<div className="row mb-50">
						<div className="w-1000px center-block text-center">
							<span className="bg-secondary p-10 pr-50 pl-50 pl-xs-10 pr-xs-10 text-light text-lg text-500 text-uppercase text-lightest text-xs-sm">
								Existing Customer Exclusive!
							</span>
							<h2 className="text-400 mt-30 text-dark">
								<em>Let Us Help Free Up Your Time</em>
							</h2>
							<h1 className="text-underline text-cta">
								Professional, Pay As You Go
								<br />
								Call Answering Service
							</h1>
							<h5 className="text-dark text-500 pt-20 text-italic">{moment().format('MMMM')} Only - DON&apos;T MISS OUT!</h5>
							<>
								<span className="p-30 bg-darker mb-20 d-inline-block center-block brad-100 mt-50">
									<Countdown date={moment().endOf('month').format('yyyy-MM-DDTHH:mm:ss')} renderer={renderCountdown} />
								</span>
								{/* <p className="text-sm mb-0 p-0">
									{this.state.added} people added <span className="text-500">Nuisance Call Blocker</span> in the last hour
								</p> */}
							</>
						</div>
					</div>
					<div className="row">
						<div className={'col-lg-7 col-md-12 col-sm-12'}>
							<div className="bg-dull p-50 p-sm-30 p-xs-20 brad-20 pr-100 pl-100 pr-sm-50 pl-sm-50 pr-xs-20 pl-xs-20 clearfix text-center">
								{this.state.diallerSent === true
								  ? <>
										<h3 className="text-500 mt-30">
											Thanks!
										</h3>
										<p className="text-400 lead mb-10 mt-0">We'll give you a call shortly.</p>
										<p className="text-md mt-30">
											We've successfully received your request and one of our team will call you shortly.
										</p>
										<p className="mt-30">
											<Button variant="cta" size="lg" className="pl-100 pr-100 pl-xs-20 pr-xs-20" onClick={() => this.props.history.push('/customer/dashboard')}>Go to my dashboard</Button>
										</p>
									</>
								  :									this.state.purchaseComplete === true
								    ? <>
											<h3 className="text-500 mt-30">
												Thanks!
											</h3>
											<p className="text-400 lead mb-10 mt-0">Your order is complete.</p>
											<p className="text-md mt-30">
												We've added {this.state.bundles[this.state.bundle].calls} calls to your account.
											</p>
											<p className="mt-30">
												<Button variant="cta" size="lg" className="pl-100 pr-100 pl-xs-20 pr-xs-20" onClick={() => this.props.history.push('/customer/dashboard')}>Go to my dashboard</Button>
											</p>
										</>
								    :										this.state.showError === true
								      ? <>
												<h3 className="text-500 mt-30">
													Oops..
												</h3>
												<p className="text-400 lead mb-10 mt-0">Sorry, something went wrong.</p>
												<p className="text-md mt-30">
													Something didn't go to plan when adding your calls.
													Please contact our support team on <a href="tel:02031891213" className="text-500 text-dark">0203 189 1213</a> or, <Button variant="link" className="text-dark" onClick={() => this.handleAddToDialler()}>request a callback</Button>
												</p>
											</>
								      :										<>
											<div className="row">
												<div className="col-md-12">
													<>
														<h2 className="text-700 mt-0 text-italic text-cta">
															Our Call Answering
															<br />
															Big-Bundle Discount!
														</h2>
														<p className="text-dark text-lg text-400">
															Grab any call-answering bundle below &amp; let us help free up your time!
														</p>
														<p>
															<a onClick={() => this.setState({ showMoreInfo: true })} className="text-secondary underline text-500">Find out more</a>
														</p>
														<p className="text-400 underline text-uppercase pt-40 pb-20">
															Select a call bundle below:
														</p>
														<div className="row">
															<div className="col-xxs-12 col-xs-4 text-center ">
																<div className={`box-shadow b-1 brad-20 bg-light plan-options p-20 mt-10 ${this.state.bundle === 0 ? 'active' : ''}`} onClick={() => this.setState({ bundle: 0 })}>
																	<span className="selected-plan">
																		<span className="icon icon-checkmark4 text-lightest"></span>
																	</span>
																	<span className="text-secondary text-500 d-block text-xl">{this.state.bundles[0].calls}</span>
																	<span className="text-secondary text-400 text-dark text-uppercase d-block text-md">calls</span>
																	<span className="text-cta text-500 text-uppercase d-block text-md">Save {this.state.bundles[0].discount}%</span>
																	<span className="text-dark text-500 text-uppercase d-block">&pound;{this.state.bundles[0].price.toFixed(2)}</span>
																	<span className="d-block text-sm">
																		<strike className="text-dullest">£3.40</strike> - &pound;{parseFloat(this.state.bundles[0].price / this.state.bundles[0].calls).toFixed(2)}/call
																	</span>
																</div>
															</div>
															<div className="col-xxs-12 col-xs-4 text-center ">
																<div className={`box-shadow b-1 brad-20 bg-light plan-options p-20 mt-10 ${this.state.bundle === 1 ? 'active' : ''}`} onClick={() => this.setState({ bundle: 1 })}>
																	<span className="selected-plan">
																		<span className="icon icon-checkmark4 text-lightest"></span>
																	</span>
																	<span className="text-secondary text-500 d-block text-xl">{this.state.bundles[1].calls}</span>
																	<span className="text-secondary text-400 text-dark text-uppercase d-block text-md">calls</span>
																	<span className="text-cta text-500 text-uppercase d-block text-md">Save {this.state.bundles[1].discount}%</span>
																	<span className="text-dark text-500 text-uppercase d-block">&pound;{this.state.bundles[1].price.toFixed(2)}</span>
																	<span className="d-block text-sm">
																		<strike className="text-dullest">£3.40</strike> - &pound;{parseFloat(this.state.bundles[1].price / this.state.bundles[1].calls).toFixed(2)}/call
																	</span>
																</div>
															</div>
															<div className="col-xxs-12 col-xs-4 text-center ">
																<div className={`box-shadow b-1 brad-20 bg-light plan-options p-20 mt-10 ${this.state.bundle === 2 ? 'active' : ''}`} onClick={() => this.setState({ bundle: 2 })}>
																	<span className="selected-plan">
																		<span className="icon icon-checkmark4 text-lightest"></span>
																	</span>
																	<span className="text-secondary text-500 d-block text-xl">{this.state.bundles[2].calls}</span>
																	<span className="text-secondary text-400 text-dark text-uppercase d-block text-md">calls</span>
																	<span className="text-cta text-500 text-uppercase d-block text-md">Save {this.state.bundles[2].discount}%</span>
																	<span className="text-dark text-500 text-uppercase d-block">&pound;{this.state.bundles[2].price.toFixed(2)}</span>
																	<span className="d-block text-sm">
																		<strike className="text-dullest">£3.40</strike> - &pound;{parseFloat(this.state.bundles[2].price / this.state.bundles[2].calls).toFixed(2)}/call
																	</span>
																</div>
															</div>
														</div>
													</>

												</div>
											</div>
											{(this.state.submittingToDialler || this.state.isPurchaseInProgress)
											  ? <div className="mt-30">
													<LoadingNotification loadingText={'Please wait...'} textColor="text-dark" isLoading={true} width={50} height={50} />
												</div>
											  :												<>
													<Button variant="cta" size="lg" className="pl-100 pr-100 pl-xs-20 pr-xs-20 btn-block brad-10 mt-30" onClick={() => this.handlePurchase()}>Add to account NOW</Button>
													{this.state.diallerSent === false &&
														<p className="text-400 text-danger text-center pt-20">
															Sorry, there was a problem submitting your request, please try again.
														</p>
													}
													<p className="text-xs text-dark pt-20">
														By clicking on the button above you will purchase  the selected bundle of calls and will be blled accordingly. To complete the process, enter your company name and caller greeting in your number setup and let us do the rest!
													</p>
													<p className="text-dark pt-30">
														Want to know more? Give us a call on <a href="tel:02031891213" className="text-500 text-dark">0203 189 1213</a> or, <Button variant="link" className="text-dark" onClick={() => this.handleAddToDialler()}>request a callback</Button>
													</p>
												</>
											}
										</>
								}
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className="bg-dull p-50 text-center">
				<sup>*</sup> Standard terms &amp; conditions apply. <Button variant="link" className="text-dark underline" onClick={() => this.setState({ showTsAndCs: true })}>Click here to find out more</Button>
			</div>

			<div className="bg-light pt-50 pb-50">
				<h3 className="text-center text-500 mb-20 text-lg pl-25 pr-25">Proud to work with leading UK businesses</h3>
				<Logos style="light" />
			</div>

			<div className="full-width bg-dull">
				<div className="container pl-xs-0 pr-xs-0">
					<CarouselWidget />
				</div>
			</div>

			<div className="full-width full-secondary p-20">
				<div className="row">
					<div className="col-md-12">
						<h3 className="text-center text-lightest mt-10 text-100 text-xl">
							<StatsBanner />
						</h3>
					</div>
				</div>
			</div>

			<div className="full-width bg-light">
				<div className="container">
					<div className="row">
						<div className="col-md-12 text-center">
							<h3 className="text-500">SwitchboardFREE</h3>
							<h6 className="text-400">The UK's favourite call management system</h6>
							<p className="text-400">Instant activation  &bull;  No long-term contracts  &bull;  Award-winning UK support</p>
						</div>
					</div>
				</div>
			</div>

			<Modal size="w-1000px" show={this.state.showMoreInfo} onHide={e => this.setState({ showMoreInfo: false })}>
				{/* <Modal.Header closeButton>
					<Modal.Title className="text-500">xxxx</Modal.Title>
				</Modal.Header> */}
				<Modal.Body className="bt-0">
					<span className="close-modal" onClick={() => this.setState({ showMoreInfo: false })}>
						<span>&times;</span>
					</span>
					<div className="col-md-8">
						<h2 className="text-cta text-500 pb-30">Live Call Answering</h2>
						<p className="text-400">
							With SwitchboardFREE Live Call Answering you’ll never miss a call again.
						</p>
						<p>
							We can answer all of your calls or just the ones you miss, so you can focus on what you need to and reply back at a time that best suits you.
						</p>
						<p>
							All calls are answered promptly and professionally, and we’ll email you all the details you need (i.e the caller’s name, phone number, reason for the call, a short message, time and date of the call).
						</p>
						<p>
							Try today and see how we can save you time and help your business grow.
						</p>
						<p className="text-primary text-italic text-500 pt-30">
							Features:
						</p>
						<ul className="unstyled cta text-400 ml-xs-0 ml-0 pl-0 mt-30">
							<li className="mb-5"><i className="icon icon-checkmark4 text-cta mr-5"></i><em>Your very own team of experienced, dedicated receptionists</em></li>
							<li className="mb-5"><i className="icon icon-checkmark4 text-cta mr-5"></i><em>Instant set up  &amp; activated in seconds</em></li>
							<li className="mb-5"><i className="icon icon-checkmark4 text-cta mr-5"></i><em>Versatile options to answer all your calls or overflow them only</em></li>
							<li className="mb-5"><i className="icon icon-checkmark4 text-cta mr-5"></i><em>Complete flexibility to turn ON and OFF at will</em></li>
							<li className="mb-5"><i className="icon icon-checkmark4 text-cta mr-5"></i><em>Zero commitment, with no monthly fees - simply pay as you go</em></li>
						</ul>
						<p className="text-dullest text-500 underline text-center pt-40 pb-40 text-sm cursor-pointer" onClick={e => this.setState({ showMoreInfo: false })}>
							Return to bundles
						</p>
					</div>
					<div className="col-md-4">
						<div className="bg-dull p-20 brad-20">
							<p className="text-500 text-uppercase text-center">
								Select a bundle now:
							</p>
							<div className="row">
								<div className="col-xs-12 text-center ">
									<div className={`box-shadow b-1 brad-20 bg-light plan-options p-20 mt-10 ${this.state.bundle === 0 ? 'active' : ''}`} onClick={() => this.setState({ bundle: 0 })}>
										<span className="selected-plan">
											<span className="icon icon-checkmark4 text-lightest"></span>
										</span>
										<span className="text-secondary text-500 d-block text-xl">10</span>
										<span className="text-secondary text-400 text-dark text-uppercase d-block text-md">calls</span>
										<span className="text-cta text-500 text-uppercase d-block text-md">Save 10%</span>
										<span className="text-dark text-500 text-uppercase d-block">&pound;XX.XX</span>
										<span className="d-block text-sm">
											<strike className="text-dullest">£3.40</strike> - £X.XX /call
										</span>
									</div>
								</div>
								<div className="col-xs-12 text-center ">
									<div className={`box-shadow b-1 brad-20 bg-light plan-options p-20 mt-10 ${this.state.bundle === 1 ? 'active' : ''}`} onClick={() => this.setState({ bundle: 1 }) }>
										<span className="selected-plan">
											<span className="icon icon-checkmark4 text-lightest"></span>
										</span>
										<span className="text-secondary text-500 d-block text-xl">20</span>
										<span className="text-secondary text-400 text-dark text-uppercase d-block text-md">calls</span>
										<span className="text-cta text-500 text-uppercase d-block text-md">Save 25%</span>
										<span className="text-dark text-500 text-uppercase d-block">&pound;XX.XX</span>
										<span className="d-block text-sm">
											<strike className="text-dullest">£3.40</strike> - £X.XX /call
										</span>
									</div>
								</div>
								<div className="col-xs-12 text-center ">
									<div className={`box-shadow b-1 brad-20 bg-light plan-options p-20 mt-10 ${this.state.bundle === 2 ? 'active' : ''}`} onClick={() => this.setState({ bundle: 2 })}>
										<span className="selected-plan">
											<span className="icon icon-checkmark4 text-lightest"></span>
										</span>
										<span className="text-secondary text-500 d-block text-xl">30</span>
										<span className="text-secondary text-400 text-dark text-uppercase d-block text-md">calls</span>
										<span className="text-cta text-500 text-uppercase d-block text-md">Save 50%</span>
										<span className="text-dark text-500 text-uppercase d-block">&pound;XX.XX</span>
										<span className="d-block text-sm">
											<strike className="text-dullest">£3.40</strike> - £X.XX /call
										</span>
									</div>
								</div>
							</div>
							<Button size="lg" variant="cta" className="mt-20 btn-block">Order now</Button>
						</div>
						<p className="text-xs text-dullest pt-20">
							SwitchboardFREE call answering is available between 8:00 - 22:00 Mon- Fri and Sat &amp; Sun 9:00 - 17:00 (including Bank Holidays). After your budle has run out calls will revert to the standard price of £3.40 /call.
							There are no extra charges,  and any call we take will come off your existing call credit. I&apos;s really that simple.
						</p>
					</div>

				</Modal.Body>
				<Modal.Footer className="bt-0">
					{/* <Button variant="primary" onClick={e => this.setState({ showMoreInfo: false })}>Close</Button> */}
				</Modal.Footer>
			</Modal>

			<Modal className="terms-modal" show={this.state.showTsAndCs} onHide={e => this.setState({ showTsAndCs: false })}>
				<Modal.Header closeButton>
					<Modal.Title className="text-500">Terms &amp; Conditions</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<TermsAndConditions />
				</Modal.Body>
				<Modal.Footer>
					<Button variant="primary" onClick={e => this.setState({ showTsAndCs: false })}>Close</Button>
				</Modal.Footer>
			</Modal>

			<ChoosePayment
				total={this.state.wpAmount ? calculateVat(this.state.wpAmount) : 0}
				clientId={this.state.clientId}
				purchaseData={this.state.key}
				returnRoute={`/get/call-answering-bundle?t=${this.state.token}`}
				email={this.props.userDetails.email}
				name={`${this.props.userDetails.fName} ${this.props.userDetails.sName}`}
				mandate={!this.props.services.hasAgreement}
			/>

			<Modal size="sm" show={this.state.showLogin}>
				<Modal.Header>
					<Modal.Title className="mx-auto">Log into your account</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div className="row">
						<div className="col-md-12 text-center pb-30">
							<form name="form" className="text-left" onSubmit={e => this.props.login({
							  userName: this.props.userName,
							  password: this.props.password,
							  event: e,
							  history: this.props.history,
							  persist: document.getElementById('persistLogin').checked,
							  route: '/get/call-answering-bundle'
							})}>
								<div className="row">
									<div className="col-xs-12">
										<p className="lead text-400">Oops, it looks like your token has expired, or is not valid. Please log into your account below.</p>
									</div>
									<div className="col-xs-12">
										<label>Email</label>
										<input className="form-control" placeholder="Your login email..." type='email' value={this.props.userName} onChange={e => this.props.updateUsername(e.target.value)} />
									</div>
									<div className="col-xs-12 mt-20">
										<label>Password</label>
										<input className="form-control" placeholder="Your password..." type='password' value={this.props.password} onChange={e => this.props.updatePassword(e.target.value)} />
									</div>
								</div>
								<div className="pt-10 pb-10 row">
									<div className="col-xs-6 text-start">
										<input type="checkbox" id='persistLogin' defaultChecked={true} /> <label>Remember</label>
									</div>
									<div className="col-xs-6 text-end">
										<Link to={'/forgot-password'} className="text-xs text-cta">Forgot password?</Link>
									</div>
								</div>
								<div className="text-center pt-30">
									{this.props.loggingIn && <p>Now logging you in, please wait ...</p>}

									{this.props.loginFailed && <p className="alert alert-danger">There has been an error logging in, please check your username and password and try again. If that doesn't help, try <Link to={'/forgot-password'}>resetting your password</Link>.</p>}

									<button className="btn btn-cta btn-lg btn-mobile-block">Log In</button>

									<p className="lead text-center bt-1 mt-30 pt-30">Don&apos;t have an account with us yet?</p>
									<p className="text-center"><Link to="/get-started" className="btn btn-secondary btn-sm">Create an account</Link></p>
								</div>
							</form>
						</div>
					</div>
				</Modal.Body>
			</Modal>

		</>
  }

  componentDidUpdate () {
    if (this.props.isLoggedIn && this.state.showLogin === true) {
      this.setState({ showLogin: false })
    }
  }

  componentWillMount () {
    const context = this
    let token = localStorage.getItem('accessToken')
    const query = new URLSearchParams(this.props.location.search)
    if (!token && query) {
      // auth
      token = query.get('t')
    }

    context.setState({ token })
  }

  componentDidMount () {
    const context = this
    if (!context.state.token && !this.props.isLoggedIn) {
      // Login
      this.setState({ showLogin: true })
    } else if (context.props.isLoggedIn) {
      this.setState({ showLogin: false })
    }

    const query = new URLSearchParams(this.props.location.search)
    if (query) {
      if (query.get('success') || query.get('new')) { context.handleAddCallsToAccount() }
    }
  }

  handlePurchase () {
    const context = this
    const url = `/api/AccessToken/PurchasePrLiteCalls?t=${context.state.token}`

    context.setState({ isPurchaseInProgress: true }, () => {
      fetchHelper.postJson(url, { Bundle: context.state.bundle }).then(res => {
        if (res.data && (res.data.success === true || res.data.isSuccess === true)) {
          context.setState({ purchaseComplete: true })
        } else if (res.data && res.data.postponed === true) {
          context.setState({ purchaseComplete: true }, () => {
            context.props.handleShowPaymentPostponedModal()
          })
        } else {
          context.setState({ wpAmount: res.data.amount, key: res.data.key, isPurchaseInProgress: false, clientId: res.clientId }, () => {
            localStorage.setItem('callsToAdd', this.state.bundles[this.state.bundle].calls)
            context.props.handleShowPaymentOptionsModal()
          })
        }
      }).catch(function (error) {
        context.setState({ isPurchaseInProgress: false })
        console.error(error)
      })
    })
  }

  handleAddCallsToAccount () {
    const context = this
    const bundle = localStorage.getItem('callsToAdd')
    const url = `/api/AccessToken/AddPrLiteCallsToCustomer?t=${context.state.token}`

    context.setState({ bundle }, () => {
      fetchHelper.postJson(url, { Bundle: bundle })
        .then(res => {
          if (res.data && res.data === true) {
            context.setState({ purchaseComplete: true })
            localStorage.removeItem('callsToAdd')
          } else {
            context.setState({ showError: true })
          }
        }).catch(err => console.error(err))
    })
  }

  handleAddToDialler () {
    const context = this

    context.setState({ submittingToDialler: true }, () => {
      const url = `/api/AccessToken/SubmitClientToDialler?t=${context.state.token}&comments=Requested%20more%20information%20about%20%C2%A37%20per%20month%20outbound%20offer&listid=10500&campaign=4`
      fetchHelper.getJson(url)
        .then(res => {
          context.setState({ diallerSent: res.data, submittingToDialler: false })
        }).catch(err => console.error(err))
    })
  }
}

export default connect(
  state => {
    return {
      isLoggedIn: isLoggedIn(state),
      services: state.sbfApp.enabledServices,
      userName: state.home.login.username,
      password: state.home.login.password,
      loggingIn: state.home.login.loggingIn,
      loginFailed: state.home.login.loginFailed,
      userDetails: state.sbfApp.authentication
    }
  },
  {
    login: fromHome.handleLogin,
    updateUsername: fromHome.updateUsername,
    updatePassword: fromHome.updatePassword,
    notify: handleNotificationChange,
    handleShowPaymentOptionsModal,
    handleShowPaymentPostponedModal
  }
)(Headphones)
