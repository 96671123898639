import React, { useEffect, useRef } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { Helmet } from 'react-helmet'
import { type RouteComponentProps } from 'react-router-dom'
import DisplayMainHeader from '../../components/DisplayMainHeader'
import DisplayNoFooter from '../../components/DisplayNoFooter'
import DisplaySimpleHeaderV2 from '../../components/DisplaySimpleHeaderV2'
import Features from '../../components/Features/Features'
import ProgressSteps from '../../components/ProgressSteps'
import ScrollToTopOnMount from '../../components/ScrollToTopOnMount'
import SelectPackage from '../../components/SelectPackage'
import VoiPTerms from '../../components/VoiPTerms'
import {
  AppOnlyPrice,
  AppOnlyPrice0800, is0800Offer,
  productBillingOption,
  ProductIds,
  type SignupStep
} from '../../constants/Constants'
import { fetchHelper } from '../../helpers/fetchHelper'
import LocalStorageHelper from '../../helpers/LocalStorageHelper'
import { type EndpointPrice, type ProductPriceDto } from '../../models/GenericTypes'
import { SbfPackage, type Basket } from '../../models/SignupTypes'

const _ = require('lodash')

export interface ChoosePlanState {
  isSignup: boolean
  loggedIn: boolean
  showInbound: boolean
  redirectToLandline: boolean
  redirectToMobile: boolean
  productDetails: ProductPriceDto[]
  showFeatures: boolean
  showBoltOnModal: boolean
  showOutbound: boolean
  userQty: number
  showUserInfo: boolean
}

export interface ChoosePlanProps extends RouteComponentProps {
  isLoggedIn: boolean
  currentBasket: Basket
  handleSetPackage: (selectedPackage?: SbfPackage) => void
  // handleAddToBasket: (item: SbfPackage, itemType: BasketItemType) => void;
  handleContinue: (pack?: SbfPackage) => void
  handleRemoveBoltOn: () => void
  handleBack: (step?: SignupStep) => void
  prefix: string
}

const scrollToRef = (ref) => {
  window.scrollTo(0, ref.current.offsetTop)
}

const ChoosePlan: React.FunctionComponent<ChoosePlanProps> = (props) => {
  function getCanProceedToCheckout (): boolean {
    const isInboundOnly = !props.currentBasket.isOutbound
    const hasSelectedPackage = props.currentBasket.package
    return isInboundOnly || hasSelectedPackage !== null
  }

  const canCheckout = getCanProceedToCheckout()
  const isOutbound = props.currentBasket.isOutbound && !props.isLoggedIn
  const isInboundOnly = !isOutbound

  const [state, setState] = React.useState<ChoosePlanState>({
    showBoltOnModal: false,
    showFeatures: false,
    showOutbound: false,
    isSignup: false,
    loggedIn: false,
    productDetails: [],
    redirectToLandline: false,
    redirectToMobile: true,
    showInbound: isInboundOnly,
    userQty: 1,
    showUserInfo: false
  })

  useEffect(() => {
    window.dataLayer.push({
      event: 'signupPlan'
    })

    getProdDetails(
      ProductIds.endpoint,
      productBillingOption.endpointBillingOptionMonthly
    )
  }, [])

  const termsRef = useRef(null)
  const scrollToTerms = () => { scrollToRef(termsRef) }

  let isUnlimited = false

  function getProdDetails (productId: number, monthlyOption: number) {
    let getPriceUrl = `api/Purchase/GetProductPrice?prodId=${productId}`

    if (monthlyOption) getPriceUrl += `&monthlyOptionId=${monthlyOption}`

    fetchHelper
      .getJson<ProductPriceDto>(getPriceUrl)
      .then((res) => {
        setState({
          ...state,
          productDetails: [...state.productDetails, res.data]
        })
      })
      .catch((err) => {
        console.error(err)
      })
  }

  if (
    (props.currentBasket.package !== null && props.currentBasket.package !== undefined) &&
		props.currentBasket.package.rental &&
		props.currentBasket.package.name.toLowerCase().includes('unlimited') &&
		!props.currentBasket.numbers[0].number.startsWith('084')
  ) { isUnlimited = true }

  const isNewCustomer = LocalStorageHelper.getItem<boolean>('isNewCustomer')?.value
	const isInboundSelected = isInboundOnly && !props.isLoggedIn

	let prefix = props.prefix;
	if (props.currentBasket && props.currentBasket.numbers.length > 0) {
		prefix = props.prefix === '' ?
			(props.currentBasket.numbers[0].number.startsWith('08') ||
				props.currentBasket.numbers[0].number.startsWith('020')) ? props.currentBasket.numbers[0].number.substring(0, 4) : props.currentBasket.numbers[0].number.substring(0, 2) : props.prefix;

	}

  function renderSelectPackage () {
    return (
			<SelectPackage
				prefix={prefix}
				isSignup={(props.location.pathname.includes('get-started') || isNewCustomer === true) && !props.isLoggedIn}
				history={props.history}
				userQty={state.userQty}	
				onClickSmallPrint={scrollToTerms}
				loggedIn={props.isLoggedIn}
				showInbound={isInboundSelected}
				showFeaturesModal={() => { setState({ ...state, showFeatures: true }) }}
				is99pOffer={false}
				isUpgradeModal={false}
				preventDefaultSelection={false}
				isBasic={false}
				isModal={false}
				noParentRowClass={false}
				location={props.location}
				match={props.match}
				continueWithoutBoltOn={() => {
				  const pack = new SbfPackage(
				    'App Only Plan',
				    0,
				    prefix.startsWith('080') && is0800Offer ? AppOnlyPrice0800 : AppOnlyPrice,
				    prefix.startsWith('080') ? AppOnlyPrice0800 : AppOnlyPrice,
				    0,
				    0,
				    prefix,
				    {
				      qty: 1,
				      monthlyPrice: 0
				    }
				  )

				  props.handleContinue(pack)
				}}
				currentBasket={props.currentBasket}
				handleSelectPackage={function (packData: SbfPackage): void {
				  props.handleSetPackage(packData)
				}}
				handleRemoveBoltOn={function (): void {
				  props.handleRemoveBoltOn()
				}}
			/>
    )
  }

  function renderFeatures () {
    return (
			<div className="col-md-12 mt-50 mb-50 clearfix" id="features">
				<div className="p-50 brad-30 bg-light box-shadow shadow-lighter p-xs-20 pt-xs-40">
					<h3 className="text-500 text-center mb-50 underline underline-cta">
						Included features
					</h3>
					<Features displayMain={true} limit={3} nobg={true} />
				</div>
			</div>
    )
  }

  function renderUsers () {
    return (
			<div className="pl-25 pr-25 pl-xs-20 pr-xs-20">
				<div className="col-md-12 text-center fixed-container clearfix">
					<div className="p-30 bg-light mb-xxs-60 box-shadow shadow-lighter brad-30 p-xs-20 mb-60 text-start extra-users">
						<div className="row">
							<div className="col-md-7">
								<h5 className="text-500 pt-0 mt-0 mb-0">
									<span className="text-cta text-xs-md">Add users/extensions</span><br />
									<span className="underline text-xxs text-primary cursor-pointer d-inline-block float-start " onClick={() => { setState({ ...state, showUserInfo: true }) }}>Learn more</span>
								</h5>
							</div>
							<div className="col-md-5 text-end text-xs-center mt-xs-20">
								<form className="qty mt-0">
									<input
										type="button"
										className="btn btn-dull no-shadow"
										onClick={() => { setState({ ...state, userQty: state.userQty - 1 }) }}
										value="-"
										disabled={state.userQty === 1}
									/>
									<input
										type="text"
										id="number"
										className="form-control"
										value={state.userQty}
									/>
									<input
										type="button"
										className="btn btn-dull no-shadow"
										onClick={() => { setState({ ...state, userQty: state.userQty + 1 }) }}
										value="+"
									/>
								</form>
							</div>
						</div>
					</div>
				</div>
			</div>
    )
  }

  function renderTermsAndConditions () {
    return (
			<div id="terms" ref={termsRef} className="mt-200 mt-xs-30">
				<p className="text-500">Terms &amp; conditions</p>

				<VoiPTerms />
			</div>
    )
  }

  return (
		<>
			<ScrollToTopOnMount />
			<DisplayNoFooter />
			<Helmet>
				<body
					className={'body-simple bg-dull body-signup body-plans step3-select-plan'}
				/>
			</Helmet>
			{props.isLoggedIn
			  ? (
				<>
					<DisplayMainHeader />
				</>
			    )
			  : (
				<>
					<DisplaySimpleHeaderV2 />
				</>
			    )}
			<div className="full-width progress-container">
				<div className="row">
					<div className="col-md-12 text-center pl-0 pr-0" id="start">
						<ProgressSteps
							current={2}
							steps={props.isLoggedIn ? 3 : 4}
							history={props.history}
							loggedIn={props.isLoggedIn}
							changeStep={step => { props.handleBack(step) }}
						/>
					</div>
				</div>
			</div>
			<div className="full-width">
				<div className="container">
					{(
						<div className="row">
							<div className="col-md-12 text-center fixed-container pb-20">
								<h3 className="text-500 mt-0">Choose your perfect plan</h3>
								<p>Select your perfect package below.</p>
							</div>

							{(isOutbound || props.isLoggedIn) && renderUsers()}

							{renderSelectPackage()}

							<p className="text-sm cursor-pointer d-inline-block pt-20 text-center" onClick={() => {
							  props.handleBack()
							}}>
								<span className="icon icon-arrow-left4 mr-3 text-xs"></span> <span className="underline" >Go back to previous step</span>
							</p>

							{renderFeatures()}

						</div>
					)}

					{isOutbound &&
						prefix &&
						renderTermsAndConditions()}
				</div>
			</div>

			<Modal
				show={state.showUserInfo}
				onHide={() => { setState({ ...state, showUserInfo: false }) }}
			>
				<Modal.Header closeButton>
					<Modal.Title>Learn more about a user</Modal.Title>
				</Modal.Header>
				<Modal.Body className="text-md">
					<p>A user, or an extension, enables you to transfer calls to an individual person. Each user can make & receive calls independently and can have their own desktop phone, softphone on PC/laptop and even a mobile app.</p>
					<p className="text-secondary text-500">Each user includes...</p>
					<ul className="tick-list text-start mt-0 ml-30 pl-0">
						<li>1 extension number, free calling app and softphone license</li>
						<li>Unlimited calls and transfers between user extensions</li>
						<li>Display any of your numbers when making an outgoing call</li>
						<li>Ability to make and receive calls anywhere in the world</li>
						<li>Plug & Play technology enables instant setup for home & remote workers</li>
						<li>Each user can be part of a ring group, allowing a team of individuals to answer inbound calls</li>
						<li>Free earbuds &amp; desk phone - <span className="text-cta">Worth &pound;90</span><br /><span className="text-dullest">(With selected plans only)</span></li>
					</ul>
				</Modal.Body>
			</Modal>

		</>
  )
}
export default ChoosePlan
